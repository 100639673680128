import { useNavigate } from "react-router-dom";
import VarifiedIcon from "./VarifiedIcon";
import {
  getWalletProfileImage,
  getWalletProfileName,
} from "../../../utils/commonUtils/WalletDetailsUtils";
import useIsMobile from "../../../customHooks/UseIsMobile";

export default function SearchResultOpenseaUser({
  item,
  index,
  isSearching,
  handleImgErr,
  toggleSearchResult,
  userType,
}) {
  const navigate = useNavigate();
  const isMobile = useIsMobile();

  return (
    <>
      {!isMobile ? (
        <button
          key={index}
          className="d-flex flex-column border-0 p-0 search-result-hover"
          style={{
            overflow: "hidden",
            width: "90%",
            height: "6vw",
            borderRadius: "0.7vw",
            marginBottom: "0.5vw",
          }}
          onClick={() => {
            userType === "creator"
              ? navigate(`/creator/${item.walletAddress}`)
              : navigate(`/wallet_report/${item.walletAddress}`);
            toggleSearchResult();
          }}
        >
          {isSearching && (
            <div className="w-100 h-100 loading-background"></div>
          )}
          {!isSearching && (
            <div className="d-flex w-100 h-100">
              <div>
                <img
                  src={getWalletProfileImage(item)}
                  style={{ height: "100%", width: "6vw", objectFit: "cover" }}
                  onError={handleImgErr}
                />
              </div>
              <div
                className="d-flex align-items-center w-100"
                style={{ backgroundColor: "rgba(255,255,255,0)" }}
              >
                <div
                  style={{
                    margin: "1vw 1vw 1vw 2vw",
                    fontSize: "1vw",
                    fontFamily: "Lato",
                    fontWeight: "bold",
                  }}
                >
                  {/* {getWalletProfileName(item)} */}
                  {getWalletProfileName(item)}
                </div>
              </div>
              <div
                style={{
                  backgroundColor: "rgba(255,255,255,0)",
                  marginTop: "0.2vw",
                  marginRight: "0.2vw",
                }}
              >
                <VarifiedIcon varified={item.varified} />
              </div>
            </div>
          )}
        </button>
      ) : (
        <button
          key={index}
          className="d-flex flex-column border-0 p-0 search-result-hover"
          style={{
            overflow: "hidden",
            width: "98%",
            height: "10vw",
            borderRadius: "2vw",
            marginBottom: "1vw",
          }}
          onClick={() => {
            userType === "creator"
              ? navigate(`/creator/${item.walletAddress}`)
              : navigate(`/wallet_report/${item.walletAddress}`);
            toggleSearchResult();
          }}
        >
          {isSearching && (
            <div className="w-100 h-100 loading-background"></div>
          )}
          {!isSearching && (
            <div className="d-flex w-100 h-100">
              <div>
                <img
                  src={getWalletProfileImage(item)}
                  style={{ height: "100%", width: "10vw", objectFit: "cover" }}
                  onError={handleImgErr}
                />
              </div>
              <div
                className="d-flex align-items-center w-100"
                style={{ backgroundColor: "rgba(255,255,255,0)" }}
              >
                <div
                  style={{
                    margin: "1vw 1vw 1vw 2vw",
                    fontSize: "2.5vw",
                    fontFamily: "Lato",
                  }}
                >
                  {/* {getWalletProfileName(item)} */}
                  {getWalletProfileName(item)}
                </div>
              </div>
              <div
                style={{
                  backgroundColor: "rgba(255,255,255,0)",
                  marginTop: "0.2vw",
                  marginRight: "0.2vw",
                }}
              >
                <VarifiedIcon varified={item.varified} />
              </div>
            </div>
          )}
        </button>
      )}
    </>
  );
}
