import React, { useContext, useEffect, useRef, useState } from "react";
import ArrowIconReverse from "../../Icons/ArrowIconReverse";
import useIsMobile from "../../customHooks/UseIsMobile";
import DropdownElement from "../Tools/DropdownElement";
import { CollectionFilterSectionContext } from "../../contexts/CollectionPage/FilterSectionContext";
import { setCollectionPageFiltersTokenSorting } from "../../actions/CollectionPage/filterSectionActions";
import { CreatorFilterSectionContext } from "../../contexts/CreatorPage.js/FilterSectionContext";
import {
  setCreatorPageFiltersActivityStatus,
  setCreatorPageFiltersCreatedTokenSorting,
  setCreatorPageFiltersMintedTokenSorting,
  setCreatorPageFiltersOwnedTokenSorting,
} from "../../actions/CreatorPage/filterSectionActions";
import { FolderFilterSectionContext } from "../../contexts/FolderPage/FolderFilterSectionContext";
import { setFolderPageFiltersTokenSorting } from "../../actions/folderFilterSectionActions";
import CustomDropdown from "../FoldersPage/CustomDropDown";
import { useParams } from "react-router-dom";

export let tokenGridSortingFilters = {
  "mintTimestamp_-1": {
    key: "mintTimestamp_-1",
    text: "Date Minted: Newest to Oldest",
    value: "mintTimestamp_-1",
  },
  mintTimestamp_1: {
    key: "mintTimestamp_1",
    text: "Date Minted: Oldest to Newest",
    value: "mintTimestamp_1",
  },
  "lastSaleTimestamp_-1": {
    key: "lastSaleTimestamp_-1",
    text: "Purchase Date: Newest to Oldest",
    value: "lastSaleTimestamp_-1",
  },
  lastSaleTimestamp_1: {
    key: "lastSaleTimestamp_1",
    text: "Purchase Date: Oldest to Newest",
    value: "lastSaleTimestamp_1",
  },
  "highestSale_-1": {
    key: "highestSale_-1",
    text: "Highest Sale: High to Low",
    value: "highestSale_-1",
  },
  highestSale_1: {
    key: "highestSale_1",
    text: "Highest Sale: Low to High",
    value: "highestSale_1",
  },
  lowestSale_1: {
    key: "lowestSale_1",
    text: "Lowest Sale: Low to High",
    value: "lowestSale_1",
  },
  "lowestSale_-1": {
    key: "lowestSale_-1",
    text: "Lowest Sale: High to Low",
    value: "lowestSale_-1",
  },
  "lastSale_-1": {
    key: "lastSale_-1",
    text: "Last Sale Price: High to Low",
    value: "lastSale_-1",
  },
  lastSale_1: {
    key: "lastSale_1",
    text: "Last Sale Price: Low to High",
    value: "lastSale_1",
  },
};

export const tokenGridProvenanceSortingFilters = {
  "provenance.dateUpdated_1": {
    key: "provenance.dateUpdated_1",
    text: "Last Updated: Low to High",
    value: "provenance.dateUpdated_1",
  },
  "provenance.dateUpdated_-1": {
    key: "provenance.dateUpdated_-1",
    text: "Last Updated: High to Low",
    value: "provenance.dateUpdated_-1",
  },
  "provenance.dateUpdated_1": {
    key: "provenance.entries_1",
    text: "Provenance Entries: Low to High",
    value: "provenance.entries_1",
  },
  "provenance.dateUpdated_-1": {
    key: "provenance.entries_-1",
    text: "Provenance Entries: High to Low",
    value: "provenance.entries_-1",
  },
};

export const TokenSortingSection = ({
  pageType,
  activeTab,
  listedTokensCount,
  smallerDropDown,
}) => {
  let { collectionId, collectionSlug, collectionCreator } = useParams();

  if (listedTokensCount && listedTokensCount > 0) {
    tokenGridSortingFilters = {
      ...tokenGridSortingFilters,
      "lowestListingPrice.value_-1": {
        key: "lowestListingPrice.value_-1",
        text: "Listing Price: High to Low",
        value: "lowestListingPrice.value_-1",
      },
      "lowestListingPrice.value_1": {
        key: "lowestListingPrice.value_1",
        text: "Listing Price: Low to High",
        value: "lowestListingPrice.value_1",
        unit: "ETH",
      },
    };
  }

  if (pageType === "CollectionPage") {
    delete tokenGridSortingFilters["lastSaleTimestamp_-1"];
    delete tokenGridSortingFilters["lastSaleTimestamp_1"];

    let rankSortKey = "rarityRank"; // rarityRankSlugLevel_, rarityRankFoundationLevel_
    if (collectionCreator) {
      rankSortKey = "rarityRankFoundationLevel";
    }
    if (collectionSlug) {
      rankSortKey = "rarityRankSlugLevel";
    }

    tokenGridSortingFilters = {
      ...tokenGridSortingFilters,
      [rankSortKey + "_-1"]: {
        key: rankSortKey + "_-1",
        text: "Rarity Rank: Low to High",
        value: rankSortKey + "_-1",
      },
      [rankSortKey + "_1"]: {
        key: rankSortKey + "_1",
        text: "Rarity Rank: High to Low",
        value: rankSortKey + "_1",
      },
    };
  }

  if (pageType === "FolderPage") {
    delete tokenGridSortingFilters["highestSale_-1"];
    delete tokenGridSortingFilters["highestSale_1"];
    delete tokenGridSortingFilters["lowestSale_1"];
    delete tokenGridSortingFilters["lowestSale_-1"];
  }

  let context = null;
  let pageFilters = null;
  let updatePageFilters = null;
  if (pageType === "CollectionPage") {
    context = CollectionFilterSectionContext;
  } else if (pageType === "FolderPage" || pageType === "ProvenancePage") {
    context = FolderFilterSectionContext;
    pageFilters = context.folderPageFilters;
  } else if (pageType === "CreatorPage") {
    context = CreatorFilterSectionContext;
  }
  context = useContext(context);
  if (pageType === "CollectionPage") {
    pageFilters = context.collectionPageFilters;
    updatePageFilters = context.updateCollectionPageFilters;
  } else if (pageType === "FolderPage" || pageType === "ProvenancePage") {
    pageFilters = context.folderPageFilters;
    updatePageFilters = context.updateFolderPageFilters;
  } else if (pageType === "CreatorPage") {
    pageFilters = context.creatorPageFilters;
    updatePageFilters = context.updateCreatorPageFilters;
  }

  const [selectedFiltersState, setSelectedFiltersState] = useState({
    updateSortingFunction: () => {},
    sortText: "",
    sortKey: "",
    sortDirection: "",
  });
  useEffect(() => {
    let sortText = "";
    let sortKey = "";
    let sortDirection = "";
    if (
      pageType === "CollectionPage" ||
      pageType === "FolderPage" ||
      pageType === "ProvenancePage"
    ) {
      sortText = pageFilters?.tokensSortText;
      sortKey = pageFilters?.tokensSortKey;
      sortDirection = pageFilters?.tokensSortDirection;
    } else if (activeTab === "Owned_Items" && pageType === "CreatorPage") {
      sortText = pageFilters?.ownedTokensSortText;
      sortKey = pageFilters?.ownedTokensSortKey;
      sortDirection = pageFilters?.ownedTokensSortDirection;
    } else if (activeTab === "Minted_Items" && pageType === "CreatorPage") {
      sortText = pageFilters?.mintedTokensSortText;
      sortKey = pageFilters?.mintedTokensSortKey;
      sortDirection = pageFilters?.mintedTokensSortDirection;
    } else if (activeTab === "Created_Items" && pageType === "CreatorPage") {
      sortText = pageFilters?.createdTokensSortText;
      sortKey = pageFilters?.createdTokensSortKey;
      sortDirection = pageFilters?.createdTokensSortDirection;
    }
    setSelectedFiltersState({
      sortText: sortText,
      sortDirection: sortDirection,
      sortKey: sortKey,

      updateSortingFunction: (selectedValue, text) => {
        const [sortKey_, sortDirection_] = selectedValue
          ? selectedValue.split("_")
          : ["mintTimestamp", "-1"];

        if (activeTab.includes("Items") && pageType === "CollectionPage") {
          updatePageFilters(
            setCollectionPageFiltersTokenSorting({
              tokensSortText: text,
              tokensSortKey: sortKey_,
              tokensSortDirection: parseInt(sortDirection_),
            })
          );
        } else if (
          activeTab.includes("Items") &&
          (pageType === "FolderPage" || pageType === "ProvenancePage")
        ) {
          updatePageFilters(
            setFolderPageFiltersTokenSorting({
              tokensSortText: text,
              tokensSortKey: sortKey_,
              tokensSortDirection: parseInt(sortDirection_),
            })
          );
        } else if (activeTab === "Owned_Items" && pageType === "CreatorPage") {
          updatePageFilters(
            setCreatorPageFiltersOwnedTokenSorting({
              tokensSortText: text,
              tokensSortKey: sortKey_,
              tokensSortDirection: parseInt(sortDirection_),
            })
          );
        } else if (activeTab === "Minted_Items" && pageType === "CreatorPage") {
          updatePageFilters(
            setCreatorPageFiltersMintedTokenSorting({
              tokensSortText: text,
              tokensSortKey: sortKey_,
              tokensSortDirection: parseInt(sortDirection_),
            })
          );
        } else if (
          activeTab === "Created_Items" &&
          pageType === "CreatorPage"
        ) {
          updatePageFilters(
            setCreatorPageFiltersCreatedTokenSorting({
              tokensSortText: text,
              tokensSortKey: sortKey_,
              tokensSortDirection: parseInt(sortDirection_),
            })
          );
        }
      },
    });
  }, [pageFilters, pageType, activeTab]);

  const isMobile = useIsMobile();

  return (
    <CustomDropdown
      smallerDropDown={smallerDropDown}
      id="token-sorting-dropdown"
      options={
        pageType === "ProvenancePage"
          ? Object.values(tokenGridProvenanceSortingFilters)
          : Object.values(tokenGridSortingFilters)
      }
      placeholder="SORT"
      onChange={(selectedOption) => {
        if (selectedOption && typeof selectedOption === "object") {
          const { value, text } = selectedOption;

          selectedFiltersState.updateSortingFunction(value, text);
        }
      }}
      selectedOption={{
        key:
          selectedFiltersState?.sortKey +
          "_" +
          selectedFiltersState?.sortDirection,
        text: selectedFiltersState?.sortText,
        value:
          selectedFiltersState?.sortKey +
          "_" +
          selectedFiltersState?.sortDirection,
      }}
      defaultPlaceholder={"Select Tokens Sorting..."}
    />
  );
};
