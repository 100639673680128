import React from "react";

const ScrollToTopArrow = () => {
  return (
    <div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        viewBox="0 0 24 31"
        fill="none"
      >
        <path
          d="M10.5 29C10.5 29.8284 11.1716 30.5 12 30.5C12.8284 30.5 13.5 29.8284 13.5 29L10.5 29ZM13.0607 0.939339C12.4749 0.353552 11.5251 0.353552 10.9393 0.939339L1.3934 10.4853C0.807612 11.0711 0.807612 12.0208 1.3934 12.6066C1.97919 13.1924 2.92893 13.1924 3.51472 12.6066L12 4.12132L20.4853 12.6066C21.0711 13.1924 22.0208 13.1924 22.6066 12.6066C23.1924 12.0208 23.1924 11.0711 22.6066 10.4853L13.0607 0.939339ZM13.5 29L13.5 2L10.5 2L10.5 29L13.5 29Z"
          fill="white"
        />
      </svg>
    </div>
  );
};

export default ScrollToTopArrow;
