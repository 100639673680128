import React, { useEffect } from "react";
import { Dropdown } from "semantic-ui-react";
import "semantic-ui-css/semantic.min.css";
import "../../styles/CommonComponentsStyles/dropdown.css";

const DropdownElement = ({
  options,
  stylePropObj,
  placeholderVal,
  onChangeFunc,
  value,
}) => {
  // Function to handle the change
  const handleChange = (e, { value }) => {
    // If an onChangeFunc was passed as a prop, call it

    if (onChangeFunc) {
      onChangeFunc(value, e.target.innerText);
    }
  };

  return (
    <div>
      <Dropdown
        onChange={handleChange}
        placeholder={placeholderVal}
        search
        selection
        options={options}
        style={{ ...stylePropObj }}
        value={value}
      />
    </div>
  );
};

export default DropdownElement;
