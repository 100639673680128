import React, { useEffect, useState } from "react";
import { CommonProvenanceCategories } from "../../UserProvenancePage/Variables/CommonProvenanceCategories";
import { Link } from "react-router-dom";

const Provenance = ({ provenanceData, item }) => {
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    let categories_ = Object.keys(CommonProvenanceCategories);

    if ((provenanceData?.data || []).length > 0) {
      categories_ = [
        ...new Set([
          ...categories_,
          ...(provenanceData?.data || []).map(
            (catProv) => catProv.categoryName
          ),
        ]),
      ];
    }

    setCategories(categories_);
  }, [provenanceData]);

  return (
    <div className="item_page_provenance_section">
      <div className="item_page_provenance_main_section">
        {categories.map((category, index) => {
          let tokenCategoryProvenance = (provenanceData?.data || []).filter(
            (catProv) => catProv?.categoryName === category
          );

          tokenCategoryProvenance =
            tokenCategoryProvenance.length > 0
              ? tokenCategoryProvenance[0].data
              : [];

          return (
            <div key={index} className="item_page_provenance_container">
              <div className="trait-value-container" key={index}>
                <div className="trait-type">{category}</div>
                <div className="trait-value">
                  {(tokenCategoryProvenance || []).length}
                </div>
              </div>
            </div>
          );
        })}
      </div>
      <Link
        className="item_page_provenance_view_detail_row"
        to={`/provenance_report/collection/${item?.contractAddress}/${item?.decimalTokenId}`}
      >
        View In Detail
      </Link>
    </div>
  );
};

export default Provenance;
