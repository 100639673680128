import axios from "axios";
import { REACT_APP_BACKEND_API_BASE_URL } from "./backend_domains/backend_api_domains";

// Base URL for the backend API
const URL = `${REACT_APP_BACKEND_API_BASE_URL}`;

export const saveErrorLogsApi = async (
  errorRequestType,
  errorUrl,
  errorHeader,
  errorBody,
  errorMessage
) => {
  try {
    let postBody = {};

    if (errorRequestType) {
      postBody["errorRequestType"] = errorBody;
    }
    if (errorBody) {
      postBody["errorBody"] = errorBody;
    }
    if (errorHeader) {
      postBody["errorHeader"] = { exists: "true", ...errorHeader };
    }

    const response = await axios.post(`${URL}/logs/errors`, {
      errorUrl,
      errorMessage: errorMessage || "",
      ...postBody,
    });
    return response.data;
  } catch (error) {
    console.error("Error in save error logs API: ");
    // throw error;
  }
};
