import React, { useState, useEffect } from "react";
import "../../styles/itemPageStyles/ItemPage.css";
import { color } from "d3";

const AlertPopup = ({
  message,
  backgroundColor = "#2fb483cd",
  duration = 3000,
  closeAfterDuration = true,
}) => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    if (message) {
      setIsVisible(true);

      if (closeAfterDuration === true) {
        const timerId = setTimeout(() => {
          setIsVisible(false);
        }, duration);

        return () => clearTimeout(timerId);
      }
    }
  }, [message]);

  return (
    <div
      className={`alert-container ${isVisible ? "visible" : ""}`}
      style={{ backgroundColor: backgroundColor }}
    >
      <div className="alert-content">
        <span style={{ color: "white" }}>{message}</span>
      </div>
    </div>
  );
};

export default AlertPopup;
