export const specialContractAddresses = {
  OSSharedFront: "0x495f947276749Ce646f68AC8c248420045cb7b5e",
  Foundation: "0x3B3ee1931Dc30C1957379FAc9aba94D1C48a5405",
};

export function getRoyaltyFromCollectionObj(collectionData) {
  const fees = collectionData?.openseaDetails?.collectionDetails?.fees;

  const royalties = fees?.reduce(
    (accumulator, feeObject) => accumulator + feeObject.fee,
    0
  );

  return royalties;
}

export function getRoyaltiesReceiverFromCollectionObj(
  collectionData,
  tokenCreator
) {
  return collectionData?.openseaDetails?.collectionDetails?.owner;
}

export function getURLForCollection(collectionData) {
  if (
    collectionData?.contractAddress?.toLowerCase() ===
    specialContractAddresses.OSSharedFront.toLowerCase()
  ) {
    return `/collection/${collectionData?.contractAddress}/slug/${collectionData?.collectionSlug}`;
  }

  if (
    collectionData?.contractAddress?.toLowerCase() ===
    specialContractAddresses.Foundation.toLowerCase()
  ) {
    return `/collection/${collectionData?.contractAddress}/created-by/${collectionData?.collectionCreator}`;
  }

  return `/collection/${collectionData?.contractAddress}`;
}

export function getNameFromCollectionObj(collectionData, realValue) {
  if (!collectionData || !collectionData.contractAddress)
    return realValue ? null : "Name Missing";

  if (
    collectionData.contractAddress.toLowerCase() ===
    specialContractAddresses.OSSharedFront.toLowerCase()
  ) {
    return (
      (collectionData?.openseaDetails?.collectionDetails?.name || "") +
      " (OS Shared Front)"
    );
  }

  if (
    collectionData.contractAddress.toLowerCase() ===
    specialContractAddresses.Foundation.toLowerCase()
  ) {
    return "Foundation";
  }

  return (
    collectionData?.openseaDetails?.collectionDetails?.name ||
    (realValue ? null : "Name Missing")
  );
}

export function getSlugFromCollectionObj(collectionData) {
  if (!collectionData || !collectionData.contractAddress)
    return "Contract Address Missing";

  return (
    collectionData?.openseaDetails?.collectionDetails?.collection ||
    "Slug Missing"
  );
}

export function getBannerImageFromCollectionObj(collectionData) {
  if (collectionData?.bannerImage?.s3Path) {
    return `https://dkkl2y2rvuhte.cloudfront.net/${collectionData?.bannerImage?.s3Path}`;
  } else if (
    collectionData?.openseaDetails?.collectionDetails?.banner_image_url
  ) {
    return collectionData?.openseaDetails?.collectionDetails?.banner_image_url;
  } else
    return "https://www.shutterstock.com/image-vector/dark-blue-green-vector-blur-600nw-1692448585.jpg";
}
