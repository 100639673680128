import { useState, useEffect } from "react";
import { atlasSearchApi } from "../../../api/atlasSearchApi";
import "../../../styles/componenetStyles/search.css";
import SearchResult from "./SearchResult";
import SearchOutline from "../../../Icons/SearchOutline";
import useIsMobile from "../../../customHooks/UseIsMobile";
import RemoveButton from "../../../Icons/CollectionPageIcons/RemoveButton";
import SearchIcon from "../../../Icons/CollectionPageIcons/SearchIcon";
import { isAddress } from "../../../utils/commonUtils/Utils";

export default function Search({ isPDF }) {
  const [searchResult, setSearchResult] = useState([]);
  const [collectionSearchResult, setCollectionSearchResult] = useState([]);
  const [openseaUserSearchResult, setOpenseaUserSearchResult] = useState([]);
  const [showSearchResult, setShowSearchResult] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  const [collectionSearching, setCollectionSearching] = useState(false);
  const [userSearching, setUserSearching] = useState(false);
  const isMobile = useIsMobile();

  function handleSearchChange(event) {
    if (event.target.value.length > 1) {
      setShowSearchResult(true);
      setIsSearching(true);
      setCollectionSearching(true);
      setUserSearching(true);
      if (!isAddress(event.target.value)) {
        let params = {
          size: 20,
          searchMethod: "token_autocomplete",
          input: event.target.value,
          searchType: "token",
        };
        atlasSearchApi
          .searchAtlas(params)
          .then((data) => {
            if (data.query !== event.target.value) return;
            setSearchResult(data.tokenDataList || []);
          })
          .catch((e) => {})
          .finally(() => {
            setIsSearching(false);
          });

        let collectionParams = {
          size: 15,
          searchMethod: "collection_autocomplete",
          searchType: "collection",
          input: event.target.value,
        };

        atlasSearchApi
          .searchAtlas(collectionParams)
          .then((data) => {
            if (data.query !== event.target.value) return;
            setCollectionSearchResult(data.collectionDataList || []);
          })
          .catch((e) => {})
          .finally(() => {
            setCollectionSearching(false);
          });

        let openseaUserParams = {
          size: 15,
          searchMethod: "user_autocomplete",
          input: event.target.value,
          searchType: "openseaUser",
        };

        atlasSearchApi
          .searchAtlas(openseaUserParams)
          .then((data) => {
            if (data.query !== event.target.value) return;
            setOpenseaUserSearchResult(data.openseaUserList || []);
          })
          .catch((e) => {})
          .finally(() => {
            setUserSearching(false);
          });
      } else {
        // search by address
        let params = {
          size: 1,
          searchMethod: "address",
          input: event.target.value,
          searchType: "address",
        };
        atlasSearchApi
          .searchAtlas(params)
          .then((data) => {
            setCollectionSearchResult(
              data.collection?.collectionDataList || []
            );
            setSearchResult(data.token?.tokenDataList || []);
            setOpenseaUserSearchResult(data.user?.openseaUserList || []);
          })
          .catch((e) => {})
          .finally(() => {
            setIsSearching(false);
            setCollectionSearching(false);
            setUserSearching(false);
          });
      }
    } else if (event?.target?.value?.length == 0) {
      setShowSearchResult(false);
      setSearchResult([]);
    }
  }

  function handleResetClick() {
    document.querySelector(".search-inputbox").value = "";
    setShowSearchResult(false);
  }

  return (
    <>
      {!isMobile || isPDF ? (
        <div className="search-bar" style={{ position: "relative" }}>
          <span className="search-input-icon">
            <SearchIcon width={"1.4em"} height={"1.4em"} />
          </span>
          <input
            className="search-inputbox"
            placeholder="Search"
            onChange={handleSearchChange}
          />
          {document.querySelector(".search-inputbox")?.value?.length > 0 && (
            <span className="reset-search" onClick={handleResetClick}>
              <RemoveButton color="#747474" width="0.55em" height="0.55em" />
            </span>
          )}
          {showSearchResult && (
            <SearchResult
              tokenResult={searchResult}
              collectionResult={collectionSearchResult}
              isSearching={isSearching}
              openseaUserSearchResult={openseaUserSearchResult}
              toggleSearchResult={setShowSearchResult}
            />
          )}
        </div>
      ) : (
        <div
          className="w-100 d-flex flex-column align-items-center"
          style={{ padding: "5vw", gap: "2vw", cursor: "pointer" }}
        >
          <div
            className="w-100 d-flex justify-content-between"
            style={{
              boxShadow: "0 1px 4px 0 rgb(0,0,0,0.2)",
              borderRadius: "2vw",
            }}
          >
            <input
              className="border-0"
              placeholder="Collection, User"
              onChange={handleSearchChange}
              style={{
                flexGrow: "1",
                height: "8vw",
                borderRadius: "2vw 0 0 2vw",
                padding: "2vw",
                backgroundColor: "rgba(255,255,255,0.6)",
                fontSize: "3vw",
              }}
            />
            <button
              className="border-0"
              style={{
                backgroundColor: "#2fb484",
                color: "white",
                borderRadius: "0 2vw 2vw 0",
                height: "8vw",
                paddingLeft: "5vw",
                paddingRight: "5vw",
                fontSize: "3vw",
                boxShadow: "0 1px 4px 0 rgb(0,0,0,0.2)",
              }}
            >
              Search
            </button>
          </div>
          {showSearchResult && (
            <SearchResult
              tokenResult={searchResult}
              collectionResult={collectionSearchResult}
              isSearching={isSearching}
              collectionSearching={collectionSearching}
              userSearching={userSearching}
              openseaUserSearchResult={openseaUserSearchResult}
              toggleSearchResult={setShowSearchResult}
            />
          )}
        </div>
      )}
    </>
  );
}
