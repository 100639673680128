import React from "react";
import ComingSoon from "../UtilComponents/ComingSoon";

const AnalysisTabSection = () => {
  return (
    <ComingSoon
      isInAnalysis={true}
      styleObj={{ marginTop: "5vw", marginBottom: "8vw" }}
    />
  );
};

export default AnalysisTabSection;
