import React, { useState, useEffect, useContext } from "react";
import "../../styles/collectionPageStyles/TokenGrid.css";
import {
  getObjectProperty,
  isNullOrUndefinedOrEmptyObject,
} from "../../utils/commonUtils/Utils";
import {
  checkTokenBurned,
  fetchCollectionNameFromTokenObj,
  fetchImageURLFromTokenObj,
  fetchOriginalImageURLFromTokenObj,
  fetchTokenNameFromTokenObj,
  getTokenRank,
  getTokenRankUI,
} from "../../utils/commonUtils/TokenUtils";
import { Link } from "react-router-dom";
import HoverdAnimation from "./HoverdAnimation";
import useIsMobile from "../../customHooks/UseIsMobile";
import { CollectionFilterSectionContext } from "../../contexts/CollectionPage/FilterSectionContext";
import { CreatorFilterSectionContext } from "../../contexts/CreatorPage.js/FilterSectionContext";
import { tokenGridSortingFilters } from "../CommonComponents/TokenSortingSection";
import { prettyWeiToEth } from "../../utils/commonUtils/PriceUtils";
import { timestampToDate } from "../../utils/commonUtils/timeUtils";
import VerifyImageSourceIcon from "../CommonComponents/VerifyImageSourceIcon";

function TokenCardFooter({ pageType, activeTab, token }) {
  const context = useContext(
    pageType === "CollectionPage"
      ? CollectionFilterSectionContext
      : CreatorFilterSectionContext
  );
  const pageFilters =
    pageType === "CollectionPage"
      ? context.collectionPageFilters
      : context.creatorPageFilters;

  let tokensSortKey, tokensSortDirection;
  if (pageType === "CollectionPage") {
    tokensSortKey = pageFilters.tokensSortKey;
    tokensSortDirection = pageFilters.tokensSortDirection;
  } else if (activeTab === "Owned_Items" && pageType === "CreatorPage") {
    tokensSortKey = pageFilters.ownedTokensSortKey;
    tokensSortDirection = pageFilters.ownedTokensSortDirection;
  } else if (activeTab === "Minted_Items" && pageType === "CreatorPage") {
    tokensSortKey = pageFilters.mintedTokensSortKey;
    tokensSortDirection = pageFilters.mintedTokensSortDirection;
  } else if (activeTab === "Created_Items" && pageType === "CreatorPage") {
    tokensSortKey = pageFilters.createdTokensSortKey;
    tokensSortDirection = pageFilters.createdTokensSortDirection;
  } else {
    tokensSortKey = pageFilters.tokensSortKey;
    tokensSortDirection = pageFilters.activitySortDirection;
  }

  let fieldToShow = tokensSortKey || "mintTimestamp";

  let valueToShow = getObjectProperty(token, fieldToShow);
  let valueToShowWithUnit = null;
  if (fieldToShow === "mintTimestamp" || fieldToShow === "lastSaleTimestamp") {
    valueToShow = getObjectProperty(token, fieldToShow);
    valueToShow = timestampToDate(valueToShow);
    valueToShowWithUnit = valueToShow;
  } else if (
    fieldToShow === "rarityRank" ||
    fieldToShow === "rarityRankSlugLevel" ||
    fieldToShow === "rarityRankFoundationLevel"
  ) {
    valueToShowWithUnit = valueToShow;
  } else if (fieldToShow === "lowestListingPrice.value") {
    valueToShowWithUnit = valueToShow + " ETH";
  } else {
    valueToShow = valueToShow ? prettyWeiToEth(valueToShow) : valueToShow;
    valueToShowWithUnit = valueToShow + " ETH";
  }

  let textToShow =
    tokenGridSortingFilters[tokensSortKey + "_" + tokensSortDirection]?.text;
  textToShow = textToShow ? textToShow.split(":")[0] : textToShow;

  return (
    <>
      {!isNullOrUndefinedOrEmptyObject(valueToShow) ? (
        <div className="d-flex token-card-footer">
          <div
            className="token-card-footer-text"
            style={{ whiteSpace: "nowrap" }}
          >
            {textToShow}:
          </div>
          <div
            className="token-card-footer-text ps-1"
            style={{ color: "#2fb484", fontWeight: "bold" }}
          >
            {valueToShowWithUnit}
          </div>
        </div>
      ) : (
        <div className="token-card-footer token-card-footer-text">
          <div>None Listed</div>
        </div>
      )}
    </>
  );
}

function TokenCard({
  pageType,
  activeTab,
  token,
  totalCount,
  isHovered,
  onCardHover,
  onCardLeave,
  isBiggerColumnGrid,
  allTokensUnranked,
}) {
  const isMobile = useIsMobile();
  const [opacityOfImage, setOpacityOfImage] = useState(1);

  useEffect(() => {
    if (isHovered) {
      setOpacityOfImage(0.5);
    } else {
      setOpacityOfImage(1);
    }
  }, [isHovered]);

  return (
    <div
      className={`${
        isBiggerColumnGrid
          ? isMobile
            ? allTokensUnranked
              ? "token-card-container-smaller-width-mobile-unranked"
              : "token-card-container-smaller-width-mobile"
            : allTokensUnranked
            ? "token-card-container-smaller-width-unranked"
            : "token-card-container-smaller-width"
          : allTokensUnranked
          ? "token-card-container-bigger-width-unranked"
          : "token-card-container-bigger-width"
      } token-card-container`}
      onMouseEnter={onCardHover}
      onMouseLeave={onCardLeave}
      style={{ overflow: "hidden" }}
    >
      <Link
        className="w-100 h-100"
        to={`/collection/${token?.openseaDetails?.contract}/id/${
          token?.openseaDetails?.identifier || 0
        }`}
        onDragStart={(e) => {
          e.preventDefault();
        }}
      >
        {!isMobile
          ? checkTokenBurned(token) && (
              <div
                style={{
                  position: "absolute",
                  zIndex: "1",
                  color: "red",
                  marginLeft: "0.7vw",
                  marginTop: "0.4vw",
                  fontWeight: "bold",
                  fontSize: "0.916vw",
                }}
              >
                Burned (No Longer Available)
              </div>
            )
          : checkTokenBurned(token) && (
              <div
                style={{
                  position: "absolute",
                  zIndex: "1",
                  color: "red",
                  marginLeft: "2vw",
                  marginTop: "0.3vw",
                  fontWeight: "bold",
                }}
              >
                Burned
              </div>
            )}
        <div
          style={{
            height: "63%",
            width: "100%",
            backgroundColor: "#2fb484",
            position: "relative",
          }}
        >
          <div
            style={{
              position: "absolute",
              top: "0",
              right: "0",
              zIndex: "20",
              width: "100%",
            }}
          >
            <VerifyImageSourceIcon
              url={fetchOriginalImageURLFromTokenObj(token)}
            />
          </div>
          <img
            src={fetchImageURLFromTokenObj(token)}
            alt="Token Img"
            className="img-card"
            style={{ opacity: opacityOfImage }}
            onError={(event) => {
              event.target.src = fetchImageURLFromTokenObj(null);
            }}
          />
        </div>

        {!isHovered && (
          <div className="token-card-text-container">
            <>
              <div className="token-card-text-row1">
                {fetchTokenNameFromTokenObj(token, 18)}
              </div>
              {pageType === "CollectionPage" ? (
                getTokenRank(token) ? (
                  getTokenRankUI(token) !== "UnRanked (No Traits)" && (
                    <div className="token-card-text-row2">
                      {getTokenRankUI(token) !== "UnRanked (No Traits)" && (
                        <strong> Rank </strong>
                      )}
                      <span className="token-card-token-ranking">
                        {getTokenRankUI(token)}
                      </span>
                      {getTokenRankUI(token) !== "UnRanked (No Traits)" &&
                        "  /"}
                      {getTokenRankUI(token) !== "UnRanked (No Traits)" && (
                        <span className="token-card-total-tokens">
                          {totalCount}{" "}
                        </span>
                      )}
                    </div>
                  )
                ) : (
                  <span className="token-card-text-row2">
                    {fetchCollectionNameFromTokenObj(token, true)}
                  </span>
                )
              ) : (
                <div className="token-card-text-row2-creator-page">
                  {fetchCollectionNameFromTokenObj(token, true)}
                </div>
              )}
              {!isMobile && (
                <div className="token-card-line-between-texts"></div>
              )}
              <TokenCardFooter
                pageType={pageType}
                activeTab={activeTab}
                token={token}
              />
            </>
          </div>
        )}
        {isHovered && <HoverdAnimation />}
      </Link>
    </div>
  );
}

export default TokenCard;
