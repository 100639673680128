import React from "react";
import { useEffect, useState } from "react";
import InfoComponent from "../components/CommonComponents/InfoComponent";
import useIsMobile from "../customHooks/UseIsMobile";
import HomePageActivity from "../components/Homepage/HomePageActivity";
import Background from "../components/UtilComponents/Background";
import HeaderDesktop from "../components/Homepage/Header/HeaderDesktop";
import { Helmet } from "react-helmet";
import FooterSection from "../components/FooterSection";
import { fetchMJUserDetails } from "../api/mjUserApi";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const NftTransactionsActivity = () => {
  const isMobile = useIsMobile();
  const userState = useSelector((state) => state.user);

  const [loadingMJUserInfo, setLoadingMJUserInfo] = React.useState(true);
  const [mjUserInfo, setMJUserInfo] = React.useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    setLoadingMJUserInfo(true);
    fetchMJUserDetails(userState?.userInfo?.userId)
      .then((mjUserInfo_) => {
        setMJUserInfo(mjUserInfo_);
      })
      .catch((e) => {})
      .finally(() => {
        setLoadingMJUserInfo(false);
      });
  }, []);

  return (
    <Background>
      <Helmet>
        <title>Metajungle NFT Transfers Activity Page</title>
        <meta
          name="NFT Transfers Activity"
          content="Welcome to the NFT Transfers Activity Page on Metajungle Website!

          Page shows all the NFT transactions happening on Ethereum Blockchain in real-time. "
        />
      </Helmet>
      <HeaderDesktop />
      <div
        // className="dashboard-sub-headings-header"
        style={{ marginTop: "7%" }}
      >
        {/* <span className="dashboard-sub-headings">
          NFT Transactions Tracker (Ethereum Blockchain)
          <InfoComponent
            iconWidth={"1.5em"}
            iconHeight={"1.5em"}
            // info="This is a test modal"
            info="Introducing our Ethereum NFT Transaction Tracker, a real-time monitoring tool dedicated to tracking the movement of non-fungible tokens (NFTs) on the Ethereum blockchain. Keep a close eye on the latest transactions as they occur, including transfers of digital art, collectibles, and other unique assets. Our streamlined interface provides instant updates on Ethereum NFT transfers, allowing you to stay informed about the latest trends and activity in the vibrant NFT ecosystem. Whether you're a collector, creator, or investor, our Ethereum NFT Transaction Tracker offers invaluable insights into the dynamic world of digital ownership on the blockchain."
            styleObj={{
              textWrap: "wrap",
              width: isMobile
                ? "calc(var(--base-font-size-home-page) * 10)"
                : "calc(var(--base-font-size-home-page) * 20)",
            }}
            renderDirection={isMobile ? "bottom-left" : ""}
          />
        </span> */}
      </div>
      <HomePageActivity />
      <FooterSection />
    </Background>
  );
};

export default NftTransactionsActivity;
