import React from "react";

const FilterIconClosedMobile = () => {
  return (
    <div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        viewBox="0 0 19 20"
        fill="none"
      >
        <path
          d="M8.94279 20H7V9.9095L0 1.57617V0H19V1.56717L12.3333 9.9005V16.6095L8.94279 20ZM8.33333 18.6667H8.39054L11 16.0572V9.43283L17.4796 1.33333H1.5375L8.33333 9.42383V18.6667Z"
          fill="#183B56"
        />
      </svg>
    </div>
  );
};

export default FilterIconClosedMobile;
