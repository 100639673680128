import React from "react";
import useIsMobile from "../../customHooks/UseIsMobile";
import {
  CommonProvenanceCategories,
  newCategoryFormDataObj,
} from "../UserProvenancePage/Variables/CommonProvenanceCategories";
import { useState } from "react";

import { BsArrowsExpandVertical } from "react-icons/bs";
import ImageEnlargeModal from "../ItemPage/Image/ImageEnlargeModal";

const EachArciveNote = ({ provenanceData, categoryName, isPDF }) => {
  const isMobile = useIsMobile();
  const [isImageEnlargeModalOpen, setIsImageEnlargeModalOpen] = useState(false);
  const openImageModal = () => {
    setIsImageEnlargeModalOpen(true);
  };

  const closeImageModal = () => {
    setIsImageEnlargeModalOpen(false);
  };
  function convertDateToFormat(stringDate, format) {
    let returnValue = stringDate.split("T")[0];
    if (!format || format === "MM/dd/yyyy") {
      returnValue =
        returnValue.split("-")[1] +
        "/" +
        returnValue.split("-")[2] +
        "/" +
        returnValue.split("-")[0];
    } else if (format === "MM/yyyy") {
      returnValue = returnValue.split("-")[1] + "/" + returnValue.split("-")[0];
    } else if (format === "yyyy") {
      returnValue = returnValue.split("-")[0];
    }

    return returnValue;
  }

  return (
    <div
      className={
        isPDF
          ? "provenance-each-archive-note-main-container"
          : !isMobile
          ? "provenance-each-archive-note-main-container"
          : "provenance-each-archive-note-main-container-mobile"
      }
    >
      {provenanceData.map((item, index) => (
        <>
          <span className="provenance-each-archive-note-date">
            Date updated:{" "}
            <span
              style={{ marginLeft: "1em" }}
              className="provenance-each-archive-note-date"
            ></span>{" "}
            <strong style={{ color: "#2fb484" }}>
              {" "}
              {item?.dateUpdated ? item?.dateUpdated.split("T")[0] : "n/a"}
            </strong>
          </span>
          <div
            key={index}
            className="provenance-each-archive-note-details-section"
          >
            {Object.entries(item)
              .filter(([label, value]) => {
                if (CommonProvenanceCategories.hasOwnProperty(categoryName)) {
                  if (
                    !CommonProvenanceCategories[categoryName][
                      "helperLabels"
                    ].includes(label)
                  ) {
                    return true;
                  }
                } else {
                  if (!newCategoryFormDataObj["helperLabels"].includes(label)) {
                    return true;
                  }
                }

                return false;
              })
              .map(
                ([key, value]) =>
                  !["Images", "dateUpdated", "Links", "Media Links"].includes(
                    key
                  ) &&
                  value !== "" &&
                  value !== null &&
                  (key === "Description" ? (
                    <span
                      key={key}
                      className={
                        isPDF
                          ? "provenance-each-archive-note-each-detail "
                          : !isMobile
                          ? "provenance-each-archive-note-each-detail"
                          : "provenance-each-archive-note-each-detail-mobile"
                      }
                      style={{ whiteSpace: "pre-wrap" }}
                    >
                      {key}: {value || "n/a"}
                    </span>
                  ) : key.includes("Date") ? (
                    <span
                      key={key}
                      className={
                        isPDF
                          ? "provenance-each-archive-note-each-detail "
                          : !isMobile
                          ? "provenance-each-archive-note-each-detail"
                          : "provenance-each-archive-note-each-detail-mobile"
                      }
                    >
                      {key}: {convertDateToFormat(value, item[key + " Format"])}
                    </span>
                  ) : (
                    <span
                      key={key}
                      className={
                        isPDF
                          ? "provenance-each-archive-note-each-detail "
                          : !isMobile
                          ? "provenance-each-archive-note-each-detail"
                          : "provenance-each-archive-note-each-detail-mobile"
                      }
                    >
                      {key}: {value || "n/a"}
                    </span>
                  ))
              )}

            {Object.keys(item).map((key) => {
              if (key.includes("Links")) {
                const links = item[key];
                return (
                  links &&
                  links.length > 0 && (
                    <span
                      key={key}
                      className={
                        isPDF
                          ? "provenance-each-archive-note-each-detail "
                          : "provenance-each-archive-note-each-detail"
                      }
                    >
                      {key}:
                      <br />
                      <a href={links}>
                        <p className="provenance-note-links">{links}</p>
                      </a>
                    </span>
                  )
                );
              }
              return null;
            })}

            {item?.Images && item?.Images.length > 0 && (
              <div
                className={
                  isPDF
                    ? "provenance-each-archive-note-each-detail "
                    : !isMobile
                    ? "provenance-each-archive-note-each-detail"
                    : "provenance-each-archive-note-each-detail-mobile"
                }
              >
                {item?.Images && item?.Images.length > 0 && "Images:"}

                {item?.Images &&
                  item?.Images.length > 0 &&
                  (item?.Images || []).map((image, idx) => (
                    <>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          gap: "1em",
                          marginTop: "1em",
                          justifyContent: "left",
                          alignItems: "center",
                          fontSize: "0.8em",
                        }}
                      >
                        <img
                          className="provenance-each-archive-note-image"
                          key={idx}
                          src={`https://d3p2e0ks7xz82c.cloudfront.net/${image?.s3Path}`}
                          alt="img"
                          height="80em"
                          width="80em"
                        />

                        {/* {image?.originalname} */}
                        {!isPDF && (
                          <span
                            onClick={() => openImageModal()}
                            style={{ fontSize: "1em" }}
                            title="Enlarge Image"
                          >
                            <BsArrowsExpandVertical size={"1.5em"} />
                          </span>
                        )}
                      </div>
                      {isImageEnlargeModalOpen && (
                        <ImageEnlargeModal
                          closeModal={closeImageModal}
                          openModal={openImageModal}
                        />
                      )}
                    </>
                  ))}
              </div>
            )}
          </div>
        </>
      ))}
    </div>
  );
};

export default EachArciveNote;
