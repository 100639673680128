import { useNavigate } from "react-router-dom";
import { fetchImageURLFromTokenObj } from "../../../utils/commonUtils/TokenUtils";
import { useEffect, useState } from "react";
import RemoveButton from "../../../Icons/CollectionPageIcons/RemoveButton";
import TokenDropdownItem from "./TokenDropdownItem";
import css from "../../../styles/CommonComponentsStyles/TokenListWithDropdown.css";

export default function TokensDropdownList({
  tokensToShow,
  deleteFromTokensList,
}) {
  const navigate = useNavigate();
  const [showResults, setShowResults] = useState(true);

  function handleImgErr(event, item) {
    const imageURL = fetchImageURLFromTokenObj(item);
    event.target.src = imageURL;
  }

  function handleClose() {
    setShowResults(false);
  }

  return (
    <>
      {showResults && (
        <div className="token-list-dropdown-container d-flex flex-column align-items-center">
          <button onClick={handleClose} className="search-close-button">
            <RemoveButton color={"black"} />
          </button>
          {tokensToShow &&
            tokensToShow.map((item, index) => {
              return (
                <TokenDropdownItem
                  item={item}
                  deleteFromTokensList={deleteFromTokensList}
                />
              );
            })}
        </div>
      )}
    </>
  );
}
