import React, { useEffect, useRef } from "react";
import useMarketplaces from "./useMarketplaces";
import VerifiedIcon from "../../Icons/VerifiedIcon";
import useIsMobile from "../../customHooks/UseIsMobile";
import RemoveButton from "../../Icons/CollectionPageIcons/RemoveButton";

function MarketplacesModal({
  tokenListingsPricesAPIData,
  selectedMarketplaceForListing,
  onSelect,
  onClose,
  tabToggles,
  toggleTab,
  openModal,
}) {
  const modalRef = useRef(null);

  useEffect(() => {
    const handleOutsideTap = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        onClose();
      }
    };

    if (openModal) {
      document.addEventListener("mousedown", handleOutsideTap);
    }

    return () => {
      document.removeEventListener("mousedown", handleOutsideTap);
    };
  }, [openModal]);
  const allMarketplaceForListing = useMarketplaces(tokenListingsPricesAPIData);

  const isMobile = useIsMobile();

  const handleMarketplaceClick = (marketplace) => {
    const isSelected = selectedMarketplaceForListing.includes(marketplace);

    if (isSelected) {
      onSelect(
        selectedMarketplaceForListing.filter(
          (selected) => selected !== marketplace
        )
      );
    } else {
      onSelect([...selectedMarketplaceForListing, marketplace]);
    }
  };

  return (
    <div className="modal-overlay">
      <div className="modal-component" ref={modalRef}>
        <div className="modal-header">
          <div className="modal-header-title">Marketplaces</div>
          <button className="close-button" onClick={onClose}>
            <RemoveButton color={"black"} />
          </button>
        </div>
        <div className="each-modal-listing">
          {allMarketplaceForListing.map((marketplace) => (
            <div
              key={marketplace}
              className={`listing-item ${
                selectedMarketplaceForListing.includes(marketplace)
                  ? "selected"
                  : "not-selected"
              }`}
              onClick={() => handleMarketplaceClick(marketplace)}
            >
              {marketplace}
              <VerifiedIcon />
            </div>
          ))}
          <br />
        </div>
        <div className="modal-row">
          <label className="modal-row-heading">Current Prices</label>
          <input
            type="checkbox"
            class="pill-toggle"
            id="traitToggle0"
            checked={tabToggles[0]}
            onChange={() => toggleTab(0)}
          />
          <label class="pill-label" for="traitToggle0"></label>
        </div>
        <div className="modal-row">
          <label className="modal-row-heading">History Prices</label>
          <input
            type="checkbox"
            class="pill-toggle"
            id="traitToggle1"
            checked={tabToggles[1]}
            onChange={() => toggleTab(1)}
          />
          <label class="pill-label" for="traitToggle1"></label>
        </div>
        <div className="modal-row">
          <label className="modal-row-heading">Loans</label>
          <input
            type="checkbox"
            class="pill-toggle"
            id="traitToggle2"
            checked={tabToggles[2]}
            onChange={() => toggleTab(2)}
          />
          <label class="pill-label" for="traitToggle2"></label>
        </div>
      </div>
    </div>
  );
}

export default MarketplacesModal;
