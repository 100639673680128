import React from "react";

const SearchIcon = ({ width, height }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width ? width : "1.1em"}
      height={height ? height : "1.1em"}
      viewBox="0 0 24 24"
      fill="grey"
    >
      <path
        d="M17.761 16.5774L14.9271 13.7519C15.8414 12.5871 16.3375 11.1487 16.3357 9.66788C16.3357 8.34911 15.9446 7.05995 15.212 5.96342C14.4793 4.8669 13.4379 4.01227 12.2195 3.50759C11.0011 3.00292 9.66046 2.87087 8.36702 3.12815C7.07358 3.38543 5.88549 4.02049 4.95297 4.953C4.02046 5.88552 3.3854 7.07362 3.12812 8.36705C2.87084 9.66049 3.00289 11.0012 3.50756 12.2196C4.01224 13.438 4.86687 14.4793 5.96339 15.212C7.05992 15.9447 8.34908 16.3357 9.66785 16.3357C11.1487 16.3376 12.5871 15.8415 13.7519 14.9272L16.5774 17.761C16.6549 17.8391 16.7471 17.9011 16.8486 17.9434C16.9502 17.9857 17.0592 18.0075 17.1692 18.0075C17.2792 18.0075 17.3882 17.9857 17.4897 17.9434C17.5913 17.9011 17.6835 17.8391 17.761 17.761C17.8391 17.6835 17.9011 17.5913 17.9434 17.4898C17.9857 17.3882 18.0075 17.2792 18.0075 17.1692C18.0075 17.0592 17.9857 16.9502 17.9434 16.8487C17.9011 16.7471 17.8391 16.6549 17.761 16.5774ZM4.66697 9.66788C4.66697 8.6788 4.96026 7.71193 5.50977 6.88954C6.05927 6.06715 6.8403 5.42617 7.7541 5.04767C8.66789 4.66916 9.6734 4.57013 10.6435 4.76309C11.6136 4.95605 12.5046 5.43234 13.204 6.13172C13.9034 6.83111 14.3797 7.72218 14.5727 8.69226C14.7656 9.66234 14.6666 10.6678 14.2881 11.5816C13.9096 12.4954 13.2686 13.2765 12.4462 13.826C11.6238 14.3755 10.6569 14.6688 9.66785 14.6688C8.34154 14.6688 7.06954 14.1419 6.13169 13.204C5.19384 12.2662 4.66697 10.9942 4.66697 9.66788Z"
        fill="#183B56"
      />
    </svg>
  );
};

export default SearchIcon;
