import Cookies from "js-cookie";
import { REACT_APP_BACKEND_API_BASE_URL } from "./backend_domains/backend_api_domains";
import Axios from "axios";
import { setupCache } from "axios-cache-interceptor";
import { encryptData } from "../utils/commonUtils/EncryptFunctions";
const instance = Axios.create();
const axios = setupCache(instance);

let BASE_URL = `${REACT_APP_BACKEND_API_BASE_URL}/mj_user`;

async function fetchMJUserDetails(userId, walletAddress, includeWalletDetails) {
  if ((!walletAddress || walletAddress === "") && (!userId || userId === ""))
    return {};
  try {
    let URL = `${BASE_URL}/details`;

    if (includeWalletDetails) includeWalletDetails = true;
    else includeWalletDetails = false;

    const response = await axios.get(URL, {
      params: {
        userId,
        walletAddress,
        includeWalletDetails,
      },
    });

    return response?.data?.mjUserInfo || {};
  } catch (error) {
    throw error;
  }
}
async function signUp(user) {
  try {
    const response = await axios.post(BASE_URL + "/sign_up", {
      email: user.email,
      password: user.password,
      profileName: user.profileName,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}
async function signUpNonVerified({ email }) {
  try {
    const response = await axios.post(BASE_URL + "/non_verified_sign_up", {
      email: email,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}
async function generateOtp(email) {
  try {
    const response = await axios.post(
      BASE_URL + "/generate_otp?email=" + email
    );
    return response.data;
  } catch (error) {
    throw error;
  }
}
async function authenticateOtp(email, otp) {
  try {
    const response = await axios.post(
      BASE_URL + "/authenticate_otp?email=" + email + "&otp=" + otp
    );
    return response?.data?.message;
  } catch (error) {
    throw error;
  }
}
async function signIn(user) {
  try {
    const response = await axios.post(BASE_URL + "/sign_in", {
      email: user.email,
      password: user.password,
    });

    return response.data;
  } catch (error) {
    throw error;
  }
}

async function signInWithGoogleUpsert(credential) {
  try {
    const res = await axios.post(BASE_URL + "/google_sign_in_upsert", {
      googleAccessToken: credential,
    });
    return res.data;
  } catch (error) {
    throw new Error("Failed to sign in with Google " + error.message);
  }
}

async function validatePassword(email, password) {
  try {
    const response = await axios.post(BASE_URL + "/verify_password", {
      email: email,
      password: password,
    });

    return true;
  } catch (error) {
    if (error?.response?.data === "Invalid Password") return false;
    if (error?.response?.data === "Email and password required") return false;

    throw error;
  }
}

async function updatePassword(email, oldPassword, newPassword, otp) {
  try {
    const response = await axios.post(BASE_URL + "/update_password", {
      email: email,
      oldPassword: oldPassword,
      newPassword,
      otp,
    });

    return response.data;
  } catch (error) {
    throw error;
  }
}

async function verifyUserToken(userId, jwtToken) {
  try {
    const response = await axios.post(BASE_URL + "/verify_auth_token", {
      userId,
      jwtToken,
    });

    return response.data;
  } catch (error) {
    throw error;
  }
}

async function addWalletAddresses(walletAddressList) {
  // Need to pass in encoded wallet address
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  let encryptedWallets = walletAddressList.map((wa) => {
    return encryptData(wa);
  });
  try {
    const response = await axios.post(
      BASE_URL + "/add_wallet_addresses",
      {
        walletAddressList: encryptedWallets,
        userId: userInfo.userId,
      },
      {
        headers: {
          Authorization: `Bearer ${Cookies.get("jwt-token")}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
}

/* Fetch  folders for Tokens Owned By Wallet Address */
const fetchFoldersForOwnedTokens = async (userId) => {
  try {
    const API_URL = `${BASE_URL}/${userId}/folders`;

    const response = await axios.get(API_URL, {
      headers: {
        Authorization: `Bearer ${Cookies.get("jwt-token")}`,
        userId: userId, // Sending userId in the headers as a workaround
      },
    });

    return response.data || [];
  } catch (error) {
    throw error;
  }
};

async function addItemInUserFavourite(userId, itemId, itemType) {
  try {
    const body = {
      userId,
      itemId,
      itemType,
    };
    const result = await axios.post(BASE_URL + "/add_item_to_favourite", body, {
      headers: {
        Authorization: `Bearer ${Cookies.get("jwt-token")}`,
      },
    });
    return result.data;
  } catch (error) {
    throw error;
  }
}

async function getUserFavourites(userId, itemType) {
  try {
    const params = {
      userId,
      itemType,
    };
    const response = await axios.get(BASE_URL + "/get_favourite_items", {
      params,
      headers: {
        Authorization: `Bearer ${Cookies.get("jwt-token")}`,
        userId: userId, // Sending userId in the headers as a workaround
      },
    });
    return response?.data || [];
  } catch (error) {
    throw error;
  }
}
async function getUserFavouritesCount(userId) {
  try {
    const params = {
      userId,
    };
    const response = await axios.get(BASE_URL + "/get_favourite_items_count", {
      params,
      headers: {
        Authorization: `Bearer ${Cookies.get("jwt-token")}`,
        userId: userId, // Sending userId in the headers as a workaround
      },
    });

    return response?.data?.itemsCount || [];
  } catch (error) {
    throw error;
  }
}

async function checkIsFavourite(userId, itemId) {
  try {
    const params = {
      userId,
      itemId,
    };
    const response = await Axios.get(BASE_URL + "/check_is_favourite_or_not", {
      params,
    });

    if (response.data === null || response.data === undefined) {
      throw new Error("Failed to fetch Favourate status of token");
    }
    return response.data;
  } catch (error) {
    throw error;
  }
}

async function removeItemFromFavourite(userId, itemId) {
  try {
    const result = await axios.put(
      BASE_URL + "/remove_item_from_favourite",
      {
        userId,
        itemId,
      },
      {
        headers: {
          Authorization: `Bearer ${Cookies.get("jwt-token")}`,
          userId: userId, // Sending userId in the headers as a workaround
        },
      }
    );
    return result.data;
  } catch (error) {
    throw error;
  }
}
async function updateMJUserDetails(
  userId,
  updateUserObj,
  profileVideoFile,
  profileImage
) {
  try {
    let formData = new FormData();

    if (updateUserObj && Object.keys(updateUserObj).length > 0)
      formData.append("updateUserObj", JSON.stringify(updateUserObj));
    if (profileImage) formData.append(`profileImage`, profileImage);
    if (profileVideoFile) formData.append(`profileVideoFile`, profileVideoFile);

    const result = await axios.post(
      BASE_URL + `/${userId}/update_user_details`,
      formData,
      {
        headers: {
          Authorization: `Bearer ${Cookies.get("jwt-token")}`,
          userId: userId, // Sending userId in the headers as a workaround
        },
      }
    );

    return result?.data?.updatedMJUser || {};
  } catch (error) {
    throw error;
  }
}
async function createUserFilter(userId, filterLocation, filterName, filters) {
  try {
    const response = await axios.post(
      BASE_URL + "/create_user_filter",
      {
        userId,
        filterLocation,
        filterName,
        filters,
      },
      {
        headers: {
          Authorization: `Bearer ${Cookies.get("jwt-token")}`,
        },
      }
    );
    return response?.data?.filter;
  } catch (error) {
    throw error;
  }
}

async function getUserFilters(userId, filterLocation) {
  try {
    const params = {
      userId,
      filterLocation,
    };
    const response = await Axios.get(BASE_URL + "/get_user_filters", {
      params,
      headers: {
        Authorization: `Bearer ${Cookies.get("jwt-token")}`,
        userId: userId, // Sending userId in the headers as a workaround
      },
    });
    return response?.data?.userfilters || [];
  } catch (error) {
    throw error;
  }
}

async function deleteUserFilter(_id) {
  try {
    const response = await Axios.delete(BASE_URL + "/delete_user_filter", {
      data: { _id }, // Use `data` to send the body in a DELETE request
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

async function updateUserFilter(_id, updatedFilterName, updatedFilters) {
  try {
    const response = await axios.put(BASE_URL + "/update_user_filter", {
      _id,
      updatedFilterName: updatedFilterName,
      updatedFilters,
    });

    return response?.data?.updatedFilter;
  } catch (error) {
    throw error;
  }
}

async function setDefaultFilter(userId, filterId, filterLocation) {
  try {
    const response = await axios.put(BASE_URL + "/set_default_filter", {
      userId,
      filterId,
      filterLocation,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export {
  fetchMJUserDetails,
  signUp,
  signUpNonVerified,
  signIn,
  validatePassword,
  updatePassword,
  verifyUserToken,
  addWalletAddresses,
  fetchFoldersForOwnedTokens,
  addItemInUserFavourite,
  getUserFavourites,
  generateOtp,
  authenticateOtp,
  checkIsFavourite,
  removeItemFromFavourite,
  updateMJUserDetails,
  signInWithGoogleUpsert,
  getUserFavouritesCount,
  createUserFilter,
  getUserFilters,
  deleteUserFilter,
  updateUserFilter,
  setDefaultFilter,
};
