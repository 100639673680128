import { useNavigate } from "react-router-dom";
import GoogleIcon from "../../assets/images/GoogleIcon.png";
import MetaMaskIcon from "../../assets/images/MetamaskIcon.webp";
import Eye from "../../assets/images/eye.svg";
import EyeSlash from "../../assets/images/eye-slash.svg";
import useIsMobile from "../../customHooks/UseIsMobile";
import "../../styles/loginPageStyles/loginPageStyles.css";
import RightArrowIcon from "../../Icons/CollectionPageIcons/RightArrowIcon";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

export default function LoginInputPartMobile({
  handleLogOutButtonClick,
  handleLoginFormSubmit,
  email,
  setEmail,
}) {
  const navigate = useNavigate();

  let fontMultiplierMobile = 2;
  // State to manage password visibility
  const [showPassword, setShowPassword] = useState(false);

  // Function to toggle password visibility
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const userState = useSelector((state) => state.user);

  return (
    <div
      className="d-flex flex-column login-page-input-animation-mobile"
      style={{ width: "100%" }}
    >
      <div className="signup-welcome-textbox-mobile"></div>
      <div
        className="d-flex flex-column"
        style={{
          marginLeft: `${7 * fontMultiplierMobile}vw`,
          marginRight: `${7 * fontMultiplierMobile}vw`,
          marginTop: "5vw",
        }}
      >
        {/* <div
          className="d-flex"
          style={{
            textDecoration: "underline",
            justifyContent: "flex-end",
            fontSize: `${1 * fontMultiplierMobile}vw`,
          }}
        >
          <div
            onClick={() => {
              navigate("/signup");
            }}
            className="d-flex"
            style={{
              gap: "1vw",
            }}
          >
            Don't have an account? Claim Wallet{" "}
            <RightArrowIcon width={"1.7vw"} height={"1.7vw"} />
          </div>
        </div> */}

        <h1
          style={{
            fontSize: `${2.2 * fontMultiplierMobile}vw`,
            marginBottom: `${2 * fontMultiplierMobile}vw`,
          }}
        >
          Login
        </h1>
        <form className="d-flex flex-column" onSubmit={handleLoginFormSubmit}>
          <div
            style={{
              marginBottom: `${0.7 * fontMultiplierMobile}vw`,
              fontSize: `${1 * fontMultiplierMobile}vw`,
            }}
          >
            Email Address
          </div>
          <input
            type="email"
            name="username"
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
            style={{
              height: `${3.2 * fontMultiplierMobile}vw`,
              marginBottom: `${1.3 * fontMultiplierMobile}vw`,
              paddingLeft: `${1 * fontMultiplierMobile}vw`,
              borderRadius: `${0.8 * fontMultiplierMobile}vw`,
              border: "1px solid rgb(200, 200, 200)",
              fontSize: `${1.2 * fontMultiplierMobile}vw`,
            }}
          ></input>
          <div
            style={{
              marginBottom: `${0.7 * fontMultiplierMobile}vw`,
              fontSize: `${1 * fontMultiplierMobile}vw`,
            }}
          >
            Your Password
          </div>
          <div style={{ position: "relative", display: "inline-block" }}>
            <input
              type={showPassword ? "text" : "password"}
              name="password"
              style={{
                height: `${3.2 * fontMultiplierMobile}vw`,
                width: `calc(100%)`,
                marginBottom: `${0.2 * fontMultiplierMobile}vw`,
                paddingLeft: `${1 * fontMultiplierMobile}vw`,
                paddingRight: `${3 * fontMultiplierMobile}vw`, // Adjust padding to make space for the icon
                borderRadius: `${0.8 * fontMultiplierMobile}vw`,
                border: "1px solid rgb(200, 200, 200)",
                fontSize: `${1.2 * fontMultiplierMobile}vw`,
              }}
            />
            <img
              src={showPassword ? EyeSlash : Eye}
              alt={showPassword ? "Hide password" : "Show password"}
              onClick={togglePasswordVisibility}
              style={{
                position: "absolute",
                right: `${1 * fontMultiplierMobile}vw`,
                top: "50%",
                transform: "translateY(-50%)",
                cursor: "pointer",
                width: `${2 * fontMultiplierMobile}vw`, // Adjust the size of the icon
                height: `${2 * fontMultiplierMobile}vw`,
              }}
            />
          </div>
          {/* <input
            type="password"
            name="password"
            style={{
              height: `${3.2 * fontMultiplierMobile}vw`,
              marginBottom: `${0.2 * fontMultiplierMobile}vw`,
              paddingLeft: `${1 * fontMultiplierMobile}vw`,
              borderRadius: `${0.8 * fontMultiplierMobile}vw`,
              border: "1px solid rgb(200, 200, 200)",
              fontSize: `${1.2 * fontMultiplierMobile}vw`,
            }}
          ></input> */}
          <div
            style={{
              marginBottom: `${1.5 * fontMultiplierMobile}vw`,
              marginLeft: "83%",
              color: "rgb(150,150,150)",
              fontSize: `${0.8 * fontMultiplierMobile}vw`,
              whiteSpace: "nowrap",
              cursor: "pointer",
            }}
            onClick={() => {
              navigate("/reset-password");
            }}
          >
            Forgot Password
          </div>
          <button
            type="submit"
            className="border-0 login-page-sign-in-animation"
            style={{
              width: "43%",
              height: `${3.5 * fontMultiplierMobile}vw`,
              borderRadius: `${2 * fontMultiplierMobile}vw`,
              fontSize: `${1.3 * fontMultiplierMobile}vw`,
            }}
          >
            Sign in
          </button>
        </form>
        <div
          className="d-flex justify-content-between align-items-center w-100"
          style={{
            marginTop: `${2.5 * fontMultiplierMobile}vw`,
            marginBottom: `${2.5 * fontMultiplierMobile}vw`,
          }}
        >
          <div
            style={{
              width: "45%",
              height: `${0.15 * fontMultiplierMobile}vw`,
              backgroundColor: "rgb(200,200,200)",
            }}
          ></div>
          <div style={{ fontSize: `${1 * fontMultiplierMobile}vw` }}>OR</div>
          <div
            style={{
              width: "45%",
              height: `${0.15 * fontMultiplierMobile}vw`,
              backgroundColor: "rgb(200,200,200)",
            }}
          ></div>
        </div>
        <button
          className="d-flex align-items-center"
          style={{
            height: `${3.5 * fontMultiplierMobile}vw`,
            borderRadius: `${2 * fontMultiplierMobile}vw`,
            border: "1px solid black",
            backgroundColor: "white",
            marginBottom: `${1.2 * fontMultiplierMobile}vw`,
          }}
        >
          <img
            src={GoogleIcon}
            alt="GoogleIcon"
            style={{
              width: `${2.3 * fontMultiplierMobile}vw`,
              marginRight: `${1 * fontMultiplierMobile}vw`,
              marginLeft: "26.5%",
            }}
          />
          <div
            style={{
              fontSize: `${1.3 * fontMultiplierMobile}vw`,
              fontWeight: "lighter",
              marginRight: `${0.6 * fontMultiplierMobile}vw`,
            }}
          >
            Continue with Google
          </div>
        </button>
        <button
          className="d-flex align-items-center"
          style={{
            height: `${3.5 * fontMultiplierMobile}vw`,
            borderRadius: `${2 * fontMultiplierMobile}vw`,
            border: "1px solid black",
            backgroundColor: "white",
          }}
        >
          <img
            src={MetaMaskIcon}
            alt="MetaMaskIcon"
            style={{
              width: `${2.5 * fontMultiplierMobile}vw`,
              marginRight: `${0.89 * fontMultiplierMobile}vw`,
              marginLeft: "26%",
            }}
          />
          <div
            style={{
              fontSize: `${1.3 * fontMultiplierMobile}vw`,
              fontWeight: "lighter",
              marginRight: `${0.6 * fontMultiplierMobile}vw`,
            }}
          >
            Continue with MetaMask
          </div>
        </button>
      </div>
    </div>
  );
}
