import React, { useState } from "react";
import DOMPurify from "dompurify";

const limit = 700;

const ArtistDescription = ({ collectionData }) => {
  const urlReg = /(https?:\/\/[^\s()]+)|(www\.[^\s()]+(\.[^\s()]+)+)/g;
  const [isExpanded, setIsExpanded] = useState(false);

  const toLink = (text) => {
    return text.replace(urlReg, (url) => {
      const href = url.startsWith("http") ? url : `http://${url}`;
      return `<a href="${href}" target="_blank" rel="noopener noreferrer">${url}</a>`;
    });
  };

  const description =
    collectionData?.openseaDetails?.collectionDetails?.description ||
    "No Description provided by the artist.";

  const sanitizedHtml = DOMPurify.sanitize(toLink(description), {
    ALLOWED_ATTR: ["href", "target", "rel"],
  });
  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div className="description-section-content">
      <div
        dangerouslySetInnerHTML={{
          __html:
            description.length > limit + 100
              ? isExpanded
                ? sanitizedHtml
                : DOMPurify.sanitize(toLink(description.slice(0, limit))) +
                  "..."
              : sanitizedHtml,
        }}
      />
      {description.length > limit + 100 && (
        <span
          onClick={toggleExpand}
          style={{ color: "#2fb484", cursor: "pointer" }}
        >
          {isExpanded ? "\nShow less" : "\nShow more"}
        </span>
      )}
    </div>
  );
};

export default ArtistDescription;
