import React, { useEffect, useState } from "react";
import "../../styles/itemPageStyles/ItemPage.css";
import {
  getNameFromCollectionObj,
  getRoyaltiesReceiverFromCollectionObj,
  getRoyaltyFromCollectionObj,
  getSlugFromCollectionObj,
} from "../../utils/commonUtils/CollectionUtils";
import { timestampToDate } from "../../utils/commonUtils/timeUtils";
import CollectionIcons from "./CollectionIcons";
import useIsMobile from "../../customHooks/UseIsMobile";
import { Link } from "react-router-dom";
import { prettyWalletAddress } from "../../utils/commonUtils/PrettyString";
import LoadingText from "../../utils/commonUtils/LoadingText";
import {
  getWalletProfileImage,
  getWalletProfileName,
} from "../../utils/commonUtils/WalletDetailsUtils";
import {
  fetchWalletDetails,
  getWalletAddressByLowerCaseAddress,
} from "../../api/walletDetailApi";
import UserModal from "../../utils/ItemPageUtils/UserModal";
import { trimMiddleText } from "../../utils/commonUtils/Utils";

const CollectionInfo = ({
  collectionData,
  tokenCreator,
  isFetchingCollectionDetails,
}) => {
  const royalties = getRoyaltyFromCollectionObj(collectionData);
  const [collectionCreator, setcollectionCreator] = useState({});
  const [royaltiesRecName, setRoyaltiesRecName] = useState({});
  const [collectionCreatorName, setCollectionCreatorName] = useState(null);
  const [isCreatorWalletAddrHovered, setIsCreatorWalletAddrHovered] =
    useState(false);
  const [isReceiverWalletAddrHovered, setIsReceiverWalletAddrHovered] =
    useState(false);

  async function fetchAndUpdateCreator(collectionCreatorAddress) {
    collectionCreatorAddress = await getWalletAddressByLowerCaseAddress(
      collectionCreatorAddress
    );

    let userDetails = await fetchWalletDetails(collectionCreatorAddress);
    setCollectionCreatorName(getWalletProfileName(userDetails));
  }

  let royaltiesRecWallet = getRoyaltiesReceiverFromCollectionObj(
    collectionData,
    tokenCreator,
    true,
    true
  );

  useEffect(() => {
    if (collectionData?.collectionCreator) {
      fetchAndUpdateCreator(collectionData?.collectionCreator);
    }
    fetchWalletDetails(tokenCreator, true, true)
      .then((walletDetail) => {
        setcollectionCreator(walletDetail);
      })
      .catch((error) => {
        setcollectionCreator({});
      });
    fetchWalletDetails(royaltiesRecWallet)
      .then((walletDetail) => {
        setRoyaltiesRecName(walletDetail);
      })
      .catch((error) => {
        /*Get all the token details*/
        setRoyaltiesRecName({});
      });
  }, [collectionData, tokenCreator]);

  return (
    <div className="collection-info-container">
      {collectionData && !isFetchingCollectionDetails ? (
        <div className="collection-info-collection-name">
          <div>{getNameFromCollectionObj(collectionData)}</div>
          {collectionCreatorName && (
            <Link
              className="collection-info-collection-name-creator"
              to={`/creator/${collectionData?.collectionCreator}`}
            >
              By {collectionCreatorName}
            </Link>
          )}
        </div>
      ) : (
        <div style={{ marginBottom: "2vw" }}>
          <LoadingText height="2vw" width="12vw" />
        </div>
      )}
      <div className="d-flex w-100 align-items-top justify-content-between align-items-center">
        <div className="collection-info-row w-100">
          <span className="collection-info-headings">
            Creator
            <Link
              to={`/creator/${tokenCreator}`}
              className="collection-info-collection-address link-field"
              onMouseEnter={() => setIsCreatorWalletAddrHovered(true)}
              onMouseLeave={() => setIsCreatorWalletAddrHovered(false)}
            >
              {getWalletProfileName(collectionCreator) !== "No Name"
                ? Object.keys(collectionCreator).length > 0
                  ? getWalletProfileName(collectionCreator)
                  : trimMiddleText(tokenCreator)
                : trimMiddleText(tokenCreator)}
            </Link>
            {isCreatorWalletAddrHovered && (
              <UserModal isCreator={true} walletAddress={tokenCreator} />
            )}
          </span>
          <span className="collection-info-collection-address-dot"></span>

          <span className="collection-info-headings">
            Royalties Receiver
            <Link
              to={`/creator/${getRoyaltiesReceiverFromCollectionObj(
                collectionData,
                tokenCreator
              )}`}
              className="collection-info-collection-address link-field"
              onMouseEnter={() => setIsReceiverWalletAddrHovered(true)}
              onMouseLeave={() => setIsReceiverWalletAddrHovered(false)}
            >
              {getWalletProfileName(royaltiesRecName) !== "No Name"
                ? Object.keys(royaltiesRecName).length > 0
                  ? getWalletProfileName(royaltiesRecName)
                  : trimMiddleText(royaltiesRecWallet)
                : trimMiddleText(royaltiesRecWallet)}
              {isReceiverWalletAddrHovered && (
                <UserModal
                  isCreator={true}
                  walletAddress={getRoyaltiesReceiverFromCollectionObj(
                    collectionData,
                    tokenCreator
                  )}
                />
              )}
            </Link>
          </span>
          <span className="collection-info-collection-address-dot"></span>
          {collectionData?.tokensSize && (
            <span className="collection-info-headings">
              Items{" "}
              <span className="collection-info-values">
                {collectionData?.tokensSize}
              </span>
            </span>
          )}
          {collectionData?.tokensSize && (
            <span className="collection-info-collection-address-dot"></span>
          )}
          <span className="collection-info-headings">
            Created{" "}
            <span className="collection-info-values">
              {" "}
              {timestampToDate(
                collectionData?.block_timestamp
                  ? collectionData.block_timestamp
                  : collectionData?.openseaDetails?.collectionDetails
                      ?.created_date
              )}{" "}
            </span>
          </span>
          <span className="collection-info-collection-address-dot"></span>
          <span className="collection-info-headings">
            Royalties{" "}
            <span className="collection-info-values">{royalties}% </span>
          </span>
          <span className="collection-info-collection-address-dot"></span>
          <span className="collection-info-headings">
            Chain{" "}
            <span className="collection-info-values">
              {" "}
              {collectionData?.blockchain || "Ethereum"}
            </span>
          </span>
        </div>
      </div>
    </div>
  );
};

export default CollectionInfo;
