import React from "react";
import { isNullOrUndefined } from "../../utils/commonUtils/Utils";

const calculateRarityScoreForTrait = (
  aggregated_traits,
  traitType,
  traitValue
) => {
  const occurrences = aggregated_traits[traitType];

  if (isNullOrUndefined(occurrences) || occurrences[traitValue] === 0) {
    return 0; // or any other default value as per your requirement
  }

  const totalOccurrences = Object.values(occurrences).reduce(
    (sum, count) => sum + count,
    0
  );
  const traitOccurrence = occurrences[traitValue];

  // Calculate rarity based on the frequency of the trait value among all trait values
  const rarityScore = 1 / (traitOccurrence / totalOccurrences);
  return { traitOccurrence, totalOccurrences, rarityScore };
};

const RarityInfoModal = ({
  traits,
  aggregated_traits,
  itemData,
  collectionData,
}) => {
  //  total rarity score
  const totalRarityScore = traits.reduce((total, trait) => {
    const traitValue = trait.value;
    const rarityInfo = calculateRarityScoreForTrait(
      aggregated_traits,
      trait.trait_type,
      traitValue
    );

    return total + rarityInfo.rarityScore;
  }, 0);

  return (
    <div className="rarity-modal-container">
      <div className="rarity-modal-text">
        <span className="rarity-modal-formula-area">
          <p className="rarity-modal-text">Rarity Score for a Trait Value = </p>
          <div className="fraction">
            <div className="rarity-modal-text">1</div>
            <div className="separator"></div>
            <div className="denominator">
              <div className="rarity-modal-text">
                [No. of Items with that Trait Value]
              </div>
              <div className="separator"></div>
              <div className="rarity-modal-text">
                [Total No. of Items in Collection]
              </div>
            </div>
          </div>
        </span>

        <p className="rarity-modal-text">
          The total Rarity Score for an NFT is the sum of the Rarity Score of
          all of its trait values.
        </p>
      </div>

      <div className="trait-value-container-rarity-modal">
        {traits?.map((trait, index) => (
          <div style={{ width: "auto" }} key={index}>
            {Object.entries(trait).map(([key, value], i) => (
              <React.Fragment key={i}>
                {key === "trait_type" ? (
                  <div style={{ width: "100%" }}>
                    <div className="trait-value-rarity-modal">
                      {value} ({trait.value}) :
                      <span className="trait-value-rarity-modal-values">
                        1 / (
                        {calculateRarityScoreForTrait(
                          aggregated_traits,
                          value,
                          trait.value
                        ).traitOccurrence +
                          " / " +
                          calculateRarityScoreForTrait(
                            aggregated_traits,
                            value,
                            trait.value
                          ).totalOccurrences}
                        ) ={" "}
                        {calculateRarityScoreForTrait(
                          aggregated_traits,
                          value,
                          trait.value
                        ).rarityScore.toFixed(2)}
                      </span>
                    </div>
                  </div>
                ) : (
                  <h6 className="trait-floor-value">{}</h6>
                )}
              </React.Fragment>
            ))}
          </div>
        ))}
      </div>
      <div className="total-rarity-value-rarity-modal ">
          Total Rarity Score for this NFT: {totalRarityScore.toFixed(2)}
        </div>
    </div>
  );
};

export default RarityInfoModal;
