import ItemPageContentBox from "../../../utils/ItemPageUtils/ItemPageContentBox";
import "../../../styles/itemPageStyles/ItemPage.css";
import { useEffect, useState } from "react";
import tokenApis from "../../../api/tokenApis";
import Tab from "../../../utils/ItemPageUtils/Tab";
import CurrentPrices from "./CurrentPrices";
import HistoryPrices from "./HistoryPrices";
import Loans from "./Loans";
import EditIcon from "../../../Icons/EditIcon";
import RefreshIcon from "../../../Icons/RefreshIcon";
import MarketplacesModal from "../../../utils/ItemPageUtils/marketplacesModal";
import useIsMobile from "../../../customHooks/UseIsMobile.js";
import AddInfoModal from "../../CommonComponents/AddInfoModal.js";

import { createPortal } from "react-dom";
import useMarketplaces from "../../../utils/ItemPageUtils/useMarketplaces";
import ArrowIconReverse from "../../../Icons/ArrowIconReverse";
import ArrowIcon from "../../../Icons/ArrowIcon";

export default function ListingPrice({ itemData, setItemData }) {
  const [activeTab, setActiveTab] = useState(0);
  const [tokenListingsPricesAPIData, setTokenListingsPricesAPIData] = useState({
    contractTokenId: "",
    contractAddress: "",
    tokenId: "",
    pricing: {},
  });

  const [showModal, setShowModal] = useState(false); // Add modal state
  const [selectedMarketplaceForListing, setSelectedMarketplaceForListing] =
    useState(["blur", "opensea", "looksrare"]); // Add selected listings state

  const [tabToggles, setTabToggles] = useState([true, true, true]);

  const isMobile = useIsMobile();

  const toggleTab = (index) => {
    const newToggles = [...tabToggles];
    newToggles[index] = !newToggles[index];
    setTabToggles(newToggles);
  };

  const handleTabClick = (index) => {
    setActiveTab(index);
  };

  const handleEditClick = () => {
    setShowModal(true); // Show the modal when EditIcon is clicked
  };

  // Function to update selected listings
  const handleListingsSelect = (selected) => {
    setSelectedMarketplaceForListing(selected);
    setShowModal(false); // Close the modal
  };

  // Function to update selected listings
  const getUpdatedListingPrice = async (contractAddress, tokenId) => {
    const listingData = await tokenApis.fetchUpdatedTokenListings(
      contractAddress,
      tokenId
    );

    if (listingData) setTokenListingsPricesAPIData(listingData);
  };

  useEffect(() => {
    async function fetchTokenListingsPricesAPIData(contractAddress, tokenId) {
      try {
        const listingData = await tokenApis.getAllListingOfToken(
          contractAddress,
          tokenId
        );

        if (listingData) setTokenListingsPricesAPIData(listingData);
      } catch (error) {
        setTokenListingsPricesAPIData({
          contractTokenId: contractAddress + "-" + tokenId,
          contractAddress,
          tokenId,
          pricing: {},
        });
      }
    }

    fetchTokenListingsPricesAPIData(
      itemData?.contractAddress,
      itemData?.tokenId
    );
  }, [itemData]);

  const [boxOpen, setBoxOpen] = useState(false);

  useEffect(() => {
    let totalMarketplacesWithPrices = Object.values(
      tokenListingsPricesAPIData?.pricing || {}
    ).filter((marketplacePrice) => {
      return (
        marketplacePrice?.lastFetchListingPrice &&
        marketplacePrice?.lastFetchListingPrice > 0
      );
    }).length;

    if (totalMarketplacesWithPrices === 0) {
      setBoxOpen(false);
    } else {
      setBoxOpen(true);
    }
  }, [tokenListingsPricesAPIData]);

  const itemPageDom = document.getElementById("itemPageBackground");

  return (
    <ItemPageContentBox>
      <div className=" listing-price-container">
        {tabToggles[0] && (
          <Tab
            label="Current Prices"
            active={activeTab === 0}
            onClick={() => handleTabClick(0)}
          />
        )}
        <div className="listing-price-icons">
          <AddInfoModal
            info={"Edit Marketplaces"}
            renderDirection={"bottom-left"}
            styleObj={{ fontWeight: "bold" }}
          >
            <button
              className={
                isMobile
                  ? "listing-price-edit-button-mobile"
                  : "listing-price-edit-button"
              }
              onClick={handleEditClick}
            >
              {" "}
              <EditIcon />{" "}
            </button>
          </AddInfoModal>

          <AddInfoModal
            info={"Refresh"}
            renderDirection={"bottom-left"}
            styleObj={{ fontWeight: "bold", cursor: "pointer" }}
          >
            <button
              onClick={() => {
                getUpdatedListingPrice(
                  itemData?.contractAddress,
                  itemData?.tokenId
                );
              }}
              style={{ backgroundColor: "transparent", border: "none" }}
            >
              <RefreshIcon color="#747474" />
            </button>
          </AddInfoModal>

          <AddInfoModal
            info={"Toggle Box"}
            renderDirection={"bottom-left"}
            styleObj={{ fontWeight: "bold", cursor: "pointer" }}
          >
            <button
              onClick={() => {
                setBoxOpen(!boxOpen);
              }}
              style={{ backgroundColor: "transparent", border: "none" }}
            >
              {boxOpen ? (
                <ArrowIcon color="#747474" />
              ) : (
                <ArrowIconReverse color="#747474" />
              )}
            </button>
          </AddInfoModal>
        </div>
      </div>

      {activeTab === 0 && tabToggles[0] && boxOpen ? (
        itemData && selectedMarketplaceForListing ? (
          <CurrentPrices
            tokenListingsPricesAPIData={tokenListingsPricesAPIData}
            selectedMarketplaceForListing={selectedMarketplaceForListing}
            itemData={itemData}
          />
        ) : (
          <div style={{ padding: "10px 0px" }}>
            Data unavailable at this moment.{" "}
          </div>
        )
      ) : null}

      {showModal &&
        createPortal(
          <MarketplacesModal
            tokenListingsPricesAPIData={tokenListingsPricesAPIData}
            selectedMarketplaceForListing={selectedMarketplaceForListing}
            onSelect={handleListingsSelect}
            tabToggles={tabToggles}
            toggleTab={toggleTab}
            onClose={() => setShowModal(false)}
            openModal={showModal}
          />,
          itemPageDom
        )}
    </ItemPageContentBox>
  );
}
