import React, { useState } from "react";
import { isNullOrUndefinedOrEmptyObject } from "../../utils/commonUtils/Utils";
import {
  fetchAdjustedSalesPriceTransferEth,
  fetchAdjustedSalesPriceTransferEthUI,
  fetchAdjustedSalesPriceTransferUSD,
  fetchFromUserNFTTransfer,
  fetchQuantityFromNftTransfer,
  fetchSalesPriceTransferEth,
  fetchSalesPriceTransferEthUI,
  fetchSalesPriceTransferUSD,
  fetchTimeOfTransfer,
  fetchToUserNFTTransfer,
  fetchTransferType,
  getDisplayImageUrlFromNft,
} from "../../utils/commonUtils/TransferHistoryUtil";

import ActivityUserCell from "./ActivityUserCell";
import {
  fetchTokenDefaultImageOnError,
  fetchOriginalImageURLFromTokenObj,
} from "../../utils/commonUtils/TokenUtils";
import { MdDoNotDisturbOn } from "react-icons/md";
import { FaCircleCheck } from "react-icons/fa6";
import AddInfoModal from "./AddInfoModal";
import useIsMobile from "../../customHooks/UseIsMobile";

const OtherActivityColumns = ({
  item,
  showImageAvailable,
  walletProfileFromName,
  walletProfileToName,
  originalImageHostedMap,
  updateOriginalImageHosted,
}) => {
  return (
    <React.Fragment>
      <table style={{ width: "100%", fontSize: "0.7rem" }}>
        <thead>
          <tr className={item.index % 2 === 0 ? "even-row" : "odd-row"}>
            <th>Event</th>
            <th>Quantity</th>
            <th>From</th>
            <th>To</th>
            <th>Time</th>
            {showImageAvailable && (
              <th>
                <AddInfoModal
                  info={"Verify the hosting status of the NFT image link."}
                  renderDirection={"up-left"}
                  styleObj={{ fontWeight: "bold", cursor: "pointer" }}
                >
                  Image
                </AddInfoModal>{" "}
              </th>
            )}
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <div style={{ color: "green" }}>{fetchTransferType(item)}</div>
            </td>
            <td>
              <span>
                {fetchQuantityFromNftTransfer(item) > 100
                  ? `> 100`
                  : fetchQuantityFromNftTransfer(item)}
              </span>
            </td>
            <td>
              <ActivityUserCell
                walletProfileName={walletProfileFromName}
                wallet_addr={fetchFromUserNFTTransfer(item)}
                showIcons={true}
              />
            </td>
            <td>
              <ActivityUserCell
                walletProfileName={walletProfileToName}
                wallet_addr={fetchToUserNFTTransfer(item)}
                showIcons={true}
              />
            </td>
            <td
              style={{ cursor: "pointer" }}
              title={fetchTimeOfTransfer(item, true)}
            >
              <span className="transfer-history-table-cell">
                {fetchTimeOfTransfer(item, false)}
              </span>
            </td>

            <td>
              <img
                src={getDisplayImageUrlFromNft(item?.tokenDetails)}
                alt="NFT "
                style={{ position: "fixed", height: "0", width: "0" }}
                onLoad={() => {
                  updateOriginalImageHosted(
                    item?.nft?.contract + "-" + item?.nft?.identifier,
                    "true"
                  );
                }}
                onError={() => {
                  updateOriginalImageHosted(
                    item?.nft?.contract + "-" + item?.nft?.identifier,
                    "false"
                  );
                }}
              />
              {
                <div>
                  {originalImageHostedMap[
                    item?.nft?.contract + "-" + item?.nft?.identifier
                  ] === "true" ? (
                    <span
                      title="Image Source Verified"
                      style={{ cursor: "pointer" }}
                    >
                      <FaCircleCheck size={"1.2em"} color="#2fb484" />
                    </span>
                  ) : originalImageHostedMap[
                      item?.nft?.contract + "-" + item?.nft?.identifier
                    ] === "false" ? (
                    <span
                      title="Image Source Missing"
                      style={{ cursor: "pointer" }}
                    >
                      <FaCircleCheck size={"1.2em"} color="red" />
                    </span>
                  ) : (
                    <span
                      title="Verifying Image Source"
                      style={{ cursor: "pointer" }}
                    >
                      <MdDoNotDisturbOn size={"1.2em"} color="orange" />
                    </span>
                  )}
                </div>
              }
            </td>
          </tr>
        </tbody>
      </table>
    </React.Fragment>
  );
};

export default OtherActivityColumns;
