import React, { useState } from "react";
import "../../../styles/itemPageStyles/ItemPage.css";
import Details from "./Details";
import { getNameFromCollectionObj } from "../../../utils/commonUtils/CollectionUtils";
import InfoComponent from "../../CommonComponents/InfoComponent";
import { fetchDescriptionFromTokenObj } from "../../../utils/commonUtils/TokenUtils";
import DOMPurify from "dompurify";
import useIsMobile from "../../../customHooks/UseIsMobile";

const limit = 500;

const Description = ({ data, collectionData }) => {
  const isMobile = useIsMobile();
  const [isExpanded, setIsExpanded] = useState(false);
  const description = fetchDescriptionFromTokenObj(data);

  const urlReg = /(https?:\/\/[^\s()]+)|(www\.[^\s()]+(\.[^\s()]+)+)/g;

  const toLink = (text) => {
    return text.replace(urlReg, (url) => {
      const href = url.startsWith("http") ? url : `http://${url}`;
      return `<a href="${href}" target="_blank" rel="noopener noreferrer">${href}</a>`;
    });
  };

  const sanitizedDescription = DOMPurify.sanitize(toLink(description), {
    ALLOWED_ATTR: ["href", "target", "rel"],
  });

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <>
      <div
        className="tab-content-heading"
        style={{ fontSize: isMobile && "1.2rem" }}
      >
        {getNameFromCollectionObj(collectionData)}
      </div>

      <p
        className="tab-content-body"
        style={{ whiteSpace: "pre-wrap", fontSize: isMobile && "1rem" }}
      >
        {description.length > limit + 50 ? (
          isExpanded ? (
            <span
              style={{ fontSize: "inherit" }}
              dangerouslySetInnerHTML={{ __html: sanitizedDescription }}
            />
          ) : (
            <span
              style={{ fontSize: "inherit" }}
              dangerouslySetInnerHTML={{
                __html: `${sanitizedDescription.slice(0, limit)}...`,
              }}
            />
          )
        ) : (
          <span dangerouslySetInnerHTML={{ __html: sanitizedDescription }} />
        )}
        {description.length > limit + 50 && (
          <span onClick={toggleExpand}>
            <p
              style={{
                color: "#2fb484",
                cursor: "pointer",
                fontSize: isMobile ? "1rem" : "0.9em",
                fontWeight: "600",
              }}
            >
              {isExpanded ? "Show Less" : "Show More"}
            </p>
          </span>
        )}
      </p>

      {/* <div
        className="tab-content-heading"
        style={{
          display: "flex",
          flexDirection: "row",
          gap: "0.3em",
          justifyContent: "flex-start",
          alignItems: "flex-start",
          lineHeight: "normal",
        }}
      >
        By MetaJungle{" "}
        <InfoComponent
          iconWidth={"1em"}
          iconHeight={"1em"}
          info="Description of the token generated by MetaJungle."
        />{" "}
      </div>
      <p className="tab-content-body">
        {data?.mjDescription ||
          "MetaJungle description unavailable at the moment."}
      </p> */}
      <Details data={data} collectionData={collectionData} />
    </>
  );
};

export default Description;
