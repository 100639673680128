const ArrowIconReverse = ({ color, width = "1em", height = "1em" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 19 10"
      fill="none"
    >
      <path
        d="M1.66173 0.328308C1.97318 0.327697 2.2751 0.436197 2.51503 0.634976L9.66173 6.60831L16.8217 0.848308C16.9581 0.737562 17.115 0.65485 17.2835 0.604946C17.452 0.555041 17.6287 0.538924 17.8034 0.557509C17.9781 0.576097 18.1474 0.62903 18.3015 0.713244C18.4557 0.797477 18.5917 0.911343 18.7017 1.04831C18.8125 1.1847 18.8952 1.34162 18.9451 1.51008C18.995 1.67853 19.0111 1.85518 18.9925 2.02989C18.9739 2.20459 18.921 2.3739 18.8368 2.52808C18.7526 2.68226 18.6387 2.81827 18.5017 2.92831L10.5017 9.36831C10.2631 9.56442 9.96386 9.67163 9.65503 9.67163C9.34619 9.67163 9.04694 9.56442 8.80839 9.36831L0.808392 2.70165C0.673296 2.58971 0.561721 2.45225 0.479933 2.29712C0.398144 2.14199 0.34774 1.97224 0.331618 1.79761C0.315497 1.62297 0.333982 1.44688 0.386089 1.2794C0.438194 1.11193 0.522781 0.956378 0.635018 0.82165C0.75913 0.668701 0.91553 0.545127 1.093 0.459828C1.27047 0.374528 1.46471 0.329615 1.66173 0.328308Z"
        fill={color || "black"}
      />
    </svg>
  );
};

export default ArrowIconReverse;
