import { formatNumber } from "./PriceUtils";
import { isNullOrUndefinedOrEmptyObject } from "./Utils";

export default function LastUpdatedTimestamp(epochTime) {
  const currentDate = new Date();
  const currentDateEpoch = currentDate.getTime();

  // Calculate the time difference in seconds
  const timeDifference = Math.floor((currentDateEpoch - epochTime) / 1000);

  const secondsAgo = timeDifference;
  const minutesAgo = Math.floor(secondsAgo / 60);
  const hoursAgo = Math.floor(minutesAgo / 60);
  const daysAgo = Math.floor(hoursAgo / 24);

  if (daysAgo > 0) {
    return `${daysAgo} day${daysAgo === 1 ? "" : "s"} ago`;
  } else if (hoursAgo > 0) {
    return `${hoursAgo} hour${hoursAgo === 1 ? "" : "s"} ago`;
  } else if (minutesAgo > 0) {
    return `${minutesAgo} min${minutesAgo === 1 ? "" : "s"} ago`;
  } else {
    return `${secondsAgo} second${secondsAgo === 1 ? "" : "s"} ago`;
  }
}

export const getTimeAgoFromTimestamp = (timestamp) => {
  if (!timestamp) {
    return "Never Happened";
  }
  const now = new Date();
  const timeDiff = now - new Date(timestamp * 1000);
  const seconds = Math.floor(timeDiff / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);

  if (days > 0) {
    return `${formatNumber(days)} Days ago`;
  } else if (hours > 0) {
    return `${hours} Hrs ago`;
  } else if (minutes > 0) {
    return `${minutes} Mins ago`;
  } else {
    return `${seconds} Secs ago`;
  }
};

export const getTimeAgoFromDate = (date) => {
  if (isNullOrUndefinedOrEmptyObject(date)) {
    return null;
  }

  const now = new Date();
  const timeDiff = now - new Date(date);
  const seconds = Math.floor(timeDiff / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);

  if (days > 0) {
    if (days === 1) return `1 Day Ago`;
    return `${days} Days Ago`;
  } else if (hours > 0) {
    return `${hours} Hrs Ago`;
  } else if (minutes > 0) {
    return `${minutes} Mins Ago`;
  } else {
    return `${seconds} Secs Ago`;
  }
};

export function getDaysAgoText(date) {
  const currentDate = new Date();
  const diffTime = Math.abs(currentDate - new Date(date));
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

  if (diffDays === 0) {
    return "Today";
  } else if (diffDays === 1) {
    return "1 day ago";
  } else {
    return `${diffDays} days ago`;
  }
}

export function timestampToDate(timestamp) {
  if (!timestamp) return "";
  const date = new Date(timestamp * 1000)?.toLocaleDateString();
  if (date === "Invalid Date") return timestamp;
  return date;
}

export function parseDateStringToYYYYMMDD(isoDateString) {
  const date = new Date(isoDateString);
  const year = date.getFullYear();
  // Get month and day with padding since getMonth() returns 0-11 and getDate() returns 1-31
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");

  return `${month}/${day}/${year}`;
}

export function secondsToDays(seconds) {
  if (!seconds) {
    return 0;
  }

  let days = seconds / 60 / 60 / 24;
  days = days.toFixed(2);
  return days;
}
