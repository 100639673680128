import React, { useEffect, useRef, useState } from "react";
import ArrowIcon from "../../Icons/ArrowIcon";
import ArrowIconReverse from "../../Icons/ArrowIconReverse";
import "../../styles/foldersPageStyles/FoldersPage.css";

const CustomDropdown = ({
  id,
  options,
  onChange,
  smallerDropDown,
  selectedOption,
  defaultPlaceholder,
  styleObj,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");

  const filteredOptions = options.filter((option) =>
    option.text.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const handleOptionClick = (option) => {
    onChange(option);
    setIsOpen(false);
  };

  const modalRef = useRef(null);

  useEffect(() => {
    const handleOutsideTap = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    if (isOpen) {
      document.addEventListener("mousedown", handleOutsideTap);
    }

    return () => {
      document.removeEventListener("mousedown", handleOutsideTap);
    };
  }, [isOpen]);

  return (
    <div
      className={
        !smallerDropDown ? "custom-dropdown" : "custom-dropdown-smaller"
      }
      onClick={() => setIsOpen(!isOpen)}
      ref={modalRef}
      style={{ ...styleObj }}
    >
      <div
        style={{
          display: "flex",
          width: "100%",
          paddingRight: "calc(var(--base-font-size-folders-page)* 0.3)",
          justifyContent: "space-between",
          alignItems: "flex-end",
        }}
      >
        <input
          type="text"
          style={{ fontSize: "calc(var(--base-font-size-folders-page)* 0.3)" }}
          placeholder={
            selectedOption
              ? selectedOption.text === "All"
                ? defaultPlaceholder
                : selectedOption.text
              : defaultPlaceholder
          }
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          className="custom-dropdown-search"
        />
        <span>
          {isOpen ? (
            <ArrowIcon color={"grey"} width={"0.8em"} height={"0.8em"} />
          ) : (
            <ArrowIconReverse color={"grey"} width={"0.8em"} height={"0.8em"} />
          )}
        </span>
      </div>

      {isOpen && (
        <div className="custom-dropdown-options">
          {filteredOptions.map((option) => (
            <div
              key={option.key}
              className="custom-dropdown-option"
              onClick={() => handleOptionClick(option)}
            >
              {option.text}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default CustomDropdown;
