import React, { useState } from "react";
import "../../styles/collectionPageStyles/CollectionPage.css";
import FourGridViewIcon from "../../Icons/CollectionPageIcons/FourGridViewIcon";
import ThreeGridViewIcon from "../../Icons/CollectionPageIcons/ThreeGridViewIcon";

const ToggleGridButton = ({ isBiggerColumnGrid, toggleGridLayout }) => {
  const [isGrid, setIsGrid] = useState(true);

  const toggleView = () => {
    setIsGrid((prevIsGrid) => !prevIsGrid);
    toggleGridLayout();
  };

  return (
    <div className="ToggleGridButton" onClick={toggleView}>
      <span
        className={`grid-button ${
          isBiggerColumnGrid ? "grid-button-active" : ""
        }`}
      >
        <FourGridViewIcon />
      </span>
      <span
        className={`grid-button ${
          !isBiggerColumnGrid ? "grid-button-active" : ""
        }`}
      >
        <ThreeGridViewIcon />
      </span>
    </div>
  );
};

export default ToggleGridButton;
