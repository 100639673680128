import React, { useState, useEffect } from "react";
import axios from "axios";
import "../../../styles/itemPageStyles/ItemPage.css";
import EnlargeIcon from "../../../Icons/EnlargeIcon";
import ThreeDotsIcon from "../../../Icons/ThreeDotsIcon";
import LineBetweenIcon from "../../../Icons/LineBetweenIcon";
import LikeIcon from "../../../Icons/LikeIcon";
import MoreOptionsMenu from "../../../utils/ItemPageUtils/MoreOptionsMenu";
import useIsMobile from "../../../customHooks/UseIsMobile.js";
import InfoIcon from "../../../Icons/InfoIcon";
import ImageInfoModal from "./ImageInfoModal";
import {
  checkTokenBurned,
  fetchTokenDefaultImageOnError,
  fetchImageURLFromTokenObj,
  fetchOriginalImageURLFromTokenObj,
  getDefaultImageUrl,
} from "../../../utils/commonUtils/TokenUtils";
import FavouritesIconImage from "../../../Icons/FavouriteIconImage";
import FavouriteIconFilled from "../../../Icons/FavouriteIconFilled";
import InfoTabsToggleModal from "../InfoTabs/InfoTabsToggleModal";
import ImageEnlargeModal from "./ImageEnlargeModal";
import { isNullOrUndefinedOrEmptyObject } from "../../../utils/commonUtils/Utils";
import { fetchURLDataFromProxy } from "../../../api/imageApi.js";
import { addContentsInFolder } from "../../../api/foldersApi.js";
import { useSelector } from "react-redux";
import ImgSrcMissingImg from "../../../assets/images/imageSRCmissing.png";
import {
  addItemInUserFavourite,
  checkIsFavourite,
  removeItemFromFavourite,
} from "../../../api/mjUserApi.js";
import AddInfoModal from "../../CommonComponents/AddInfoModal";

const ItemImage = ({ itemData }) => {
  const [isHovered, setIsHovered] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isInfoModalOpen, setInfoModalOpen] = useState(false);
  const [isFavourite, setIsFavourite] = useState(false);
  const [originalImageHosted, setOriginalImageHosted] = useState(true);
  const [isImageEnlargeModalOpen, setIsImageEnlargeModalOpen] = useState(false);
  const [isCheckingOriginalImageHosted, setIsCheckingOriginalImageHosted] =
    useState(true);
  const [imageSource, setImageSource] = useState("");
  const [burned, setBurned] = useState(false);

  const isMobile = useIsMobile();
  const userId = useSelector((state) => state.user.userInfo.userId);

  useEffect(() => {
    setIsCheckingOriginalImageHosted(true);
    if (!itemData) return;
    const imageUrl = fetchImageURLFromTokenObj(itemData, true);
    setImageSource(imageUrl);

    if (!userId) return;
    // checkIsFavourite(userId, itemData.contractAddress + "-" + itemData.tokenId)
    //   .then((isFavourite) => {
    //     setIsFavourite(isFavourite);
    //   })
    //   .catch((e) => {
    //     console.error("Error in fetching favourite status", e);
    //   });

    if (checkTokenBurned(itemData)) {
      setBurned(true);
    } else {
      setBurned(false);
    }
  }, [itemData]);

  const handleMouseEnter = () => {
    setIsHovered(true);
    setIsMenuOpen(false);
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const toggleInfoModal = () => {
    setInfoModalOpen(!isInfoModalOpen);
  };

  const handleFavourite = async () => {
    const tokenToAddToFavorites =
      itemData.contractAddress + "-" + itemData.tokenId;
    if (!userId) return;
    try {
      if (isFavourite) {
        await removeItemFromFavourite(userId, tokenToAddToFavorites);
      } else {
        await addItemInUserFavourite(userId, tokenToAddToFavorites, "TOKEN");
      }
      setIsFavourite(!isFavourite);
    } catch (e) {}
  };

  const openImageModal = () => {
    setIsImageEnlargeModalOpen(true);
  };

  const closeImageModal = () => {
    setIsImageEnlargeModalOpen(false);
  };

  return (
    <div
      className={
        isMobile
          ? "item-image-details-container-mobile"
          : "item-image-details-container"
      }
    >
      {!isMobile && burned && (
        <div style={{ position: "absolute", zIndex: "2", padding: "1vw" }}>
          <div
            style={{
              color: "white",
              fontSize: "1em",
              fontWeight: "600",
              border: "1px solid red",
              backgroundColor: "red",
              padding: "0.3em",
              display: "flex",
              lineHeight: "normal",
              justifyContent: "center",
              alugnItems: "center",
              borderRadius: "0.5em",
            }}
          >
            Burned (No Longer Available)
          </div>
        </div>
      )}
      <div
        className={
          isMobile ? "item-image-container-mobile" : "item-image-container"
        }
        onMouseEnter={() => handleMouseEnter(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        {imageSource && (
          <img
            src={imageSource}
            alt="Token Img"
            className={isMobile ? "item-image-mobile" : "item-image"}
            onError={(event) => {
              event.target.src = fetchTokenDefaultImageOnError(itemData);
            }}
            style={{
              height: "100%",
              width: "100%",
              objectFit: "cover",
              padding: imageSource === ImgSrcMissingImg && "0.5em",
            }}
          />
        )}
        <img
          src={fetchOriginalImageURLFromTokenObj(itemData)}
          style={{ position: "fixed", height: "0", width: "0" }}
          onLoad={() => {
            setOriginalImageHosted(true);
            setIsCheckingOriginalImageHosted(false);
          }}
          onError={(event) => {
            event.target.src = ImgSrcMissingImg;
            setOriginalImageHosted(false);
            setIsCheckingOriginalImageHosted(false);
          }}
        />
        {/* Buttons */}
        {(isHovered || isMobile) && (
          <div
            className={
              isMobile
                ? "item-page-image-button-placeholder-mobile"
                : "item-page-image-button-placeholder"
            }
          >
            {isMobile ? (
              <div className="button-image-mobile">
                {/* <span onClick={() => openImageModal()}>
                  {" "}
                  <EnlargeIcon />{" "}
                </span>
                <div className="line-between-elements"></div> */}
                <div onClick={toggleMenu}>
                  <ThreeDotsIcon />
                </div>
              </div>
            ) : (
              <div className="button-image">
                <span onClick={() => openImageModal()}>
                  {" "}
                  <EnlargeIcon />{" "}
                </span>
                <div className="line-between-elements"></div>
                <div onClick={toggleMenu}>
                  <ThreeDotsIcon />
                </div>
              </div>
            )}
            {isMenuOpen && (
              <div className="menu">
                <MoreOptionsMenu itemData={itemData} />
              </div>
            )}
          </div>
        )}
      </div>

      <div
        className="item-page-image-bot-box-container"
        style={{ height: "calc(var(--base-font-size-item-page) * 1.2)" }}
      >
        {isCheckingOriginalImageHosted && (
          <div
            className="d-flex align-items-center gap-2 w-100"
            style={{ height: "100%" }}
          >
            <div
              style={{
                backgroundColor: "orange",
                width: "15%",
                height: "46%",
              }}
              className="rounded-3"
            />
            <div className="item-image-bar-at-the-bottom-container-input">
              Checking Image Status
            </div>
          </div>
        )}
        {!isCheckingOriginalImageHosted && (
          <div
            style={{
              backgroundColor: originalImageHosted ? "green" : "red",
              width: "15%",
              height: "46%",
            }}
            className="rounded-3"
          />
        )}
        {!isCheckingOriginalImageHosted && (
          <div
            style={{
              color: originalImageHosted ? "green" : "red",
              whiteSpace: "nowrap",
            }}
            className="d-flex align-items-center item-image-bar-at-the-bottom-container-input"
          >
            {originalImageHosted
              ? "Original Source Verified"
              : "Original Source Missing!"}
          </div>
        )}
        {!originalImageHosted && !isCheckingOriginalImageHosted && (
          <span
            onMouseEnter={() => toggleInfoModal(true)}
            onMouseLeave={() => toggleInfoModal(false)}
            style={{ cursor: "pointer" }}
          >
            <InfoIcon />
            {isInfoModalOpen && <ImageInfoModal />}
          </span>
        )}

        <div style={{ flexGrow: 1 }}></div>

        {/* {userId && (
          <AddInfoModal
            info={"Add to Favorites"}
            renderDirection={"up-left"}
            styleObj={{ fontWeight: "bold", cursor: "pointer" }}
          >
            <span onClick={handleFavourite} style={{ cursor: "pointer" }}>
              {" "}
              {isFavourite ? (
                <FavouriteIconFilled />
              ) : (
                <FavouritesIconImage />
              )}{" "}
            </span>
          </AddInfoModal>
        )} */}
      </div>
      {isImageEnlargeModalOpen && (
        <ImageEnlargeModal
          closeModal={closeImageModal}
          itemData={itemData}
          openModal={openImageModal}
        />
      )}
    </div>
  );
};

export default ItemImage;
