import React, { useState, useEffect } from "react";
import { getNameFromCollectionObj } from "../utils/commonUtils/CollectionUtils";
import {
  fetchImageURLFromTokenObj,
  fetchTokenDefaultImageOnError,
  fetchTokenNameFromTokenObj,
  getTokenRankUI,
} from "../utils/commonUtils/TokenUtils";
import { Link } from "react-router-dom";
import LoadingSpinner from "../utils/commonUtils/LoadingSpinner";
import ImgSrcMissingImg from "../assets/images/imageSRCmissing.png";
import ScrollableContainerHorizontal from "../components/CommonComponents/ScrollableContainerHorizontal";

const RecommendationsFromCollection = ({
  itemData,
  isFetchingData,
  collectionData,
  itemsFromCollection,
  isFetchingItemsFromCollection,
  totalCount,
}) => {
  const filteredItems = itemsFromCollection.filter(
    (token) => token.tokenId !== itemData.tokenId
  );

  return (
    <div className="collection-tab-container">
      {/* <br /> */}
      {filteredItems.length > 0 && (
        <div className="collection-tab-heading">More From This Collection</div>
      )}
      <ScrollableContainerHorizontal
        styleObj={{ width: "96%", marginLeft: "2%" }}
      >
        {isFetchingData || isFetchingItemsFromCollection ? (
          <LoadingSpinner />
        ) : (
          <div
            className="collection-tab"
            style={{
              display: "flex",
            }}
          >
            {filteredItems?.map((token, index) => (
              <Link
                to={`/collection/${token?.contractAddress}/id/${token?.tokenId}`}
                key={index}
                className={"recommendationsFromCollection-token-box"}
                style={{
                  textDecoration: "none",
                  color: "inherit",
                  cursor: "pointer",
                }}
              >
                <div className="recommendationsFromCollection-token-info-box">
                  <div className="recommendationsFromCollection-token-image-container">
                    <img
                      className="recommendationsFromCollection-token-image"
                      src={fetchImageURLFromTokenObj(token)}
                      alt={token.name}
                      style={{
                        padding:
                          fetchImageURLFromTokenObj(token) === ImgSrcMissingImg
                            ? "0.5em"
                            : undefined,
                        objectFit:
                          fetchImageURLFromTokenObj(token) === ImgSrcMissingImg
                            ? "contain"
                            : undefined,
                      }}
                      onError={(event) => {
                        event.target.src = fetchTokenDefaultImageOnError(token);
                      }}
                    />
                  </div>
                  <div className="recommendationsFromCollection-collection-name">
                    {getNameFromCollectionObj(collectionData)}
                  </div>

                  <div
                    className={
                      getTokenRankUI(token) !== "UnRanked (No Traits)"
                        ? "recommendationsFromCollection-token-name"
                        : "recommendationsFromCollection-token-name no-rank"
                    }
                  >
                    {fetchTokenNameFromTokenObj(token, 18)}
                  </div>

                  {getTokenRankUI(token) !== "UnRanked (No Traits)" && (
                    <div className="recommendationsFromCollection-token-ranking-heading">
                      {getTokenRankUI(token) !== "UnRanked (No Traits)" && (
                        <strong> Rarity Rank: </strong>
                      )}
                      <span className="recommendationsFromCollection-token-ranking">
                        {getTokenRankUI(token)}
                      </span>
                      {(totalCount || collectionData?.tokensSize) && (
                        <>
                          {" "}
                          /
                          <span className="recommendationsFromCollection-total-tokens">
                            {collectionData?.tokensSize || totalCount}
                          </span>
                        </>
                      )}
                    </div>
                  )}
                </div>
              </Link>
            ))}
          </div>
        )}
      </ScrollableContainerHorizontal>
    </div>
  );
};

export default RecommendationsFromCollection;
