const ws = new WebSocket("wss://ws-feed.exchange.coinbase.com");
let ethPrice = 0;
ws.onopen = () => {
  setTimeout(() => {
    ws.close();
  }, 120000);
  ws.send(
    JSON.stringify({
      type: "subscribe",
      product_ids: ["ETH-USD"],
      channels: [
        "level2",
        {
          name: "ticker",
          product_ids: ["ETH-USD"],
        },
      ],
    })
  );
  ws.onmessage = (e) => {
    const data = JSON.parse(e.data);
    ethPrice = data.price;
  };
};

export function getEthToUsdExchangeRate() {
  return ethPrice;
}
