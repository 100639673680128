import React from "react";
import { Link } from "react-router-dom";

const UserReport = ({ url, width, height, title }) => {
  return (
    <Link to={url} title={title || `Link to Wallet Report page!`}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width ? width : "1.5em"}
        height={height ? height : "1.5em"}
        viewBox="0 1 20 20"
        fill="none"
      >
        <path
          d="M6.5625 11.8125H11.8125V13.125H6.5625V11.8125Z"
          fill="#505050"
        />
        <path
          d="M6.5625 8.53125L14.4375 8.53125V9.84375L6.5625 9.84375V8.53125Z"
          fill="#505050"
        />
        <path
          d="M6.5625 15.0938H9.84375V16.4062H6.5625V15.0938Z"
          fill="#505050"
        />
        <path
          d="M16.4063 3.28125L14.4375 3.28125V2.625C14.4375 2.2769 14.2992 1.94306 14.0531 1.69692C13.8069 1.45078 13.4731 1.3125 13.125 1.3125L7.875 1.3125C7.5269 1.3125 7.19306 1.45078 6.94692 1.69692C6.70078 1.94306 6.5625 2.2769 6.5625 2.625V3.28125L4.59375 3.28125C4.24565 3.28125 3.91181 3.41953 3.66567 3.66567C3.41953 3.91181 3.28125 4.24565 3.28125 4.59375L3.28125 18.375C3.28125 18.7231 3.41953 19.0569 3.66567 19.3031C3.91181 19.5492 4.24565 19.6875 4.59375 19.6875L16.4063 19.6875C16.7543 19.6875 17.0882 19.5492 17.3343 19.3031C17.5805 19.0569 17.7188 18.7231 17.7188 18.375L17.7188 4.59375C17.7188 4.24565 17.5805 3.91181 17.3343 3.66567C17.0882 3.41953 16.7543 3.28125 16.4063 3.28125ZM7.875 2.625L13.125 2.625V5.25L7.875 5.25L7.875 2.625ZM16.4063 18.375L4.59375 18.375L4.59375 4.59375H6.5625L6.5625 6.5625L14.4375 6.5625V4.59375H16.4063L16.4063 18.375Z"
          fill="#505050"
        />
      </svg>
    </Link>
  );
};

export default UserReport;
