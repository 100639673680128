import { useEffect, useState } from "react";

import EditProvenanceDetailsSection from "./EditProvenanceDetailsSection";
import { fetchTokenProvenance } from "../../api/tokenDetailsApi";
import LoadingSpinner from "../../utils/commonUtils/LoadingSpinner";
import {
  CommonProvenanceCategories,
  newCategoryFormDataObj,
} from "./Variables/CommonProvenanceCategories";
import useIsMobile from "../../customHooks/UseIsMobile";
import ArrowIconReverse from "../../Icons/ArrowIconReverse";
import ArrowIcon from "../../Icons/ArrowIcon";
import cloneDeep from "lodash/cloneDeep";
import { deepCopyObj } from "../../utils/commonUtils/Utils";
import { fetchTokenNameFromTokenObj } from "../../utils/commonUtils/TokenUtils";

const EditProvenanceSection = ({
  setShowAPISuccessAlert,
  setShowAPIFailureAlert,
  selectedProvenanceTokens,
  setSelectedProvenanceTokens,
  setIsFetching,
  isFetching,
}) => {
  const [selectedHeading, setSelectedHeading] = useState("Awards");

  const [addCategoryValue, setAddcategoryValue] = useState("");

  const [AddCategoryArray, setAddCategoryArray] = useState([]);

  const [tokenProvenance, setTokenProvenance] = useState([]);
  const [selectedHeadingMobile, setSelectedHeadingMobile] = useState(null);

  const isMobile = useIsMobile();

  const handleHeadingChange = (heading) => {
    setSelectedHeading(heading);
  };

  const handleHeadingClicked = (heading) => {
    setSelectedHeading(selectedHeading === heading ? null : heading);
  };

  const [tokenProvenanceFormData, setTokenProvenanceFormData] = useState(
    CommonProvenanceCategories
  );

  const [showInput, setShowInput] = useState(false);
  const [categoryName, setCategoryName] = useState("");

  const handleButtonClick = (heading) => {
    setShowInput(true);
  };

  const handleInputChange = (e) => {
    setCategoryName(e.target.value);
    setAddcategoryValue(e.target.value);
  };

  const handleInputBlur = () => {
    setShowInput(false);
  };

  const handleEnterKeyPress = (e) => {
    if (e.key === "Enter") {
      handleNewCategorySubmit(e);
    }
  };

  const handleNewCategorySubmit = (e) => {
    e.preventDefault();
    if (addCategoryValue.trim() !== "") {
      setTokenProvenanceFormData((prevData) => {
        const newTokenProvenanceFormData = deepCopyObj(prevData);
        newTokenProvenanceFormData[addCategoryValue.trim()] = deepCopyObj(
          newCategoryFormDataObj
        );

        return newTokenProvenanceFormData;
      });
    }
  };

  useEffect(() => {
    if (selectedProvenanceTokens.length === 1) {
      let contractAddress = selectedProvenanceTokens[0]._id.split("-")[0];
      let tokenId = selectedProvenanceTokens[0]._id.split("-")[1];
      fetchTokenProvenance(contractAddress, tokenId)
        .then((provenance) => {
          if (provenance?.data) {
            setTokenProvenance(provenance?.data);
          }
        })
        .catch((e) => {});
    } else {
      setTokenProvenance([]);
    }
  }, [selectedProvenanceTokens]);

  useEffect(() => {
    setTokenProvenanceFormData(() => {
      let updatedPrevTokenProvenanceFormData = deepCopyObj(
        CommonProvenanceCategories
      );

      let addedCategoryLabels = tokenProvenance
        .map((categoryProv) => categoryProv.categoryName)
        .filter(
          (categoryName) =>
            !Object.keys(updatedPrevTokenProvenanceFormData)?.includes(
              categoryName
            )
        );

      addedCategoryLabels.forEach(
        (addedCategoryLabel) =>
          (updatedPrevTokenProvenanceFormData[addedCategoryLabel] = deepCopyObj(
            newCategoryFormDataObj
          ))
      );

      (tokenProvenance || []).map((categoryProvenance) => {
        categoryProvenance?.data &&
          categoryProvenance["data"].map((categoryProvenanceForm) => {
            let formDataToPush = {
              ...updatedPrevTokenProvenanceFormData[
                categoryProvenance.categoryName
              ].emptyForm,
            };

            Object.keys(categoryProvenanceForm).map((label) => {
              if (label !== "ImagesToUpload") {
                formDataToPush[label] = categoryProvenanceForm[label];
              }
            });

            updatedPrevTokenProvenanceFormData[
              categoryProvenance.categoryName
            ].data.push(formDataToPush);
          });
      });

      return updatedPrevTokenProvenanceFormData;
    });
  }, [tokenProvenance]);

  useEffect(() => {}, [tokenProvenanceFormData]);

  return (
    <div
      className="modal-overlay-add-provenance"
      onClick={(e) => {
        setSelectedProvenanceTokens([]);
      }}
    >
      <div
        className="add-provenance-modal-container"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        {isFetching ? (
          <p
            style={{
              alignSelf: "center",
            }}
          >
            <LoadingSpinner />
          </p>
        ) : !isMobile ? (
          <>
            <span className="add-provenance-name-heading">
              {(selectedProvenanceTokens || []).map((token) => (
                <span className="selected-provenance-token-name">
                  {fetchTokenNameFromTokenObj(token)}
                </span>
              ))}
            </span>

            <div className="add-provenance-main-section">
              <div className="add-provenance-headings">
                {Object.keys(tokenProvenanceFormData || {}).map(
                  (key, index) => (
                    <span
                      className={
                        selectedHeading === key
                          ? "add-provenance-heading-active"
                          : "add-provenance-heading"
                      }
                      onClick={() => handleHeadingChange(key)}
                      key={index}
                    >
                      {key}
                    </span>
                  )
                )}

                <div
                  className={
                    selectedHeading === "+Add Category"
                      ? "add-provenance-heading-active"
                      : "add-provenance-heading"
                  }
                >
                  {showInput ? (
                    <div>
                      <input
                        type="text"
                        className="add-category-input"
                        value={addCategoryValue}
                        onChange={handleInputChange}
                        onBlur={handleInputBlur}
                        onKeyPress={handleEnterKeyPress}
                        placeholder="New Category"
                      />
                    </div>
                  ) : (
                    <div>
                      <span
                        className="add-category-heading"
                        onClick={() => handleButtonClick("Add Category")}
                      >
                        + Add Category
                      </span>
                    </div>
                  )}
                </div>
              </div>
              <EditProvenanceDetailsSection
                setShowAPISuccessAlert={setShowAPISuccessAlert}
                setShowAPIFailureAlert={setShowAPIFailureAlert}
                setIsFetching={setIsFetching}
                heading={selectedHeading}
                tokenProvenanceFormData={tokenProvenanceFormData}
                setTokenProvenanceFormData={setTokenProvenanceFormData}
                setTokenProvenance={setTokenProvenance}
                selectedProvenanceTokens={selectedProvenanceTokens}
                setSelectedProvenanceTokens={setSelectedProvenanceTokens}
              />
            </div>
          </>
        ) : (
          <div>
            <span className="add-provenance-name-heading">
              {(selectedProvenanceTokens || []).map((token) => (
                <span className="selected-provenance-token-name">
                  {token?.tokenMetadata?.data?.name
                    ? token?.tokenMetadata?.data?.name
                    : "n/a"}
                </span>
              ))}
            </span>
            <div className="provenance-archive-note-section">
              {Object.keys(tokenProvenanceFormData || {}).map((key, index) => (
                <span
                  className={
                    selectedHeading === key
                      ? "provenance-archive-note open-note"
                      : "provenance-archive-note"
                  }
                  onClick={() => handleHeadingChange(key)}
                  key={index}
                >
                  <span
                    className="provenance-archive-note-headings"
                    onClick={() => handleHeadingClicked(key)}
                  >
                    {key}{" "}
                    <span>
                      {selectedHeading === key ? (
                        <ArrowIcon />
                      ) : (
                        <ArrowIconReverse />
                      )}
                    </span>
                  </span>
                  {selectedHeading === key && (
                    <EditProvenanceDetailsSection
                      setShowAPISuccessAlert={setShowAPISuccessAlert}
                      setShowAPIFailureAlert={setShowAPIFailureAlert}
                      setIsFetching={setIsFetching}
                      heading={selectedHeading}
                      tokenProvenanceFormData={tokenProvenanceFormData}
                      setTokenProvenanceFormData={setTokenProvenanceFormData}
                      setTokenProvenance={setTokenProvenance}
                      selectedProvenanceTokens={selectedProvenanceTokens}
                      setSelectedProvenanceTokens={setSelectedProvenanceTokens}
                    />
                  )}
                </span>
              ))}

              <div
                className={
                  selectedHeading === "+Add Category"
                    ? "provenance-archive-note open-note"
                    : "provenance-archive-note"
                }
              >
                {showInput ? (
                  <div>
                    <input
                      type="text"
                      className="add-category-input"
                      value={addCategoryValue}
                      onChange={handleInputChange}
                      onBlur={handleInputBlur}
                      onKeyPress={handleEnterKeyPress}
                      placeholder="New Category"
                    />
                    <input
                      class="button--submit"
                      value="Subscribe"
                      type="submit"
                    >
                      New Category
                    </input>
                  </div>
                ) : (
                  <div>
                    <span
                      className="add-category-heading"
                      onClick={() => handleButtonClick("Add Category")}
                    >
                      + Add Category
                    </span>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default EditProvenanceSection;
