import React, { useEffect, useState } from "react";
import "../../styles/collectionPageStyles/CollectionPage.css";
import RefreshIcon from "../../Icons/RefreshIcon";
import { getTimeAgoFromDate } from "../../utils/commonUtils/timeUtils";
import { fetchListingTokens } from "../../api/collectionDetailsApi";
import { fetchListingInfoForAllTokenForUser } from "../../api/walletDetailApi";

const LiveButton = ({
  pageType,
  activeTab,
  collectionData,
  walletData,
  setTokenDetailsArray,
  setListedTokensCount,
}) => {
  const [listingPriceLastUpdated, setListingPriceLastUpdated] = useState();
  const [fetchingListingPrice, setFetchingListingPrice] = useState(false);
  const [minterListingPriceLastUpdated, setMinterListingPriceLastUpdated] =
    useState();
  const [fetchingMinterListingPrice, setFetchingMinterListingPrice] =
    useState(false);
  const [ownerListingPriceLastUpdated, setOwnerListingPriceLastUpdated] =
    useState();
  const [fetchingOwnerListingPrice, setFetchingOwnerListingPrice] =
    useState(false);

  useEffect(() => {
    if (pageType === "CreatorPage") {
      setListingPriceLastUpdated(walletData?.listingPriceLastUpdated);
      setMinterListingPriceLastUpdated(
        walletData?.minterListingPriceLastUpdated
      );
      setOwnerListingPriceLastUpdated(walletData?.ownerListingPriceLastUpdated);
    } else {
      setListingPriceLastUpdated(collectionData?.listingPriceLastUpdated);
    }
  }, [collectionData, walletData, pageType]);

  useEffect(() => {
    if (!walletData?.ownerListingPriceLastUpdated) {
      refetchCreatorTokenListings();
    }
  }, [walletData, activeTab]);

  const handleRefreshButtonClick = () => {
    switch (pageType) {
      case "CollectionPage":
        refetchCollectionTokenListings();
        break;
      case "CreatorPage":
        refetchCreatorTokenListings();
        break;
      default:
        console.error(`Unknown page type: ${pageType}`);
      // or throw an error, or return a default value, etc.
    }
  };

  const refetchCollectionTokenListings = async () => {
    try {
      let { collectionTokenListings, listingPriceLastUpdated } =
        await fetchListingTokens(
          collectionData?.contractAddress,
          collectionData?.collectionSlug,
          collectionData?.collectionCreator
        );

      setListingPriceLastUpdated(listingPriceLastUpdated);
      setListedTokensCount((Object.keys(collectionTokenListings) || []).length);

      setTokenDetailsArray((prevTokenDetailsArray) => {
        let newTokenDetailsArray = prevTokenDetailsArray.map((token) => {
          if (collectionTokenListings.hasOwnProperty(token.decimalTokenId))
            token.lowestListingPrice = {
              value: collectionTokenListings[token.decimalTokenId],
              currency: "ETH",
            };

          return token;
        });

        return newTokenDetailsArray;
      });
    } catch (e) {}
  };

  const refetchCreatorTokenListings = async () => {
    const userType = "Owner";
    if (fetchingOwnerListingPrice) return;
    if (!walletData?.walletAddress) return;
    setFetchingOwnerListingPrice(true);

    try {
      let {
        tokensListings,
        listingPriceLastUpdated,
        ownerListingPriceLastUpdated,
        minterListingPriceLastUpdated,
      } = await fetchListingInfoForAllTokenForUser(
        walletData?.walletAddress,
        userType
      );

      setListingPriceLastUpdated(listingPriceLastUpdated);
      setMinterListingPriceLastUpdated(minterListingPriceLastUpdated);
      setOwnerListingPriceLastUpdated(ownerListingPriceLastUpdated);

      setListedTokensCount((Object.keys(tokensListings) || []).length);

      setTokenDetailsArray((prevTokenDetailsArray) => {
        let newTokenDetailsArray = prevTokenDetailsArray.map((token) => {
          if (tokensListings.hasOwnProperty(token.decimalTokenId))
            token.lowestListingPrice = {
              value: tokensListings[token.decimalTokenId],
              currency: "ETH",
            };

          return token;
        });

        return newTokenDetailsArray;
      });
    } catch (e) {
    } finally {
      if (userType === "Owner") {
        setFetchingOwnerListingPrice(false);
      } else if (userType === "Minter") {
        setFetchingMinterListingPrice(false);
      } else {
        setFetchingListingPrice(false);
      }
    }
  };

  return (
    <button
      className="items_tab_header_buttons live-button"
      onClick={handleRefreshButtonClick}
    >
      <RefreshIcon />
      {activeTab === "Owned_Items" && pageType === "CreatorPage"
        ? fetchingOwnerListingPrice
          ? "Fetching..."
          : ownerListingPriceLastUpdated
          ? getTimeAgoFromDate(ownerListingPriceLastUpdated)
          : "Not Updated"
        : activeTab === "Minted_Items" && pageType === "CreatorPage"
        ? fetchingMinterListingPrice
          ? "Fetching..."
          : minterListingPriceLastUpdated
          ? getTimeAgoFromDate(minterListingPriceLastUpdated)
          : "Not Updated"
        : fetchingListingPrice
        ? "Fetching..."
        : listingPriceLastUpdated
        ? getTimeAgoFromDate(listingPriceLastUpdated)
        : "Not Updated"}{" "}
    </button>
  );
};

export default LiveButton;
