import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getNameFromCollectionObj } from "../../utils/commonUtils/CollectionUtils";
import SearchIcon from "../../Icons/CollectionPageIcons/SearchIcon";
import useIsMobile from "../../customHooks/UseIsMobile";
import RemoveButton from "../../Icons/CollectionPageIcons/RemoveButton";
import LoadingSpinner from "../../utils/commonUtils/LoadingSpinner";

const ProvenanceCollectionsFilterSection = ({
  allCollections,
  selectedCollections,
  setSelectedCollections,
  allCollectionSlugs,
  selectedCollectionSlugs,
  setSelectedCollectionSlugs,
  filterSectionVisible,
  onFilterClickClose,
}) => {
  const [collections, setCollections] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [toggleSearch, setToggleSearch] = useState(false);
  const [fetchingCollections, setFetchingCollections] = useState(false);
  const [fetchingCollectionSlugs, setFetchingCollectionSlugs] = useState(false);
  const [collectionSlugs, setCollectionSlugs] = useState("");
  const [searchQueryCollSlugs, setSearchQueryCollSlugs] = useState("");
  const [toggleSearchCollSlugs, setToggleSearchCollSlugs] = useState(false);

  const isMobile = useIsMobile();

  useEffect(() => {
    setFetchingCollections(true);
    setCollections(
      allCollections.filter((collection) =>
        getNameFromCollectionObj(collection)
          .toLowerCase()
          .includes(searchQuery.toLowerCase())
      )
    );
    setFetchingCollections(false);
  }, [searchQuery, allCollections]);

  useEffect(() => {
    setFetchingCollectionSlugs(true);
    setCollectionSlugs(
      allCollectionSlugs.filter((slug) =>
        slug.toLowerCase().includes(searchQueryCollSlugs.toLowerCase())
      )
    );
    setFetchingCollectionSlugs(false);
  }, [searchQueryCollSlugs, allCollectionSlugs]);

  const handleCheckboxChange = (collectionObjToAdd) => {
    setSelectedCollections((prevSelected) => {
      let prevContractIdsSelectd = prevSelected.map(
        (obj) => obj.contractAddress
      );

      if (
        prevContractIdsSelectd?.includes(collectionObjToAdd?.contractAddress)
      ) {
        return prevSelected.filter(
          (obj) => obj.contractAddress !== collectionObjToAdd?.contractAddress
        );
      } else {
        return [
          ...prevSelected,
          {
            contractAddress: collectionObjToAdd.contractAddress,
            name: getNameFromCollectionObj(collectionObjToAdd),
          },
        ];
      }
    });
  };
  const handleCollSlugCheckboxChange = (collSlugToAdd) => {
    setSelectedCollectionSlugs((prevSelected) => {
      if (prevSelected?.includes(collSlugToAdd)) {
        return prevSelected.filter((sluf) => sluf !== collSlugToAdd);
      } else {
        return [...prevSelected, collSlugToAdd];
      }
    });
  };

  const searchTraitOpen = () => {
    setSearchQuery("");
    setToggleSearch(!toggleSearch);
  };
  const searchTraitOpenCollSlug = () => {
    setSearchQueryCollSlugs("");
    setToggleSearchCollSlugs(!toggleSearchCollSlugs);
  };

  return (
    <div
      className={
        isMobile
          ? `provenance-filter-section-mobile ${
              filterSectionVisible ? "visible" : "not_visible"
            }`
          : "provenance-filter-section-container"
      }
    >
      {isMobile ? (
        <span
          style={{ display: "flex", justifyContent: "flex-end" }}
          onClick={onFilterClickClose}
        >
          <RemoveButton color={"black"} />
        </span>
      ) : null}
      <span className="provenance-filter-section-heading">
        Collections{" "}
        <span
          onClick={searchTraitOpen}
          style={{
            cursor: "pointer",
            marginLeft: "0.8em",
          }}
        >
          <SearchIcon />{" "}
        </span>
      </span>

      {toggleSearch && (
        <input
          type="text"
          style={{ width: "80%", marginLeft: "10%" }}
          placeholder="Search collections..."
          className="filter-section-search-bar"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
      )}
      {!fetchingCollections ? (
        <span className="provenance-filter-section-data">
          {(collections || []).map((collection) => (
            <div
              key={collection.contractAddress}
              className="provenance-filter-section-collections"
            >
              <input
                className="provenance-filter-section-input-row"
                type="checkbox"
                id={collection.contractAddress}
                name={collection.contractAddress}
                value={collection.contractAddress}
                checked={selectedCollections
                  .map((obj) => obj.contractAddress)
                  ?.includes(collection.contractAddress)}
                onChange={() => handleCheckboxChange(collection)}
              />
              <label
                style={{ cursor: "pointer" }}
                htmlFor={collection.contractAddress}
              >
                {getNameFromCollectionObj(collection)}
              </label>
            </div>
          ))}
        </span>
      ) : (
        <p>
          <br />
          <LoadingSpinner />
          <br />
        </p>
      )}

      <span className="provenance-filter-section-heading">
        Collection Slugs{" "}
        <span
          onClick={searchTraitOpenCollSlug}
          style={{
            cursor: "pointer",
            marginLeft: "0.8em",
          }}
        >
          <SearchIcon />{" "}
        </span>
      </span>

      {toggleSearchCollSlugs && (
        <input
          type="text"
          style={{ width: "80%", marginLeft: "10%" }}
          placeholder="Search collection Slugs..."
          className="filter-section-search-bar"
          value={searchQueryCollSlugs}
          onChange={(e) => setSearchQueryCollSlugs(e.target.value)}
        />
      )}
      {!fetchingCollectionSlugs ? (
        <span className="provenance-filter-section-data">
          {(collectionSlugs || []).map((collectionSlug) => (
            <div
              key={collectionSlug}
              className="provenance-filter-section-collections"
            >
              <input
                className="provenance-filter-section-input-row"
                type="checkbox"
                id={collectionSlug}
                name={collectionSlug}
                value={collectionSlug}
                checked={selectedCollectionSlugs?.includes(collectionSlug)}
                onChange={() => handleCollSlugCheckboxChange(collectionSlug)}
              />
              <label style={{ cursor: "pointer" }} htmlFor={collectionSlug}>
                {collectionSlug}
              </label>
            </div>
          ))}
        </span>
      ) : (
        <p>
          <br />
          <LoadingSpinner />
          <br />
        </p>
      )}
    </div>
  );
};

export default ProvenanceCollectionsFilterSection;
