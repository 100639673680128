import { createStore } from "redux";
import rootReducer from "./reducers";
import { loadAllReducerStates, saveReducerState } from "./localStorage";

// Load initial state for all reducers
const persistedState = loadAllReducerStates();

// Create the Redux store
const store = createStore(rootReducer, persistedState);

// Subscribe to store changes and save each reducer state separately
store.subscribe(() => {
  const state = store.getState();

  saveReducerState("userInfo", {
    userId: state?.user?.userInfo?.userId,
    emailAddress: state?.user?.userInfo?.emailAddress,
    profileName: state?.user?.userInfo?.profileName,
    role: state?.user?.userInfo?.role,
    verifiedEmail: state?.user?.userInfo?.verifiedEmail,
  });
  // Add additional reducers here
});

export default store;
