import { useEffect, useState } from "react";
import useIsMobile from "../../customHooks/UseIsMobile";

export default function VerifyImageSourceIcon({ url }) {
  const [imageStatus, setImageStatus] = useState("LOADING");
  const [color, setColor] = useState("orange");
  const [message, setMessage] = useState("Fetching");
  const [isHover, setIsHover] = useState(false);

  const isMobile = useIsMobile();

  function handleImageLoaded() {
    setImageStatus("LOADED");
    setMessage("Original Source Verified!");
  }

  function handleImageErrored() {
    setImageStatus("FAILED");
    setMessage("Original Source Missing!");
  }

  useEffect(() => {
    if (imageStatus === "LOADED") {
      setColor("#2fb484");
    } else if (imageStatus === "FAILED") {
      setColor("red");
    }
  }, [imageStatus]);

  return (
    <>
      {!isMobile ? (
        <>
          <div
            className="d-flex w-100 justify-content-between align-items-top"
            style={{
              paddingTop: "3%",
              paddingLeft: "3.5%",
              paddingRight: "4.5%",
            }}
          >
            {isHover ? (
              <div
                style={{
                  whiteSpace: "nowrap",
                  fontSize: "0.7vw",
                  color: imageStatus === "LOADED" ? "#183B56" : "red",
                  backgroundColor: "white",
                  padding: "0.5vw",
                  borderRadius: "0.8vw",
                }}
              >
                {message}
              </div>
            ) : (
              <div></div>
            )}
            <div
              onMouseEnter={() => setIsHover(true)}
              onMouseLeave={() => setIsHover(false)}
              style={{
                width: "1.3vw",
                height: "1.3vw",
                backgroundColor: color,
                borderRadius: "50%",
              }}
            />
          </div>
          <img
            src={url}
            onLoad={handleImageLoaded}
            onError={handleImageErrored}
            style={{ width: "0", height: "0" }}
            loading="lazy"
          />
        </>
      ) : (
        <>
          <div
            className="d-flex w-100 justify-content-between align-items-center"
            style={{
              paddingTop: "2%",
              paddingLeft: "4.2%",
              paddingRight: "4.2%",
            }}
          >
            {isHover ? (
              <div
                style={{
                  whiteSpace: "nowrap",
                  fontSize: "2.5vw",
                }}
              >
                {message}
              </div>
            ) : (
              <div></div>
            )}
            <div
              onMouseEnter={() => setIsHover(true)}
              onMouseLeave={() => setIsHover(false)}
              style={{
                width: "3vw",
                height: "3vw",
                backgroundColor: color,
                borderRadius: "50%",
              }}
            />
          </div>
          <img
            src={url}
            onLoad={handleImageLoaded}
            onError={handleImageErrored}
            style={{ width: "0", height: "0" }}
            loading="lazy"
          />
        </>
      )}
    </>
  );
}
