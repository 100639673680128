import { useEffect, useState } from "react";
import TokenSearchResultItem from "./TokenSearchResultItem";
import RemoveButton from "../../Icons/CollectionPageIcons/RemoveButton";
import useIsMobile from "../../customHooks/UseIsMobile";

export default function TokensSearchResult({
  tokensResult,
  isSearching,
  tokensSelected,
  addToTokensSelected,
  deleteFromTokensSelected,
  onClose,
  resetSearch,
}) {
  const [showResults, setShowResults] = useState(true);
  const isMobile = useIsMobile();

  return (
    <>
      {showResults && (
        <div
          className={
            !isMobile
              ? "search-results d-flex flex-column align-items-center border-0"
              : "search-results-mobile d-flex flex-column align-items-center border-0"
          }
          style={{ zIndex: 300 }}
        >
          <span
            onClick={resetSearch}
            style={{
              alignSelf: "flex-end",
              cursor: "pointer",
            }}
          >
            <RemoveButton color="black" />
          </span>
          {tokensResult &&
            tokensResult.map((item, index) => {
              let tokenSelected = Object.keys(tokensSelected).includes(
                item?.contractAddress + "-" + item?.tokenId
              );

              return (
                <TokenSearchResultItem
                  item={item}
                  isSearching={isSearching}
                  key={index}
                  tokenSelected={tokenSelected}
                  addToTokensSelected={addToTokensSelected}
                  deleteFromTokensSelected={deleteFromTokensSelected}
                />
              );
            })}
        </div>
      )}
    </>
  );
}
