import { createTheme } from "@mui/material";
import { grey } from "@mui/material/colors";

export const theme = createTheme({
  palette: {
    mode: "light",
    primary: {
      main: "#368060",
      light: "#2fb484",
      dark: "#2c4738",
    },
    secondary: {
      main: grey[50],
    },
    background: {
      paper: grey[900],
      default: grey[300],
    },
    text: {
      primary: "black",
    },
  },

  typography: {
    h1: {
      fontSize: "2.25rem",
    },
    h2: {
      fontSize: "2rem",
    },
    h3: {
      fontSize: "1.75rem",
    },
    h4: {
      fontSize: "1.5rem",
    },
    h5: {
      fontSize: "1.25rem",
    },
    h6: {
      fontSize: "1rem",
    },
    // fontFamily: "Monospace",
  },

  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
});
