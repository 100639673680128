import { getEthToUsdExchangeRate } from "../../api/ethExchangeRateApi";
import { prettyPrice, prettyPriceUSD } from "./PriceUtils";
import { isNullOrUndefined } from "./Utils";
import { getDaysAgoText, timestampToDate } from "./timeUtils";
/* global BigInt */ //Dont delete this comment, it's used to include BigInt in the project

export const generateTraitsFromTransferHistoryArray = (
  transferHistoryArray
) => {
  const traitOptions = {};

  if (!transferHistoryArray || transferHistoryArray.length === 0) {
    return { error: "No data available" };
  }

  transferHistoryArray.forEach((transfer) => {
    const token = transfer.tokenDetails;
    const tokenMetadata = token.tokenMetadata;

    if (!tokenMetadata || !tokenMetadata.data) {
      return;
    }

    if (tokenMetadata.data === null) {
      return;
    }

    if (!tokenMetadata.data.attributes) {
      return;
    }

    tokenMetadata.data.attributes.forEach((attribute) => {
      if (!traitOptions[attribute.trait_type]) {
        traitOptions[attribute.trait_type] = {};
      }
      if (!traitOptions[attribute.trait_type][attribute.value]) {
        traitOptions[attribute.trait_type][attribute.value] = 1;
      } else {
        traitOptions[attribute.trait_type][attribute.value]++;
      }
    });
  });

  // Sort the trait types based on the number of values and then alphabetically
  const sortedTraitTypes = Object.keys(traitOptions).sort((a, b) => {
    const countA = Object.keys(traitOptions[a]).length;
    const countB = Object.keys(traitOptions[b]).length;

    if (countA === countB) {
      // If the counts are the same, sort alphabetically
      return a.localeCompare(b);
    }

    return countB - countA;
  });

  const sortedTraitOptions = {};
  for (const traitType of sortedTraitTypes) {
    sortedTraitOptions[traitType] = Object.entries(
      traitOptions[traitType]
    ).sort((a, b) => {
      if (b[1] === a[1]) {
        return a[0].localeCompare(b[0]);
      }
      return b[1] - a[1];
    });
  }

  return sortedTraitOptions;
};

// export function fetchQuantityFromNftTransfer(transfer) {
//   if (transfer?.price?.tokensTransferredInNFTTransfer) {
//     return transfer?.price?.tokensTransferredInNFTTransfer;
//   }

//   if (transfer?.amount === "1") {
//     return transfer?.amount;
//   }

//   if (transfer?.amount && transfer.amount !== "1") {
//     return parseInt(transfer?.amount, 16);
//   }

//   return "n/a";
// }

export function fetchQuantityFromNftTransfer(transfer) {
  if (transfer?.quantity) {
    return transfer?.quantity;
  }

  return "n/a";
}

export function fetchSalesPriceTransferWei(transfer) {
  return !isNullOrUndefined(transfer?.price?.amount)
    ? parseFloat(transfer?.price?.amount)
    : 0;
}

export function fetchAdjustedSalesPriceTransferEth(transfer) {
  return !isNullOrUndefined(transfer?.price?.combinedNftTransferAmount) &&
    parseFloat(transfer?.price?.combinedNftTransferAmount) > 0
    ? parseFloat(transfer?.price?.combinedNftTransferAmount)
    : !isNullOrUndefined(
        transfer?.previousTokenSalePrice?.combinedNftTransferAmount
      )
    ? parseFloat(transfer?.previousTokenSalePrice?.combinedNftTransferAmount)
    : 0;
}

export function fetchAdjustedSalesPriceTransferEthUI(transfer) {
  let ethPrice = fetchAdjustedSalesPriceTransferEth(transfer);

  return prettyPrice(ethPrice);
}

export function fetchSalesPriceTransferUSDUI(transfer) {
  let usdPrice = fetchSalesPriceTransferUSD(transfer);
  return prettyPriceUSD(usdPrice);
}

export function fetchAdjustedSalesPriceTransferUSD(transfer) {
  getEthToUsdExchangeRate();
  return !isNullOrUndefined(transfer?.price?.amountUSD) &&
    parseFloat(transfer?.price?.amountUSD) > 0
    ? parseFloat(transfer?.price?.amountUSD)
    : !isNullOrUndefined(transfer?.previousTokenSalePrice?.amountUSD)
    ? parseFloat(transfer?.previousTokenSalePrice?.amountUSD)
    : 0;
}

export function fetchAdjustedSalesPriceTransferUSDUI(transfer) {
  let usdPrice = fetchAdjustedSalesPriceTransferUSD(transfer);
  return prettyPriceUSD(usdPrice);
}

export function fetchFromUserNFTTransfer(transfer) {
  let eventType = transfer.event_type;

  if (eventType === "sale") {
    return transfer.seller;
  } else if (eventType === "transfer") {
    return transfer.from_address;
  } else if (eventType === "order") {
    return transfer.maker;
  } else if (eventType === "cancel") {
    return transfer.maker;
  } else if (eventType === "redemption") {
    return transfer.from_address;
  }
  return transfer.from_address;
}

export function fetchToUserNFTTransfer(transfer) {
  let eventType = transfer.event_type;

  let answer = transfer.to_address;
  if (eventType === "sale") {
    answer = transfer.buyer;
  } else if (eventType === "transfer") {
    answer = transfer.to_address;
  } else if (eventType === "order") {
    answer = transfer.taker;
  } else if (eventType === "cancel") {
    answer = "";
  } else if (eventType === "redemption") {
    answer = transfer.to_address;
  }

  return answer;
}

export function fetchSalesPriceTransferEth(transfer) {
  let weiPrice = transfer?.payment?.quantity || 0;
  let ethPrice = weiPrice / 10 ** 18;

  let finalEthPrice = ethPrice.toFixed(2);

  return finalEthPrice;
}

export function fetchSalesPriceTransferEthUI(transfer) {
  let ethPrice = fetchSalesPriceTransferEth(transfer);

  return prettyPrice(ethPrice);
}

export function fetchSalesPriceTransferUSD(transfer) {
  let weiPrice = transfer?.payment?.quantity || 0;
  let ethPrice = weiPrice / 10 ** 18;

  let ethToUSDRate = parseFloat(transfer?.payment?.ethToUSDRate || 0);

  let usdPrice = ethToUSDRate && ethToUSDRate * ethPrice;
  let usdPriceString = usdPrice.toFixed(2);

  return usdPriceString;
}

export function fetchTransferType(transfer) {
  let fromUser = fetchFromUserNFTTransfer(transfer);
  let toUser = fetchToUserNFTTransfer(transfer);
  let eventType = transfer.event_type;

  if (fromUser === "0x0000000000000000000000000000000000000000") {
    eventType = "minted/air-dropped";
  } else if (toUser === "0x0000000000000000000000000000000000000000") {
    eventType = "burned";
  }

  return eventType;
}

export function fetchTimeOfTransfer(transfer, showTimestamp) {
  return showTimestamp
    ? timestampToDate(transfer.event_timestamp)
    : getDaysAgoText(transfer.event_timestamp * 1000);
}

export function getTokenNameFromNft(nft, fullName = false, maxLength = 50) {
  if (!nft || !nft.name) return "No Name";

  if (fullName) {
    return nft.name;
  } else {
    return nft.name.length > maxLength
      ? `${nft.name.slice(0, maxLength)}...`
      : nft.name;
  }
}

export function getCollectionNameFromNft(nft) {
  return nft && nft.collection ? nft.collection : "No Name";
}

export function getDisplayImageUrlFromNft(nft) {
  return nft && nft.display_image_url ? nft.display_image_url : "";
}

export function getImageUrlFromNft(nft) {
  return nft?.image_url || "";
}
