import React, { useState, useEffect, useContext, useRef } from "react";
import "../../styles/collectionPageStyles/CollectionPage.css";
import ArrowIcon from "../../Icons/ArrowIcon";
import ArrowIconReverse from "../../Icons/ArrowIconReverse";
import SmallArrowIcon from "../../Icons/SmallArrowIcon";
import SmallArrowIconReversed from "../../Icons/SmallArrowIconReversed";
import { isNullOrUndefined } from "../../utils/commonUtils/Utils";
import DropdownElement from "../Tools/DropdownElement";
import useIsMobile from "../../customHooks/UseIsMobile";
import RemoveButton from "../../Icons/CollectionPageIcons/RemoveButton";
import { CollectionFilterSectionContext } from "../../contexts/CollectionPage/FilterSectionContext";
import {
  setCollectionPageFiltersActivitySelectedTraits,
  setCollectionPageFiltersActivityStatus,
  setCollectionPageFiltersListingPriceCurrency,
  setCollectionPageFiltersListingPriceFilter,
  setCollectionPageFiltersMaxListingPrice,
  setCollectionPageFiltersMinListingPrice,
  setCollectionPageFiltersTokenStatus,
  setCollectionPageFiltersTokensSelectedTraits,
} from "../../actions/CollectionPage/filterSectionActions";
import {
  setCreatorPageFiltersActivitySelectedTraits,
  setCreatorPageFiltersActivityStatus,
  setCreatorPageFiltersCreatedTokenStatus,
  setCreatorPageFiltersCreatedTokensSelectedTraits,
  setCreatorPageFiltersListingPriceCurrency,
  setCreatorPageFiltersListingPriceFilter,
  setCreatorPageFiltersMaxListingPrice,
  setCreatorPageFiltersMinListingPrice,
  setCreatorPageFiltersMintedTokenStatus,
  setCreatorPageFiltersMintedTokensSelectedTraits,
  setCreatorPageFiltersOwnedTokenStatus,
  setCreatorPageFiltersOwnedTokensSelectedTraits,
} from "../../actions/CreatorPage/filterSectionActions";
import SearchIcon from "../../Icons/CollectionPageIcons/SearchIcon";
import { CreatorFilterSectionContext } from "../../contexts/CreatorPage.js/FilterSectionContext";

function FilterSection({
  pageType,
  activeTab,
  filterSectionVisible,
  aggregated_traits,
  onFilterClickClose,
  setConditionType,
  conditionType,
  listedTokensCount,
  traitCount,
}) {
  const context = useContext(
    pageType === "CollectionPage"
      ? CollectionFilterSectionContext
      : CreatorFilterSectionContext
  );
  const pageFilters =
    pageType === "CollectionPage"
      ? context.collectionPageFilters
      : context.creatorPageFilters;
  const updatePageFilters =
    pageType === "CollectionPage"
      ? context.updateCollectionPageFilters
      : context.updateCreatorPageFilters;

  const [selectedFiltersState, setSelectedFiltersState] = useState({});
  useEffect(() => {
    let status = "";
    if (activeTab.includes("Items") && pageType === "CollectionPage") {
      status = pageFilters?.tokenStatus;
    } else if (activeTab === "Owned_Items" && pageType === "CreatorPage") {
      status = pageFilters?.ownedTokenStatus;
    } else if (activeTab === "Minted_Items" && pageType === "CreatorPage") {
      status = pageFilters?.mintedTokenStatus;
    } else if (activeTab === "Created_Items" && pageType === "CreatorPage") {
      status = pageFilters?.createdTokenStatus;
    } else {
      status = pageFilters?.activityStatus;
    }

    let selectedTraitValues = "";
    if (activeTab.includes("Items") && pageType === "CollectionPage") {
      selectedTraitValues = pageFilters?.tokenSelectedTraitValues;
    } else if (activeTab === "Owned_Items" && pageType === "CreatorPage") {
      selectedTraitValues = pageFilters?.ownedTokenSelectedTraitValues;
    } else if (activeTab === "Minted_Items" && pageType === "CreatorPage") {
      selectedTraitValues = pageFilters?.mintedTokensSelectedTraitValues;
    } else if (activeTab === "Created_Items" && pageType === "CreatorPage") {
      selectedTraitValues = pageFilters?.createdTokensSelectedTraitValues;
    } else {
      selectedTraitValues = pageFilters?.activitySelectedTraitValues;
    }

    setSelectedFiltersState({
      status: status,

      minListingPriceFilter: activeTab.includes("Items")
        ? pageFilters?.minListingPriceFilter
        : null,
      maxListingPriceFilter: activeTab.includes("Items")
        ? pageFilters?.maxListingPriceFilter
        : null,

      listingPriceCurrencyFilter: activeTab.includes("Items")
        ? pageFilters.listingPriceCurrencyFilter
        : null,

      selectedTraitValues: selectedTraitValues,

      updateSelectedTraitValuesFunction: (traitType, value) => {
        if (activeTab.includes("Items") && pageType === "CollectionPage") {
          updatePageFilters(
            setCollectionPageFiltersTokensSelectedTraits({
              traitType,
              traitValue: value,
            })
          );
        } else if (activeTab === "Owned_Items" && pageType === "CreatorPage") {
          updatePageFilters(
            setCreatorPageFiltersOwnedTokensSelectedTraits({
              traitType,
              traitValue: value,
            })
          );
        } else if (activeTab === "Minted_Items" && pageType === "CreatorPage") {
          updatePageFilters(
            setCreatorPageFiltersMintedTokensSelectedTraits({
              traitType,
              traitValue: value,
            })
          );
        } else if (
          activeTab === "Created_Items" &&
          pageType === "CreatorPage"
        ) {
          updatePageFilters(
            setCreatorPageFiltersCreatedTokensSelectedTraits({
              traitType,
              traitValue: value,
            })
          );
        } else {
          if (pageType === "CreatorPage") {
            updatePageFilters(
              setCreatorPageFiltersActivitySelectedTraits({
                traitType,
                traitValue: value,
              })
            );
          } else {
            updatePageFilters(
              setCollectionPageFiltersActivitySelectedTraits({
                traitType,
                traitValue: value,
              })
            );
          }
        }
      },

      updateStatusFunction: (value) => {
        if (activeTab.includes("Items") && pageType === "CollectionPage") {
          updatePageFilters(setCollectionPageFiltersTokenStatus(value));
        } else if (activeTab === "Owned_Items" && pageType === "CreatorPage") {
          updatePageFilters(setCreatorPageFiltersOwnedTokenStatus(value));
        } else if (activeTab === "Minted_Items" && pageType === "CreatorPage") {
          updatePageFilters(setCreatorPageFiltersMintedTokenStatus(value));
        } else if (
          activeTab === "Created_Items" &&
          pageType === "CreatorPage"
        ) {
          updatePageFilters(setCreatorPageFiltersCreatedTokenStatus(value));
        } else {
          if (pageType === "CreatorPage") {
            updatePageFilters(setCreatorPageFiltersActivityStatus(value));
          } else {
            updatePageFilters(setCollectionPageFiltersActivityStatus(value));
          }
        }
      },

      updateMinListingPriceFunction: (value) => {
        if (pageType === "CreatorPage") {
          updatePageFilters(
            setCreatorPageFiltersMinListingPrice(
              value === "" ? null : parseFloat(value)
            )
          );
        } else {
          updatePageFilters(
            setCollectionPageFiltersMinListingPrice(
              value === "" ? null : parseFloat(value)
            )
          );
        }
      },

      updateMaxListingPriceFunction: (value) => {
        if (pageType === "CreatorPage") {
          updatePageFilters(
            setCreatorPageFiltersMaxListingPrice(
              value === "" ? null : parseFloat(value)
            )
          );
        } else {
          updatePageFilters(
            setCollectionPageFiltersMaxListingPrice(
              value === "" ? null : parseFloat(value)
            )
          );
        }
      },

      updateListingPriceCurrencyFunction: (value) => {
        if (pageType === "CreatorPage") {
          updatePageFilters(setCreatorPageFiltersListingPriceCurrency(value));
        } else {
          updatePageFilters(
            setCollectionPageFiltersListingPriceCurrency(value)
          );
        }
      },

      updateListingPriceFilterFunction: () => {
        if (pageType === "CreatorPage") {
          updatePageFilters(
            setCreatorPageFiltersListingPriceFilter({
              minListingPriceFilter: selectedFiltersState.minListingPriceFilter,
              maxListingPriceFilter: selectedFiltersState.maxListingPriceFilter,
              listingPriceCurrencyFilter:
                selectedFiltersState.listingPriceCurrencyFilter,
            })
          );
        } else {
          updatePageFilters(
            setCollectionPageFiltersListingPriceFilter({
              minListingPriceFilter: selectedFiltersState.minListingPriceFilter,
              maxListingPriceFilter: selectedFiltersState.maxListingPriceFilter,
              listingPriceCurrencyFilter:
                selectedFiltersState.listingPriceCurrencyFilter,
            })
          );
        }
      },
    });
  }, [pageFilters, pageType, activeTab]);

  const [togglePriceHeading, setTogglePriceHeading] = useState(false);
  const [toggleTraitHeading, setToggleTraitHeading] = useState(true);
  const [expandedTraitTypes, setExpandedTraitTypes] = useState({});
  const [toggleStatusHeading, setToggleStatusHeading] = useState(true);

  const [searchTraitQuery, setSearchTraitQuery] = useState("");
  const [searchTraitToggle, setSearchTraitToggle] = useState(false);
  const filterSectionRef = useRef(null);

  //useEffect for closing filter section on outside click
  useEffect(() => {
    const handleOutsideTap = (event) => {
      if (
        filterSectionRef.current &&
        !filterSectionRef.current.contains(event.target)
      ) {
        onFilterClickClose();
      }
    };

    if (filterSectionVisible && isMobile) {
      document.addEventListener("touchstart", handleOutsideTap);
    }

    return () => {
      document.removeEventListener("touchstart", handleOutsideTap);
    };
  }, [filterSectionVisible]);

  const filterTraits = (traits, searchQuery) => {
    searchQuery = searchQuery.toLowerCase();
    const filteredTraits = {};

    // Loop through each trait category
    for (const category in traits) {
      if (category.toLowerCase().includes(searchQuery)) {
        filteredTraits[category] = traits[category];
        continue;
      }

      if (traits.hasOwnProperty(category)) {
        const categoryValues = traits[category];

        const filteredCategoryValues = {};

        // Loop through trait values in the category
        for (const value in categoryValues) {
          if (categoryValues.hasOwnProperty(value)) {
            // Check if the trait value contains the search query
            if (value.toLowerCase().includes(searchQuery)) {
              filteredCategoryValues[value] = categoryValues[value];
            }
          }
        }

        // If any values match the search query, add the category with filtered values
        if (Object.keys(filteredCategoryValues).length > 0) {
          filteredTraits[category] = filteredCategoryValues;
        }
      }
    }

    setFilteredTraits(filteredTraits);
  };

  const [filteredTraits, setFilteredTraits] = useState({});
  useEffect(() => {
    setFilteredTraits(aggregated_traits || {});
  }, [aggregated_traits]);

  const handleTraitSearch = (searchStringValue) => {
    let traitOptions = aggregated_traits || {};

    setSearchTraitQuery(searchStringValue);

    const filteredTraits = filterTraits(traitOptions, searchStringValue);
  };

  const searchTraitOpen = () => {
    setSearchTraitToggle(!searchTraitToggle);
    setSearchTraitQuery("");
    handleTraitSearch("");
  };

  const toggleTraitType = (traitType) => {
    setExpandedTraitTypes((prevState) => ({
      ...prevState,
      [traitType]: !prevState[traitType],
    }));
  };

  const listingPriceButtonDisabled =
    !selectedFiltersState.minListingPriceFilter ||
    !selectedFiltersState.maxListingPriceFilter ||
    !selectedFiltersState.listingPriceCurrencyFilter ||
    selectedFiltersState.minListingPriceFilter >
      selectedFiltersState.maxListingPriceFilter
      ? true
      : false;

  const isMobile = useIsMobile();
  return (
    <div
      ref={filterSectionRef}
      className={
        isMobile
          ? `filter-section-mobile ${
              filterSectionVisible ? "visible" : "not_visible"
            }`
          : `filter-section ${filterSectionVisible ? "visible" : "not_visible"}`
      }
    >
      {isMobile ? (
        <span
          style={{
            display: "flex",
            justifyContent: "flex-end",
            paddingTop: "1rem",
            paddingBottom: "3rem",
          }}
          onClick={onFilterClickClose}
        >
          <RemoveButton color={"#183b56"} />
        </span>
      ) : null}
      <div
        className="filter-section-headings-row"
        onClick={() => setToggleStatusHeading(!toggleStatusHeading)}
      >
        <h3 className="filter-section-headings">Status</h3>
        {toggleStatusHeading ? <ArrowIcon /> : <ArrowIconReverse />}
      </div>

      {toggleStatusHeading && (
        <div>
          {(activeTab.includes("Items")
            ? listedTokensCount && listedTokensCount > 0
              ? ["All", "Listed"]
              : ["All"]
            : ["All", "Transfer", "Sale", "Minted/AirDropped"]
          ).map((listedStatus) => {
            return (
              <>
                <div className="filter-section-traits-filter-trait-values-row">
                  <span
                    className="filter-section-sub-headings"
                    style={{ fontWeight: 600, marginBottom: "5%" }}
                  >
                    {listedStatus}
                  </span>
                  <input
                    className="filter-section-traits-filter-trait-value-checkbox"
                    type="checkbox"
                    value={listedStatus}
                    checked={listedStatus === selectedFiltersState.status}
                    onChange={(e) =>
                      selectedFiltersState?.updateStatusFunction(e.target.value)
                    }
                  />
                </div>
              </>
            );
          })}
        </div>
      )}

      {selectedFiltersState.status === "Listed" && (
        <div className="price-filter">
          <div
            className="filter-section-headings-row"
            onClick={() => setTogglePriceHeading(!togglePriceHeading)}
          >
            <h3 className="filter-section-headings">Price</h3>
            {togglePriceHeading ? <ArrowIcon /> : <ArrowIconReverse />}
          </div>

          {togglePriceHeading && (
            <>
              <div className="price-filter-block">
                <div className="price-filter-price-range">
                  <input
                    className="price-filter-price-input"
                    type="number"
                    placeholder="Min"
                    value={selectedFiltersState.minListingPriceFilter}
                    onChange={(e) =>
                      selectedFiltersState.updateMinListingPriceFunction(
                        e.target.value
                      )
                    }
                  />
                  <span className="price-filter-min-max-to">to</span>
                  <input
                    className="price-filter-price-input"
                    type="number"
                    placeholder="Max"
                    value={selectedFiltersState.maxListingPriceFilter}
                    onChange={(e) =>
                      selectedFiltersState.updateMaxListingPriceFunction(
                        e.target.value
                      )
                    }
                  />
                </div>
                <DropdownElement
                  onChangeFunc={(value) => {
                    selectedFiltersState.updateListingPriceCurrencyFunction(
                      value
                    );
                  }}
                  options={[
                    {
                      key: "ETH",
                      text: "ETH",
                      value: "ETH",
                    },
                    {
                      key: "USD",
                      text: "USD",
                      value: "USD",
                    },
                  ]}
                  stylePropObj={{
                    color: "#747474",
                    backgroundColor: "#ecf0f9",
                    width: `${58 / 8}em`,
                    height: `${24 / 8}em`,
                    // padding: `${7 / 8}px ${5 / 8}px ${7 / 8}px ${18 / 8}px`,
                    minWidth: "0",
                    borderRadius: `${10 / 8}em`,

                    fontStyle: "normal",
                    fontWeight: 400,
                    lineHeight: "normal",
                    zIndex: 100,
                  }}
                  placeholderVal={"Filter"}
                  value={pageFilters.listingPriceCurrencyFilter}
                />
              </div>
              <button
                onClick={() => {
                  selectedFiltersState.updateListingPriceFilterFunction();
                }}
                className="price-filter-apply-button"
                disabled={listingPriceButtonDisabled}
              >
                Apply
              </button>
            </>
          )}
        </div>
      )}

      {activeTab.includes("Items") && (
        <div className="filter-section-traits-filter">
          <div className="filter-section-headings-row">
            <h3 className="filter-section-headings align-items-center">
              Traits{" "}
              <span
                onClick={searchTraitOpen}
                style={{
                  cursor: "pointer",
                }}
              >
                <SearchIcon />{" "}
              </span>
              <label className="toggle-switch">
                <div className="toggle-switch-container">
                  <button
                    onClick={() => {
                      setConditionType("AND");
                    }}
                    style={{
                      backgroundColor:
                        conditionType === "AND" ? "#2fb484" : "#CDFCC9",
                      color: conditionType === "AND" ? "white" : "black",
                      border: "none",
                    }}
                  >
                    And
                  </button>
                  <button
                    onClick={() => {
                      setConditionType("OR");
                    }}
                    style={{
                      backgroundColor:
                        conditionType === "OR" ? "#2fb484" : "#CDFCC9",
                      color: conditionType === "OR" ? "white" : "black",
                      border: "none",
                    }}
                  >
                    Or
                  </button>
                </div>
              </label>
            </h3>

            {toggleTraitHeading ? (
              <span onClick={() => setToggleTraitHeading(!toggleTraitHeading)}>
                <ArrowIcon />{" "}
              </span>
            ) : (
              <span onClick={() => setToggleTraitHeading(!toggleTraitHeading)}>
                <ArrowIconReverse />{" "}
              </span>
            )}
          </div>

          {searchTraitToggle && (
            <input
              type="text"
              placeholder="Search Traits"
              value={searchTraitQuery}
              onChange={(e) => handleTraitSearch(e.target.value)}
              className="filter-section-search-bar"
            />
          )}

          {toggleTraitHeading && (
            <div
              className="filter-section-traits-filter"
              style={{ marginTop: 0 }}
            >
              {Object.keys(filteredTraits).map((traitType) => (
                <React.Fragment key={traitType}>
                  <div
                    className="filter-section-traits-filter-trait-type"
                    onClick={() => toggleTraitType(traitType)}
                  >
                    <div className="filter-section-traits-sub-headings">
                      {traitType}
                    </div>
                    <div className="filter-section-traits-filter-heading-count-toggle">
                      {filteredTraits[traitType].length}
                      {expandedTraitTypes[traitType] ? (
                        <SmallArrowIcon />
                      ) : (
                        <SmallArrowIconReversed />
                      )}
                    </div>
                  </div>

                  {expandedTraitTypes[traitType] && (
                    <div className="filter-section-traits-filter-trait-values">
                      {Object.keys(filteredTraits[traitType])
                        .sort((a, b) => {
                          // priotity1: count
                          const countComparison =
                            filteredTraits[traitType][b] -
                            filteredTraits[traitType][a];
                          if (countComparison !== 0) {
                            return countComparison;
                          }

                          // priotity2: alphabetically
                          return a.localeCompare(b);
                        })
                        .map((value) => {
                          let count;
                          if (
                            traitCount &&
                            Object.keys(traitCount).length > 0
                          ) {
                            const key = traitType + "-" + value;
                            count = traitCount[key] ? traitCount[key] : 0;
                          } else count = filteredTraits[traitType][value];

                          return (
                            <div
                              key={value}
                              className="filter-section-traits-filter-trait-values-row"
                            >
                              <span>
                                <span className="filter-section-sub-headings">
                                  {value}{" "}
                                </span>
                                <span className="filter-section-traits-filter-trait-value-count">
                                  {" "}
                                  ({count}){" "}
                                </span>
                              </span>
                              <input
                                className="filter-section-traits-filter-trait-value-checkbox"
                                type="checkbox"
                                value={value}
                                checked={
                                  selectedFiltersState.selectedTraitValues[
                                    traitType
                                  ] &&
                                  selectedFiltersState.selectedTraitValues[
                                    traitType
                                  ].includes(value)
                                }
                                onChange={() =>
                                  selectedFiltersState.updateSelectedTraitValuesFunction(
                                    traitType,
                                    value
                                  )
                                }
                              />
                            </div>
                          );
                        })}
                    </div>
                  )}
                </React.Fragment>
              ))}
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default FilterSection;
