import React, { useState, useEffect } from "react";
import Joyride from "react-joyride";

const JoyrideHelper = ({ steps, startTour, setStartTour, finishTourFunc }) => {
  const [run, setRun] = useState(startTour);
  const [stepIndex, setStepIndex] = useState(0);

  useEffect(() => {
    if (startTour) {
      setRun(true);
      setStepIndex(0);
    }
  }, [startTour]);

  const handleJoyrideCallback = (data) => {
    const { status, index, type } = data;
    const finishedStatuses = ["finished", "skipped"];

    if (data.action === "close" || finishedStatuses.includes(status)) {
      setRun(false);
      setStartTour(false);

      finishTourFunc();
      return;
    }

    if (data.action === "next" && data.lifecycle === "complete") {
      setStepIndex((prev) => prev + 1);
    }
    if (data.action === "prev" && data.lifecycle === "complete") {
      setStepIndex((prev) => prev - 1);
    }
    if (type === "step:after") {
      if (steps[index].afterFunc) {
        steps[index].afterFunc();
      }
    } else if (type === "step:before") {
      if (steps[index].beforeFunc) {
        steps[index].beforeFunc();
      }
    } else if (type === "target:notFound") {
    }
  };

  return (
    <Joyride
      steps={steps}
      continuous
      run={run}
      stepIndex={stepIndex}
      callback={handleJoyrideCallback}
      showSkipButton
      showProgress
      styles={{
        options: {
          arrowColor: "#2fb484",
          backgroundColor: "white",
          overlayColor: "transparent",
          primaryColor: "#2fb484",
          textColor: "black",
          width: "100%",
          height: "100%",
          zIndex: "1000000000000000000000000",
          position: "fixed",
          top: 0,
          left: 0,
        },
      }}
    />
  );
};

export default JoyrideHelper;
