// src/contexts/FilterSectionContext.js
import React, { createContext, useEffect, useReducer } from "react";
import filterSectionReducer from "../../reducers/CollectionPage/filterSectionReducer";

export const CollectionFilterSectionContext = createContext();

export const initialCollectionPageContext = {
  tokenStatus: "All",
  activityStatus: "All",

  minListingPriceFilter: null,
  maxListingPriceFilter: null,

  listingPriceCurrencyFilter: "ETH",

  listingPriceFilter: {
    minListingPriceFilter: null,
    maxListingPriceFilter: null,
    listingPriceCurrencyFilter: "ETH",
  },

  tokenSelectedTraitValues: {},
  activitySelectedTraitValues: {},

  tokensSortText: "Date Minted: Newest to Oldest",
  tokensSortKey: "mintTimestamp",
  tokensSortDirection: -1,

  searchQuery: null,
  searchQueryTokensSelected: {},
};

const initialCollectionPageFiltersState =
  JSON.parse(localStorage.getItem("collectionPageFiltersState")) ||
  initialCollectionPageContext;
export const CollectionFilterSectionProvider = ({ children }) => {
  const [collectionPageFilters, updateCollectionPageFilters] = useReducer(
    filterSectionReducer,
    initialCollectionPageFiltersState
  );

  useEffect(() => {
    localStorage.setItem(
      "collectionPageFiltersState",
      JSON.stringify(collectionPageFilters)
    );
  }, [collectionPageFilters]);

  return (
    <CollectionFilterSectionContext.Provider
      value={{ collectionPageFilters, updateCollectionPageFilters }}
    >
      {children}
    </CollectionFilterSectionContext.Provider>
  );
};
