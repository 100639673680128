import React, { useState } from "react";
import CloseButton from "../../Icons/CloseButton";
import { setDefaultFilter, updateUserFilter } from "../../api/mjUserApi";

const FilterEditModal = ({
  setOpenFilterModal,
  editFilter,
  userId,
  onRename,
  defaultFilterGroup,
  setDefaultFilterGroup,
}) => {
  const [inputText, setInputText] = useState(editFilter?.filterName || "");
  const closemodal = () => {
    setOpenFilterModal(false);
  };
  const renameFilter = () => {
    if (inputText.trim() === "") {
      alert("Filter name cannot be empty");
      return;
    }

    updateUserFilter(editFilter._id, inputText)
      .then(() => {
        onRename(editFilter._id, inputText);
        setOpenFilterModal(false);
      })
      .catch((error) => {
        console.error("Error renaming filter:", error);
      });
  };

  const makeDefault = () => {
    setDefaultFilter(userId, editFilter?._id, editFilter?.filterLocation)
      .then(() => {
        setOpenFilterModal(false);
        setDefaultFilterGroup(editFilter);
      })
      .catch((error) => {
        console.error("Error making default filter:", error);
      });
  };

  return (
    <div className="modal-overlay" style={{ background: "rgba(0,0,0, 0.5)" }}>
      <div className="filter-edit-modal-component">
        <span
          style={{
            display: "flex",
            justifyContent: "flex-end",
            cursor: "pointer",
          }}
          onClick={closemodal}
        >
          <CloseButton />
        </span>
        <span className="home-page-edit-modal-heading"> Edit Filter</span>
        {
          <div className="filter-edit--modal-filters-home-page">
            {editFilter?.userId && (
              <input
                type="text"
                placeholder="Enter new filter name"
                className="profile-account-info-value"
                value={inputText}
                onChange={(e) => setInputText(e.target.value)}
              />
            )}
            <div className="edit-filters-buttons tour-step-14">
              {editFilter?.userId && (
                <span className="add-folder-button" onClick={renameFilter}>
                  {"Rename"}
                </span>
              )}
              <span
                className="add-folder-button tour-step-15"
                onClick={makeDefault}
              >
                {editFilter?._id === defaultFilterGroup?._id
                  ? "Remove Default"
                  : "Make Default"}
              </span>
            </div>
          </div>
        }
      </div>
    </div>
  );
};

export default FilterEditModal;
