import React, { useEffect, useState } from "react";
// import Header from "../components/Homepage/Header/HeaderDesktop";
import ListingPrice from "../components/ItemPage/PriceListings/ListingPrice";
import "../styles/itemPageStyles/ItemPage.css";
import "../../src/styles/itemPageStyles/ItemPage.css";
import InfoTabs from "../components/ItemPage/InfoTabs/InfoTabs.js";
import Background from "../components/UtilComponents/Background";
import Scorecard from "../components/ItemPage/Scorecard/Scorecard";
import Highlights from "../components/ItemPage/Highlights/Highlights";
import ItemImage from "../components/ItemPage/Image/ItemImage";
import { fetchTokenDetails } from "../api/tokenDetailsApi";
import TokenInfo from "../components/ItemPage/TokenInfo/TokenInfo";
import RecommendationsFromCollection from "./RecommendationsFromCollection";
import useIsMobile from "../customHooks/UseIsMobile.js";
import HeaderDesktop from "../components/Homepage/Header/HeaderDesktop";
import { useParams, useNavigate } from "react-router-dom";
import {
  fetchCollectionDetails,
  fetchCollectionTokens,
  fetchContractAddressbylowercase,
} from "../api/collectionDetailsApi";
import tokenApis from "../api/tokenApis";
import FooterSection from "../components/FooterSection.js";
import { specialContractAddresses } from "../utils/commonUtils/CollectionUtils";
import { Helmet } from "react-helmet";
import { fetchTokenNameFromTokenObj } from "../utils/commonUtils/TokenUtils";
import nftTransferApi from "../api/nftTransferApi.js";

export default function ItemPage(params) {
  const [itemData, setItemData] = useState(null);
  const [isFetchingData, setIsFetchingData] = useState(false);
  const [collectionData, setCollectionData] = useState({});
  const [itemsFromCollection, setItemsFromCollection] = useState([]);
  const [isFetchingItemsFromCollection, setIsFetchingItemsFromCollection] =
    useState([]);
  const [
    tokensFromCollectionPaginatedAPIMetadata,
    setTokensFromCollectionPaginatedAPIMetadata,
  ] = useState({
    totalCount: -1,
    totalPages: -1,
    currentPage: 0,
    limit: 32,
    sortKey: null,
    sortDirection: -1,
    currentCount: 0,
  });

  const [tokenTransferHistory, setTokenTransferHistory] = useState([]);
  const [
    transferHistoryPaginatedAPIMetadata,
    setTransferHistoryPaginatedAPIMetadata,
  ] = useState({
    more: true,
    currentPage: 0,
    limit: 16,
    sortKey: "event_timestamp",
    sortDirection: -1,
    currentCount: 0,
  });
  const [isFetchingTransferHistory, setIsFetchingTransferHistory] =
    useState(false);

  const isMobile = useIsMobile();

  const { collectionId, itemId } = useParams();

  const fetchPaginatedTokensFromCollection = async (
    collectionSlug_,
    collectionCreator_
  ) => {
    setIsFetchingItemsFromCollection(true);
    fetchCollectionTokens(
      collectionId,
      [],
      null,
      null,
      null,
      1,
      tokensFromCollectionPaginatedAPIMetadata.limit,
      tokensFromCollectionPaginatedAPIMetadata.sortKey,
      tokensFromCollectionPaginatedAPIMetadata.sortDirection,
      null,
      false,
      collectionSlug_,
      collectionCreator_
    )
      .then((response) => {
        if (response.tokensWithDetails.length > 0) {
          setItemsFromCollection(response.tokensWithDetails);
        }
      })
      .catch((e) => {
        setItemsFromCollection([]);
      })
      .finally(() => {
        setIsFetchingItemsFromCollection(false);
      });
  };
  const fetchPaginatedTransferHistory = async (sort, direction, refresh) => {
    if (refresh === false && transferHistoryPaginatedAPIMetadata.more === false)
      return;

    if (isFetchingTransferHistory) return;

    setIsFetchingTransferHistory(true);
    nftTransferApi
      .fetchNftTransfers(
        itemId,
        collectionId,
        refresh ? 1 : transferHistoryPaginatedAPIMetadata.currentPage + 1,
        transferHistoryPaginatedAPIMetadata.limit,
        sort || transferHistoryPaginatedAPIMetadata.sortKey,
        direction || transferHistoryPaginatedAPIMetadata.sortDirection
      )
      .then((response) => {
        if (refresh)
          setTokenTransferHistory(
            (prevTransferHistory) => response.nftTransfers || []
          );
        else
          setTokenTransferHistory((prevTransferHistory) => [
            ...prevTransferHistory,
            ...(response.nftTransfers || []),
          ]);
        const limit = transferHistoryPaginatedAPIMetadata.limit;
        const resCount = response.nftTransfers.length;
        let more = true;
        if (!resCount || resCount < limit) more = false;
        if (response) {
          setTransferHistoryPaginatedAPIMetadata({
            ...transferHistoryPaginatedAPIMetadata,
            more: more,
            currentPage: parseInt(response?.page) || 1,
            sortKey: response?.sortKey,
            sortDirection: response?.sortDirection || -1,

            currentCount:
              transferHistoryPaginatedAPIMetadata.currentCount +
              (response?.count || 0),
          });
        }
      })
      .catch((error) => {
        setTokenTransferHistory([]);
      })
      .finally(() => {
        setIsFetchingTransferHistory(false);
      });
  };
  useEffect(() => {
    fetchPaginatedTransferHistory();
    setIsFetchingData(true);
    fetchTokenDetails(collectionId, itemId)
      .then((tokenDetail) => {
        setItemData(tokenDetail);
        let collectionSlug =
          tokenDetail?.contractAddress ===
          specialContractAddresses.OSSharedFront
            ? tokenDetail?.openseaDetails?.collection
            : null;
        let collectionCreator =
          tokenDetail?.contractAddress === specialContractAddresses.Foundation
            ? tokenDetail?.openseaDetails?.creator
            : null;
        fetchPaginatedTokensFromCollection(collectionSlug, collectionCreator);
        fetchCollectionDetails(collectionId, collectionSlug, collectionCreator)
          .then((collectionDetail) => {
            setCollectionData(collectionDetail);
          })
          .catch((error) => {
            setCollectionData({});
          });
      })
      .catch((error) => {
        setIsFetchingData(false);
      })
      .finally(() => {
        setIsFetchingData(false);
      });
  }, [collectionId, itemId]);

  return (
    <div>
      <Helmet>
        <title>
          {fetchTokenNameFromTokenObj(itemData) === "No Name"
            ? collectionId + "-" + itemId
            : fetchTokenNameFromTokenObj(itemData)}
        </title>
        <meta
          name="description"
          content="Welcome to the Token/Item Page!

          On this page, you'll find detailed information about your Token/NFT/Item minted on Blockchain!"
        />
      </Helmet>
      {isMobile ? (
        // Mobile version of the component
        <div id="ItemPage">
          <HeaderDesktop />
          <Background>
            <div className="item-page-layout-mobile">
              <div className="item-page-container-left-column-mobile">
                <TokenInfo
                  itemData={itemData}
                  collectionData={collectionData}
                  burnedDate={tokenTransferHistory}
                  isFetchingData={isFetchingData}
                />
                <ItemImage itemData={itemData} />
              </div>
              <div className="item-page-container-right-column-mobile">
                <br></br>
                <ListingPrice
                  itemData={itemData}
                  setItemData={setItemData}
                  collectionData={collectionData}
                />
                <InfoTabs
                  itemData={itemData}
                  collectionData={collectionData}
                  tokenTransferHistory={tokenTransferHistory}
                  fetchPaginatedTransferHistory={fetchPaginatedTransferHistory}
                  transferHistoryPaginatedAPIMetadata={
                    transferHistoryPaginatedAPIMetadata
                  }
                  isFetchingData={isFetchingTransferHistory}
                />
              </div>
              <RecommendationsFromCollection
                itemData={itemData}
                isFetchingData={isFetchingData}
                collectionData={collectionData}
                itemsFromCollection={itemsFromCollection}
                isFetchingItemsFromCollection={isFetchingItemsFromCollection}
                totalCount={null}
              />
            </div>
            <FooterSection />
          </Background>
        </div>
      ) : (
        // Desktop version of the component
        <div id="ItemPage">
          <HeaderDesktop />
          <br />
          <br />
          <br />
          <Background>
            <div className="item-page-layout mt-2">
              <div className="item-page-container-left-column">
                <ItemImage itemData={itemData} />
                <InfoTabs
                  itemData={itemData}
                  collectionData={collectionData}
                  tokenTransferHistory={tokenTransferHistory}
                  fetchPaginatedTransferHistory={fetchPaginatedTransferHistory}
                  transferHistoryPaginatedAPIMetadata={
                    transferHistoryPaginatedAPIMetadata
                  }
                  isFetchingData={isFetchingTransferHistory}
                />
              </div>
              <div className="item-page-container-right-column">
                <TokenInfo
                  itemData={itemData}
                  collectionData={collectionData}
                  burnedDate={tokenTransferHistory}
                  isFetchingData={isFetchingData}
                />
                <ListingPrice itemData={itemData} setItemData={setItemData} />
              </div>
            </div>
            <RecommendationsFromCollection
              itemData={itemData}
              isFetchingData={isFetchingData}
              collectionData={collectionData}
              itemsFromCollection={itemsFromCollection}
              isFetchingItemsFromCollection={isFetchingItemsFromCollection}
              totalCount={tokensFromCollectionPaginatedAPIMetadata?.totalCount}
            />
            <FooterSection />
          </Background>
        </div>
      )}
    </div>
  );
}
