import {
  GoogleLogin,
  GoogleOAuthProvider,
  useGoogleLogin,
} from "@react-oauth/google";
import MetaMaskIcon from "../../assets/images/MetamaskIcon.webp";
import useIsMobile from "../../customHooks/UseIsMobile";
import "../../styles/loginPageStyles/loginPageStyles.css";
import { useNavigate } from "react-router-dom";
import CustomGoogleButton from "./CustomGoogleButton";
import { useEffect, useState } from "react";
import { FaEyeSlash, FaRegEye } from "react-icons/fa";
import { useSelector } from "react-redux";

export default function LoginInputPart({
  handleLogOutButtonClick,
  handleLoginFormSubmit,
  handleGoogleSignIn,
  email,
  setEmail,
}) {
  let isMobile = useIsMobile();

  let navigate = useNavigate();
  // State to manage password visibility
  const [showPassword, setShowPassword] = useState(false);

  // Function to toggle password visibility
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const userState = useSelector((state) => state.user);

  useEffect(() => {
    if (userState?.loggedIn) {
      setEmail(userState?.userInfo?.emailAddress);
    }
  }, []);

  return (
    <div
      className="d-flex flex-column login-page-input-animation"
      style={{ width: isMobile ? "100%" : "60%", paddingTop: "4vw" }}
    >
      <div
        className="d-flex flex-column"
        style={{ marginLeft: "13vw", marginRight: "6vw", marginTop: "10vh" }}
      >
        <h1 style={{ fontSize: "2.2vw", marginBottom: "2vw" }}>Login</h1>
        <form className="d-flex flex-column" onSubmit={handleLoginFormSubmit}>
          <div style={{ marginBottom: "0.7vw", fontSize: "1vw" }}>
            Email Address
          </div>
          <input
            type="email"
            placeholder="Email"
            name="username"
            className={"feedback-form-input"}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            style={{ fontSize: "1em", marginBottom: "0.2vw" }}
          ></input>
          <div
            style={{
              marginTop: "1em",
              marginBottom: "0.7vw",
              fontSize: "1vw",
            }}
          >
            Your Password
          </div>
          <div style={{ position: "relative", display: "inline-block" }}>
            <input
              placeholder="Password"
              type={showPassword ? "text" : "password"}
              name="password"
              className="feedback-form-input"
              style={{
                fontSize: "1em",
                marginBottom: "0.2vw",
                paddingRight: "2em",
              }}
            />
            <span
              onClick={togglePasswordVisibility}
              style={{
                position: "absolute",
                right: "0.5em",
                top: "50%",
                transform: "translateY(-50%)",
                cursor: "pointer",
              }}
            >
              {" "}
              {showPassword ? (
                <FaEyeSlash size={"1.2em"} />
              ) : (
                <FaRegEye size={"1.2em"} />
              )}
            </span>
          </div>
          {/* <input
            placeholder="Password"
            type="password"
            name="password"
            className="feedback-form-input"
            style={{ fontSize: "1em", marginBottom: "0.2vw" }}
          /> */}
          <div
            style={{
              marginBottom: "1.5vw",
              marginLeft: "83%",
              color: "rgb(150,150,150)",
              fontSize: "0.8vw",
              whiteSpace: "nowrap",
              cursor: "pointer",
            }}
            onClick={() => {
              navigate("/reset-password");
            }}
          >
            Forgot Password
          </div>
          <button
            type="submit"
            className="login-send-otp-button"
            style={{
              width: "30%",
              height: "3vw",
            }}
          >
            Sign in
          </button>
        </form>
        <div
          className="d-flex justify-content-between align-items-center w-100"
          style={{ marginTop: "2.5vw", marginBottom: "2.5vw" }}
        >
          <div
            style={{
              width: "45%",
              height: "0.15vw",
              backgroundColor: "rgb(200,200,200)",
            }}
          ></div>
          <div style={{ fontSize: "1vw", fontWeight: "500" }}>OR</div>
          <div
            style={{
              width: "45%",
              height: "0.15vw",
              backgroundColor: "rgb(200,200,200)",
            }}
          ></div>
        </div>
        <GoogleOAuthProvider clientId="1034694776802-fn254mm4m5ttd1p766of161rrp104aej.apps.googleusercontent.com">
          <CustomGoogleButton handleGoogleSignIn={handleGoogleSignIn} />
        </GoogleOAuthProvider>
        {/* <button
          className="d-flex align-items-center"
          style={{
            height: "3.5vw",
            borderRadius: "2vw",
            border: "1px solid black",
            backgroundColor: "white",
          }}
        >
          <img
            src={MetaMaskIcon}
            alt="MetaMaskIcon"
            style={{ width: "2.5vw", marginRight: "0.89vw", marginLeft: "26%" }}
          />
          <div
            style={{
              fontSize: "1.3vw",
              fontWeight: "lighter",
              marginRight: "0.6vw",
            }}
          >
            Continue with MetaMask
          </div>
        </button> */}
      </div>
    </div>
  );
}
