import React from "react";

const ImageInfoModal = () => {
  return (
    <div className="item-image-modal-container">
      <span className="rarity-modal-text">
      Be careful! This image is no longer hosted!
        This means this image does not exist on the blockchain and that the original source has been compromised. 
        To read more about this topic, click here
      </span>
    </div>
  );
};

export default ImageInfoModal;
