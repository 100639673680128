import { REACT_APP_ATLAS_SEARCH_API_BASE_URL } from "./backend_domains/backend_api_domains";
import axios from "axios";

class AtlasSearchApi {
  constructor() {
    this.baseUrl = `${REACT_APP_ATLAS_SEARCH_API_BASE_URL}`;
  }

  async searchAtlasForMJUsers({ input, page, limit }) {
    try {
      let everyoneResp = [];
      if (input && "everyone".startsWith(input.toLowerCase())) {
        everyoneResp = ["everyone"];
      }

      let response = await axios.get(`${this.baseUrl}/public/search/mjusers`, {
        params: {
          input,
          page,
          limit,
        },
      });

      if (!response || !response.data) {
        response = [];
      }

      let resp = this.processSearchMJUsersResponse([
        ...(response.data || []),
        ...(everyoneResp || []),
      ]);
      return resp.mjUserslist || [];
    } catch (e) {
      console.error(`Error searching atlas for MJusers ${input}: `, e);
      throw e;
    }
  }

  async searchAtlasForTokens({
    size,
    input,
    tokenOwners,
    tokenCreator,
    contractAddress,
  }) {
    try {
      const response = await axios.get(`${this.baseUrl}/public/search/tokens`, {
        params: {
          size,
          input,
          tokenOwners,
          tokenCreator,
          contractAddress,
        },
      });

      if (!response || !response.data) {
        return [];
      }

      let resp = this.processSearchTokenResponse(response);
      return resp.tokenDataList || [];
    } catch (e) {
      console.error(
        `Error searching atlas for Tokens In collection ${contractAddress}: `,
        e
      );
      throw e;
    }
  }

  async searchAtlas({
    index,
    field,
    size,
    searchMethod,
    input,
    collectionName,
    searchType,
  }) {
    try {
      const response = await axios.get(`${this.baseUrl}/public/search`, {
        params: {
          index: index,
          field: field,
          size: size,
          searchMethod: searchMethod,
          input: input,
          collectionName: collectionName,
        },
      });

      if (!response || !response.data) {
        return [];
      }

      if (searchType === "token") {
        return this.processSearchTokenResponse(response) || [];
      } else if (searchType === "collection") {
        return this.processSearchCollectionResponse(response) || [];
      } else if (searchType === "openseaUser") {
        return this.processSearchOpenseaUserResponse(response) || [];
      } else if (searchType === "address") {
        return this.processAddressSearchResponse(response) || [];
      }

      return [];
    } catch (e) {
      console.error("Error searching atlas:", e);
      throw e;
    }
  }

  processAddressSearchResponse(response) {
    if (!response || !response.data) {
      return [];
    }
    const collection = this.processSearchCollectionResponse(
      response.data.collection
    );
    const token = this.processSearchTokenResponse(response.data.token);
    const user = this.processSearchOpenseaUserResponse(response.data.user);
    return { collection, token, user };
  }

  processSearchTokenResponse(response) {
    const res = response.data || response;
    let tokenDataList = res.map((item) => {
      let varified = false;
      if (item.mjDescription) varified = true;

      let openseaDetails = {};
      if (item.openseaDetails) {
        openseaDetails = item.openseaDetails?.reduce((obj, item) => {
          obj[item.Key] = item.Value;
          return obj;
        }, {});
      }

      let tokenMetadata = {};
      if (item.tokenMetadata && item.tokenMetadata[0]) {
        tokenMetadata = item.tokenMetadata[0].Value?.reduce((obj, item) => {
          obj[item.Key] = item.Value;
          return obj;
        }, {});
      }

      let s3Image = {};
      if (item.s3Image) {
        item.s3Image.forEach((item) => {
          s3Image[item.Key] = item.Value;
        });
        let error = null;
        s3Image.error.forEach((item) => {
          if (item.Key === "exists") {
            error = item.Value;
          }
        });
        delete s3Image.error;
        s3Image.error = {};
        s3Image.error.exists = error;
      }

      let compressedS3Image = {};
      if (item.compressedS3Image) {
        item.compressedS3Image.forEach((item) => {
          compressedS3Image[item.Key] = item.Value;
        });
        // const error = compressedS3Image.error[0].Value;
        let imageError = null;
        compressedS3Image.error.forEach((item) => {
          if (item.Key === "exists") {
            imageError = item.Value;
          }
        });
        delete compressedS3Image.error;
        compressedS3Image.error = {};
        compressedS3Image.error.exists = imageError;
      }

      if (Object.keys(s3Image).length === 0) s3Image = null;
      if (Object.keys(compressedS3Image).length === 0) compressedS3Image = null;

      return {
        contractAddress: item.contractAddress,
        tokenId: item.tokenId,
        tokenMetadata: tokenMetadata,
        openseaDetails: openseaDetails,
        varified: varified,
        s3Image: s3Image,
        compressedS3Image: compressedS3Image,
      };
    });
    return { query: response.config?.params?.input, tokenDataList };
  }

  processSearchCollectionResponse(response) {
    const res = response.data || response;
    let collectionDataList = res.map((item) => {
      let varified = false;
      if (item.priority === "white") varified = true;
      let collectionDetailList = item.openseaDetails[1].Value;
      let collectionDetails = {};
      let scores = {
        volumeScore: item.scores ? item.scores[0]?.Value : 1,
      };
      collectionDetailList.forEach((item) => {
        collectionDetails[item.Key] = item.Value;
      });
      return {
        contractAddress: item._id,
        collectionDetails: collectionDetails,
        scores: scores,
        varified: varified,
        totalVolume: item?.totalVolume || 0,
      };
    });
    return { query: response.config?.params?.input, collectionDataList };
  }

  processSearchOpenseaUserResponse(response) {
    const res = response.data || response;
    let openseaUserList = res.map((item) => {
      let varified = false;
      if (item.mjUserDescription) varified = true;
      let openseaDetails = {};
      if (item.openseaDetails) {
        item.openseaDetails.forEach((item) => {
          openseaDetails[item.Key] = item.Value;
        });
      }
      return {
        walletAddress: item._id,
        openseaDetails: openseaDetails,
        walletENSName: item.walletENSName,
        varified: varified,
      };
    });
    return { query: response.config?.params?.input, openseaUserList };
  }

  processSearchMJUsersResponse(response) {
    const res = response.data || response;
    let mjUserslist = res.map((item) => {
      if (item === "everyone") {
        return {
          _id: "everyone",
          emailAddress: "everyone",
          firstName: "everyone",
          lastName: "everyone",
          profileName: "everyone",
        };
      }

      let externalLinks = {};
      if (item.externalLinks) {
        item.externalLinks.forEach((item) => {
          externalLinks[item.Key] = item.Value;
        });
      }

      let profileImage = {};
      if (item.profileImage) {
        item.profileImage.forEach((item) => {
          profileImage[item.Key] = item.Value;
        });
      }
      return {
        _id: item._id,
        emailAddress: item._id,
        firstName: item.firstName,
        isGoogleUser: item.isGoogleUser,
        lastName: item.lastName,
        lastName: item.lastName,
        profileName: item.profileName,
        walletAddressList: item.walletAddressList,
        externalLinks: externalLinks,
        externalLinks: item.walletENSName,
        profileImage: profileImage,
      };
    });
    return { query: response.config?.params?.input, mjUserslist };
  }
}

const atlasSearchApi = new AtlasSearchApi();
export { atlasSearchApi };
