import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import loadingImage from "../../assets/images/loading-gear.gif";

import VarifiedIcon from "../Homepage/Header/VarifiedIcon";
import { prettyName } from "../../utils/commonUtils/PrettyString";
import {
  fetchImageURLFromTokenObj,
  fetchTokenDefaultImageOnError,
} from "../../utils/commonUtils/TokenUtils";

import ImgSrcMissingImg from "../../assets/images/imageSRCmissing.png";

const defaultImageUrl =
  "https://www.shutterstock.com/image-vector/dark-blue-green-vector-blur-600nw-1692448585.jpg";

export default function CollectionSearchResultItem({
  item,
  isSearching,
  contractAddrSelected,
  addToCollectionObjsSelected,
  deleteFromCollectionObjsSelected,
  allFiltersSelected,
}) {
  const navigate = useNavigate();
  const [imgUrl, setImgUrl] = useState("");
  const [selected, setSelected] = useState(false);

  useEffect(() => {
    if (isSearching) {
      setImgUrl(loadingImage);
    } else {
      setImgUrl(item?.collectionDetails?.image_url);
    }
  }, [item, isSearching]);

  function handleImgErr(event, item) {
    event.target.src = defaultImageUrl;
  }

  return (
    <div
      className="search-section-result-item search-result-hover"
      onClick={(e) => {
        if (
          (!allFiltersSelected && !contractAddrSelected) ||
          (allFiltersSelected && contractAddrSelected)
        ) {
          addToCollectionObjsSelected(item);
        } else {
          deleteFromCollectionObjsSelected(item);
        }
      }}
    >
      <div className="search-section-result-item-checkbox">
        <input
          style={{ height: "0.8rem", width: "0.8rem" }}
          type="checkbox"
          checked={contractAddrSelected}
        />
      </div>
      <img
        src={imgUrl}
        alt="Collection Img"
        className="search-section-result-item-image"
        style={{ objectFit: "cover", width: "4vw" }}
        onError={(event) => {
          event.target.src = ImgSrcMissingImg;
        }}
      />
      <div className="search-section-result-item-content">
        {item?.collectionDetails?.name
          ? prettyName(item?.collectionDetails?.name)
          : prettyName(item?.collectionDetails?.collection)}
      </div>
      {item.varified && (
        <div
          className="verified-icon"
          style={{
            backgroundColor: "rgba(255,255,255,0)",
            marginTop: "0.2vw",
            marginRight: "0.2vw",
            marginBottom: "0.2vw",
          }}
        >
          <VarifiedIcon varified={item.varified} />
        </div>
      )}
    </div>
  );
}
