import React from "react";

const ShareIcon = () => {
  return (
    <div>
      <svg xmlns="http://www.w3.org/2000/svg" width="12" height="14" viewBox="0 0 15 16" fill="none">
        <path d="M1 9.10522V13.5263C1 14.3402 1.65979 15 2.47368 15H12.7895C13.6034 15 14.2632 14.3402 14.2632 13.5263V9.10522" stroke="black" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M7.63148 11.3158V1M7.63148 1L3.94727 5.01171M7.63148 1L11.3157 5.01169" stroke="black" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
    </div>
  );
};

export default ShareIcon;
