import { useEffect, useState } from "react";
import HeaderDesktop from "../components/Homepage/Header/HeaderDesktop";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";

export default function DefaultPage({ errorPage }) {
  const [isFirstRender, setIsFirstRender] = useState(true);
  const location = useLocation();
  useEffect(() => {
    if (isFirstRender) setIsFirstRender(false);
    else window.location.reload();
  }, [location]);

  return (
    <div className="d-flex justify-content-center align-items-center w-100">
      <Helmet>
        <title>Metajungle</title>
        <meta name="description" />
      </Helmet>
      <div className="w-100 h-100" style={{ backgroundColor: "white" }}>
        <HeaderDesktop />
        <div className="w-100 d-flex justify-content-center align-items-center">
          {errorPage ? (
            <div style={{ marginTop: "10vw" }}>
              <h1>Something Went Wrong.</h1>
              <h4>Try Reloading the Page.</h4>
              <h4>Meanwhile, our team is working on resolving the issues!</h4>
            </div>
          ) : (
            <div className="d-flex flex-column align-items-center">
              <h1 style={{ marginTop: "10vw" }}>404 NOT FOUND</h1>
              <div>This page doesn't exist</div>
              <div>Bad Link</div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
