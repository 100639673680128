import { isNullOrUndefinedOrEmptyObject } from "../utils/commonUtils/Utils";
import { REACT_APP_BACKEND_API_BASE_URL } from "./backend_domains/backend_api_domains";
import axios from "axios";
import { setupCache } from "axios-cache-interceptor";
// const instance = Axios.create();
// const axios = setupCache(instance);

const URL = `${REACT_APP_BACKEND_API_BASE_URL}`;

export const createFolder = async (userId, parentFolderId, folderName) => {
  try {
    const response = await axios.post(
      `${REACT_APP_BACKEND_API_BASE_URL}/mj_user/${userId}/folders/`,
      {
        parentFolderId: parentFolderId,
        folderName: folderName,
      }
    );

    return response?.data?.createdFolder || null;
  } catch (error) {
    console.error("Error fetching Sub Folders in Folders:", error);
    throw error;
  }
};

export const updateFolder = async (
  userId,
  folderId,
  updatedName,
  parentFolderId
) => {
  try {
    let updateObj = {};
    if (updatedName) {
      updateObj["name"] = updatedName;
    }
    if (parentFolderId) {
      updateObj["parentFolderId"] = parentFolderId;
    }

    const response = await axios.put(
      `${REACT_APP_BACKEND_API_BASE_URL}/mj_user/${userId}/folders/${folderId}`,
      updateObj
    );

    return response?.data?.updatedFolderObject || null;
  } catch (error) {
    console.error("Error Updating Folder:", error);
    throw error;
  }
};

export const deleteFolderApi = async (userId, folderId) => {
  try {
    const response = await axios.delete(
      `${REACT_APP_BACKEND_API_BASE_URL}/mj_user/${userId}/folders/${folderId}`
    );

    if (response?.data?.success === true) return true;
    else return null;
  } catch (error) {
    throw error;
  }
};

export const fetchContentsInFolder = async (
  userId,
  parentFolderId,
  selectedWalletAddresses
) => {
  try {
    const response = await axios.get(
      `${REACT_APP_BACKEND_API_BASE_URL}/mj_user/${userId}/folders/${parentFolderId}/content`,
      {
        params: {
          selectedWalletAddresses: (selectedWalletAddresses || []).join(","),
        },
      }
    );

    return response?.data?.folderObjWithContent || null;
  } catch (error) {
    console.error("Error Updating Folder:", error);
    throw error;
  }
};

export const addContentsInFolder = async (
  userId,
  parentFolderId,
  contentsToAdd
) => {
  try {
    const response = await axios.put(
      `${REACT_APP_BACKEND_API_BASE_URL}/mj_user/${userId}/folders/${parentFolderId}/content`,
      {
        contentsToAdd: contentsToAdd,
      }
    );

    return response?.data?.folderDetailsWithContentsAdded || null;
  } catch (error) {
    throw error;
  }
};

export const moveContentsBetweenFolder = async (
  userId,
  fromFolderId,
  toFolderId,
  contentsToMove
) => {
  try {
    const response = await axios.put(
      `${REACT_APP_BACKEND_API_BASE_URL}/mj_user/${userId}/folders/content/move`,
      {
        contentsToMove,
        fromFolderId,
        toFolderId,
      }
    );

    return response?.data || null;
  } catch (error) {
    throw error;
  }
};

export const deleteContentFromFolder = async (
  userId,
  parentFolderId,
  contentToDelete
) => {
  try {
    const response = await axios.delete(
      `${REACT_APP_BACKEND_API_BASE_URL}/mj_user/${userId}/folders/${parentFolderId}/content`,
      { data: { contentToDelete } }
    );

    if (response?.data?.success === true) return true;
    else throw new Error("Failed to delete content from Folder");
  } catch (error) {
    throw error;
  }
};

export const fetchSubFolders = async (userId, parentFolderId) => {
  try {
    if (!userId) {
      return [];
    }
    const response = await axios.get(
      `${REACT_APP_BACKEND_API_BASE_URL}/mj_user/${userId}/folders/${parentFolderId}/subFolders`
    );

    return response?.data?.subFolders || [];
  } catch (error) {
    console.error("Error fetching Sub Folders in Folders:", error);
    throw error;
  }
};
