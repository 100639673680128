import React from "react";

const MetaJungleDescription = ({ collectionData }) => {
  return (
    <div className="description-section-content">
      {collectionData?.mjCollectionDescription
        ? collectionData.mjCollectionDescription
        : "No MetaJungle Description at the moment."}
    </div>
  );
};

export default MetaJungleDescription;
