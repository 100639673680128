import React, { useState } from "react";
import "../../styles/CommonComponentsStyles/InfoComponent.css";

export default function AddInfoModal({
  children,
  info,
  renderDirection,
  styleObj,
  styleObjModal,
}) {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleInfoIconHover = () => {
    setIsModalOpen(true);
  };
  const handleCloseInfoModal = () => {
    setIsModalOpen(false);
  };

  let position =
    renderDirection === "up-right"
      ? {
          bottom: "calc(1vw)",
        }
      : renderDirection === "up-left"
      ? {
          bottom: "calc(1vw)",
          right: "0",
        }
      : renderDirection === "bottom-left"
      ? {
          right: "0",
        }
      : {
          top: "calc(1vw)",
        };

  return (
    <div
      style={{
        position: "relative",
        cursor: "pointer",
      }}
    >
      <div
        onMouseEnter={() => handleInfoIconHover(true)}
        onMouseLeave={() => handleCloseInfoModal(true)}
        style={{ ...styleObj }}
      >
        {children}
      </div>
      {isModalOpen && (
        <pre
          className="info-text-modal-container"
          style={{
            lineHeight: "2em",
            textAlign: "left",
            fontWeight: "bold",
            ...position,
            zIndex: "99999999999999999",
            ...styleObjModal,
          }}
        >
          {info || "This is a test modal"}
        </pre>
      )}
    </div>
  );
}
