import React from "react";
import { GoogleOAuthProvider, useGoogleLogin } from "@react-oauth/google";
import GoogleIcon from "../../assets/images/GoogleIcon.png";

const CustomGoogleButton = ({ handleGoogleSignIn }) => {
  const googleLogin = useGoogleLogin({
    onSuccess: (res) => {
      handleGoogleSignIn(res.access_token);
    },
    onError: () => {
      alert("Error while logging in with Google");
    },
    scope: "openid email profile",
    accessType: "offline",
    prompt: "consent",
    responseType: "token id_token",
  });

  return (
    <button
      onClick={() => {
        googleLogin();
      }}
      className="d-flex align-items-center google-sign-in-button"
    >
      <img
        src={GoogleIcon}
        alt="GoogleIcon"
        style={{ width: "2.3vw", marginRight: "1vw" }}
      />
      <div
        style={{
          fontSize: "1.2vw",
          fontWeight: "lighter",
        }}
      >
        Continue with Google
      </div>
    </button>
  );
};

export default CustomGoogleButton;
