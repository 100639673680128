import React, { useContext, useEffect, useRef, useState } from "react";
import "../../styles/userProvenancePageStyles/UserProvenancePage.css";
import {
  fetchCollectionNameFromTokenObj,
  fetchImageURLFromTokenObj,
  fetchTokenDefaultImageOnError,
  fetchTokenNameFromTokenObj,
} from "../../utils/commonUtils/TokenUtils";
import { Link } from "react-router-dom";
import { timestampToDate } from "../../utils/commonUtils/timeUtils";
import { FolderFilterSectionContext } from "../../contexts/FolderPage/FolderFilterSectionContext";

import { FaArrowUp, FaArrowDown } from "react-icons/fa";
import { HiArrowsUpDown } from "react-icons/hi2";
import { setFolderPageFiltersTokenSorting } from "../../actions/folderFilterSectionActions";
import LoadingSpinner from "../../utils/commonUtils/LoadingSpinner";
import useIsMobile from "../../customHooks/UseIsMobile";

const ProvenanceTokens = ({
  tokensOwned,
  fetchPaginatedTokensOwnedByWallet,
  isFetchingData,
  selectedProvenanceTokens,
  setSelectedProvenanceTokens,
  checkedProvenanceTokens,
  setCheckedProvenanceTokens,
}) => {
  const { folderPageFilters, updateFolderPageFilters } = useContext(
    FolderFilterSectionContext
  );
  let pageFilters = folderPageFilters;
  let updatePageFilters = updateFolderPageFilters;

  const observer = useRef(null);

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.1,
    };

    observer.current = new IntersectionObserver((tokenList) => {
      tokenList.forEach((token) => {
        if (token.isIntersecting) {
          fetchPaginatedTokensOwnedByWallet(false);
        }
      });
    }, options);

    if (observer.current) {
      observer.current.observe(
        document.getElementById("intersectionObserverTarget")
      );
    }

    return () => {
      if (observer.current) {
        observer.current.disconnect();
      }
    };
  }, [fetchPaginatedTokensOwnedByWallet]);

  const isMobile = useIsMobile();

  const handleEntriesHeaderClick = () => {
    // Handle click logic for Price header
    if (!pageFilters) return;
    if (pageFilters.tokensSortText === "Provenance Entries: Low to High") {
      updatePageFilters(
        setFolderPageFiltersTokenSorting({
          tokensSortKey: "provenance.entries",
          tokensSortDirection: -1,
          tokensSortText: "Provenance Entries: High to Low",
        })
      );
    } else if (
      pageFilters.tokensSortText === "Provenance Entries: High to Low"
    ) {
      updatePageFilters(
        setFolderPageFiltersTokenSorting({
          tokensSortKey: "mintTimestamp",
          tokensSortDirection: -1,
          tokensSortText: "",
        })
      );
    } else {
      updatePageFilters(
        setFolderPageFiltersTokenSorting({
          tokensSortKey: "provenance.entries",
          tokensSortDirection: 1,
          tokensSortText: "Provenance Entries: Low to High",
        })
      );
    }
  };

  const handleLastUpdatedHeaderClick = () => {
    // Handle click logic for Price header
    if (!pageFilters) return;
    if (pageFilters.tokensSortText === "Last Updated: Low to High") {
      updatePageFilters(
        setFolderPageFiltersTokenSorting({
          tokensSortKey: "provenance.dateUpdated",
          tokensSortDirection: -1,
          tokensSortText: "Last Updated: High to Low",
        })
      );
    } else if (pageFilters.tokensSortText === "Last Updated: High to Low") {
      updatePageFilters(
        setFolderPageFiltersTokenSorting({
          tokensSortKey: "mintTimestamp",
          tokensSortDirection: -1,
          tokensSortText: "",
        })
      );
    } else {
      updatePageFilters(
        setFolderPageFiltersTokenSorting({
          tokensSortKey: "provenance.dateUpdated",
          tokensSortDirection: 1,
          tokensSortText: "Last Updated: Low to High",
        })
      );
    }
  };

  const onTokenCheck = (tokenToAdd) => {
    let checkedProvenanceTokensIds = checkedProvenanceTokens.map(
      (token) => token._id
    );
    if (checkedProvenanceTokensIds?.includes(tokenToAdd._id)) {
      setCheckedProvenanceTokens((prevCheckedProvenanceTokens) =>
        prevCheckedProvenanceTokens.filter(
          (token) => token._id !== tokenToAdd._id
        )
      );
    } else {
      setCheckedProvenanceTokens((prevCheckedProvenanceTokens) => [
        ...prevCheckedProvenanceTokens,
        tokenToAdd,
      ]);
    }
  };

  return (
    <div className="provenance-tokens-table-container">
      {!isMobile ? (
        <table className="provenance-tokens-data-table">
          <thead>
            <tr>
              <th className="provenance-tokens-sorting-th-heading">Selected</th>
              <th className="provenance-tokens-sorting-th-heading">Name</th>
              <th
                className="provenance-tokens-sorting-th-heading"
                onClick={handleEntriesHeaderClick}
              >
                <span
                  style={{
                    marginRight: "0.1em",
                    color: "#747474",
                    fontWeight: "600",
                    cursor: "pointer",
                    fontSize: "1em",
                  }}
                >
                  Entries
                </span>
                {pageFilters ? (
                  pageFilters.tokensSortText ===
                  "Provenance Entries: Low to High" ? (
                    <span style={{}}>
                      <FaArrowUp fontSize={"0.7em"} />
                    </span>
                  ) : pageFilters.tokensSortText ===
                    "Provenance Entries: High to Low" ? (
                    <span style={{}}>
                      <FaArrowDown fontSize={"0.7em"} />
                    </span>
                  ) : (
                    <span style={{}}>
                      <HiArrowsUpDown fontSize={"1em"} />
                    </span>
                  )
                ) : null}
              </th>
              <th
                className="provenance-tokens-sorting-th-heading"
                onClick={handleLastUpdatedHeaderClick}
              >
                <span
                  style={{
                    marginRight: "0.1em",
                    color: "#747474",
                    fontWeight: "600",
                    cursor: "pointer",
                    fontSize: "1em",
                  }}
                >
                  Last Updated
                </span>
                {pageFilters ? (
                  pageFilters.tokensSortText === "Last Updated: Low to High" ? (
                    <span>
                      <FaArrowUp fontSize={"0.7em"} />
                    </span>
                  ) : pageFilters.tokensSortText ===
                    "Last Updated: High to Low" ? (
                    <span>
                      <FaArrowDown fontSize={"0.7em"} />
                    </span>
                  ) : (
                    <span>
                      <HiArrowsUpDown fontSize={"1em"} />
                    </span>
                  )
                ) : null}
              </th>
              <th className="provenance-tokens-sorting-th-heading">
                Purchased
              </th>
              <th className="provenance-tokens-sorting-th-heading"></th>
            </tr>
          </thead>
          <tbody>
            {tokensOwned &&
              tokensOwned.map((item, index) => (
                <tr key={index}>
                  <td>
                    <input
                      type="checkbox"
                      className={`${
                        checkedProvenanceTokens
                          .map((token) => token._id)
                          .includes(item._id)
                          ? "selected"
                          : ""
                      }`}
                      checked={checkedProvenanceTokens
                        .map((token) => token._id)
                        .includes(item._id)}
                      onChange={(e) => {
                        e.stopPropagation();
                        onTokenCheck(item);
                      }}
                    />
                  </td>
                  <td>
                    <Link
                      className="provenance-tokens-item-section"
                      to={`/collection/${item?.contractAddress}/id/${item?.tokenId}`}
                    >
                      <img
                        className="provenance-tokens-images"
                        src={fetchImageURLFromTokenObj(item)}
                        alt={item?.tokenMetadata?.data?.name}
                        onError={(e) => {
                          e.target.src = fetchTokenDefaultImageOnError(item);
                        }}
                      />
                      <span className="provenance-tokens-name-section">
                        <span
                          className="provenance-tokens-name link-field"
                          title={item?.tokenMetadata?.data?.name}
                        >
                          {fetchTokenNameFromTokenObj(item)}
                        </span>
                        <p
                          className="provenance-tokens-collection-name"
                          title={fetchCollectionNameFromTokenObj(item)}
                        >
                          {fetchCollectionNameFromTokenObj(item, true)}
                        </p>
                      </span>
                    </Link>
                  </td>
                  <td>{item?.provenance?.entries || 0}</td>
                  <td className="provenance-tokens-dates">
                    {item?.provenance?.dateUpdated
                      ? item?.provenance?.dateUpdated.split("T")[0]
                      : "-"}
                  </td>
                  <td className="provenance-tokens-dates">
                    {timestampToDate(item?.block_timestamp) || "n/a"}
                  </td>
                  <td>
                    <span className="provenance-tokens-buttons-section">
                      <span
                        className="provenance-tokens-buttons"
                        onClick={() => {
                          setSelectedProvenanceTokens([item]);
                        }}
                      >
                        {(item?.provenance?.data || []).length === 0
                          ? "Add"
                          : "Edit"}
                      </span>
                      <Link
                        to={`/provenance_report/collection/${item?.contractAddress}/${item?.decimalTokenId}`}
                        className="provenance-tokens-buttons"
                      >
                        View
                      </Link>
                    </span>
                  </td>
                </tr>
              ))}
            {isFetchingData && (
              <tr>
                <td colSpan="100%">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "100%",
                      padding: "1em",
                      marginLeft: "1.5rem",
                    }}
                  >
                    <LoadingSpinner
                      styleObj={{ width: "2em", height: "2em" }}
                    />
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      ) : (
        <div className="provenance-tokens-main-section-mobile">
          {tokensOwned.map((item, index) => (
            <div
              style={{
                marginBottom: "10%",
                borderBottom: "1px solid #747474",
                paddingBottom: "5%",
              }}
            >
              <div
                key={index}
                className="provenance-tokens-item-section"
                style={{
                  marginBottom: "2em",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  gap: "1em",
                }}
              >
                <div className="provenance-tokens-item-section">
                  <input
                    type="checkbox"
                    className={`${
                      checkedProvenanceTokens
                        .map((token) => token._id)
                        .includes(item._id)
                        ? "selected"
                        : ""
                    }`}
                    checked={checkedProvenanceTokens
                      .map((token) => token._id)
                      .includes(item._id)}
                    onChange={(e) => {
                      e.stopPropagation();
                      onTokenCheck(item);
                    }}
                    style={{ marginRight: "2em" }}
                  />
                  <Link
                    className="provenance-tokens-item-section"
                    to={`/collection/${item?.contractAddress}/id/${item?.tokenId}`}
                  >
                    <img
                      className="provenance-tokens-images"
                      src={fetchImageURLFromTokenObj(item)}
                      alt={fetchTokenNameFromTokenObj(item)}
                      onError={(e) => {
                        e.target.src = fetchTokenDefaultImageOnError(item);
                      }}
                    />
                    <span className="provenance-tokens-name-section">
                      <span
                        className="provenance-tokens-name"
                        title={fetchTokenNameFromTokenObj(item)}
                      >
                        {fetchTokenNameFromTokenObj(item)}
                      </span>
                      <p
                        className="provenance-tokens-collection-name"
                        title={fetchCollectionNameFromTokenObj(item)}
                      >
                        {fetchCollectionNameFromTokenObj(item).slice(0, 20)}
                      </p>
                    </span>
                  </Link>
                </div>
                <div className="provenance-tokens-item-section">
                  <span
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "0.6em",
                    }}
                  >
                    <span
                      className="provenance-tokens-buttons"
                      onClick={() => {
                        setSelectedProvenanceTokens([item]);
                      }}
                    >
                      {(item?.provenance?.data || []).length === 0
                        ? "Add"
                        : "Edit"}
                    </span>
                    <Link
                      to={`/provenance_report/collection/${item?.contractAddress}/id/${item?.tokenId}`}
                      className="provenance-tokens-buttons"
                    >
                      View
                    </Link>
                  </span>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: "95%",
                  marginLeft: "2.5%",
                  borderTop: "1px solid #e0e0e0",
                  paddingTop: "2em",
                  justifyContent: "space-between",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "1.5em",
                  }}
                >
                  <span>Entries</span>
                  <span>
                    <strong>{`${item?.provenance?.entries || 0}`}</strong>
                  </span>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "1.5em",
                  }}
                >
                  <span>Last Updated</span>
                  <span>
                    <strong>{`${
                      item?.provenance?.dateUpdated
                        ? item?.provenance?.dateUpdated.split("T")[0]
                        : "-"
                    }`}</strong>
                  </span>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "1.5em",
                  }}
                >
                  <span>Purchased</span>
                  <span>
                    <strong>{`${
                      timestampToDate(item?.block_timestamp) || "n/a"
                    }`}</strong>
                  </span>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
      <div id="intersectionObserverTarget" style={{ height: "10px" }}></div>
    </div>
  );
};

export default ProvenanceTokens;
