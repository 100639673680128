// utility component for Section Tabs on Collection page

import InfoComponent from "../CommonComponents/InfoComponent";

// import React from "react";

const SectionTab = ({ label, active, onClick, info }) => {
  return (
    <div className={`section-tab ${active ? "active" : ""}`} onClick={onClick}>
      {label}
      <span>
        <InfoComponent
          iconWidth={"1.1em"}
          iconHeight={"1.1em"}
          info={info}
          styleObj={{ width: "20em", textWrap: "wrap" }}
        />
      </span>
    </div>
  );
};

export default SectionTab;
