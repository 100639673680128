import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import loadingImage from "../../../assets/images/loading-gear.gif";

import VarifiedIcon from "./VarifiedIcon";
import { prettyName } from "../../../utils/commonUtils/PrettyString";
import {
  fetchTokenDefaultImageOnError,
  fetchImageURLFromTokenObj,
} from "../../../utils/commonUtils/TokenUtils";

export default function SearchResultToken({
  item,
  isSearching,
  toggleSearchResult,
}) {
  const navigate = useNavigate();
  const [imgUrl, setImgUrl] = useState("");

  useEffect(() => {
    if (isSearching) {
      setImgUrl(loadingImage);
    } else {
      setImgUrl(fetchImageURLFromTokenObj(item));
    }
  }, [item, isSearching]);

  function tokenClickHandler(item) {
    const contractAddress = item.contractAddress;
    const tokenId = item.tokenId;
    navigate(`/collection/${contractAddress}/id/${tokenId}`);
    toggleSearchResult();
  }

  return (
    <button
      className="d-flex flex-column border-0 p-0"
      style={{
        overflow: "hidden",
        width: "90%",
        height: "5vw",
        borderRadius: "0.7vw",
        marginBottom: "0.5vw",
      }}
      onClick={() => {
        tokenClickHandler(item);
      }}
    >
      {isSearching && <div className="w-100 h-100 loading-background"></div>}
      {!isSearching && (
        <div className="d-flex w-100 h-100 search-result-hover">
          <div>
            <img
              src={imgUrl}
              style={{ height: "100%", width: "5vw", objectFit: "cover" }}
              onError={(event) => {
                event.target.src = fetchTokenDefaultImageOnError(item);
              }}
            />
          </div>
          <div
            className="d-flex align-items-center w-100"
            style={{ backgroundColor: "rgba(255,255,255,0)" }}
          >
            <div
              style={{
                margin: "1vw 1vw 1vw 2vw",
                fontSize: "1vw",
                fontFamily: "Lato",
                fontWeight: "bold",
              }}
            >
              {item.openseaDetails?.name
                ? prettyName(item.openseaDetails.name)
                : prettyName(item.tokenMetadata?.name)}
            </div>
          </div>
          <div
            style={{
              width: "1.7vw",
              opacity: 1,
              marginTop: "0.2vw",
              marginRight: "0.2vw",
            }}
          >
            <VarifiedIcon varified={item.varified} />
          </div>
        </div>
      )}
    </button>
  );
}
