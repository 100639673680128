export const updateUserInfoRedux = (userInfo) => ({
  type: "UPDATE_USER_INFO",
  payload: {
    userId: userInfo._id,
    emailAddress: userInfo.emailAddress,
    profileName: userInfo.profileName,
    role: userInfo.role,
  },
});

export const login = (userInfo) => ({
  type: "LOGIN",
  payload: {
    userId: userInfo._id,
    emailAddress: userInfo.emailAddress,
    profileName: userInfo.profileName,
    role: userInfo.role,
    jwtToken: userInfo.jwtToken,
    verifiedEmail: userInfo.verifiedEmail,
  },
});

export const updateWalletAddressRedux = (walletAddress) => ({
  type: "UPDATE_WALLET_ADDRESS",
  payload: walletAddress,
});

export function logout() {
  return {
    type: "LOGOUT",
  };
}
