import React from "react";

const SalesModalIcon = () => {
  return (
    <div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1.4em"
        height="1em"
        viewBox="0 0 28 17"
        fill="none"
      >
        <path
          d="M21 9L16 6.11325V11.8868L21 9ZM0 9.5H16.5V8.5H0V9.5Z"
          fill="gray"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M26.9997 8.5C26.9997 12.6421 23.6418 16 19.4997 16C16.8133 16 14.4568 14.5876 13.1322 12.4649L12.2656 12.9653C13.7635 15.3867 16.4432 17 19.4997 17C24.1941 17 27.9997 13.1944 27.9997 8.5C27.9997 3.80558 24.1941 0 19.4997 0C16.4432 0 13.7635 1.61327 12.2656 4.03474L13.1322 4.53507C14.4568 2.41237 16.8133 1 19.4997 1C23.6418 1 26.9997 4.35786 26.9997 8.5Z"
          fill="gray"
        />
      </svg>
    </div>
  );
};

export default SalesModalIcon;
