import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import useIsMobile from "../../customHooks/UseIsMobile";
import CollectionIcons from "./CollectionIcons";
import {
  fetchImageURLFromTokenObj,
  getDefaultImageUrl,
} from "../../utils/commonUtils/TokenUtils";
import WhitelistBlacklistSection from "./WhitelistBlacklistSection";
import { getBannerImageFromCollectionObj } from "../../utils/commonUtils/CollectionUtils";
import userNull from "../../assets/images/userNull.png";
import MetaJungle_MJ from "../../assets/images/MetaJungle_MJ.png";

export default function BannerProfileSection({
  collectionData,
  isFetchingData,
  isFetchingCollectionDetails,
}) {
  const [userInfo, setUserInfo] = React.useState(
    useSelector((state) => state.user.userInfo)
  );

  const userId = useSelector((state) => state.user.userInfo.userId);

  return (
    <div className="mb-4">
      {useIsMobile() ? (
        <div>
          <div>
            {(isFetchingData || isFetchingCollectionDetails) && (
              <img
                src={getBannerImageFromCollectionObj()}
                alt="defaultBanner"
                style={{ height: "65vw", width: "100vw" }}
              />
            )}
            {!isFetchingData && !isFetchingCollectionDetails && (
              <img
                src={getBannerImageFromCollectionObj(collectionData)}
                alt="Banner"
                className="banner-image"
                style={{ height: "62vw" }}
              />
            )}
          </div>
          <div className="banner-icon-section">
            {userInfo?.role === "Admin" && (
              <WhitelistBlacklistSection
                contractId={collectionData?.contractAddress}
                collectionSlug={collectionData?.collectionSlug}
                collectionCreator={collectionData?.collectionCreator}
                priority={collectionData?.priority}
              />
            )}
            <CollectionIcons
              openSeaURL={
                collectionData?.openseaDetails?.collectionDetails?.opensea_url
              }
              userId={userId}
              collectionId={collectionData?._id}
            />
          </div>
          <div className="profile-image-box-mobile">
            <div>
              {!isFetchingData && !isFetchingCollectionDetails ? (
                <img
                  src={
                    collectionData?.openseaDetails?.collectionDetails?.image_url
                      ? collectionData?.openseaDetails?.collectionDetails
                          ?.image_url
                      : fetchImageURLFromTokenObj()
                  }
                  alt="Profile"
                  className="profile-image"
                  style={{ objectFit: "cover" }}
                />
              ) : (
                <div></div>
              )}
            </div>
          </div>
        </div>
      ) : (
        <div>
          {" "}
          <div className="banner-container">
            {(isFetchingData || isFetchingCollectionDetails) && (
              <img
                src={getBannerImageFromCollectionObj()}
                alt="defaultBanner"
                style={{ width: "100vw" }}
              />
            )}
            {!isFetchingData && !isFetchingCollectionDetails && (
              <img
                src={getBannerImageFromCollectionObj(collectionData)}
                alt="Banner"
                className="banner-image"
              />
            )}
          </div>
          <div className="profile-image-box-container">
            <div className="profile-image-box d-flex align-items-center justify-content-center">
              {!isFetchingData && !isFetchingCollectionDetails ? (
                <img
                  src={
                    collectionData?.openseaDetails?.collectionDetails?.image_url
                      ? collectionData?.openseaDetails?.collectionDetails
                          ?.image_url
                      : MetaJungle_MJ
                  }
                  alt="Profile"
                  className="profile-image"
                  onError={(event) => {
                    event.target.src = MetaJungle_MJ;
                  }}
                />
              ) : (
                <img
                  src={MetaJungle_MJ}
                  className="profile-image"
                  style={{
                    width: "70%",
                    objectFit: "contain",
                  }}
                />
              )}
            </div>
            <div className="d-flex justify-content-center align-items-center">
              {userInfo?.role === "Admin" && (
                <WhitelistBlacklistSection
                  contractId={collectionData?.contractAddress}
                  collectionSlug={collectionData?.collectionSlug}
                  collectionCreator={collectionData?.collectionCreator}
                  priority={collectionData?.priority}
                />
              )}

              <CollectionIcons
                openSeaURL={
                  collectionData?.openseaDetails?.collectionDetails?.opensea_url
                }
                userId={userId}
                collectionId={collectionData?._id}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
