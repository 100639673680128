import React, { useEffect, useRef, useState } from "react";
import * as d3 from "d3";
import ArrowIcon from "../../../Icons/ArrowIcon";
import ArrowIconReverse from "../../../Icons/ArrowIconReverse";
import "../../../styles/itemPageStyles/ItemPage.css";
import { LineChart } from "@mui/x-charts/LineChart";

import InfoComponent from "../../CommonComponents/InfoComponent";
import { weiToEth } from "../../../utils/commonUtils/PriceUtils";

const SalesHistoryChart = ({ tokenTransferHistory }) => {
  const [toggleDetail, setToggleDetail] = useState(true);
  const [selectedOption, setSelectedOption] = useState("all");
  const [loading, setLoading] = useState(false);

  const [groupedDataTransfers, setGroupedDataTransfers] = useState([]);
  const [groupedDataSales, setGroupedDataSales] = useState([]);

  function returnGroupedData(sortedHistory) {
    const today = new Date();

    if (selectedOption === "last30") {
      const thirtyDaysAgo = new Date(today);
      thirtyDaysAgo.setDate(today.getDate() - 30);
      sortedHistory = sortedHistory.filter(
        (d) => new Date(d?.event_timestamp * 1000) >= thirtyDaysAgo
      );
    } else if (selectedOption === "last24") {
      const twentyFourHoursAgo = new Date(today);
      twentyFourHoursAgo.setHours(today.getHours() - 24);
      sortedHistory = sortedHistory.filter(
        (d) => new Date(d?.event_timestamp * 1000) >= twentyFourHoursAgo
      );
    }

    let groupedData = sortedHistory
      // Use reduce to group by date and find maxEthPrice for each date
      .reduce((acc, curr) => {
        const { date, ethPrice } = curr;
        if (!acc[date]) {
          // If the date doesn't exist in the accumulator, add it
          acc[date] = {
            maxEthPrice: ethPrice || 0,
            allPrices: ethPrice !== null ? [ethPrice] : [],
          };
        } else {
          // If the date exists, compare and store the maximum value
          if (ethPrice !== null) {
            acc[date].allPrices.push(ethPrice);
          }
          if (ethPrice > acc[date].maxEthPrice) {
            acc[date].maxEthPrice = ethPrice;
          }
        }
        return acc;
      }, {});

    groupedData = Object.keys(groupedData).map((date) => ({
      date,
      maxEthPrice: groupedData[date].maxEthPrice,
      allPrices: groupedData[date].allPrices,
    }));

    return groupedData;
  }

  useEffect(() => {
    if (!toggleDetail) return; // Only draw the chart if details are shown

    setLoading(true);
    let sortedHistory = tokenTransferHistory.slice().map((d) => {
      if (!d) {
        return;
      }
      let value = parseFloat(weiToEth(d?.payment?.quantity)) || 0.0;
      value = isNaN(value) ? 0.0 : value;
      return {
        ethPrice: value,
        date: new Date(d?.event_timestamp * 1000).toLocaleDateString("en-US", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
        }),
        event_timestamp: d?.event_timestamp,
        event_type: d?.event_type,
      };
    });

    if (sortedHistory) {
      sortedHistory.sort((a, b) => {
        const logIdA = parseInt(a?.event_timestamp || "0");
        const logIdB = parseInt(b?.event_timestamp || "0");
        return logIdA - logIdB;
      });
    }

    let transferHistoryTransfers = sortedHistory.map((d) => {
      if (d?.event_type == "transfer") {
        return d;
      } else {
        return { ...d, ethPrice: null };
      }
    });
    let transferHistorySales = sortedHistory.map((d) => {
      if (d?.event_type == "sale") {
        return d;
      } else {
        return { ...d, ethPrice: null };
      }
    });

    let groupedDataTransfers = returnGroupedData(transferHistoryTransfers);
    let groupedDataSales = returnGroupedData(transferHistorySales);

    setGroupedDataTransfers(groupedDataTransfers);
    setGroupedDataSales(groupedDataSales);
    setLoading(false);
  }, [tokenTransferHistory, selectedOption, toggleDetail]);

  const handleDetail = () => {
    setToggleDetail(!toggleDetail);
  };

  // Calculate the max value of your data
  const maxValue = Math.max(
    ...[...groupedDataSales, ...groupedDataTransfers].map(
      (d) => d?.maxEthPrice || 0
    )
  );

  // Calculate the tick interval (x) based on your logic. Here, we're dividing max value into 5 ticks.
  const tickInterval = maxValue / 5;

  // Define the min value as -x
  const minY = -tickInterval;

  return (
    <div style={{ marginBottom: "3em" }}>
      <div className="details-header">
        Sales History
        <span onClick={() => handleDetail()}>
          {toggleDetail ? <ArrowIcon /> : <ArrowIconReverse />}
        </span>
      </div>

      {toggleDetail && (
        <div>
          <div className="sales-history-filterdata-row">
            <div className="sales-history-filterdata-heading">
              Filter Data based on:
              <select
                className="sales-history-filterdata-dropdown"
                value={selectedOption}
                onChange={(e) => setSelectedOption(e.target.value)}
              >
                <option value="all">All</option>
                <option value="last30">Last 30 Days</option>
                <option value="last24">Last 24 Hours</option>
              </select>
              <span style={{ marginLeft: "0.5em", cursor: "pointer" }}>
                <InfoComponent
                  iconWidth={"1.2em"}
                  iconHeight={"1.2em"}
                  info="The chart plots data based on the above activity table. To see more data, try loading more!"
                />{" "}
              </span>
            </div>
          </div>
          {loading ? (
            <p>Loading...</p>
          ) : groupedDataSales.length === 0 ? (
            <strong>No transactions took place during this time frame.</strong>
          ) : (
            <div
              style={{
                width: "100%",
                height: "100%",
                background: "transparent",
                padding: "0px 0px 0px 5px",
                boxShadow: "0px 2px 15px 0px rgba(0, 0, 0, 0.15)",
                fontWeight: "bold",
                borderRadius: "12px",

                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <LineChart
                sx={{
                  "& .MuiAreaElement-root": {
                    fill: "#b3e7cd",
                  },
                  "& .MuiMarkElement-root": {
                    fill: "black",
                    stroke: "none",
                    r: 4, // Ensure markers are visible for 0 values
                  },
                  // ".MuiChartsLegend-mark": {
                  //   fill: "#2fb484",
                  // },
                  ".MuiChartsAxis-label": {
                    fontWeight: "bold",
                    transform: "translate(-18px, 0.1em)", // Rotate labels by 45 degrees
                  },
                }}
                tooltip={{
                  // trigger: "item",
                  formatter: (params) =>
                    `Date: ${params.x}<br/>Price: ${params.y} ETH`, // Custom tooltip formatter
                }}
                xAxis={[
                  {
                    scaleType: "band",
                    data: groupedDataTransfers.map((d) => d?.date),
                    label: "Date (mm/dd/yyyy)",
                  },
                ]}
                series={[
                  {
                    data: groupedDataSales.map((d) => d?.maxEthPrice || 0),
                    valueFormatter: (value, { dataIndex }) => {
                      const allPrices =
                        groupedDataSales[dataIndex]?.allPrices || [];
                      let pricesList = allPrices
                        .map((price) => `${price} ETH`)
                        .join(", ");

                      return pricesList
                        ? `${value} ETH (Total sales: ${allPrices.length} -> (${pricesList}) )`
                        : "No sales";
                    },
                    label: "Sales",
                    color: "#2fb484",
                  },
                  {
                    data: groupedDataTransfers.map((d) => d?.maxEthPrice || 0),
                    valueFormatter: (value, { dataIndex }) => {
                      const allPrices =
                        groupedDataTransfers[dataIndex]?.allPrices || [];
                      return `${value} ETH (Total transfers: ${allPrices.length})`;
                    },
                    label: "Transfers",
                  },
                ]}
                width={500}
                height={300}
                yAxis={[
                  {
                    label: "Sale Price (ETH)",
                    min: minY,
                  },
                ]}
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default SalesHistoryChart;
