const CommonProvenanceCategories = {
  Awards: {
    emptyForm: {
      Competition: "",
      Date: "",
      "Date Format": "MM/dd/yyyy",
      Placement: "",
      Category: "",
      Links: "",
      Description: "",
      Images: [],
      ImagesToUpload: [],
    },
    helperLabels: ["Date Format", "ImagesToUpload", "ImagesToRemove"],
    notSendToBackend: ["ImagesToUpload", "ImagesToRemove"],
    data: [],
  },
  Exhibitions: {
    emptyForm: {
      "Exhibition Name": "",
      "Start Date": "",
      "Start Date Format": "MM/dd/yyyy",
      "End Date": "",
      "End Date Format": "MM/dd/yyyy",
      Location: "",
      Exhibitor: "",
      "Media Links": "",
      Description: "",
      Images: [],
      ImagesToUpload: [],
    },
    helperLabels: [
      "Start Date Format",
      "End Date Format",
      "ImagesToUpload",
      "ImagesToRemove",
    ],
    notSendToBackend: ["ImagesToUpload", "ImagesToRemove"],
    data: [],
  },
  "Publications/Media": {
    emptyForm: {
      Title: "",
      Date: "",
      "Date Format": "MM/dd/yyyy",
      "Page#": "",
      "on Cover": "",
      Links: "",
      Description: "",
      Images: [],
      ImagesToUpload: [],
    },
    helperLabels: ["Date Format", "ImagesToUpload", "ImagesToRemove"],
    notSendToBackend: ["ImagesToUpload", "ImagesToRemove"],
    data: [],
  },
  Book: {
    emptyForm: {
      "International Standard Book Number (ISBN)": "",
      Title: "",
      "Page#": "",
      "Cover Image": "",
      Date: "",
      "Date Format": "MM/dd/yyyy",
      Description: "",
      Images: [],
      ImagesToUpload: [],
    },
    helperLabels: ["Date Format", "ImagesToUpload", "ImagesToRemove"],
    notSendToBackend: ["ImagesToUpload", "ImagesToRemove"],
    data: [],
  },
  "Notable Physical Sales": {
    emptyForm: {
      Venue: "",
      "Sale Date": "",
      "Sale Date Format": "MM/dd/yyyy",
      "Sale Amount": "",
      "Media Links": "",
      Description: "",
      Images: [],
      ImagesToUpload: [],
    },
    helperLabels: ["ImagesToUpload", "Sale Date Format", "ImagesToRemove"],
    notSendToBackend: ["ImagesToUpload", "ImagesToRemove"],
    data: [],
  },
  "Notable Physical Collectors": {
    emptyForm: {
      Name: "",
      "Acquisition Date": "",
      "Acquisition Date Format": "MM/dd/yyyy",
      "Media Links": "",
      Description: "",
      Images: [],
      ImagesToUpload: [],
    },
    helperLabels: [
      "ImagesToUpload",
      "Acquisition Date Format",
      "ImagesToRemove",
    ],
    notSendToBackend: ["ImagesToUpload", "ImagesToRemove"],
    data: [],
  },
  "Commercial Use": {
    emptyForm: {
      Product: "",
      Links: "",
      Description: "",
      Images: [],
      ImagesToUpload: [],
    },
    helperLabels: ["ImagesToUpload", "ImagesToRemove"],
    notSendToBackend: ["ImagesToUpload", "ImagesToRemove"],
    data: [],
  },
};

let newCategoryFormDataObj = {
  emptyForm: {
    Date: "",
    "Date Format": "MM/dd/yyyy",
    Location: "",
    Description: "",
    Links: "",
    Images: [],
    ImagesToUpload: [],
  },
  helperLabels: ["Date Format", "ImagesToUpload", "ImagesToRemove"],
  notSendToBackend: ["ImagesToUpload", "ImagesToRemove"],
  data: [],
};

module.exports = { CommonProvenanceCategories, newCategoryFormDataObj };
