import React from "react";

const OpenOriginalIcon = ({ url, width, height }) => {
  return (
    <a href={url} target="_blank">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width ? width : "1.2em"}
        height={height ? height : "1.2em"}
        viewBox="0 0 14 14"
        fill="none"
      >
        <path
          d="M11.375 12.25H2.625C2.39303 12.2497 2.17065 12.1574 2.00663 11.9934C1.8426 11.8293 1.75031 11.607 1.75 11.375V2.625C1.75031 2.39303 1.8426 2.17065 2.00663 2.00663C2.17065 1.8426 2.39303 1.75031 2.625 1.75H7V2.625H2.625V11.375H11.375V7H12.25V11.375C12.2497 11.607 12.1574 11.8293 11.9934 11.9934C11.8293 12.1574 11.607 12.2497 11.375 12.25Z"
          fill="black"
        />
        <path
          d="M8.75 0.875V1.75H11.6314L7.875 5.50638L8.49362 6.125L12.25 2.36863V5.25H13.125V0.875H8.75Z"
          fill="black"
        />
      </svg>
    </a>
  );
};

export default OpenOriginalIcon;
