import React, { useState, useEffect, useContext, useRef } from "react";
import FilterSection from "./FilterSection";
import TokenGrid from "./TokenGrid";
import { TokenSortingSection } from "../CommonComponents/TokenSortingSection";
import TokenSearchSection from "../CommonComponents/TokenSearchSection";
import "../../styles/collectionPageStyles/CollectionPage.css";
import FilterButton from "./FilterButton";
import LiveButton from "./LiveButton";
import ToggleGridButton from "./ToggleGridButton";
import ActivityTableContainer from "./ActivityTableContainer";
import useIsMobile from "../../customHooks/UseIsMobile";
import { isNullOrUndefined } from "../../utils/commonUtils/Utils";
import FilterButtonMobile from "./FilterButtonMobile";
import RemoveButton from "../../Icons/CollectionPageIcons/RemoveButton";
import { CollectionFilterSectionContext } from "../../contexts/CollectionPage/FilterSectionContext";
import {
  setCollectionPageFiltersActivitySelectedTraits,
  setCollectionPageFiltersActivityStatus,
  setCollectionPageFiltersListingPriceFilter,
  setCollectionPageFiltersTokenStatus,
  setCollectionPageFiltersTokensSelectedTraits,
} from "../../actions/CollectionPage/filterSectionActions";
import { CreatorFilterSectionContext } from "../../contexts/CreatorPage.js/FilterSectionContext";
import {
  setCreatorPageFiltersActivitySelectedTraits,
  setCreatorPageFiltersActivityStatus,
  setCreatorPageFiltersCreatedTokenStatus,
  setCreatorPageFiltersCreatedTokensSelectedTraits,
  setCreatorPageFiltersListingPriceFilter,
  setCreatorPageFiltersMintedTokenStatus,
  setCreatorPageFiltersMintedTokensSelectedTraits,
  setCreatorPageFiltersOwnedTokenStatus,
  setCreatorPageFiltersOwnedTokensSelectedTraits,
} from "../../actions/CreatorPage/filterSectionActions";

export default function ItemsTabSection({
  pageType,
  activeTab,
  setDataArray,
  fetchPaginatedData,
  dataArray,
  aggregated_traits,
  dataTotalSize,
  collectionData,
  walletData,
  isFetchingData,
  setConditionType,
  conditionType,
  listedTokensCount,
  setListedTokensCount,
  activitySortingState,
  setActivitySortingState,
  traitCount,
}) {
  const context = useContext(
    pageType === "CollectionPage"
      ? CollectionFilterSectionContext
      : CreatorFilterSectionContext
  );
  const pageFilters =
    pageType === "CollectionPage"
      ? context.collectionPageFilters
      : context.creatorPageFilters;
  const updatePageFilters =
    pageType === "CollectionPage"
      ? context.updateCollectionPageFilters
      : context.updateCreatorPageFilters;

  const [selectedFiltersState, setSelectedFiltersState] = useState({
    selectedTraitValues: [],
    updateSelectedTraitValuesFunction: () => {},
  });

  let observer = useRef(null);
  useEffect(() => {
    if (dataArray?.length >= 32) {
      const options = {
        root: null,
        rootMargin: "200px",
        threshold: 0.1,
      };

      observer.current = new IntersectionObserver((tokenList) => {
        tokenList.forEach((token) => {
          if (token.isIntersecting) fetchPaginatedData(false);
        });
      }, options);

      if (observer.current) {
        observer.current.observe(
          document.getElementById("intersectionObserverTarget")
        );
      }
    }

    return () => {
      if (observer.current) {
        observer.current.disconnect();
      }
    };
  }, [fetchPaginatedData, dataArray.length]);

  useEffect(() => {
    let status = "";
    if (activeTab.includes("Items") && pageType === "CollectionPage") {
      status = pageFilters?.tokenStatus;
    } else if (activeTab === "Owned_Items" && pageType === "CreatorPage") {
      status = pageFilters?.ownedTokenStatus;
    } else if (activeTab === "Minted_Items" && pageType === "CreatorPage") {
      status = pageFilters?.mintedTokenStatus;
    } else if (activeTab === "Created_Items" && pageType === "CreatorPage") {
      status = pageFilters?.createdTokenStatus;
    } else {
      status = pageFilters?.activityStatus;
    }

    let selectedTraitValues = "";
    if (activeTab.includes("Items") && pageType === "CollectionPage") {
      selectedTraitValues = pageFilters?.tokenSelectedTraitValues;
    } else if (activeTab === "Owned_Items" && pageType === "CreatorPage") {
      selectedTraitValues = pageFilters?.ownedTokenSelectedTraitValues;
    } else if (activeTab === "Minted_Items" && pageType === "CreatorPage") {
      selectedTraitValues = pageFilters?.mintedTokensSelectedTraitValues;
    } else if (activeTab === "Created_Items" && pageType === "CreatorPage") {
      selectedTraitValues = pageFilters?.createdTokensSelectedTraitValues;
    } else {
      selectedTraitValues = pageFilters?.activitySelectedTraitValues;
    }

    let searchQueryTokensSelected = [];
    if (activeTab.includes("Items")) {
      searchQueryTokensSelected = pageFilters?.searchQueryTokensSelected;
    }

    setSelectedFiltersState({
      status: status,

      minListingPriceFilter: activeTab.includes("Items")
        ? pageFilters?.listingPriceFilter?.minListingPriceFilter
        : null,
      maxListingPriceFilter: activeTab.includes("Items")
        ? pageFilters?.listingPriceFilter?.maxListingPriceFilter
        : null,
      listingPriceCurrencyFilter: activeTab.includes("Items")
        ? pageFilters?.listingPriceFilter?.listingPriceCurrencyFilter
        : null,

      selectedTraitValues: selectedTraitValues,

      searchQueryTokensSelected: searchQueryTokensSelected,

      updateSelectedTraitValuesFunction: (traitType, value) => {
        if (activeTab.includes("Items") && pageType === "CollectionPage") {
          updatePageFilters(
            setCollectionPageFiltersTokensSelectedTraits({
              traitType,
              traitValue: value,
            })
          );
        } else if (activeTab === "Owned_Items" && pageType === "CreatorPage") {
          updatePageFilters(
            setCreatorPageFiltersOwnedTokensSelectedTraits({
              traitType,
              traitValue: value,
            })
          );
        } else if (activeTab === "Minted_Items" && pageType === "CreatorPage") {
          updatePageFilters(
            setCreatorPageFiltersMintedTokensSelectedTraits({
              traitType,
              traitValue: value,
            })
          );
        } else if (
          activeTab === "Created_Items" &&
          pageType === "CreatorPage"
        ) {
          updatePageFilters(
            setCreatorPageFiltersCreatedTokensSelectedTraits({
              traitType,
              traitValue: value,
            })
          );
        } else {
          if (pageType === "CreatorPage") {
            updatePageFilters(
              setCreatorPageFiltersActivitySelectedTraits({
                traitType,
                traitValue: value,
              })
            );
          } else {
            updatePageFilters(
              setCollectionPageFiltersActivitySelectedTraits({
                traitType,
                traitValue: value,
              })
            );
          }
        }
      },

      addToTokensSelectedAfterSearchFunction: (tokenObj) => {
        if (activeTab.includes("Items")) {
          updatePageFilters({
            type: "ADD_TO_TOKENS_SELECTED_ON_SEARCH",
            payload: { searchQueryTokensSelected: tokenObj },
          });
        } else {
          // if (pageType === "CreatorPage") {
          //   updatePageFilters(
          //     setCollectionPageFiltersTokensSelectedOnSearchQuery(tokenObj)
          //   );
          // } else {
          //   updatePageFilters(
          //     setCollectionPageFiltersTokensSelectedOnSearchQuery(tokenObj)
          //   );
          // }
        }
      },

      deleteFromTokensSelectedAfterSearchFunction: (tokenObj) => {
        if (activeTab.includes("Items")) {
          updatePageFilters({
            type: "DELETE_FROM_TOKENS_SELECTED_ON_SEARCH",
            payload: { searchQueryTokensSelected: tokenObj },
          });
        } else {
          // if (pageType === "CreatorPage") {
          //   updatePageFilters(
          //     setCollectionPageFiltersTokensSelectedOnSearchQuery(tokenObj)
          //   );
          // } else {
          //   updatePageFilters(
          //     setCollectionPageFiltersTokensSelectedOnSearchQuery(tokenObj)
          //   );
          // }
        }
      },

      updateStatusFunction: (value) => {
        if (activeTab.includes("Items") && pageType === "CollectionPage") {
          updatePageFilters(setCollectionPageFiltersTokenStatus(value));
        } else if (activeTab === "Owned_Items" && pageType === "CreatorPage") {
          updatePageFilters(setCreatorPageFiltersOwnedTokenStatus(value));
        } else if (activeTab === "Minted_Items" && pageType === "CreatorPage") {
          updatePageFilters(setCreatorPageFiltersMintedTokenStatus(value));
        } else if (
          activeTab === "Created_Items" &&
          pageType === "CreatorPage"
        ) {
          updatePageFilters(setCreatorPageFiltersCreatedTokenStatus(value));
        } else {
          if (pageType === "CreatorPage") {
            updatePageFilters(setCreatorPageFiltersActivityStatus(value));
          } else {
            updatePageFilters(setCollectionPageFiltersActivityStatus(value));
          }
        }
      },

      updateListingPriceFilterFunction: () => {
        if (pageType === "CreatorPage") {
          updatePageFilters(
            setCreatorPageFiltersListingPriceFilter({
              minListingPriceFilter: null,
              maxListingPriceFilter: null,
              listingPriceCurrencyFilter: "ETH",
            })
          );
        } else {
          updatePageFilters(
            setCollectionPageFiltersListingPriceFilter({
              minListingPriceFilter: null,
              maxListingPriceFilter: null,
              listingPriceCurrencyFilter: "ETH",
            })
          );
        }
      },
    });
  }, [pageFilters, pageType, activeTab]);

  const isMobile = useIsMobile();

  const [isSingleColumn, setIsSingleColumn] = useState(false);
  const [showFilterSection, setShowFilterSection] = useState(
    !isMobile ? false : true
  );
  const [showItemsData, setShowItemsData] = useState(true);
  const [isFilterApplied, setIsFilterApplied] = useState(true);
  const [isBiggerColumnGrid, setIsBiggerColumnGrid] = useState(true);
  const [filteredData, setFilteredData] = useState([]);

  useEffect(() => {
    setFilteredData(dataArray);
  }, [dataArray]);

  const toggleColumnLayout = () => {
    setIsSingleColumn(!isSingleColumn);
  };

  const toggleGridLayout = () => {
    setIsBiggerColumnGrid((prevIsBiggerColumnGrid) => !prevIsBiggerColumnGrid);
  };

  const handleFilterClick = () => {
    setShowFilterSection(!showFilterSection);
    setShowItemsData(true);
    toggleColumnLayout();
    setIsFilterApplied(!isFilterApplied);
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        width: "100vw",
      }}
    >
      {isMobile ? (
        <div className="items-tab-section-header-mobile">
          <TokenSearchSection
            pageType={pageType}
            activeTab={activeTab}
            tokenOwners={[walletData?._id]}
            tokenCreator={walletData?._id}
            tokenContractAddress={collectionData?._id}
            tokensSelected={pageFilters?.searchQueryTokensSelected || []}
            addToTokensSelected={(obj) =>
              selectedFiltersState?.addToTokensSelectedAfterSearchFunction(obj)
            }
            deleteFromTokensSelected={(obj) =>
              selectedFiltersState?.deleteFromTokensSelectedAfterSearchFunction(
                obj
              )
            }
          />
          <div className="items-tab-section-header-second-row-mobile">
            {!showFilterSection && (
              <FilterButtonMobile onClick={handleFilterClick} />
            )}

            {activeTab.includes("Items") && (
              <LiveButton
                pageType={pageType}
                activeTab={activeTab}
                collectionData={collectionData}
                walletData={walletData}
                setTokenDetailsArray={setDataArray}
                setListedTokensCount={setListedTokensCount}
              />
            )}
            {activeTab.includes("Items") && (
              <TokenSortingSection
                pageType={pageType}
                activeTab={activeTab}
                listedTokensCount={listedTokensCount}
              />
            )}
          </div>
        </div>
      ) : (
        <div className="items-tab-section-header">
          <FilterButton onClick={handleFilterClick} />
          {activeTab.includes("Items") && (
            <LiveButton
              pageType={pageType}
              activeTab={activeTab}
              collectionData={collectionData}
              walletData={walletData}
              setTokenDetailsArray={setDataArray}
              setListedTokensCount={setListedTokensCount}
            />
          )}
          {activeTab.includes("Items") && (
            <TokenSearchSection
              pageType={pageType}
              activeTab={activeTab}
              tokenOwners={[walletData?._id]}
              tokenCreator={walletData?._id}
              tokenContractAddress={collectionData?._id}
              tokensSelected={pageFilters?.searchQueryTokensSelected || []}
              addToTokensSelected={(obj) =>
                selectedFiltersState?.addToTokensSelectedAfterSearchFunction(
                  obj
                )
              }
              deleteFromTokensSelected={(obj) =>
                selectedFiltersState?.deleteFromTokensSelectedAfterSearchFunction(
                  obj
                )
              }
            />
          )}
          <span style={{ width: "20%" }}>
            {activeTab.includes("Items") && (
              <TokenSortingSection
                smallerDropDown={true}
                pageType={pageType}
                activeTab={activeTab}
                listedTokensCount={listedTokensCount}
              />
            )}
          </span>
          {activeTab.includes("Items") ? (
            <ToggleGridButton
              isBiggerColumnGrid={isBiggerColumnGrid}
              toggleGridLayout={toggleGridLayout}
            />
          ) : (
            ""
          )}
        </div>
      )}

      {(selectedFiltersState?.searchQueryTokensSelected ||
        Object.keys(selectedFiltersState?.selectedTraitValues || {}).length >
          0 ||
        (!isNullOrUndefined(selectedFiltersState.minListingPriceFilter) &&
          !isNullOrUndefined(selectedFiltersState?.maxListingPriceFilter)) ||
        selectedFiltersState?.status !== "All") && (
        <div className="selected-filters">
          {Object.values(
            selectedFiltersState?.searchQueryTokensSelected || {}
          ).map((tokenSelectedObj, index) => {
            return (
              <div key={index} className="selected-filter">
                {tokenSelectedObj?.openseaDetails?.name}
                <button
                  className="selected-filter-remove-button"
                  onClick={() =>
                    selectedFiltersState?.deleteFromTokensSelectedAfterSearchFunction(
                      tokenSelectedObj
                    )
                  }
                >
                  <RemoveButton color={"white"} />
                </button>
              </div>
            );
          })}

          {Object.keys(selectedFiltersState?.selectedTraitValues).map(
            (traitType, index) => {
              return selectedFiltersState?.selectedTraitValues[traitType].map(
                (value, index2) => {
                  return (
                    <div key={index} className="selected-filter">
                      {traitType + " : " + value}
                      <button
                        className="selected-filter-remove-button"
                        onClick={() =>
                          selectedFiltersState?.updateSelectedTraitValuesFunction(
                            traitType,
                            value
                          )
                        }
                      >
                        <RemoveButton color={"white"} />
                      </button>
                    </div>
                  );
                }
              );
            }
          )}

          {activeTab.includes("Items") &&
            !isNullOrUndefined(selectedFiltersState.minListingPriceFilter) &&
            !isNullOrUndefined(selectedFiltersState.maxListingPriceFilter) &&
            selectedFiltersState.status === "Listed" && (
              <div className="selected-filter">
                Listing Price{" : "}
                {"( Between " +
                  selectedFiltersState.minListingPriceFilter +
                  " and " +
                  selectedFiltersState.maxListingPriceFilter +
                  " ) " +
                  selectedFiltersState.listingPriceCurrencyFilter}
                <button
                  className="selected-filter-remove-button"
                  onClick={() =>
                    selectedFiltersState.updateListingPriceFilterFunction()
                  }
                >
                  <RemoveButton color={"white"} />
                </button>
              </div>
            )}

          {!isNullOrUndefined(selectedFiltersState.status) &&
            selectedFiltersState.status !== "All" && (
              <div className="selected-filter">
                Status {" : "}
                {selectedFiltersState.status}
                <button
                  className="selected-filter-remove-button"
                  onClick={() => {
                    selectedFiltersState?.updateStatusFunction("All");
                  }}
                >
                  <RemoveButton color={"white"} />
                </button>
              </div>
            )}
        </div>
      )}
      {/* {dataArray &&
        dataArray.length === 0 &&
        Object.keys(selectedFiltersState.selectedTraitValues)?.length > 0 && (
          <div
            className="no-tokens-message-container"
            style={{ height: "55vh" }}
          >
            No tokens to display for current selection, Use OR filter instead of
            AND:
            <button
              onClick={() => {
                setConditionType("OR");
              }}
              className="filter-section-and-or-button"
              style={{
                fontSize: "0.8em",
                textAlign: "center",
                color: "white",
                borderRadius: "5px",
                border: "none",
                marginLeft: "0.2vw",
                backgroundColor: "#2fb484",
                height: "2rem",
              }}
            >
              OR
            </button>
          </div>
        )} */}
      <div className="items-tab-section-items-container">
        {!isMobile ? (
          showFilterSection && (
            <div>
              <FilterSection
                pageType={pageType}
                activeTab={activeTab}
                filterSectionVisible={showFilterSection}
                aggregated_traits={aggregated_traits}
                onFilterClickClose={handleFilterClick}
                setConditionType={setConditionType}
                conditionType={conditionType}
                listedTokensCount={listedTokensCount}
                traitCount={traitCount}
              />
            </div>
          )
        ) : (
          <FilterSection
            pageType={pageType}
            activeTab={activeTab}
            filterSectionVisible={showFilterSection}
            aggregated_traits={aggregated_traits}
            onFilterClickClose={handleFilterClick}
            setConditionType={setConditionType}
            conditionType={conditionType}
            listedTokensCount={listedTokensCount}
            traitCount={traitCount}
          />
        )}

        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
          className={
            `${
              isMobile
                ? "items-tab-items-container-without-filter-section "
                : !showFilterSection
                ? "items-tab-items-container-without-filter-section "
                : "items-tab-items-container-with-filter-section "
            }` + "items-tab-items-container"
          }
        >
          {showItemsData &&
            (activeTab.includes("Items") ? (
              <TokenGrid
                pageType={pageType}
                activeTab={activeTab}
                tokenDetailsArray={filteredData}
                tokensCount={dataTotalSize}
                showFilterSection={showFilterSection}
                isBiggerColumnGrid={isBiggerColumnGrid}
                isFetchingData={isFetchingData}
              />
            ) : (
              <ActivityTableContainer
                transferHistory={filteredData}
                showFilterSection={showFilterSection}
                isFetchingData={isFetchingData}
                activitySortingState={activitySortingState}
                setActivitySortingState={setActivitySortingState}
                stickyHeader={true}
              />
            ))}

          {dataArray?.length >= 32 && (
            <div
              id="intersectionObserverTarget"
              style={{ height: "50px" }}
            ></div>
          )}
        </div>
      </div>
    </div>
  );
}
