import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import VarifiedIcon from "../../Homepage/Header/VarifiedIcon";
import { prettyName } from "../../../utils/commonUtils/PrettyString";
import {
  fetchImageURLFromTokenObj,
  fetchTokenNameFromTokenObj,
} from "../../../utils/commonUtils/TokenUtils";
import RemoveButton from "../../../Icons/CollectionPageIcons/RemoveButton";

const defaultImageUrl =
  "https://www.shutterstock.com/image-vector/dark-blue-green-vector-blur-600nw-1692448585.jpg";

export default function TokenDropdownItem({ item, deleteFromTokensList }) {
  const navigate = useNavigate();
  const [imgUrl, setImgUrl] = useState("");

  useEffect(() => {
    setImgUrl(fetchImageURLFromTokenObj(item));
  }, [item]);

  function handleImgErr(event, item) {
    event.target.src = defaultImageUrl;
  }

  return (
    <div className="token-list-dropdown-item search-result-hoverxw">
      {/* <img
        src={imgUrl}
        alt="Token Img"
        className="search-section-result-item-image"
        style={{ width: "5vw" }}
      /> */}
      <div>{fetchTokenNameFromTokenObj(item)}</div>
      <span
        onClick={(e) => deleteFromTokensList(item)}
        className="search-section-result-item-checkbox"
      >
        <RemoveButton color="#b02a2a" />
      </span>
    </div>
  );
}
