import React from "react";

const SearchOutline = () => {
    return (
      <div className="searchOutlineIcon-container-mobile">
        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
            <path d="M23.6813 22.1032L19.9028 18.3359C21.1219 16.7828 21.7834 14.8649 21.7809 12.8905C21.7809 11.1321 21.2595 9.41322 20.2826 7.95119C19.3057 6.48916 17.9172 5.34965 16.2927 4.67675C14.6682 4.00385 12.8806 3.82779 11.156 4.17083C9.43145 4.51387 7.84732 5.36061 6.60396 6.60396C5.36061 7.84732 4.51387 9.43145 4.17083 11.156C3.82779 12.8806 4.00385 14.6682 4.67675 16.2927C5.34965 17.9172 6.48916 19.3057 7.95119 20.2826C9.41322 21.2595 11.1321 21.7809 12.8905 21.7809C14.8649 21.7834 16.7828 21.1219 18.3359 19.9028L22.1032 23.6813C22.2065 23.7854 22.3294 23.8681 22.4649 23.9245C22.6003 23.981 22.7455 24.01 22.8922 24.01C23.039 24.01 23.1842 23.981 23.3196 23.9245C23.4551 23.8681 23.578 23.7854 23.6813 23.6813C23.7854 23.578 23.8681 23.4551 23.9245 23.3196C23.981 23.1842 24.01 23.039 24.01 22.8922C24.01 22.7455 23.981 22.6003 23.9245 22.4649C23.8681 22.3294 23.7854 22.2065 23.6813 22.1032ZM6.22262 12.8905C6.22262 11.5717 6.61368 10.2825 7.34636 9.18601C8.07903 8.08949 9.12041 7.23485 10.3388 6.73018C11.5572 6.22551 12.8979 6.09346 14.1913 6.35074C15.4847 6.60802 16.6728 7.24307 17.6054 8.17559C18.5379 9.10811 19.1729 10.2962 19.4302 11.5896C19.6875 12.8831 19.5554 14.2238 19.0508 15.4421C18.5461 16.6605 17.6915 17.7019 16.5949 18.4346C15.4984 19.1673 14.2092 19.5583 12.8905 19.5583C11.122 19.5583 9.42605 18.8558 8.17559 17.6054C6.92512 16.3549 6.22262 14.6589 6.22262 12.8905Z" fill="#183B56"/>
        </svg>
      </div>
    );
  };
  
  export default SearchOutline;