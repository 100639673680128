import React from "react";

const LeftArrowIcon = ({ width, height }) => {
  return (
    <div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width ? width : "0.9em"}
        height={height ? height : "0.9em"}
        viewBox="0 0 7 12"
        fill="#183B56"
        style={{ flexShrink: 0 }}
      >
        <path
          d="M6.67413 11.4816C6.88285 11.2716 7 10.9876 7 10.6915C7 10.3955 6.88285 10.1115 6.67413 9.9015L2.70717 5.8785L6.67413 1.91154C6.88285 1.70158 7 1.41756 7 1.12151C7 0.825456 6.88285 0.541435 6.67413 0.331475C6.56996 0.226442 6.44602 0.143074 6.30946 0.0861825C6.1729 0.0292905 6.02643 -4.25559e-08 5.8785 -4.90223e-08C5.73057 -5.54887e-08 5.5841 0.0292905 5.44754 0.0861824C5.31098 0.143074 5.18704 0.226442 5.08287 0.331475L0.331475 5.08287C0.226442 5.18704 0.143075 5.31098 0.0861828 5.44754C0.0292909 5.5841 -2.50491e-07 5.73057 -2.56957e-07 5.8785C-2.63424e-07 6.02643 0.0292909 6.1729 0.0861828 6.30946C0.143075 6.44602 0.226442 6.56996 0.331474 6.67413L5.08287 11.4816C5.18704 11.5866 5.31098 11.67 5.44754 11.7268C5.5841 11.7837 5.73057 11.813 5.8785 11.813C6.02643 11.813 6.1729 11.7837 6.30946 11.7268C6.44602 11.67 6.56996 11.5866 6.67413 11.4816Z"
          fill="#183B56"
        />
      </svg>
    </div>
  );
};

export default LeftArrowIcon;
