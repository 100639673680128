import "../../styles/itemPageStyles/ItemPage.css";
import useIsMobile from "../../customHooks/UseIsMobile";

export default function ItemPageContentBox({ children }) {
  const isMobile = useIsMobile();

  return (
    <div
      id={isMobile ? "item-page-content-box-mobile" : "item-page-content-box"}
    >
      {children}
    </div>
  );
}
