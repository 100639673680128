import React, { useState } from "react";
import InfoIcon from "../../Icons/InfoIcon";

import css from "../../styles/CommonComponentsStyles/InfoComponent.css";

export default function InfoComponent({
  info,
  iconWidth,
  iconHeight,
  renderDirection,
  styleObj,
}) {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleInfoIconHover = () => {
    setIsModalOpen(true);
  };
  const handleCloseInfoModal = () => {
    setIsModalOpen(false);
  };

  let position =
    renderDirection === "up-right"
      ? {
          bottom: "calc(1vw)",
        }
      : renderDirection === "up-left"
      ? {
          bottom: "calc(1vw)",
          right: "0",
        }
      : renderDirection === "bottom-left"
      ? {
          right: "0",
          top: "calc(3vw)",
        }
      : {
          left: "0",
          top: "calc(1vw)",
        };

  return (
    <div
      style={{
        position: "relative",
        cursor: "pointer",
        display: "flex",
        alignItems: "center",
      }}
    >
      <span
        onMouseEnter={() => handleInfoIconHover(true)}
        onMouseLeave={() => handleCloseInfoModal(true)}
      >
        <InfoIcon width={iconWidth || "1.2em"} height={iconHeight || "1.2em"} />
      </span>
      {isModalOpen && (
        <pre
          className="info-text-modal-container"
          style={{
            lineHeight: "2em",
            textAlign: "left",
            ...position,
            ...styleObj,
          }}
        >
          {info || "This is a test modal"}
        </pre>
      )}
    </div>
  );
}
