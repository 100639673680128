import React, { useEffect, useState } from "react";
import useIsMobile from "../../../customHooks/UseIsMobile";
import TokensDropdownList from "./TokensDropdownList";

function TokensListWithDropdown({ tokensToShow, deleteFromTokensList }) {
  const [showTokensDropdown, setShowTokensDropdown] = useState(false);

  const isMobile = useIsMobile();

  return (
    <div className="token-search-wrapper" style={{ minWidth: "20em" }}>
      <span
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "65%",
        }}
        className="provenance-tokens-buttons"
        onClick={() => {
          setShowTokensDropdown((prev) => {
            return !prev;
          });
        }}
      >
        {tokensToShow.length} Selected
      </span>

      {showTokensDropdown && (
        <>
          <TokensDropdownList
            tokensToShow={tokensToShow}
            deleteFromTokensList={deleteFromTokensList}
          />
        </>
      )}
    </div>
  );
}

export default TokensListWithDropdown;
