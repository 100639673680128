import React, { useRef, useState } from "react";
import "../../styles/itemPageStyles/ItemPage.css";
import OpenOriginalIcon from "../../Icons/MoreOptionsMenuIcon/OpenOriginal";
import Share from "../../Icons/MoreOptionsMenuIcon/Share";
import CopyLink from "../../Icons/MoreOptionsMenuIcon/CopyLink";
import Etherscan from "../../assets/images/Etherscan.png";
import AlertPopup from "../commonUtils/AlertPopup";
import { Link } from "react-router-dom";
import { copyToClipboard } from "../commonUtils/Utils";
import EtherScanLogo from "../../Icons/EtherscanLogo";
import useIsMobile from "../../customHooks/UseIsMobile";
import { fetchOriginalImageURLFromTokenObj } from "../commonUtils/TokenUtils";

const MoreOptionsMenu = ({ itemData }) => {
  const [alertPopupMessage, setAlertPopupMessage] = useState(false);
  const isMobile = useIsMobile();
  const openOriginalImageSourceHandler = () => {
    window.open(fetchOriginalImageURLFromTokenObj(itemData), "_blank");
  };

  const openEtherscanHandler = () => {
    window.open(
      `https://etherscan.io/nft/${itemData.contractAddress}/${itemData.decimalTokenId}`,
      "_blank"
    );
  };

  const shareImageHandler = () => {
    copyToClipboard(fetchOriginalImageURLFromTokenObj(itemData));
    setAlertPopupMessage(true);
    setTimeout(() => {
      setAlertPopupMessage(false);
    }, 1500);
  };

  const copyLinkHandler = () => {
    copyToClipboard(window.location.href);
    setAlertPopupMessage(true);
    setTimeout(() => {
      setAlertPopupMessage(false);
    }, 1500);
  };

  return (
    <div className="more-options-menu-container">
      <div className="more-options-icon-and-text-container">
        <div className="more-options-original-icon-layout">
          <OpenOriginalIcon />
        </div>
        <div
          className="more-options-text-layout"
          onClick={openOriginalImageSourceHandler}
        >
          Open original
        </div>
      </div>
      <div className="more-options-line-between-elements"></div>
      <div className="more-options-icon-and-text-container">
        <div className="more-options-etherscan-icon-layout">
          {/* <img src={Etherscan} height={14} width={14} alt="etherscan img"></img> */}
          {!isMobile ? (
            <EtherScanLogo width="0.6vw" height="0.6vw" />
          ) : (
            <EtherScanLogo width="6vw" height="6vw" />
          )}
          {/* <EtherScanLogo width="0.6vw" height="0.6vw"/> */}
        </div>
        <div
          className="more-options-text-layout"
          onClick={openEtherscanHandler}
        >
          View on Etherscan
        </div>
      </div>
      <div className="more-options-line-between-elements"></div>
      <div className="more-options-icon-and-text-container">
        <div className="more-options-share-icon-layout">
          <Share />
        </div>
        <div className="more-options-text-layout" onClick={shareImageHandler}>
          Share Image
        </div>
      </div>
      <div className="more-options-line-between-elements"></div>
      <div className="more-options-icon-and-text-container">
        <div className="more-options-copy-link-icon-layout">
          <CopyLink />
        </div>
        <div className="more-options-text-layout" onClick={copyLinkHandler}>
          Copy Link
        </div>
      </div>
      {alertPopupMessage && <AlertPopup message={"Link Copied!"} />}
    </div>
  );
};

export default MoreOptionsMenu;
