import React, { useEffect, useState } from "react";
import { fetchCollectionInfoBoxData } from "../../api/collectionDetailsApi";
import { valueToPercentage } from "../../utils/commonUtils/PrettyString";
import {
  getDaysAgoText,
  getTimeAgoFromDate,
} from "../../utils/commonUtils/timeUtils";
import {
  formatNumber,
  prettyPrice,
  prettyWeiToEth,
} from "../../utils/commonUtils/PriceUtils";

const InfoBoxMobile = ({ collectionData }) => {
  const [collectionInfoBoxData, setCollectionInfoBoxData] = useState(null);
  useEffect(() => {
    if (!collectionData?.contractAddress) return;
    fetchCollectionInfoBoxData(collectionData.contractAddress).then((data) => {
      setCollectionInfoBoxData(data);
    });
  }, [collectionData?.contractAddress]);

  useEffect(() => {}, []);

  return (
    <div className="d-flex justify-content-center">
      <div className="collection-info-box-mobile-container-with-time">
        <div className="info-box-mobile-container">
          <div className="info-box-mobile-container-row">
            <div className="collection-info-box">
              <span className="collection-info-box-values">
                {collectionInfoBoxData
                  ? prettyWeiToEth(collectionInfoBoxData.totalVolume) + " ETH"
                  : "Fetching"}
              </span>
              <span className="collection-info-box-headings-mobile">
                Total Volume{" "}
              </span>
            </div>
            <div className="collection-info-box">
              <span className="collection-info-box-values">
                {collectionInfoBoxData
                  ? collectionInfoBoxData.floorPrice
                    ? prettyPrice(collectionInfoBoxData.floorPrice) + " ETH"
                    : "None Listed"
                  : "Fetching"}
              </span>
              <span className="collection-info-box-headings-mobile">
                Floor Price
              </span>
            </div>

            <div className="collection-info-box">
              <span className="collection-info-box-values">
                {collectionInfoBoxData?.listingCount &&
                collectionData?.tokensSize
                  ? valueToPercentage(
                      collectionInfoBoxData?.listingCount,
                      collectionData?.tokensSize
                    )
                  : "None Listed"}
              </span>
              <span className="collection-info-box-headings-mobile">
                Listed
              </span>
            </div>
          </div>
          <div className="info-box-mobile-container-row">
            <div className="collection-info-box">
              <span className="collection-info-box-values">
                {collectionInfoBoxData
                  ? collectionInfoBoxData.bestCollectionOffer &&
                    collectionInfoBoxData.bestCollectionOffer > 0
                    ? prettyPrice(collectionInfoBoxData.bestCollectionOffer) +
                      " ETH"
                    : "No Collection Offers"
                  : "Fetching"}
              </span>
              <span className="collection-info-box-headings-mobile">
                Collection Offer
              </span>
              {/* <span className="collection-info-box-headings-mobile">Offer</span> */}
            </div>
            <div className="collection-info-box">
              <div className="d-flex gap-2">
                <span className="collection-info-box-values">
                  {collectionInfoBoxData
                    ? formatNumber(collectionInfoBoxData.uniqueOwners || 0)
                    : "Fetching"}
                </span>
              </div>
              <span className="collection-info-box-headings-mobile">
                Owners
              </span>
            </div>
            <div className="collection-info-box">
              <span className="collection-info-box-unique-owners-value-mobile">
                {collectionInfoBoxData
                  ? (
                      (collectionInfoBoxData.uniqueOwners * 100) /
                      collectionData?.tokensSize
                    ).toFixed(2) + " %"
                  : "Fetching"}
              </span>
              <span className="collection-info-box-headings-mobile">
                Unique Owners
              </span>
            </div>
          </div>
        </div>
        <div className="collection-info-box-time">
          Last Updated: {getTimeAgoFromDate(collectionInfoBoxData?.updated)}
        </div>
      </div>
    </div>
  );
};

export default InfoBoxMobile;
