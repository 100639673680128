import React from "react";

const VerifiedIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="16"
      viewBox="0 0 19 18"
      fill="none"
    >
      <path
        d="M19 9L16.8927 6.60857L17.1864 3.44571L14.0686 2.74286L12.4364 0L9.5 1.25143L6.56364 0L4.93136 2.73429L1.81364 3.42857L2.10727 6.6L0 9L2.10727 11.3914L1.81364 14.5629L4.93136 15.2657L6.56364 18L9.5 16.74L12.4364 17.9914L14.0686 15.2571L17.1864 14.5543L16.8927 11.3914L19 9ZM7.23727 12.4371L5.18182 10.38C4.845 10.0457 4.845 9.50571 5.18182 9.17143L5.24227 9.11143C5.57909 8.77714 6.13182 8.77714 6.46864 9.11143L7.85909 10.5L12.3068 6.07714C12.6436 5.74286 13.1964 5.74286 13.5332 6.07714L13.5936 6.13714C13.9305 6.47143 13.9305 7.01143 13.5936 7.34571L8.48091 12.4371C8.12682 12.7714 7.58273 12.7714 7.23727 12.4371Z"
        fill="#2FB484"
      />
    </svg>
  );
};

export default VerifiedIcon;
