import React from "react";

const ComingSoon = ({ styleObj, isInAnalysis }) => {
  return (
    <div
      className="d-flex justify-content-center"
      style={{
        ...styleObj,
        fontSize: isInAnalysis ? "2em" : "2em",
      }}
    >
      <div style={{ color: "#2fb484" }}>Coming Soon</div>
    </div>
  );
};

export default ComingSoon;
