import React, { useEffect, useState } from "react";
import "../../../styles/itemPageStyles/ItemPage.css";
import ArrowIcon from "../../../Icons/ArrowIcon";
import ArrowIconReverse from "../../../Icons/ArrowIconReverse";
import TokenMetadataModal from "../../../utils/ItemPageUtils/TokenMetadataModal";
import CopyLink from "../../../Icons/MoreOptionsMenuIcon/CopyLink";
import {
  copyToClipboard,
  isNullOrUndefinedOrEmptyObject,
  trimMiddleText,
} from "../../../utils/commonUtils/Utils";
import { fetchRefreshedTokenDetails } from "../../../api/tokenDetailsApi";
import {
  getNameFromCollectionObj,
  getRoyaltyFromCollectionObj,
} from "../../../utils/commonUtils/CollectionUtils";
import { fetchImage, fetchURLDataFromProxy } from "../../../api/imageApi";
import { Link } from "react-router-dom";
import AlertPopup from "../../../utils/commonUtils/AlertPopup";
import useIsMobile from "../../../customHooks/UseIsMobile";

const Details = ({ data, collectionData }) => {
  const [itemData, setItemData] = useState({});
  const isMobile = useIsMobile();
  const [toggleDetail, setToggleDetail] = useState(true);

  const mintingDate = new Date(data?.block_timestamp * 1000).toLocaleString();
  const [showModal, setShowModal] = useState(false); // Add modal state
  const [modalTitle, setModalTitle] = useState("");
  const [modalData, setModalData] = useState({});
  const [modalDataLoading, setModalDataLoading] = useState(false);
  const [addressClicked, setAddressClicked] = useState(true);
  const [validMetadata, setValidMetadata] = useState(true);
  const [validTokenUri, setValidTokenUri] = useState(true);

  useEffect(() => {
    setItemData(data);
  }, [data]);

  async function refreshMetadata() {
    setModalDataLoading(true);
    try {
      let refreshedItemData_ = await fetchRefreshedTokenDetails(itemData);

      setItemData(refreshedItemData_);
    } catch (e) {}
    setModalDataLoading(false);
  }

  useEffect(() => {
    if (isNullOrUndefinedOrEmptyObject(itemData?.tokenMetadata?.data)) {
      setValidMetadata(false);
    } else {
      setValidMetadata(true);
    }

    if (isNullOrUndefinedOrEmptyObject(itemData?.tokenURI?.uri)) {
      setValidTokenUri(false);
    } else {
      if (
        itemData?.tokenURI?.uri.includes(
          "https://api.opensea.io/api/v1/metadata/"
        ) &&
        itemData?.openseaDetails
      ) {
        setValidTokenUri(true);
      } else {
        setValidTokenUri(false);
      }
    }
  }, [itemData]);

  const royalties = getRoyaltyFromCollectionObj(collectionData);
  const [alertPopupMessage, setAlertPopupMessage] = useState(false);

  const copyLinkHandler = () => {
    copyToClipboard(itemData?.contractAddress);
    setAlertPopupMessage(true);
    setTimeout(() => {
      setAlertPopupMessage(false);
    }, 1500);
  };

  return (
    <div>
      <div className="details-header" style={{ fontSize: isMobile && "1rem" }}>
        Details
        <span onClick={() => setToggleDetail(!toggleDetail)}>
          {toggleDetail ? <ArrowIcon /> : <ArrowIconReverse />}
        </span>
      </div>

      {toggleDetail && (
        <div className="detail-section" style={{ fontSize: "1rem" }}>
          <div className="detail">
            <span>Collection</span>
            <Link
              to={`/collection/${itemData?.contractAddress}`}
              className="link-field"
            >
              {" "}
              {getNameFromCollectionObj(collectionData)}
            </Link>
          </div>
          <div className="detail">
            <span className="detail" title={itemData?.contractAddress}>
              Contract Address
            </span>
            <div
              title={itemData?.contractAddress}
              onClick={() => setAddressClicked(!addressClicked)}
            >
              <span
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "0.4em",
                  lineHeight: "normal",
                }}
              >
                {trimMiddleText(itemData?.contractAddress)}
                <span onClick={copyLinkHandler}>
                  <CopyLink />
                </span>
              </span>
            </div>
          </div>

          <div className="detail">
            <span className="detail" title={itemData?.contractAddress}>
              Token ID
            </span>
            <div title={itemData?.tokenId}>
              {trimMiddleText(itemData?.decimalTokenId)}
            </div>
          </div>

          <div className="detail">
            <span>Token Standard</span>
            <span>{itemData?.tokenType}</span>
          </div>

          <div className="detail">
            <span>Chain</span>
            <span>Ethereum</span>
          </div>

          <div className="detail">
            <span>Minting Date</span>
            <span>{mintingDate}</span>
          </div>
          <div className="detail">
            <span>Creator Earnings</span>
            <span>{royalties}%</span>
          </div>
          <div className="detail">
            <span style={{ color: validTokenUri ? "" : "red" }}>Token URI</span>
            <div
              className="token-info-detail-metadata"
              onClick={() => {
                if (
                  itemData?.tokenURI?.uri.includes(
                    "https://api.opensea.io/api/v1/metadata/"
                  )
                ) {
                  itemData.tokenURI.uri = itemData.tokenURI.uri.replace(
                    "{id}",
                    itemData?.decimalTokenId
                  );
                }

                setShowModal(true);
                setModalTitle("Token URI");
                setModalData(itemData?.tokenURI?.uri);
              }}
              style={{
                color: validTokenUri ? "#2081e2" : "red",
                fontWeight: "600",
              }}
            >
              View
            </div>
          </div>
          <div className="detail">
            <span style={{ color: validMetadata ? "" : "red" }}>Metadata</span>
            <div
              className="token-info-detail-metadata"
              onClick={() => {
                setShowModal(true);
                setModalTitle("Metadata");
                setModalData(itemData?.openseaDetails);
              }}
              style={{
                color: validMetadata ? "#2081e2" : "red",
                fontWeight: "600",
              }}
            >
              View
            </div>
            {showModal && (
              <TokenMetadataModal
                closeModal={() => setShowModal(false)}
                title={modalTitle}
                modalData={modalData}
                refreshMetadata={refreshMetadata}
                modalDataLoading={modalDataLoading}
                validData={
                  modalTitle === "Token URI" ? validTokenUri : validMetadata
                }
                openModal={showModal}
              />
            )}
          </div>
          <br></br>
        </div>
      )}
      {alertPopupMessage && <AlertPopup message={"Contract Address Copied!"} />}
    </div>
  );
};

export default Details;
