const initialState = {
  activeTab: "Home",
};
const headerReducer = (state = initialState, action) => {
  switch (action.type) {
    case "CHANGE_ACTIVE_TAB":
      return {
        activeTab: action.payload,
      };
    default:
      return state;
  }
};

export default headerReducer;
