import { isNullOrUndefinedOrEmptyObject } from "../utils/commonUtils/Utils";

// Save a specific part of the state to local storage
export const saveReducerState = (key, state) => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem(key, serializedState);
  } catch (err) {
    console.error(`Could not save ${key} state`, err);
  }
};

// Load a specific part of the state from local storage
export const loadReducerState = (key) => {
  try {
    const serializedState = localStorage.getItem(key);
    if (serializedState === null) {
      return undefined; // No state found in local storage
    }
    return JSON.parse(serializedState);
  } catch (err) {
    console.error(`Could not load ${key} state`, err);
    return undefined;
  }
};

// Load all reducers' states from local storage
export const loadAllReducerStates = () => {
  let userInfo = loadReducerState("userInfo") || {};
  let loggedIn = false;
  if (!isNullOrUndefinedOrEmptyObject(userInfo?.emailAddress)) {
    loggedIn = true;
  }

  return {
    user: {
      loggedIn: loggedIn,
      userInfo: userInfo,
    },
    header: { activeTab: "Home" },
    // Add other reducer states here
  };
};
