import React from "react";
import QRCode from "react-qr-code";

const CreatorQRcode = () => {
  const currentURL = window.location.href;

  return (
    <div className="creator-qr-code">
      <QRCode value={currentURL} fgColor="#183b56" bgColor="transparent" />
    </div>
  );
};

export default CreatorQRcode;
