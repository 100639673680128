import React, { useEffect } from "react";
import ContentLoader from "react-content-loader";

const GridLoader = ({ index }) => {
  return (
    <ContentLoader
      speed={1}
      viewBox="0 0 10% 10%"
      backgroundColor="#f3f3f3"
      foregroundColor="#2fb4835a"
      style={{ width: "100%", height: "100%", borderRadius: "1vw" }}
    >
      <>
        <rect x="-1" y="0" rx="8" ry="8" width="100%" height="100%" />
        <rect x={`${0}`} y="250" rx="0" ry="0" width="100%" height="100%" />
        <rect x={`${0}`} y="275" rx="0" ry="0" width="100%" height="100%" />
      </>
    </ContentLoader>
  );
};

export default GridLoader;
