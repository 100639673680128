import { useNavigate } from "react-router-dom";
import VarifiedIcon from "./VarifiedIcon";
import { prettyVolumeScore } from "../../../utils/commonUtils/PriceUtils";
import { useEffect, useState } from "react";

import { prettyName } from "../../../utils/commonUtils/PrettyString";
import useIsMobile from "../../../customHooks/UseIsMobile";

export default function SearchResultCollection({
  item,
  index,
  isSearching,
  handleImgErr,
  toggleSearchResult,
}) {
  const navigate = useNavigate();
  const [score, setScore] = useState(1);
  const isMobile = useIsMobile();

  useEffect(() => {
    setScore(prettyVolumeScore(item.scores.volumeScore));
  });

  function getScoreColor(score) {
    if (score > 4) return "rgb(0, 200, 190)";
    else if (score > 3) return "#2fb484";
    else if (score > 2) return "rgb(190, 190, 0)";
    else return "red";
  }

  return (
    <>
      {!isMobile ? (
        <button
          key={index}
          className="d-flex flex-column border-0 p-0"
          style={{
            overflow: "hidden",
            width: "90%",
            height: "7vw",
            borderRadius: "0.7vw",
            marginBottom: "0.5vw",
          }}
          onClick={() => {
            navigate(`/collection/${item.contractAddress}`);
            toggleSearchResult();
          }}
        >
          {isSearching && (
            <div className="w-100 h-100 loading-background"></div>
          )}
          {!isSearching && (
            <div className="d-flex w-100 h-100 search-result-hover">
              <div>
                <img
                  src={item.collectionDetails?.image_url}
                  style={{ height: "100%", width: "7vw", objectFit: "cover" }}
                  onError={handleImgErr}
                />
              </div>
              <div
                className="d-flex align-items-center w-100 justify-content-start-0"
                style={{ backgroundColor: "rgba(255,255,255,0)" }}
              >
                <div
                  style={{
                    margin: "1vw 1vw 1vw 2vw",
                    fontSize: "1vw",
                    fontFamily: "Lato",
                    fontWeight: "bold",
                  }}
                >
                  {prettyName(item.collectionDetails?.name)}
                </div>
              </div>
              <div
                className="d-flex flex-column justify-content-between align-items-end"
                style={{
                  backgroundColor: "rgba(255,255,255,0)",
                  marginTop: "0.2vw",
                  marginRight: "0.2vw",
                  marginBottom: "0.2vw",
                }}
              >
                <VarifiedIcon varified={item.varified} />
                <div
                  style={{
                    marginRight: "0.2vw",
                    color: getScoreColor(score),
                    fontWeight: "bold",
                  }}
                >
                  {score}
                </div>
              </div>
            </div>
          )}
        </button>
      ) : (
        <button
          key={index}
          className="d-flex flex-column border-0 p-0"
          style={{
            overflow: "hidden",
            width: "98%",
            height: "10vw",
            borderRadius: "2vw",
            marginBottom: "1vw",
          }}
          onClick={() => {
            navigate(`/collection/${item.contractAddress}`);
            toggleSearchResult();
          }}
        >
          {isSearching && (
            <div className="w-100 h-100 loading-background"></div>
          )}
          {!isSearching && (
            <div className="d-flex w-100 h-100 search-result-hover">
              <div>
                <img
                  src={item.collectionDetails?.image_url}
                  style={{ height: "100%", width: "10vw", objectFit: "cover" }}
                  onError={handleImgErr}
                />
              </div>
              <div
                className="d-flex align-items-center w-100 justify-content-start-0"
                style={{ backgroundColor: "rgba(255,255,255,0)" }}
              >
                <div
                  style={{
                    margin: "1vw 1vw 1vw 2vw",
                    fontSize: "2.5vw",
                    fontFamily: "Lato",
                  }}
                >
                  {prettyName(item.collectionDetails?.name)}
                </div>
              </div>
              <div
                className="d-flex flex-column justify-content-between align-items-center"
                style={{
                  backgroundColor: "rgba(255,255,255,0)",
                  marginTop: "0.2vw",
                  marginRight: "0.2vw",
                  marginBottom: "0.2vw",
                }}
              >
                <VarifiedIcon varified={item.varified} />
                <div
                  style={{
                    marginRight: "2vw",
                    color: getScoreColor(score),
                    fontWeight: "bold",
                    fontSize: "2.5vw",
                  }}
                >
                  {score}
                </div>
              </div>
            </div>
          )}
        </button>
      )}
    </>
  );
}
