import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  addItemInUserFavourite,
  checkIsFavourite,
  removeItemFromFavourite,
} from "../../api/mjUserApi";
import { useSelector } from "react-redux";
import useIsMobile from "../../customHooks/UseIsMobile";
import { trimLongText, trimMiddleText } from "../../utils/commonUtils/Utils";

const ActivityUserCell = ({
  wallet_addr,
  iconsWidth,
  iconsHeight,
  showIcons,
  walletProfileName,
  cellInItemPage,
}) => {
  const isMobile = useIsMobile();
  const mjUserInfo = useSelector((state) => state.user);
  const [isfavouriteWalletAddresses, setIsFavouriteWalletAddresses] =
    useState(false);

  // useEffect(() => {
  //   if (!mjUserInfo?.userInfo?.userId) return;
  //   checkIsFavourite(mjUserInfo?.userInfo?.userId, wallet_addr)
  //     .then((isFavourite) => {
  //       setIsFavouriteWalletAddresses(isFavourite);
  //     })
  //     .catch((e) => {});
  // }, [wallet_addr, mjUserInfo]);

  const handleFavourite = async () => {
    const walletsToAddToFavorites = wallet_addr;
    if (!mjUserInfo?.userInfo?.userId) return;
    try {
      if (isfavouriteWalletAddresses) {
        await removeItemFromFavourite(
          mjUserInfo?.userInfo?.userId,
          walletsToAddToFavorites
        );
      } else {
        await addItemInUserFavourite(
          mjUserInfo?.userInfo?.userId,
          walletsToAddToFavorites,
          "WALLET_ADDRESS"
        );
      }
      setIsFavouriteWalletAddresses(!isfavouriteWalletAddresses);
    } catch (e) {}
  };

  return (
    <div className="transfer-history-table-user-details-cell transfer-history-table-cell">
      <Link
        // to={`/wallet_report/${wallet_addr}`}
        to={`https://opensea.io/${wallet_addr}`}
        target="_blank"
        className="transfer-history-table-cell link-field"
        title={wallet_addr}
      >
        {walletProfileName && walletProfileName.length > 0
          ? trimLongText(walletProfileName, 18)
          : trimMiddleText(wallet_addr)}
      </Link>
      {/* {showIcons && (
        <div
          className={
            cellInItemPage
              ? "transfer-history-table-user-details-icons-item-page"
              : "transfer-history-table-user-details-icons"
          }
        >
          <Link
            to={`https://opensea.io/${wallet_addr}`}
            title={"Link to Token Page on Opensea Marketplace"}
            target="_blank"
          >
            <OpenSeaIcon
              width={iconsWidth || "1.2em"}
              height={iconsHeight || "1.2em"}
            />
          </Link>
          {<UserReport
            url={`/wallet_report/${wallet_addr}`}
            width={iconsWidth || "1.2em"}
            height={iconsHeight || "1.2em"}
            title={`Link to Wallet Report page for the Wallet Address ${wallet_addr}`}
          /> }

          {mjUserInfo.loggedIn && (
            <span
              onClick={handleFavourite}
              style={{ cursor: "pointer" }}
              title={`Use this icon to add ${wallet_addr} wallet address in your favorite wallet list!`}
            >
              {" "}
              {isfavouriteWalletAddresses ? (
                <FavouriteIconFilled
                  width={iconsWidth || isMobile ? "1em" : "1.1em"}
                  height={iconsHeight || isMobile ? "1em" : "1.1em"}
                />
              ) : (
                <FavouritesIconImage
                  width={iconsWidth || isMobile ? "1em" : "1.1em"}
                  height={iconsHeight || isMobile ? "1em" : "1.1em"}
                />
              )}{" "}
            </span>
          )} 
        </div>
      )} */}
    </div>
  );
};

export default ActivityUserCell;
