import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import loadingImage from "../../assets/images/loading-gear.gif";

import VarifiedIcon from "./../Homepage/Header/VarifiedIcon";
import { prettyName } from "../../utils/commonUtils/PrettyString";
import { fetchImageURLFromTokenObj } from "../../utils/commonUtils/TokenUtils";

const defaultImageUrl =
  "https://www.shutterstock.com/image-vector/dark-blue-green-vector-blur-600nw-1692448585.jpg";

export default function TokenSearchResultItem({
  item,
  isSearching,
  tokenSelected,
  addToTokensSelected,
  deleteFromTokensSelected,
}) {
  const navigate = useNavigate();
  const [imgUrl, setImgUrl] = useState("");
  const [selected, setSelected] = useState(false);

  useEffect(() => {
    if (isSearching) {
      setImgUrl(loadingImage);
    } else {
      const imageUrl = fetchImageURLFromTokenObj(item);
      setImgUrl(imageUrl);
    }
  }, [item, isSearching]);

  function handleImgErr(event, item) {
    event.target.src = defaultImageUrl;
  }

  return (
    <div
      className="search-section-result-item search-result-hoverxw"
      onClick={(e) => {
        if (!tokenSelected) {
          addToTokensSelected(item);
        } else {
          deleteFromTokensSelected(item);
        }
      }}
    >
      <div className="search-section-result-item-checkbox">
        <input
          style={{ height: "0.8rem", width: "0.8rem" }}
          type="checkbox"
          checked={tokenSelected}
        />
      </div>
      <img
        src={imgUrl}
        alt="Token Img"
        className="search-section-result-item-image"
        style={{ objectFit: "cover", width: "5vw" }}
      />
      <div className="search-section-result-item-content">
        {prettyName(item.openseaDetails.name) || "No Name"}
      </div>
      <div className="verified-icon">
        <VarifiedIcon verified={item.verified} />
      </div>
    </div>
  );
}
