import React, { useEffect, useRef, useState } from "react";
import useIsMobile from "../../customHooks/UseIsMobile";
import { atlasSearchApi } from "../../api/atlasSearchApi";
import TokensSearchResult from "./TokensSearchResult";
import "../../styles/CommonComponentsStyles/TokenSearch.css";
import RemoveButton from "../../Icons/CollectionPageIcons/RemoveButton";
import SearchIcon from "../../Icons/CollectionPageIcons/SearchIcon";

function TokenSearchSection({
  pageType,
  activeTab,
  tokenOwners,
  tokenCreator,
  tokenContractAddress,
  tokensSelected,
  addToTokensSelected,
  deleteFromTokensSelected,
  styleobj,
}) {
  const [searchQuery, setSearchQuery] = useState("");
  const [searchResult, setSearchResult] = useState([]);
  const [showSearchResult, setShowSearchResult] = useState(false);
  const [isSearching, setIsSearching] = useState(false);

  const handleSearchChange = (event) => {
    if (event.target.value.length > 0) {
      setSearchQuery(event.target.value);

      let params = {
        size: 20,
        input: event.target.value,
      };

      if (pageType === "CollectionPage") {
        params = { ...params, contractAddress: tokenContractAddress };
      } else if (activeTab === "Owned_Items" && pageType === "CreatorPage") {
        params = {
          ...params,
          tokenOwners: tokenOwners ? tokenOwners.join(",") : null,
        };
      } else if (activeTab === "Minted_Items" && pageType === "CreatorPage") {
        params = {
          ...params,
          tokenOwners: tokenOwners ? tokenOwners.join(",") : null,
        };
      } else if (activeTab === "Created_Items" && pageType === "CreatorPage") {
        params = { ...params, tokenCreator: tokenCreator };
      } else if (pageType === "FolderPage") {
        params = {
          ...params,
          tokenOwners: tokenOwners ? tokenOwners.join(",") : null,
        };
      } else {
        return;
      }

      setShowSearchResult(true);
      setIsSearching(true);

      atlasSearchApi
        .searchAtlasForTokens(params)
        .then((data) => {
          setSearchResult(data || []);
          setIsSearching(false);
        })
        .catch((e) => {
          setIsSearching(false);
        })
        .finally(() => {});
    } else if (event.target.value.length == 0) {
      setShowSearchResult(false);
      setSearchResult([]);
    }
  };

  const handleSearchReset = () => {
    setSearchQuery("");
    setShowSearchResult(false);
    setSearchResult([]);
  };
  const isMobile = useIsMobile();

  const inputRef = useRef();
  const [searchInputFocus, setSearchInputFocus] = useState(false);

  useEffect(() => {
    // Click Events
    inputRef.current.addEventListener("click", (event) => {
      // Focus on the exact box
      setSearchInputFocus(true);
    });

    // When we click outside of a <div>
    inputRef.current.addEventListener("focusout", (event) => {
      setSearchInputFocus(false);
    });
  }, []);

  return (
    <div className="token-search-wrapper" style={styleobj}>
      <div
        className="token-search-input-wrapper"
        ref={inputRef}
        tabIndex="0"
        style={{
          border: searchInputFocus
            ? "calc(var(--base-font-size-collection-page) * (1.5 / 32)) solid #b4b8bf"
            : "",
        }}
      >
        <span className="token-search-input-icon">
          <SearchIcon width={"1.4em"} height={"1.4em"} />
        </span>
        <input
          type="text"
          className={
            !isMobile ? "token-search-input" : "token-search-input-mobile"
          }
          placeholder="Search by Token Name, ID, or Collection Name, Slug"
          onChange={handleSearchChange}
        />
      </div>

      {showSearchResult && (
        <>
          <TokensSearchResult
            tokensResult={searchResult}
            isSearching={isSearching}
            tokensSelected={tokensSelected}
            addToTokensSelected={addToTokensSelected}
            deleteFromTokensSelected={deleteFromTokensSelected}
            resetSearch={handleSearchReset}
          />
        </>
      )}
    </div>
  );
}

export default TokenSearchSection;
