import { REACT_APP_BACKEND_API_BASE_URL } from "./backend_domains/backend_api_domains";
import { CommonProvenanceCategories } from "../components/UserProvenancePage/Variables/CommonProvenanceCategories";
import Axios from "axios";
import { setupCache } from "axios-cache-interceptor";
const instance = Axios.create();
const axios = setupCache(instance);
const URL = `${REACT_APP_BACKEND_API_BASE_URL}`;

export const fetchTokenDetails = async (contractAddress, tokenId) => {
  try {
    const response = await axios.get(`${URL}/token_details`, {
      params: {
        contractAddress: contractAddress,
        identifier: tokenId,
      },
      timeout: 5000,
    });
    return response?.data?.tokenDetail;
  } catch (error) {
    console.error("Token Details is not working.");
    throw error;
  }
};

export const fetchRefreshedTokenDetails = async (tokenObj) => {
  try {
    let contractAddress = tokenObj.contractAddress;
    let tokenId = tokenObj.tokenId;

    const response = await axios.get(
      `${URL}/token_details/refresh?contractAddress=${contractAddress}&tokenId=${tokenId}`
    );
    return response?.data?.refreshedTokenDetail;
  } catch (error) {
    return tokenObj;
  }
};

export const fetchFloorValueForTrait = async (
  contractAddress,
  collectionSlug,
  collectionCreator,
  tokenTraitObj
) => {
  try {
    const response = await axios.post(
      `${URL}/token_details/floor_value_trait`,
      { contractAddress, collectionSlug, collectionCreator, tokenTraitObj }
    );
    return response.data.floorValueTrait;
  } catch (error) {
    console.error("Error in floor_value_trait API: ");
    throw error;
  }
};

export const fetchTokenProvenance = async (contractAddress, tokenId) => {
  try {
    const response = await axios.get(
      `${URL}/token_details/provenance?contractAddress=${contractAddress}&tokenId=${tokenId}`
    );
    return response?.data?.provenance;
  } catch (error) {
    console.error("Error in get provenance API: ");
    throw error;
  }
};

export const updateTokenProvenance = async (
  contractAddress,
  tokenId,
  categoryName,
  provenanceUpdateObjectsArray,
  notSendToBackendFieledsArray,
  provenanceReportPdfKey
) => {
  if (!provenanceUpdateObjectsArray) {
    return null;
  }

  try {
    let formData = new FormData();
    formData.append("categoryName", categoryName);

    let images = provenanceUpdateObjectsArray
      .map((provenance, index) => {
        if (
          provenance &&
          provenance.ImagesToUpload &&
          provenance.ImagesToUpload.length > 0
        ) {
          return provenance.ImagesToUpload.map((image) => {
            image.formIndex = index;
            return image;
          });
        } else return [];
      })
      .flat();

    for (let i = 0; i < images.length; i++) {
      formData.append(`images`, images[i]);
      formData.append(`imageFormIndex[]`, images[i].formIndex);
    }

    const config = {
      headers: { "content-type": "multipart/form-data" },
    };

    provenanceUpdateObjectsArray = provenanceUpdateObjectsArray.map(
      (provenance, index) => {
        for (let label of notSendToBackendFieledsArray) {
          delete provenance[label];
        }

        return provenance;
      }
    );
    formData.append(
      "provenanceUpdateObjectsArray",
      JSON.stringify(provenanceUpdateObjectsArray)
    );
    if (provenanceReportPdfKey === null)
      formData.append("provenanceReportPdfKey", null);

    const response = await axios.put(
      `${URL}/token_details/provenance?contractAddress=${contractAddress}&tokenId=${tokenId}`,
      formData
    );
    return response?.data?.updatedTokenProvenance || [];
  } catch (error) {
    console.error("Error in update provenance API: " + error.message);
    throw error;
  }
};

export const addProvenanceToMultipleTokens = async (
  contractTokenIds,
  categoryName,
  addProvenanceObjectsArray,
  notSendToBackendArray
) => {
  try {
    let formData = new FormData();
    formData.append("categoryName", categoryName);

    let images = addProvenanceObjectsArray
      .map((provenance, index) => {
        if (
          provenance &&
          provenance.ImagesToUpload &&
          provenance.ImagesToUpload.length > 0
        ) {
          return provenance.ImagesToUpload.map((image) => {
            image.formIndex = index;
            return image;
          });
        } else return [];
      })
      .flat();

    for (let i = 0; i < images.length; i++) {
      formData.append(`images`, images[i]);
      formData.append(`imageFormIndex[]`, images[i].formIndex);
    }

    const config = {
      headers: { "content-type": "multipart/form-data" },
    };

    addProvenanceObjectsArray = addProvenanceObjectsArray.map(
      (provenance, index) => {
        for (let label of notSendToBackendArray) {
          delete provenance[label];
        }

        return provenance;
      }
    );
    formData.append(
      "addProvenanceObjectsArray",
      JSON.stringify(addProvenanceObjectsArray)
    );

    const response = await axios.post(
      `${URL}/token_details/provenance?contractTokenIds=${contractTokenIds}`,
      formData
    );
    return response?.data?.updatedTokenProvenance;
  } catch (error) {
    console.error("Error in update provenance API: " + error.message);
    throw error;
  }
};

export const removeUploadedImageFromProvenanceApi = async (
  contractTokenId,
  categoryName,
  formIndex,
  imageDataToDelete
) => {
  try {
    const config = {
      headers: { "content-type": "multipart/form-data" },
    };

    const response = await axios.put(
      `${URL}/token_details/provenance/removeImage?contractTokenId=${contractTokenId}`,
      {
        categoryName: categoryName,
        imageDataToDelete: imageDataToDelete,
        formIndex: formIndex,
      }
    );
    return response.data.updatedTokenProvenance;
  } catch (error) {
    console.error("Error in update provenance API: " + error.message);
    throw error;
  }
};

export const fetchBatchTokenDetails = async (tokenList, selectFields) => {
  try {
    const tokenListString = tokenList.join(",");
    const selectFieldsString = selectFields.join(",");
    const response = await axios.post(`${URL}/token_details/batch`, {
      tokenIdList: tokenListString,
      selectFields: selectFieldsString,
    });
    if (!response.data.success)
      throw new Error("Error in batch token details API: " + response.message);
    return response.data;
  } catch (error) {
    console.error("Error in batch token details API: ");
    throw error;
  }
};
