import React from "react";

const ShareIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={`${16 / 18}em`}
      height="1em"
      viewBox="0 0 16 18"
      fill="none"
    >
      <path
        d="M13 12.4001C12.3667 12.4001 11.8 12.6501 11.3667 13.0417L5.425 9.58342C5.46667 9.39175 5.5 9.20008 5.5 9.00008C5.5 8.80008 5.46667 8.60842 5.425 8.41675L11.3 4.99175C11.75 5.40841 12.3417 5.66675 13 5.66675C14.3833 5.66675 15.5 4.55008 15.5 3.16675C15.5 1.78341 14.3833 0.666748 13 0.666748C11.6167 0.666748 10.5 1.78341 10.5 3.16675C10.5 3.36675 10.5333 3.55841 10.575 3.75008L4.7 7.17508C4.25 6.75842 3.65833 6.50008 3 6.50008C1.61667 6.50008 0.5 7.61675 0.5 9.00008C0.5 10.3834 1.61667 11.5001 3 11.5001C3.65833 11.5001 4.25 11.2417 4.7 10.8251L10.6333 14.2917C10.5917 14.4667 10.5667 14.6501 10.5667 14.8334C10.5667 16.1751 11.6583 17.2667 13 17.2667C14.3417 17.2667 15.4333 16.1751 15.4333 14.8334C15.4333 13.4917 14.3417 12.4001 13 12.4001Z"
        fill="#515457"
      />
    </svg>
  );
};

export default ShareIcon;
