import React, { useState } from "react";
import ArrowIconReverse from "../../Icons/ArrowIconReverse";
import ArrowIcon from "../../Icons/ArrowIcon";
import InfoComponent from "../CommonComponents/InfoComponent";
import { useRevalidator } from "react-router-dom";
import useIsMobile from "../../customHooks/UseIsMobile";

function HomePageActivityDropdown({
  filterDropdownValue,
  setFilterDropdownValue,
  userLoggedIn,
}) {
  const [isDropDownOpen, setIsDropDownOpen] = useState(false);
  const isMobile = useIsMobile();
  const loggedInDropdownOptions = userLoggedIn
    ? [
        {
          key: "Your_Collections",
          text: "Your Collections",
          value: "Your_Collections",
        },
        {
          key: "Your_Top_20_Trending_Collections",
          text: "Your Top 20 Trending Collections",
          value: "Your_Top_20_Trending_Collections",
        },
      ]
    : [];

  const dropdownOptions = [
    {
      key: "All_Collections",
      text: "All Collections",
      value: "All_Collections",
    },
    {
      key: "Top_20_Trending_Collections",
      text: "Top 20 Trending Collections",
      value: "Top_20_Trending_Collections",
    },
    ...loggedInDropdownOptions,
  ];

  return (
    <span
      className={
        !isMobile
          ? "home-page-filter-section-heading tour-step-1"
          : "home-page-filter-section-heading-mobile tour-step-1"
      }
      style={{ zIndex: "99999999" }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          gap: "calc(var(--base-font-size-home-page) * (6 / 32))",
        }}
      >
        {filterDropdownValue.text}
        <InfoComponent
          info={filterDropdownValue.text}
          renderDirection={"up-left"}
          styleObj={{
            width: "calc(var(--base-font-size-home-page) * 5)",
            height: "auto",
            textWrap: "wrap",
          }}
        />
      </div>
      <span
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
        }}
        onClick={() => {
          setIsDropDownOpen(!isDropDownOpen);
        }}
      >
        {isDropDownOpen ? (
          <ArrowIcon color={"grey"} width={"0.8em"} height={"0.8em"} />
        ) : (
          <ArrowIconReverse color={"grey"} width={"0.8em"} height={"0.8em"} />
        )}
      </span>
      {isDropDownOpen && (
        <div
          className={
            !isMobile
              ? "home-page-dropdown-options"
              : "home-page-dropdown-options-mobile"
          }
        >
          {dropdownOptions.map((option) => (
            <div
              key={option.key}
              className="home-page-dropdown-option"
              onClick={() => {
                setFilterDropdownValue(option);
                setIsDropDownOpen(false);
              }}
            >
              {option.text}
            </div>
          ))}
        </div>
      )}
    </span>
  );
}

export default HomePageActivityDropdown;
