import React, { useState, useEffect, useRef } from "react";
import "../../styles/collectionPageStyles/TokenGrid.css";
import TokenCard from "./TokenCard";
import GridLoader from "../../utils/commonUtils/GridLoader";
import useIsMobile from "../../customHooks/UseIsMobile";
import { getTokenRankUI } from "../../utils/commonUtils/TokenUtils";

function TokenGrid({
  pageType,
  activeTab,
  tokenDetailsArray,
  tokensCount,
  showFilterSection,
  isBiggerColumnGrid,
  isFetchingData,
}) {
  const isMobile = useIsMobile();
  const [hoveredCard, setHoveredCard] = useState(null);
  const [allTokensUnranked, setAllTokensUnranked] = useState(false);

  function handleCardHover(index) {
    setHoveredCard(index);
  }

  function handleCardLeave() {
    setHoveredCard(null);
  }

  useEffect(() => {
    if (tokenDetailsArray && tokenDetailsArray.length > 0) {
      const allTokens = tokenDetailsArray.every(
        (token) =>
          token.rarityRanking < 0 ||
          getTokenRankUI(token) === "UnRanked (No Traits)"
      );
      setAllTokensUnranked(allTokens);
    }
  }, [tokenDetailsArray]);

  return (
    <React.Fragment>
      <div
        className={`${
          isMobile
            ? !isBiggerColumnGrid
              ? "items-tab-smaller-items-container-without-filter-section"
              : "items-tab-bigger-items-container-without-filter-section"
            : !showFilterSection
            ? !isBiggerColumnGrid
              ? "items-tab-smaller-items-container-without-filter-section"
              : "items-tab-bigger-items-container-without-filter-section"
            : !isBiggerColumnGrid
            ? "items-tab-smaller-items-container-with-filter-section"
            : "items-tab-bigger-items-container-with-filter-section"
        }`}
      >
        {tokenDetailsArray?.map((token, index) => {
          return (
            <TokenCard
              pageType={pageType}
              activeTab={activeTab}
              key={index}
              token={token}
              totalCount={tokensCount}
              index={index}
              isHovered={hoveredCard === index}
              onCardHover={() => handleCardHover(index)}
              onCardLeave={handleCardLeave}
              isBiggerColumnGrid={isBiggerColumnGrid}
              allTokensUnranked={allTokensUnranked}
            />
          );
        })}
        {isFetchingData &&
          Array.from({ length: 40 }, (_, index) => (
            <div
              className="token-card-container-smaller-width token-card-container"
              key={index}
            >
              <GridLoader index={1} />
            </div>
          ))}
      </div>
    </React.Fragment>
  );
}

export default TokenGrid;
