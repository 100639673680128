import React, { useEffect } from "react";
import CurrentPriceSectionRow from "./Marketplaces/CurrentPriceSectionRow";
import useMarketplaces from "../../../utils/ItemPageUtils/useMarketplaces";
import "../../../styles/itemPageStyles/ItemPage.css";

function CurrentPrices({
  tokenListingsPricesAPIData,
  selectedMarketplaceForListing,
  itemData,
}) {
  useEffect(() => {}, [tokenListingsPricesAPIData]);

  return (
    <div className="current-prices-container">
      <>
        {selectedMarketplaceForListing.map((marketPlace) => {
          return (
            tokenListingsPricesAPIData?.pricing?.hasOwnProperty(
              marketPlace
            ) && (
              <CurrentPriceSectionRow
                marketplacePricingObj={
                  tokenListingsPricesAPIData?.pricing[marketPlace]
                }
                marketPlace={marketPlace}
                itemData={itemData}
              />
            )
          );
        })}
      </>
    </div>
  );
}

export default CurrentPrices;
