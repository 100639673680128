import React, { useEffect, useState } from "react";
import {
  fetchCollectionInfoBoxData,
  fetchListingTokens,
} from "../../api/collectionDetailsApi";
import LoadingText from "../../utils/commonUtils/LoadingText";
import { valueToPercentage } from "../../utils/commonUtils/PrettyString";
import {
  getDaysAgoText,
  getTimeAgoFromDate,
} from "../../utils/commonUtils/timeUtils";
import {
  formatNumber,
  prettyPrice,
  prettyWeiToEth,
  weiToEth,
} from "../../utils/commonUtils/PriceUtils";
import TokenListingsModal from "./TokenListingsModal";

const InfoBox = ({ collectionData }) => {
  const [collectionInfoBoxData, setCollectionInfoBoxData] = useState(null);
  const [isFetchgInfoBoxData, setIsFetchgInfoBoxData] = useState(false);
  const [openTokenListingModal, setopenTokenListingModal] = useState(false);

  useEffect(() => {
    if (!collectionData?.contractAddress) return;

    setIsFetchgInfoBoxData(true);
    fetchCollectionInfoBoxData(collectionData.contractAddress)
      .then((data) => {
        if (data) {
          if (!collectionData?.totalVolume) data.totalVolume = 0;
          else data.totalVolume = collectionData.totalVolume;
          setCollectionInfoBoxData(data);
        }
      })
      .catch((error) => {
        console.error("Error fetching collection info box data:", error);
      })
      .finally(() => {
        setIsFetchgInfoBoxData(false);
      });
  }, [collectionData?.contractAddress]);

  return (
    <>
      <div className="collection-info-box-container-with-time">
        <div className="collection-info-box-container">
          <div className="collection-info-box">
            <span className="collection-info-box-values">
              {!isFetchgInfoBoxData ? (
                weiToEth(collectionInfoBoxData?.totalVolume || 0) + " ETH"
              ) : (
                <LoadingText height="1vw" width="12vw" />
              )}
            </span>
            <span className="collection-info-box-headings">Total Volume </span>
          </div>
          <span className="collection-info-box-division"> </span>
          <div
            className="collection-info-box-floor-price"
            onClick={() => {
              setopenTokenListingModal(true);
            }}
          >
            <span className="collection-info-box-values">
              {!isFetchgInfoBoxData ? (
                collectionInfoBoxData?.floorPrice ? (
                  prettyPrice(collectionInfoBoxData?.floorPrice) + " ETH"
                ) : (
                  "None Listed"
                )
              ) : (
                <LoadingText height="1vw" width="12vw" />
              )}
            </span>
            <span className="collection-info-box-headings">Floor Price</span>
          </div>
          <span className="collection-info-box-division"> </span>
          <div className="collection-info-box">
            <span className="collection-info-box-values">
              {!isFetchgInfoBoxData ? (
                collectionInfoBoxData?.bestCollectionOffer &&
                (collectionInfoBoxData?.bestCollectionOffer || 0) > 0 ? (
                  prettyPrice(collectionInfoBoxData.bestCollectionOffer) +
                  " ETH"
                ) : (
                  "No Offers"
                )
              ) : (
                <LoadingText height="1vw" width="12vw" />
              )}
            </span>
            <span className="collection-info-box-headings">
              Best Collection Offer
            </span>
          </div>
          <span className="collection-info-box-division"> </span>
          <div className="collection-info-box">
            <span className="collection-info-box-values">
              {collectionInfoBoxData?.listingCount && collectionData?.tokensSize
                ? valueToPercentage(
                    collectionInfoBoxData?.listingCount || 0,
                    collectionData?.tokensSize || 0
                  )
                : "None Listed"}
            </span>
            <span className="collection-info-box-headings">Listed</span>
          </div>
          <span className="collection-info-box-division"> </span>
          <div className="collection-info-box">
            <div className="d-flex gap-2">
              <span className="collection-info-box-values">
                {!isFetchgInfoBoxData ? (
                  formatNumber(collectionInfoBoxData?.uniqueOwners || 0)
                ) : (
                  <LoadingText height="1vw" width="6vw" />
                )}
              </span>
              <span className="collection-info-box-headings">Owners</span>
            </div>
            <div className="collection-info-box-unique-owners-row">
              <span className="collection-info-box-unique-owners-value">
                {isFetchgInfoBoxData
                  ? (collectionData?.tokensSize || 0) > 0
                    ? (
                        ((collectionInfoBoxData?.uniqueOwners || 0) * 100) /
                        collectionData?.tokensSize
                      ).toFixed(2) + " %"
                    : 0
                  : "Fetching"}
              </span>
              <span className="collection-info-box-headings">
                Unique Owners
              </span>
            </div>
          </div>
        </div>
        <div className="collection-info-box-time">
          Last Updated:{" "}
          {getTimeAgoFromDate(collectionInfoBoxData?.updated) ||
            "Not Yet Updated"}
        </div>
      </div>
      {openTokenListingModal && (
        <TokenListingsModal
          collectionInfoBoxData={collectionInfoBoxData}
          collectionData={collectionData}
          onClose={() => setopenTokenListingModal(false)}
          showModal={openTokenListingModal}
        />
      )}
    </>
  );
};

export default InfoBox;
