import React, { useContext, useEffect, useRef, useState } from "react";
import HeaderDesktop from "../components/Homepage/Header/HeaderDesktop";
import Background from "../components/UtilComponents/Background";
import ItemsTabSection from "../components/CollectionPage/ItemsTabSection";
import AnalysisTabSection from "../components/CollectionPage/AnalysisTabSection";
import SectionTab from "../components/CollectionPage/SectionTabs";
import "../styles/creatorPageStyles/CreatorPage.css";
import "../styles/collectionPageStyles/CollectionPage.css";
import "../styles/collectionPageStyles/TokenGrid.css";
import {
  fetchWalletDetails,
  fetchTokensMintedByWallet,
  fetchWalletTransferHistoryDetails,
  fetchTokensOwnedByWallet,
  fetchListingCountForAllTokenForUser,
  getUserOwnedTokensCount,
} from "../api/walletDetailApi";
import ScrollToTopArrow from "../Icons/CollectionPageIcons/ScrollToTopArrow.js";
import {
  CreatorFilterSectionContext,
  initialCreatorPageContext,
} from "../contexts/CreatorPage.js/FilterSectionContext.js";
import FooterSection from "../components/FooterSection.js";
import { Helmet } from "react-helmet";
import { getWalletProfileName } from "../utils/commonUtils/WalletDetailsUtils.js";
import useIsMobile from "../customHooks/UseIsMobile.js";

export default function CreatorPage({ walletAddress }) {
  const isMobile = useIsMobile();
  const isFirstRender = useRef(true);
  const { creatorPageFilters, updateCreatorPageFilters } = useContext(
    CreatorFilterSectionContext
  );
  const [walletData, setWalletData] = useState(null);
  const [activeTab, setActiveTab] = useState("Owned_Items");
  const [tokensOwnedArray, setTokensOwnedArray] = useState([]);
  const [transferHistory, setTransferHistory] = useState([]);
  const [isFetchingData, setIsFetchingData] = useState(false);
  const [isFetchingActivity, setIsFetchingActivity] = useState(false);
  const [showScrollButton, setShowScrollButton] = useState(false);
  const [creatorOwnedTokensCount, setCreatorOwnedTokensCount] = useState("");
  const [listedTokensCount, setListedTokensCount] = useState(0);
  const [listedTokensCountObject, setListedTokensCountObject] = useState({
    creatorListedCount: 0,
    ownerListedCount: 0,
    minterListedCount: 0,
  });
  const [tokensOwnedPaginatedAPIMetadata, setTokensOwnedPaginatedAPIMetadata] =
    useState({
      more: true,
      currentPage: 0,
      limit: 40,
      currentCount: 0,
    });
  const [
    transferHistoryPaginatedAPIMetadata,
    setTransferHistoryPaginatedAPIMetadata,
  ] = useState({
    more: true,
    currentPage: 0,
    limit: 32,
    currentCount: 0,
  });
  const [activitySortingState, setActivitySortingState] = useState({
    activitySortText: "Transfer Date: Newest to Oldest",
    activitySortKey: "event_timestamp",
    activitySortDirection: -1,
  });

  function handleTabClick(index) {
    setActiveTab(index);
  }

  function handleScroll() {
    const scrollThreshold = 200;
    setShowScrollButton(window.scrollY > scrollThreshold);
  }

  function scrollToTop() {
    window.scrollTo({ top: 1600, behavior: "smooth" });
  }

  // async function fetchPaginatedTokensOwnedByWallet(refetchData) {
  //   if (refetchData === false && !tokensOwnedPaginatedAPIMetadata.more) return;
  //   if (isFetchingData) return;
  //   setIsFetchingData(true);
  //   let traitsArray = [];
  //   Object.keys(creatorPageFilters?.ownedTokenSelectedTraitValues).map(
  //     (traitType) => {
  //       creatorPageFilters?.ownedTokenSelectedTraitValues[traitType].map(
  //         (traitValue) => {
  //           traitsArray.push({ traitType, traitValue });
  //         }
  //       );
  //     }
  //   );
  //   let searchQueryTokensSelectedIds = Object.values(
  //     creatorPageFilters.searchQueryTokensSelected || {}
  //   ).map((obj) => obj.contractAddress + "-" + obj.tokenId);
  //   fetchTokensOwnedByWallet(
  //     [walletAddress],
  //     searchQueryTokensSelectedIds,
  //     null,
  //     null,
  //     traitsArray,
  //     creatorPageFilters.ownedTokenStatus,
  //     creatorPageFilters.listingPriceFilter,
  //     false,
  //     refetchData === true
  //       ? 1
  //       : tokensOwnedPaginatedAPIMetadata.currentPage + 1,
  //     tokensOwnedPaginatedAPIMetadata.limit,
  //     creatorPageFilters?.ownedTokensSortKey,
  //     creatorPageFilters?.ownedTokensSortDirection
  //   )
  //     .then((response) => {
  //       if (refetchData) setTokensOwnedArray(response.tokensOwned);
  //       else {
  //         setTokensOwnedArray((prevTokens) => [
  //           ...prevTokens,
  //           ...response.tokensOwned,
  //         ]);
  //       }
  //       const limit = tokensOwnedPaginatedAPIMetadata.limit;
  //       const resCount = response?.tokensOwned?.length;
  //       let more = true;
  //       if (!resCount || resCount < limit) more = false;
  //       setTokensOwnedPaginatedAPIMetadata({
  //         ...tokensOwnedPaginatedAPIMetadata,
  //         more: more,
  //         currentPage: response.page,
  //         currentCount:
  //           tokensOwnedPaginatedAPIMetadata.currentCount + response.count,
  //       });
  //     })
  //     .catch((error) => {
  //       console.error(error);
  //     })
  //     .finally(() => {
  //       setIsFetchingData(false);
  //     });
  // }

  async function fetchPaginatedTokensOwnedByWallet(refetchData) {
    if (refetchData === false && !tokensOwnedPaginatedAPIMetadata.more) return;
    if (isFetchingData) return;
    setIsFetchingData(true);
    let traitsArray = [];
    Object.keys(creatorPageFilters?.ownedTokenSelectedTraitValues).forEach(
      (traitType) => {
        creatorPageFilters?.ownedTokenSelectedTraitValues[traitType].forEach(
          (traitValue) => {
            traitsArray.push({ traitType, traitValue });
          }
        );
      }
    );
    let searchQueryTokensSelectedIds = Object.values(
      creatorPageFilters.searchQueryTokensSelected || {}
    ).map((obj) => {
      return {
        contractAddress: obj?.contractAddress,
        decimalTokenId: obj?.openseaDetails?.identifier || "",
      };
    });
    try {
      const response = await fetchTokensOwnedByWallet(
        [walletAddress],
        searchQueryTokensSelectedIds,
        null,
        null,
        traitsArray,
        creatorPageFilters.ownedTokenStatus,
        creatorPageFilters.listingPriceFilter,
        false,
        refetchData === true
          ? 1
          : tokensOwnedPaginatedAPIMetadata.currentPage + 1,
        tokensOwnedPaginatedAPIMetadata.limit,
        creatorPageFilters?.ownedTokensSortKey,
        creatorPageFilters?.ownedTokensSortDirection
      );
      if (refetchData) {
        setTokensOwnedArray(response.tokensOwned);
      } else {
        setTokensOwnedArray((prevTokens) => [
          ...prevTokens,
          ...response.tokensOwned,
        ]);
      }
      const limit = tokensOwnedPaginatedAPIMetadata.limit;
      const resCount = response?.tokensOwned?.length;
      let more = true;
      if (!resCount || resCount < limit) more = false;
      setTokensOwnedPaginatedAPIMetadata({
        ...tokensOwnedPaginatedAPIMetadata,
        more: more,
        currentPage: response.page,
        currentCount:
          tokensOwnedPaginatedAPIMetadata.currentCount + response.count,
      });
    } catch (error) {
      console.error(error);
      alert("Error fetching tokens owned by wallet");
    } finally {
      setIsFetchingData(false);
    }
  }

  async function fetchPaginatedTransferHistory(refetchData) {
    if (refetchData === false && !transferHistoryPaginatedAPIMetadata.more)
      return;
    if (isFetchingActivity) return;

    setIsFetchingActivity(true);
    fetchWalletTransferHistoryDetails(
      [walletAddress],
      creatorPageFilters?.activityStatus,
      refetchData === true
        ? 1
        : transferHistoryPaginatedAPIMetadata.currentPage + 1,
      transferHistoryPaginatedAPIMetadata.limit,
      activitySortingState?.activitySortKey || "event_timestamp",
      activitySortingState?.activitySortDirection || -1
    )
      .then((response) => {
        refetchData === true
          ? setTransferHistory(response.nftTransfers || [])
          : setTransferHistory((prevTransferHistory) => [
              ...prevTransferHistory,
              ...(response.nftTransfers || []),
            ]);
        const limit = transferHistoryPaginatedAPIMetadata.limit;
        const resCount = response?.nftTransfers?.length;
        let more = true;
        if (!resCount || resCount < limit) more = false;
        setTransferHistoryPaginatedAPIMetadata({
          ...transferHistoryPaginatedAPIMetadata,
          more: more,
          currentPage: parseInt(response.page),
          currentCount:
            transferHistoryPaginatedAPIMetadata.currentCount + response.count,
        });
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setIsFetchingActivity(false);
      });
  }

  useEffect(() => {
    if (!isFirstRender.current) fetchPaginatedTokensOwnedByWallet(true);
  }, [creatorPageFilters?.ownedTokenSelectedTraitValues]);

  useEffect(() => {
    if (isFirstRender.current) return;
    setTokensOwnedArray([]);
    fetchPaginatedTokensOwnedByWallet(true);
  }, [
    creatorPageFilters.ownedTokenStatus,
    creatorPageFilters.ownedTokensSortText,
    creatorPageFilters.ownedTokensSortKey,
    creatorPageFilters.ownedTokensSortDirection,
    creatorPageFilters.listingPriceFilter,
  ]);

  useEffect(() => {
    if (!isFirstRender.current) fetchPaginatedTokensOwnedByWallet(true);
  }, [creatorPageFilters.searchQueryTokensSelected]);

  useEffect(() => {
    if (!isFirstRender.current) fetchPaginatedTransferHistory(true);
  }, [creatorPageFilters.activityStatus, activitySortingState]);

  useEffect(() => {
    if (!walletAddress) return;
    fetchWalletDetails(walletAddress, true, true)
      .then((walletDetail) => {
        setWalletData(walletDetail);
      })
      .catch((error) => {
        setWalletData({});
        console.error(error);
      });
    fetchPaginatedTokensOwnedByWallet(true);
    fetchPaginatedTransferHistory(true);
    if (isFirstRender.current) {
      isFirstRender.current = false;
    }
  }, [walletAddress]);

  useEffect(() => {
    if (activeTab === "Created_Items") {
      setListedTokensCount(listedTokensCountObject?.creatorListedCount);
    } else if (activeTab === "Minted_Items") {
      setListedTokensCount(listedTokensCountObject?.minterListedCount);
    } else if (activeTab === "Owned_Items") {
      setListedTokensCount(listedTokensCountObject?.ownerListedCount);
    }
  }, [activeTab, listedTokensCountObject, walletAddress]);

  useEffect(() => {
    if (creatorPageFilters.creator !== walletAddress) {
      updateCreatorPageFilters({
        type: "REPLACE",
        payload: { creator: walletAddress, ...initialCreatorPageContext },
      });
    }
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (walletAddress) {
      getUserOwnedTokensCount(walletAddress)
        .then((owned_count) => {
          setCreatorOwnedTokensCount(owned_count);
        })
        .catch((error) => {
          setCreatorOwnedTokensCount(0);
        });
    }
  }, [walletAddress]);

  return (
    <div>
      <Background>
        <Helmet>
          <title>
            {getWalletProfileName(walletData, null, true) || walletAddress}
          </title>
          <meta
            name="description"
            content="Welcome to the NFT Creator Page! On this page, you'll find detailed information about your all NFT creator's and Artists on Blockchain!!"
          />
        </Helmet>
        <HeaderDesktop walletAddress={walletAddress} />
        <React.Fragment>
          <div
            className="section-tab-container"
            style={{
              marginTop: isMobile ? "20vw" : "5.5vw",
            }}
          >
            <SectionTab
              // label={`Collected (${creatorOwnedTokensCount})`}
              label={`Collected`}
              active={activeTab === "Owned_Items"}
              onClick={() => handleTabClick("Owned_Items")}
              info="The 'Collected' tab displays all NFT tokens owned/collected by the creator."
            />
            <SectionTab
              label="Activity"
              active={activeTab === "Activity"}
              onClick={() => handleTabClick("Activity")}
              info="The 'Activity' tab displays all NFT transfers where creator was involved."
            />
          </div>

          <div className="items-container-with-filter-sections-container">
            {(activeTab === "Owned_Items" ||
              activeTab === "Created_Items" ||
              activeTab === "Minted_Items") && (
              <ItemsTabSection
                pageType={"CreatorPage"}
                activeTab={activeTab}
                setDataArray={setTokensOwnedArray}
                fetchPaginatedData={fetchPaginatedTokensOwnedByWallet}
                dataArray={tokensOwnedArray}
                aggregated_traits={
                  activeTab === "Owned_Items"
                    ? walletData?.ownerTokenAggregatedTraits
                    : activeTab === "Created_Items"
                    ? walletData?.creatorTokenAggregatedTraits
                    : walletData?.minterTokenAggregatedTraits
                }
                dataTotalSize={
                  activeTab === "Owned_Items"
                    ? walletData?.ownerTokensSize
                    : activeTab === "Created_Items"
                    ? walletData?.creatorTokensSize
                    : walletData?.minterTokensSize
                }
                walletData={walletData}
                isFetchingData={isFetchingData}
                listedTokensCount={listedTokensCount}
                setListedTokensCount={setListedTokensCount}
              />
            )}
          </div>
          {activeTab === "Activity" && (
            <ItemsTabSection
              pageType={"CreatorPage"}
              activeTab={activeTab}
              setDataArray={setTransferHistory}
              fetchPaginatedData={fetchPaginatedTransferHistory}
              dataArray={transferHistory}
              aggregated_traits={{}}
              dataTotalSize={0}
              walletData={walletData}
              isFetchingData={isFetchingActivity}
              listedTokensCount={listedTokensCount}
              setListedTokensCount={setListedTokensCount}
              activitySortingState={activitySortingState}
              setActivitySortingState={setActivitySortingState}
            />
          )}
          {activeTab === "Analysis" && <AnalysisTabSection />}
        </React.Fragment>
        <div
          style={{
            display: "flex",
            height: "100px",
            justifyContent: "flex-end",
            alignItems: "end",
            position: "fixed",
            bottom: "0",
            right: "0",
          }}
        >
          <button
            className={`scroll-to-top-button ${
              showScrollButton ? "scroll-to-top-button-visible" : ""
            }`}
            onClick={scrollToTop}
          >
            <ScrollToTopArrow />
          </button>
        </div>
        <FooterSection />
      </Background>
    </div>
  );
}
