import React from "react";
import "../../../styles/itemPageStyles/ItemPage.css";
import ItemPageContentBox from "../../../utils/ItemPageUtils/ItemPageContentBox";
import ComingSoon from "../../UtilComponents/ComingSoon";
const Scorecard = () => {
  return (
    <ItemPageContentBox>
      <div className="scorecard-header">
        <span className="scorecard-heading "> Scorecard</span>
      </div>
      <ComingSoon styleObj={{ marginBottom: "10%" }} />
    </ItemPageContentBox>
  );
};

export default Scorecard;
