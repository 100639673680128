import React from "react";

const UploadIcon = ({ color }) => {
  return (
    <div>
      <svg
        width="1.5em"
        height="1.5em"
        viewBox="0 0 42 38"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M28 22.624L21.5 16.124L15 22.624L16.6307 24.2547L20.3469 20.5385V37.8488H22.6531V20.5385L26.3693 24.2547L28 22.624Z"
          fill="#747474"
          fill-opacity="0.45"
        />
        <path
          d="M33.599 13.2268V13.0996C33.599 6.15234 27.9467 0.5 20.9994 0.5C14.0521 0.5 8.39976 6.15234 8.39976 13.0996V13.3176C6.32617 13.7089 4.41459 14.7046 2.90544 16.1796C1.98704 17.0709 1.25672 18.1374 0.757676 19.316C0.258629 20.4945 0.000991518 21.7612 0 23.041C0 25.7639 1.11892 28.3576 3.15078 30.3444C5.1361 32.2857 7.81685 33.3991 10.5058 33.3991H16.0995V30.5992H10.5058C6.40079 30.5992 2.79992 27.0673 2.79992 23.041C2.79992 19.3427 5.91833 16.2236 9.8992 15.9402L11.1997 15.8475V13.0996C11.1997 10.5006 12.2321 8.008 14.07 6.17019C15.9078 4.33239 18.4004 3.29992 20.9994 3.29992C23.5984 3.29992 26.091 4.33239 27.9288 6.17019C29.7667 8.008 30.7991 10.5006 30.7991 13.0996V15.9031L32.1816 15.9206C36.2478 15.972 39.1989 18.9666 39.1989 23.041C39.1989 27.2792 36.0792 30.5992 32.0967 30.5992H25.8993V33.3991H32.0967C33.4306 33.4035 34.7509 33.1299 35.9732 32.5958C37.1956 32.0617 38.2932 31.2788 39.1963 30.2969C41.0035 28.3539 41.9988 25.7771 41.9988 23.041C41.9988 17.9279 38.474 13.9214 33.599 13.2268Z"
          fill="#747474"
          fill-opacity="0.45"
        />
      </svg>
    </div>
  );
};

export default UploadIcon;
