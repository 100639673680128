import Cookies from "js-cookie";

const initialState = {
  loggedIn: false,
  walletAddress: "",
  userInfo: {},
};
const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case "UPDATE_USER_INFO": {
      return {
        ...state,
        userInfo: action.payload,
      };
    }
    case "LOGIN": {
      const jwtToken = action.payload.jwtToken;
      Cookies.set("jwt-token", jwtToken, { expires: 7, secure: false }); //TODO: change secure to true in production
      return {
        ...state,
        loggedIn: true,
        userInfo: action.payload,
      };
    }
    case "LOGOUT": {
      Cookies.remove("jwt-token");
      return {
        ...state,
        loggedIn: false,
        userInfo: {},
      };
    }
    case "UPDATE_WALLET_ADDRESS": {
      return {
        ...state,
        walletAddress: action.payload,
      };
    }
    default:
      return state;
  }
};

export default userReducer;
