export function prettyWalletAddress(walletAddress) {
  if (!walletAddress) return "Fetching";
  return walletAddress?.length > 30
    ? walletAddress.substring(0, 4) +
        "..." +
        walletAddress.substring(walletAddress.length - 4)
    : walletAddress;
}

export function valueToPercentage(value, total) {
  if (!value || !total) return "0 %";
  return ((value / total) * 100).toFixed(2) + " %";
}

export function prettyName(name) {
  if (!name) return "Fetching";
  return name.length > 23
    ? name.slice(0, 18) + "..." + name.slice(name.length - 5, name.length)
    : name;
}
