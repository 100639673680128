import React, { useEffect, useRef } from "react";
import useIsMobile from "../../../customHooks/UseIsMobile.js";
import RemoveButton from "../../../Icons/CollectionPageIcons/RemoveButton";
import {
  fetchTokenDefaultImageOnError,
  fetchImageURLFromTokenObj,
  getDefaultImageUrl,
  fetchOriginalImageURLFromTokenObj,
} from "../../../utils/commonUtils/TokenUtils.js";
import ImgSrcMissingImg from "../../../assets/images/imageSRCmissing.png";

const ImageEnlargeModal = ({ closeModal, itemData, openModal, imageUrl }) => {
  const modalRef = useRef(null);

  useEffect(() => {
    const handleOutsideTap = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        closeModal();
      }
    };

    if (openModal) {
      document.addEventListener("mousedown", handleOutsideTap);
    }

    return () => {
      document.removeEventListener("mousedown", handleOutsideTap);
    };
  }, [openModal]);

  const isMobile = useIsMobile();
  return (
    <div className="modal-overlay">
      <div
        className={
          isMobile
            ? "modal-component-image-enlarge-mobile"
            : "modal-component-image-enlarge"
        }
        ref={modalRef}
      >
        <div className="modal-header">
          <div className="modal-header-title">
            {imageUrl ? "Image" : "Token Image"}
          </div>
          <button className="close-button" onClick={closeModal}>
            <RemoveButton color={"black"} />
          </button>
        </div>
        <img
          src={
            imageUrl ? imageUrl : fetchOriginalImageURLFromTokenObj(itemData)
          }
          onError={(event) => {
            event.target.src = ImgSrcMissingImg;
          }}
          alt="Token Img"
          style={{ height: "100%", width: "100%" }}
        />
      </div>
    </div>
  );
};

export default ImageEnlargeModal;
