import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css"; // Import the styles for react-datepicker
import UploadIcon from "../../Icons/UploadIcon.js";
import "../../font.css";
import {
  addProvenanceToMultipleTokens,
  removeUploadedImageFromProvenanceApi,
  updateTokenProvenance,
} from "../../api/tokenDetailsApi.js";
import AlertPopup from "../../utils/commonUtils/AlertPopup.js";
import RemoveButton from "../../Icons/CollectionPageIcons/RemoveButton.js";
import useIsMobile from "../../customHooks/UseIsMobile.js";
import { deepCopyObj } from "../../utils/commonUtils/Utils.js";

const EditProvenanceDetailsSection = ({
  heading,
  tokenProvenanceFormData,
  setTokenProvenanceFormData,
  selectedProvenanceTokens,
  setSelectedProvenanceTokens,
  setShowAPISuccessAlert,
  setShowAPIFailureAlert,
  setIsFetching,
  setTokenProvenance,
}) => {
  const isMobile = useIsMobile();

  const handleChange = (e, formIndex, label) => {
    const { type } = e.target;
    let newValue;

    if (type === "file") {
      const newValue = Array.from(e.target.files);

      setTokenProvenanceFormData((prevTokenProvenanceFormData) => {
        const newTokenProvenanceFormData = deepCopyObj(
          prevTokenProvenanceFormData
        );

        if (newTokenProvenanceFormData?.[heading]?.["data"]?.[formIndex]) {
          newTokenProvenanceFormData[heading]["data"][formIndex][label] = [
            ...newTokenProvenanceFormData[heading]["data"][formIndex][label],
            ...newValue,
          ];
        }
        return newTokenProvenanceFormData;
      });
    } else {
      const { value } = e.target;
      newValue = value;

      setTokenProvenanceFormData((prevTokenProvenanceFormData) => {
        const newTokenProvenanceFormData = deepCopyObj(
          prevTokenProvenanceFormData
        );

        if (newTokenProvenanceFormData?.[heading]?.["data"]?.[formIndex])
          newTokenProvenanceFormData[heading]["data"][formIndex][label] =
            newValue;
        return newTokenProvenanceFormData;
      });
    }
  };

  const handleDateChange = (date, formIndex, label) => {
    setTokenProvenanceFormData((prevTokenProvenanceFormData) => {
      const newTokenProvenanceFormData = deepCopyObj(
        prevTokenProvenanceFormData
      );

      if (newTokenProvenanceFormData?.[heading]?.["data"]?.[formIndex])
        newTokenProvenanceFormData[heading]["data"][formIndex][label] = date;
      return newTokenProvenanceFormData;
    });
  };

  const removeImageToUploadFromFormArray = (formIndex, imageIndex) => {
    setTokenProvenanceFormData((prevTokenProvenanceFormData) => {
      const newTokenProvenanceFormData = deepCopyObj(
        prevTokenProvenanceFormData
      );

      // Filter out the image at imageIndex from the ImagesToUpload array
      const updatedImagesToUpload = newTokenProvenanceFormData[heading]["data"][
        formIndex
      ]["ImagesToUpload"].filter((item, index) => index !== imageIndex);

      newTokenProvenanceFormData[heading]["data"][formIndex]["ImagesToUpload"] =
        updatedImagesToUpload;
      return newTokenProvenanceFormData;
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    setIsFetching(true);

    let categoryName = heading;
    if (selectedProvenanceTokens.length === 1) {
      let contractAddress = selectedProvenanceTokens[0]._id.split("-")[0];
      let tokenId = selectedProvenanceTokens[0]._id.split("-")[1];

      updateTokenProvenance(
        contractAddress,
        tokenId,
        categoryName,
        tokenProvenanceFormData[heading]["data"],
        tokenProvenanceFormData[heading]["notSendToBackend"],
        null
      )
        .then((updatedCategoryProvenance) => {
          setTokenProvenance(
            updatedCategoryProvenance
              ? updatedCategoryProvenance[0]?.data || []
              : []
          );

          setShowAPISuccessAlert(true);
          setTimeout(() => {
            setShowAPISuccessAlert(false);
          }, 1500);
        })
        .catch((e) => {
          setShowAPIFailureAlert(true);
          setTimeout(() => {
            setShowAPIFailureAlert(false);
          }, 1500);
        })
        .finally(() => {
          setIsFetching(false);
        });
    } else {
      let contractTokenIds = selectedProvenanceTokens.map((token) => token._id);

      addProvenanceToMultipleTokens(
        contractTokenIds,
        categoryName,
        tokenProvenanceFormData[heading]["data"],
        tokenProvenanceFormData[heading]["notSendToBackend"]
      )
        .then((updatedCategoryProvenance) => {
          setShowAPISuccessAlert(true);
          setTimeout(() => {
            setShowAPISuccessAlert(false);
          }, 1500);
        })
        .catch((e) => {
          setShowAPIFailureAlert(true);
          setTimeout(() => {
            setShowAPIFailureAlert(false);
          }, 1500);
        })
        .finally(() => {
          setIsFetching(false);
        });
    }
  };

  const handleRemoveForm = (formIndex) => {
    if (
      tokenProvenanceFormData &&
      tokenProvenanceFormData[heading]?.data &&
      tokenProvenanceFormData[heading]?.data[formIndex] &&
      tokenProvenanceFormData[heading]?.data[formIndex]?.Images &&
      tokenProvenanceFormData[heading]?.data[formIndex].Images.length > 0
    ) {
      return;
    }

    setTokenProvenanceFormData((prevTokenProvenanceFormData) => {
      const newTokenProvenanceFormData = deepCopyObj(
        prevTokenProvenanceFormData
      );

      newTokenProvenanceFormData[heading]["data"].splice(formIndex, 1);
      return newTokenProvenanceFormData;
    });
  };

  const addForm = () => {
    setTokenProvenanceFormData((prevTokenProvenanceFormData) => {
      const newTokenProvenanceFormData = deepCopyObj(
        prevTokenProvenanceFormData
      );

      newTokenProvenanceFormData[heading]["data"] = [
        ...newTokenProvenanceFormData[heading]["data"],
        { ...newTokenProvenanceFormData[heading]["emptyForm"] },
      ];
      return newTokenProvenanceFormData;
    });
  };

  let removeUploadedImageFromProvenance = (
    categoryName,
    formIndex,
    imageDataToDelete
  ) => {
    if (selectedProvenanceTokens.length === 1) {
      setIsFetching(true);

      let contractAddress = selectedProvenanceTokens[0]._id.split("-")[0];
      let tokenId = selectedProvenanceTokens[0]._id.split("-")[1];

      removeUploadedImageFromProvenanceApi(
        contractAddress + "-" + tokenId,
        categoryName,
        formIndex,
        imageDataToDelete
      )
        .then((updatedCategoryProvenance) => {
          if (updatedCategoryProvenance)
            setTokenProvenance(updatedCategoryProvenance);

          setShowAPISuccessAlert(true);
          setTimeout(() => {
            setShowAPISuccessAlert(false);
          }, 1500);
        })
        .catch((e) => {
          setShowAPIFailureAlert(true);
          setTimeout(() => {
            setShowAPIFailureAlert(false);
          }, 1500);
        })
        .finally(() => {
          setIsFetching(false);
        });
    }
  };

  return (
    <div>
      <div>
        <div className="add-provenance-details-main-section">
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <span className="add-provenance-details-heading">
              {!isMobile && heading}
            </span>

            <div
              onClick={addForm}
              className={
                !isMobile
                  ? "add-provenance-details-add-button"
                  : "add-provenance-details-add-button-mobile"
              }
            >
              <span>+</span>
            </div>
          </div>

          {tokenProvenanceFormData?.[heading]?.["data"].map(
            (formData, formIndex) => {
              return (
                <form className="add-provenance-details-form" key={formIndex}>
                  {Object.keys(tokenProvenanceFormData[heading]["emptyForm"])
                    .filter(
                      (category) =>
                        !tokenProvenanceFormData[heading][
                          "helperLabels"
                        ]?.includes(category)
                    )
                    .map((label, labelIndex) => (
                      <div key={labelIndex}>
                        <label className="add-provenance-details-form-label">
                          {label?.includes("Date") ? (
                            <>
                              <div>{label}</div>
                              <select
                                value={formData[label + " Format"]}
                                onChange={(e) =>
                                  handleChange(e, formIndex, label + " Format")
                                }
                                className="sales-history-filterdata-dropdown"
                                required
                              >
                                <option value="MM/dd/yyyy">MM/dd/yyyy</option>
                                <option value="MM/yyyy">MM/yyyy</option>
                                <option value="yyyy">yyyy</option>
                              </select>
                            </>
                          ) : (
                            label
                          )}
                        </label>
                        {label === "Images" ? (
                          <>
                            <div className="add-provenance-details-form-file-input">
                              <div>
                                <UploadIcon />
                              </div>
                              {
                                <>
                                  <label
                                    htmlFor={`file-upload-${formIndex}`}
                                    className="custom-file-upload"
                                  >
                                    Choose Files
                                  </label>

                                  <input
                                    id={`file-upload-${formIndex}`}
                                    type="file"
                                    className="hidden"
                                    accept=".jpeg, .jpg, .png,"
                                    multiple
                                    onChange={(e) => {
                                      handleChange(
                                        e,
                                        formIndex,
                                        "ImagesToUpload"
                                      );
                                    }}
                                  />
                                </>
                              }
                            </div>
                            <span>
                              {formData["ImagesToUpload"] &&
                                formData["ImagesToUpload"].length > 0 && (
                                  <span className="add-provenance-details-form-uploaded-images">
                                    <span className="add-provenance-details-form-label">
                                      Files To Upload:
                                    </span>
                                    {formData["ImagesToUpload"] &&
                                      formData["ImagesToUpload"].length > 0 &&
                                      formData["ImagesToUpload"].map(
                                        (image, imageIndex) => (
                                          <div
                                            key={imageIndex}
                                            style={{
                                              display: "flex",
                                              alignItems: "center",
                                              flexDirection: "row",
                                              width: "96%",
                                              marginLeft: "2%",
                                            }}
                                          >
                                            {" "}
                                            <div
                                              key={imageIndex}
                                              style={{
                                                display: "flex",
                                                alignItems: "center",
                                                gap: "0.5em",
                                                flexDirection: "row",
                                                width: "100%",
                                              }}
                                            >
                                              {/* <img
                                        src={`https://d3p2e0ks7xz82c.cloudfront.net/${image.s3Path}`}
                                        alt={image.s3Path}
                                        style={{
                                          width: "2em",
                                          height: "2em",
                                          objectFit: "cover",
                                        }}
                                      /> */}
                                              <span
                                                style={{ fontSize: "0.8em" }}
                                              >
                                                {
                                                  formData["ImagesToUpload"][
                                                    imageIndex
                                                  ].name
                                                }
                                              </span>
                                            </div>
                                            <span
                                              onClick={(e) =>
                                                removeImageToUploadFromFormArray(
                                                  formIndex,
                                                  imageIndex
                                                )
                                              }
                                            >
                                              <RemoveButton color="#b02a2a" />
                                            </span>
                                          </div>
                                        )
                                      )}
                                  </span>
                                )}
                            </span>
                            <span>
                              {formData[label] &&
                                formData[label].length > 0 && (
                                  <span className="add-provenance-details-form-uploaded-images">
                                    <span className="add-provenance-details-form-label">
                                      Files Uploaded:
                                    </span>
                                    {formData[label] &&
                                      formData[label].length > 0 &&
                                      formData[label].map(
                                        (image, imageIndex) => (
                                          <div
                                            key={imageIndex}
                                            style={{
                                              display: "flex",
                                              alignItems: "center",
                                              flexDirection: "row",
                                              width: "96%",
                                              marginLeft: "2%",
                                            }}
                                          >
                                            {" "}
                                            <div
                                              key={imageIndex}
                                              style={{
                                                display: "flex",
                                                alignItems: "center",
                                                gap: "0.5em",
                                                flexDirection: "row",
                                                width: "100%",
                                              }}
                                            >
                                              <img
                                                src={`https://d3p2e0ks7xz82c.cloudfront.net/${image.s3Path}`}
                                                alt={image.s3Path}
                                                style={{
                                                  width: "2em",
                                                  height: "2em",
                                                  objectFit: "cover",
                                                }}
                                              />
                                              <span
                                                style={{ fontSize: "0.8em" }}
                                              >
                                                {
                                                  formData["Images"][imageIndex]
                                                    .originalname
                                                }
                                              </span>
                                            </div>
                                            <span
                                              onClick={() => {
                                                removeUploadedImageFromProvenance(
                                                  heading,
                                                  formIndex,
                                                  image
                                                );
                                              }}
                                            >
                                              <RemoveButton color="#b02a2a" />
                                            </span>
                                          </div>
                                        )
                                      )}
                                  </span>
                                )}
                            </span>
                          </>
                        ) : label?.includes("Date") ? (
                          <>
                            {formData[label + " Format"] === "MM/dd/yyyy" ? (
                              <DatePicker
                                className="add-provenance-details-form--date-input"
                                selected={formData[label]}
                                onChange={(date) =>
                                  handleDateChange(date, formIndex, label)
                                }
                                dateFormat="MM/dd/yyyy"
                              />
                            ) : formData[label + " Format"] === "MM/yyyy" ? (
                              <DatePicker
                                className="add-provenance-details-form--date-input"
                                selected={formData[label]}
                                onChange={(date) =>
                                  handleDateChange(date, formIndex, label)
                                }
                                dateFormat="MM/yyyy"
                                showMonthYearPicker
                                showFullMonthYearPicker
                                showTwoColumnMonthYearPicker
                              />
                            ) : (
                              <DatePicker
                                className="add-provenance-details-form--date-input"
                                selected={formData[label]}
                                onChange={(date) =>
                                  handleDateChange(date, formIndex, label)
                                }
                                dateFormat="yyyy"
                                showYearPicker
                              />
                            )}
                          </>
                        ) : label?.includes("Cover") ? (
                          <div className="provenance-radio-buttons">
                            <input
                              type="radio"
                              id={`${label}-yes`}
                              name={`${label}-${formIndex}`}
                              value="yes"
                              checked={formData[label] === "yes"}
                              onChange={(e) =>
                                handleChange(e, formIndex, label)
                              }
                            />
                            <label htmlFor={`${label}-yes`}>Yes</label>
                            <input
                              type="radio"
                              id={`${label}-no`}
                              name={`${label}-${formIndex}`}
                              value="no"
                              checked={formData[label] === "no"}
                              onChange={(e) =>
                                handleChange(e, formIndex, label)
                              }
                            />
                            <label htmlFor={`${label}-no`}>No</label>
                          </div>
                        ) : label?.includes("Description") ? (
                          <textarea
                            className="add-provenance-details-form-input"
                            type="text"
                            name={label}
                            rows={7}
                            value={formData[label]}
                            onChange={(e) => handleChange(e, formIndex, label)}
                            required
                            style={{ resize: "none", height: "auto" }} // Set height to auto
                          />
                        ) : (
                          <input
                            className="add-provenance-details-form-input"
                            type="text"
                            name={label}
                            value={formData[label]}
                            onChange={(e) => handleChange(e, formIndex, label)}
                            required
                          />
                        )}
                      </div>
                    ))}

                  <span
                    className="remove-form-button"
                    type="button"
                    onClick={() => handleRemoveForm(formIndex)}
                  >
                    <RemoveButton color="#b02a2a" /> Remove
                  </span>
                </form>
              );
            }
          )}

          <button
            className="add-provenance-details-submit-button"
            onClick={handleSubmit}
          >
            Submit
          </button>

          <button
            className="add-provenance-details-cancel-button"
            onClick={() => {
              setSelectedProvenanceTokens([]);
            }}
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default EditProvenanceDetailsSection;
