const CloseButton = ({ black }) => {
  return (
    <>
      <svg
        width="1.5em"
        height="1.5em"
        viewBox="0 0 310 311"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#filter0_d_7077_15336)">
          <path
            d="M236.282 74.5119C231.251 69.4813 223.125 69.4813 218.094 74.5119L155.019 137.459L91.9429 74.3829C86.9123 69.3523 78.7859 69.3523 73.7554 74.3829C68.7248 79.4135 68.7248 87.5399 73.7554 92.5704L136.831 155.646L73.7554 218.722C68.7248 223.753 68.7248 231.879 73.7554 236.91C78.7859 241.94 86.9123 241.94 91.9429 236.91L155.019 173.834L218.094 236.91C223.125 241.94 231.251 241.94 236.282 236.91C241.313 231.879 241.313 223.753 236.282 218.722L173.206 155.646L236.282 92.5704C241.184 87.6688 241.184 79.4135 236.282 74.5119Z"
            fill="black"
          />
        </g>
        <defs>
          <filter
            id="filter0_d_7077_15336"
            x="65.9824"
            y="70.61"
            width="178.072"
            height="178.073"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="4" />
            <feGaussianBlur stdDeviation="2" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_7077_15336"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_7077_15336"
              result="shape"
            />
          </filter>
        </defs>
      </svg>
    </>
  );
};

export default CloseButton;
