import React, { useState, useEffect } from "react";
import Tab from "../../../utils/ItemPageUtils/Tab";
import Description from "./Description";
import Traits from "./Traits";
import Provenance from "./Provenance";
import Activity from "./Activity";
import EditIcon from "../../../Icons/EditIcon";
import useIsMobile from "../../../customHooks/UseIsMobile.js";
import InfoTabsToggleModal from "./InfoTabsToggleModal";
import { fetchTraitsFromTokenObj } from "../../../utils/commonUtils/TokenUtils";
import AddInfoModal from "../../CommonComponents/AddInfoModal";

const InfoTabs = ({
  itemData,
  collectionData,
  tokenTransferHistory,
  fetchPaginatedTransferHistory,
  isFetchingData,
  transferHistoryPaginatedAPIMetadata,
}) => {
  const [activeTab, setActiveTab] = useState(0);
  const [tabToggles, setTabToggles] = useState([true, true, true, true]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showSalesHistory, setShowSalesHistory] = useState(true);

  const isMobile = useIsMobile();

  const handleTabClick = (index) => {
    setActiveTab(index);
  };

  const toggleTab = (index) => {
    const newToggles = [...tabToggles];
    newToggles[index] = !newToggles[index];
    setTabToggles(newToggles);
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const traitsLength = fetchTraitsFromTokenObj(itemData)?.length || 0;

  const provenanceCount = itemData?.provenance?.entries || 0;
  const activityData = tokenTransferHistory?.length || 0;

  return (
    <div className={isMobile ? "info-container-mobile" : "info-container"}>
      <div className="tab-container">
        {tabToggles[0] && (
          <Tab
            label="Description"
            active={activeTab === 0}
            onClick={() => handleTabClick(0)}
          />
        )}
        {tabToggles[1] && (
          <Tab
            label={
              <div style={{ whiteSpace: "nowrap" }}>
                Traits ({traitsLength})
              </div>
            }
            active={activeTab === 1}
            onClick={() => handleTabClick(1)}
          />
        )}
        {tabToggles[2] && (
          <Tab
            label={
              <div style={{ whiteSpace: "nowrap" }}>
                Provenance ({provenanceCount})
              </div>
            }
            active={activeTab === 2}
            onClick={() => handleTabClick(2)}
          />
        )}
        {tabToggles[3] && (
          <Tab
            label={
              <div style={{ whiteSpace: "nowrap" }}>
                Activity ({activityData})
              </div>
            }
            active={activeTab === 3}
            onClick={() => handleTabClick(3)}
          />
        )}
        <div style={{ paddingBottom: "8px", width: "1em" }}>
          <AddInfoModal
            info={"Toggle Tabs"}
            renderDirection={"up-left"}
            styleObj={{ fontWeight: "bold", cursor: "pointer" }}
          >
            <span style={{ paddingBottom: "10px", cursor: "pointer" }}>
              <span onClick={() => openModal()}>
                <EditIcon />{" "}
              </span>
            </span>
          </AddInfoModal>
        </div>
      </div>
      <div className="tab-content">
        {activeTab === 0 && tabToggles[0] && (
          <Description data={itemData} collectionData={collectionData} />
        )}
        {activeTab === 1 && tabToggles[1] && (
          <Traits
            traits={fetchTraitsFromTokenObj(itemData)}
            aggregated_traits={collectionData?.aggregated_traits}
            contractAddress={itemData?.contractAddress}
            collectionSlug={collectionData?.collectionSlug}
            collectionCreator={collectionData?.collectionCreator}
          />
        )}
        {activeTab === 2 && tabToggles[2] && (
          <Provenance
            provenanceData={itemData?.provenance || {}}
            item={itemData}
          />
        )}
        {activeTab === 3 && tabToggles[3] && (
          <Activity
            tokenTransferHistory={tokenTransferHistory}
            fetchPaginatedTransferHistory={fetchPaginatedTransferHistory}
            showSalesHistory={showSalesHistory}
            transferHistoryPaginatedAPIMetadata={
              transferHistoryPaginatedAPIMetadata
            }
            isFetchingData={isFetchingData}
          />
        )}
      </div>
      {isModalOpen && (
        <InfoTabsToggleModal
          tabToggles={tabToggles}
          toggleTab={toggleTab}
          closeModal={closeModal}
          openModal={openModal}
        />
      )}
    </div>
  );
};

export default InfoTabs;
