import Axios from "axios";

const serverURL = process.env.REACT_APP_BACKEND_API_BASE_URL || "";

export async function generatePDF(contractAddress, decimalTokenId, url) {
  try {
    const response = await Axios.post(`${serverURL}/pdf/generate-pdf`, {
      contractAddress,
      decimalTokenId,
      url,
    });

    if (!response.data) return null;
    return response.data.key;
  } catch (error) {
    throw new Error(`Error in generatePDF: ${error.message}`);
  }
}
