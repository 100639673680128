import React from "react";
import CreatorPage from "./CreatorPage";
import { useSelector } from "react-redux";

export default function CreatorPageParent() {
  const walletAddressFromStore = useSelector(
    (state) => state.user.walletAddress
  );

  return <CreatorPage walletAddress={walletAddressFromStore} />;
}
