// src/reducers/filterSectionReducer.js

const filterSectionReducer = (state, action) => {
  switch (action.type) {
    case "REPLACE":
      return { ...action.payload };
    case "SET_CREATOR":
      return { ...state, creator: action.payload };
    case "SET_OWNED_TOKEN_STATUS":
      return { ...state, ownedTokenStatus: action.payload };
    case "SET_CREATED_TOKEN_STATUS":
      return { ...state, createdTokenStatus: action.payload };
    case "SET_MINTED_TOKEN_STATUS":
      return { ...state, mintedTokenStatus: action.payload };
    case "SET_ACTIVITY_STATUS":
      return { ...state, activityStatus: action.payload };
    case "SET_MIN_LISTING_PRICE_FILTER":
      return { ...state, minListingPriceFilter: action.payload };
    case "SET_MAX_LISTING_PRICE_FILTER":
      return { ...state, maxListingPriceFilter: action.payload };
    case "SET_LISTING_PRICE_CURRENCY_FILTER":
      return { ...state, listingPriceCurrencyFilter: action.payload };
    case "SET_LISTING_PRICE_FILTER":
      return {
        ...state,
        listingPriceFilter: {
          minListingPriceFilter: action.payload.minListingPriceFilter,
          maxListingPriceFilter: action.payload.maxListingPriceFilter,
          listingPriceCurrencyFilter: action.payload.listingPriceCurrencyFilter,
        },
      };
    case "SET_OWNED_TOKENS_SORTING":
      return {
        ...state,
        ownedTokensSortText: action.payload.tokensSortText,
        ownedTokensSortKey: action.payload.tokensSortKey,
        ownedTokensSortDirection: action.payload.tokensSortDirection,
      };
    case "SET_CREATED_TOKENS_SORTING":
      return {
        ...state,
        createdTokensSortText: action.payload.tokensSortText,
        createdTokensSortKey: action.payload.tokensSortKey,
        createdTokensSortDirection: action.payload.tokensSortDirection,
      };
    case "SET_MINTED_TOKENS_SORTING":
      return {
        ...state,
        mintedTokensSortText: action.payload.tokensSortText,
        mintedTokensSortKey: action.payload.tokensSortKey,
        createdTokensSortDirection: action.payload.tokensSortDirection,
      };
    case "SET_OWNED_TOKENS_SELECTED_TRAITS":
      const newValues = { ...state.ownedTokenSelectedTraitValues };
      if (!newValues[action.payload.traitType]) {
        newValues[action.payload.traitType] = [];
      }

      if (newValues[action.payload.traitType].includes(action.payload.value)) {
        newValues[action.payload.traitType] = newValues[
          action.payload.traitType
        ].filter((v) => v !== action.payload.value);
      } else {
        newValues[action.payload.traitType] = [
          ...newValues[action.payload.traitType],
          action.payload.value,
        ];
      }

      return {
        ...state,
        ownedTokenSelectedTraitValues: newValues,
      };
    case "SET_CREATED_TOKENS_SELECTED_TRAITS": {
      const newValues = { ...state.createdTokensSelectedTraitValues };
      if (!newValues[action.payload.traitType]) {
        newValues[action.payload.traitType] = [];
      }

      if (newValues[action.payload.traitType].includes(action.payload.value)) {
        newValues[action.payload.traitType] = newValues[
          action.payload.traitType
        ].filter((v) => v !== action.payload.value);
      } else {
        newValues[action.payload.traitType] = [
          ...newValues[action.payload.traitType],
          action.payload.value,
        ];
      }

      return {
        ...state,
        createdTokensSelectedTraitValues: newValues,
      };
    }
    case "SET_MINTED_TOKENS_SELECTED_TRAITS": {
      const newValues = { ...state.mintedTokensSelectedTraitValues };
      if (!newValues[action.payload.traitType]) {
        newValues[action.payload.traitType] = [];
      }

      if (newValues[action.payload.traitType].includes(action.payload.value)) {
        newValues[action.payload.traitType] = newValues[
          action.payload.traitType
        ].filter((v) => v !== action.payload.value);
      } else {
        newValues[action.payload.traitType] = [
          ...newValues[action.payload.traitType],
          action.payload.value,
        ];
      }

      return {
        ...state,
        mintedTokensSelectedTraitValues: newValues,
      };
    }
    case "SET_ACTIVITY_SELECTED_TRAITS": {
      const newValues = { ...state.activitySelectedTraitValues };
      if (!newValues[action.payload.traitType]) {
        newValues[action.payload.traitType] = [];
      }

      if (newValues[action.payload.traitType].includes(action.payload.value)) {
        newValues[action.payload.traitType] = newValues[
          action.payload.traitType
        ].filter((v) => v !== action.payload.value);
      } else {
        newValues[action.payload.traitType] = [
          ...newValues[action.payload.traitType],
          action.payload.value,
        ];
      }

      return {
        ...state,
        activitySelectedTraitValues: newValues,
      };
    }
    case "SET_TOKENS_SEARCH_QUERY":
      return {
        ...state,
        searchQuery: action.payload.searchQuery,
      };
    case "ADD_TO_TOKENS_SELECTED_ON_SEARCH":
      const tokenObjToBeAdded = action.payload?.searchQueryTokensSelected;

      const newValuesSearchQueryTokensSelected = {
        ...state.searchQueryTokensSelected,
      };
      newValuesSearchQueryTokensSelected[
        tokenObjToBeAdded?.contractAddress + "-" + tokenObjToBeAdded?.tokenId
      ] = action.payload?.searchQueryTokensSelected;

      return {
        ...state,
        searchQueryTokensSelected: newValuesSearchQueryTokensSelected,
      };
    case "DELETE_FROM_TOKENS_SELECTED_ON_SEARCH":
      const tokenObjToBeDeleted = action.payload?.searchQueryTokensSelected;

      const searchQueryTokensSelectedAfterDeletion = {
        ...state.searchQueryTokensSelected,
      };

      delete searchQueryTokensSelectedAfterDeletion[
        tokenObjToBeDeleted?.contractAddress +
          "-" +
          tokenObjToBeDeleted?.tokenId
      ];

      return {
        ...state,
        searchQueryTokensSelected: searchQueryTokensSelectedAfterDeletion,
      };

    // ... other action handlers
    default:
      return state;
  }
};

export default filterSectionReducer;
