import React from "react";

const TokenRarityInfoModal = ({
  traitValue,
  tokenRarityScore,
  tokenOccurences,
  totalOccurences,
  tokenRarityScorePercentage,
}) => {
  return (
    <div className="token-rarity-modal-container">
      <div className="rarity-modal-text">
        <span className="rarity-modal-formula-area">
          <p className="rarity-modal-text">Rarity Score for {traitValue} = </p>
          <div className="fraction">
            <div className="rarity-modal-text">1</div>
            <div className="separator"></div>
            <div className="denominator">
              <div className="rarity-modal-text">
                No. of Items with {traitValue}
              </div>
              <div className="separator"></div>
              <div className="rarity-modal-text">
                Total No. of Items in Collection
              </div>
            </div>
          </div>
        </span>
        <div className="trait-value-container-rarity-modal">
          <div className="trait-value-rarity-modal">
            <span className="trait-value-rarity-modal">Rarity Score:</span>
            <span className="trait-value-rarity-modal-values">
              1 / ({tokenOccurences + " / " + totalOccurences}) ={" "}
              {tokenRarityScore}
            </span>
          </div>
        </div>
        <div className="trait-value-container-rarity-modal">
          <div className="trait-value-rarity-modal">
            <span className="trait-value-rarity-modal">Rarity Percentage:</span>
            <span className="trait-value-rarity-modal-values">
              ({tokenOccurences + " / " + totalOccurences}) * 100 ={" "}
              {tokenRarityScorePercentage}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TokenRarityInfoModal;
