import React, { useEffect, useState } from "react";
import { Link, Navigate, useParams } from "react-router-dom";
import { fetchTokenDetails } from "../../api/tokenDetailsApi";
import { fetchCollectionDetails } from "../../api/collectionDetailsApi";
import "../../styles/provenanceReportPageStyles/ProvenanceReportPage.css";
import {
  fetchImageURLFromTokenObj,
  fetchOriginalImageURLFromTokenObj,
  fetchTokenNameFromTokenObj,
} from "../../utils/commonUtils/TokenUtils";
import { getNameFromCollectionObj } from "../../utils/commonUtils/CollectionUtils";
import useIsMobile from "../../customHooks/UseIsMobile";
import { TbFileDownload } from "react-icons/tb";
import LoadingSpinner from "../../utils/commonUtils/LoadingSpinner";
import { all } from "axios";

const ProvenanceTokenDetailsSection = ({
  itemData,
  collectionData,
  isPDF,
  downloadClicked,
  imageUrl,
  setDownloadClicked,
}) => {
  const { collectionId, itemId } = useParams();

  const isMobile = useIsMobile();

  const mintingDate = new Date(
    itemData?.block_timestamp * 1000
  ).toLocaleString();

  return (
    <>
      <div
        className={
          !isMobile || isPDF
            ? "provenance-details-main-section"
            : "provenance-details-main-section-mobile"
        }
      >
        <div
          className={
            !isMobile || isPDF
              ? "provenance-item-image-details-container"
              : "provenance-item-image-details-container-mobile"
          }
        >
          <img
            src={fetchImageURLFromTokenObj(itemData)}
            alt="Token Img"
            className={"provenance-item-image-pdf "}
          />
        </div>
        <div
          className={
            !isMobile || isPDF
              ? "provenance-token-details-container"
              : "provenance-token-details-container-mobile"
          }
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <Link
              style={{ textDecoration: "none", color: "black" }}
              className="provenance-token-details-headings"
              to={`/collection/${collectionId}`}
            >
              Collection:{" "}
              <span
                style={{ marginLeft: "0.2em" }}
                className="provenance-token-collection-value"
              >
                {getNameFromCollectionObj(collectionData)}
              </span>
            </Link>

            {!isPDF &&
              (!downloadClicked ? (
                <span
                  style={{ cursor: "pointer" }}
                  title="Download Report"
                  onClick={() => {
                    setDownloadClicked(true);
                  }}
                >
                  <TbFileDownload size={"1.5em"} />
                </span>
              ) : (
                <div>
                  <LoadingSpinner styleObj={{ width: "2em", height: "2em" }} />
                </div>
              ))}
          </div>

          <Link
            className="provenance-token-name-heading"
            style={{ textDecoration: "none", color: "black" }}
            to={`/collection/${collectionId}/id/${itemId}`}
          >
            {" "}
            {fetchTokenNameFromTokenObj(itemData)}
          </Link>
          <span className="provenance-token-details-headings">
            Created By{" "}
            <span
              style={{ marginLeft: "0.2em" }}
              className="provenance-token-highlighted-values"
            >
              {" "}
              {getNameFromCollectionObj(collectionData)}{" "}
            </span>
          </span>
          <span className="provenance-token-details-headings">
            Wallet Address:
            <p
              style={{ marginTop: "0.2em" }}
              className="provenance-token-highlighted-values"
            >
              {itemData?.openseaDetails?.creator}
            </p>
          </span>
          <span className="provenance-token-details-headings">
            Collection Size:
            <span style={{ marginLeft: "0.2em" }}>
              {" "}
              {collectionData?.tokensSize}
            </span>
          </span>
          <span className="provenance-token-details-headings">
            Contract Address:
            <p
              style={{ marginTop: "0.2em" }}
              className="provenance-token-highlighted-values"
            >
              {collectionId}
            </p>
          </span>
          <span className="provenance-token-details-headings">
            Token Type:{" "}
            <span
              style={{ marginLeft: "0.2em" }}
              className="provenance-token-details-values"
            >
              {itemData?.openseaDetails?.token_standard.toUpperCase() ||
                "ERC-721"}
            </span>
          </span>
          <span className="provenance-token-details-headings">
            Chain:{" "}
            <span
              style={{ marginLeft: "0.2em" }}
              className="provenance-token-details-values"
            >
              Etherium
            </span>
          </span>
          <span className="provenance-token-details-headings">
            Token ID:{" "}
            <span
              style={{ marginLeft: "0.2em" }}
              className="provenance-token-details-values"
            >
              {itemId}
            </span>
          </span>
          <span className="provenance-token-details-headings">
            Mint Date:{" "}
            <span
              style={{ marginLeft: "0.2em" }}
              className="provenance-token-highlighted-values"
            >
              {mintingDate}
            </span>
          </span>
          <Link
            className="provenance-token-details-headings"
            to={fetchImageURLFromTokenObj(itemData)}
            style={{ textDecoration: "none", color: "black" }}
          >
            Image Link:{" "}
            <p
              className="provenance-token-image-link"
              style={{ marginTop: "0.2em" }}
            >
              {fetchImageURLFromTokenObj(itemData)}
            </p>
          </Link>
        </div>
      </div>
    </>
  );
};

export default ProvenanceTokenDetailsSection;
