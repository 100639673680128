import React from "react";

const ArrowIcon = ({ color, width = "1em", height = "1em" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 19 10"
      fill="none"
    >
      <path
        d="M17.3383 9.67169C17.0268 9.6723 16.7249 9.5638 16.485 9.36502L9.33831 3.39169L2.1783 9.15169C2.04192 9.26244 1.88499 9.34515 1.71654 9.39506C1.54809 9.44497 1.37143 9.46109 1.19673 9.44251C1.02202 9.42392 0.852712 9.37099 0.69853 9.28676C0.544348 9.20253 0.408332 9.08866 0.298302 8.95169C0.187547 8.8153 0.104839 8.65838 0.05493 8.48992C0.00502104 8.32147 -0.0111041 8.14482 0.00748153 7.97011C0.0260671 7.79541 0.0789969 7.6261 0.163229 7.47191C0.247461 7.31773 0.361333 7.18172 0.498302 7.07169L8.49831 0.631687C8.73688 0.435577 9.03614 0.328369 9.34497 0.328369C9.65381 0.328369 9.95306 0.435577 10.1916 0.631687L18.1916 7.29835C18.3267 7.41029 18.4383 7.54775 18.5201 7.70288C18.6019 7.85801 18.6523 8.02776 18.6684 8.20239C18.6845 8.37703 18.666 8.55312 18.6139 8.7206C18.5618 8.88807 18.4772 9.04362 18.365 9.17835C18.2409 9.3313 18.0845 9.45487 17.907 9.54017C17.7295 9.62547 17.5353 9.67039 17.3383 9.67169Z"
        fill={color || "#183B56"}
      />
    </svg>
  );
};

export default ArrowIcon;
