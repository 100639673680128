import React from "react";

const RemoveButton = ({ color = "Red", width, height }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width ? width : "0.7em"}
      height={height ? height : "0.7em"}
      viewBox="0 0 172 171"
      fill="none"
    >
      <path
        d="M167.282 4.51192C162.251 -0.518669 154.125 -0.518669 149.094 4.51192L86.0187 67.4588L22.9429 4.38293C17.9123 -0.647661 9.78595 -0.647661 4.75536 4.38293C-0.275225 9.41351 -0.275225 17.5399 4.75536 22.5704L67.8312 85.6463L4.75536 148.722C-0.275225 153.753 -0.275225 161.879 4.75536 166.91C9.78595 171.94 17.9123 171.94 22.9429 166.91L86.0187 103.834L149.094 166.91C154.125 171.94 162.251 171.94 167.282 166.91C172.313 161.879 172.313 153.753 167.282 148.722L104.206 85.6463L167.282 22.5704C172.184 17.6688 172.184 9.41352 167.282 4.51192Z"
        fill={color}
      />
    </svg>
  );
};

export default RemoveButton;
