import React from "react";

const RightArrowIcon = ({ width, height }) => {
  return (
    <div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width ? width : "0.9em"}
        height={height ? height : "0.9em"}
        viewBox="0 0 7 12"
        fill="#183B56"
        style={{ flexShrink: 0 }}
      >
        <path
          d="M0.325866 0.331475C0.117148 0.541435 0 0.825456 0 1.12151C0 1.41756 0.117148 1.70158 0.325866 1.91154L4.29283 5.8785L0.325866 9.9015C0.117148 10.1115 0 10.3955 0 10.6915C0 10.9876 0.117148 11.2716 0.325866 11.4816C0.430034 11.5866 0.553974 11.67 0.690536 11.7268C0.827097 11.7837 0.973566 11.813 1.1215 11.813C1.26943 11.813 1.4159 11.7837 1.55246 11.7268L6.30386 6.91939C6.40892 6.81522 6.49229 6.69128 6.54918 6.55471C6.60607 6.41815 6.63536 6.27168 6.63536 6.12375C6.63536 5.97582 6.60607 5.82935 6.54918 5.69279C6.49229 5.55623 6.40892 5.43229 6.30386 5.32812L1.55246 0.520721C1.4159 0.46383 1.26943 0.433541 1.1215 0.433541C0.973566 0.433541 0.827097 0.46383 0.690536 0.520721C0.553974 0.577613 0.430034 0.661079 0.325866 0.331475Z"
          fill="#183B56"
        />
      </svg>
    </div>
  );
};

export default RightArrowIcon;
