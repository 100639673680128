import React, { useState } from "react";

const ThreeDotsIcon = ({ onClick }) => {
  return (
    <div onClick={onClick}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1.7rem"
        height="1.7em"
        viewBox="0 0 28 6"
        fill="none"
      >
        <path
          d="M13.999 6C15.6559 6 16.999 4.65685 16.999 3C16.999 1.34315 15.6559 0 13.999 0C12.3422 0 10.999 1.34315 10.999 3C10.999 4.65685 12.3422 6 13.999 6Z"
          fill="#2FB484"
        />
        <path
          d="M24.499 6C26.1559 6 27.499 4.65685 27.499 3C27.499 1.34315 26.1559 0 24.499 0C22.8422 0 21.499 1.34315 21.499 3C21.499 4.65685 22.8422 6 24.499 6Z"
          fill="#2FB484"
        />
        <path
          d="M3.49902 6C5.15588 6 6.49902 4.65685 6.49902 3C6.49902 1.34315 5.15588 0 3.49902 0C1.84217 0 0.499023 1.34315 0.499023 3C0.499023 4.65685 1.84217 6 3.49902 6Z"
          fill="#2FB484"
        />
      </svg>
    </div>
  );
};

export default ThreeDotsIcon;
