import { useEffect, useState } from "react";
import { signIn, signInWithGoogleUpsert } from "../api/mjUserApi";
import Cookies from "js-cookie";
import { useDispatch, useSelector } from "react-redux";
import { login, logout } from "../redux/reducers/actions/userActions";
import HeaderDesktop from "../components/Homepage/Header/HeaderDesktop";
import LoginInputPart from "../components/LoginPage/LoginInputPart";
import LoginWelcomePart from "../components/LoginPage/LoginWelcomePart";
import { Navigate, useNavigate } from "react-router-dom";
import useIsMobile from "../customHooks/UseIsMobile";
import LoginInputPartMobile from "../components/LoginPage/LoginInputPartMobile";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { GoogleLogin } from "@react-oauth/google";

export default function LoginPage({ setShowLogin }) {
  let isMobile = useIsMobile();

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const [email, setEmail] = useState("");

  function handleLoginFormSubmit(event) {
    event.preventDefault();
    let email = event.target[0].value;
    let password = event.target[1].value;
    signIn({ email: email, password: password })
      .then((data) => {
        if (!data || !data.jwtToken) return;

        dispatch(login(data)); //redux login dispatch

        navigate("/");
      })
      .catch((e) => {
        alert("Invalid email or password");
      });
  }

  function handleGoogleSignIn(credential) {
    signInWithGoogleUpsert(credential)
      .then((data) => {
        if (!data || !data.jwtToken) return;

        dispatch(login(data));

        navigate("/");
      })
      .catch((e) => {
        alert("ERROR login with google");
      });
  }

  function handleLogOutButtonClick() {
    dispatch(logout());
  }

  return (
    <div className="d-flex justify-content-center align-items-center w-100">
      <div className="w-100 h-100" style={{ backgroundColor: "white" }}>
        <HeaderDesktop />
        <div className={isMobile ? "d-flex signup-page-mobile" : "d-flex"}>
          {!isMobile && <LoginWelcomePart />}
          {!isMobile ? (
            <LoginInputPart
              handleLogOutButtonClick={handleLogOutButtonClick}
              handleLoginFormSubmit={handleLoginFormSubmit}
              handleGoogleSignIn={handleGoogleSignIn}
              email={email}
              setEmail={setEmail}
            />
          ) : (
            <LoginInputPartMobile
              handleLogOutButtonClick={handleLogOutButtonClick}
              handleLoginFormSubmit={handleLoginFormSubmit}
              email={email}
              setEmail={setEmail}
            />
          )}
        </div>
      </div>
    </div>
  );
}
