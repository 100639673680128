import { useNavigate } from "react-router-dom";
import { fetchImageURLFromTokenObj } from "../../../utils/commonUtils/TokenUtils";
import SearchResultToken from "./SearchResultToken";
import SearchResultCollection from "./SearchResultCollection";
import SearchResultOpenseaUser from "./SearchResultOpenseaUser";
import useIsMobile from "../../../customHooks/UseIsMobile";

export default function SearchResult({
  tokenResult,
  collectionResult,
  isSearching,
  collectionSearching,
  userSearching,
  openseaUserSearchResult,
  toggleSearchResult,
}) {
  const isMobile = useIsMobile();

  function handleImgErr(event, item) {
    const imageURL = fetchImageURLFromTokenObj(item);
    event.target.src = imageURL;
  }

  return (
    <>
      {!isMobile ? (
        <div
          className="mt-2 d-flex flex-row rounded-3 p-3"
          style={{
            position: "fixed",
            width: "85vw",
            left: "7.5vw",
            top: "4.2vw",
            overflowY: "hidden",
            maxHeight: "87vh",

            backgroundColor: "rgba(47, 180, 132, 0.9)",
            zIndex: 2000,
          }}
        >
          <div
            style={{ width: "33%", height: "fit-content" }}
            className="d-flex flex-column align-items-center"
          >
            <h4
              className="d-flex justify-content-center rounded-4 pt-1 pb-1"
              style={{
                color: "rgb(47, 180, 132)",
                backgroundColor: "white",
                width: "70%",
                fontSize: "1vw",
              }}
            >
              Tokens
            </h4>
            <div
              className="w-100"
              style={{ height: "80vh", overflowY: "auto", paddingTop: "2px" }}
            >
              <div className="w-100 d-flex flex-column align-items-center">
                {tokenResult &&
                  tokenResult.map((item, index) => {
                    return (
                      <SearchResultToken
                        item={item}
                        isSearching={isSearching}
                        key={index}
                        toggleSearchResult={toggleSearchResult}
                      />
                    );
                  })}
              </div>
            </div>
          </div>

          <div
            style={{ width: "33%", height: "fit-content" }}
            className="d-flex flex-column align-items-center"
          >
            <h4
              className="d-flex justify-content-center rounded-4 pt-1 pb-1"
              style={{
                color: "rgb(47, 180, 132)",
                backgroundColor: "white",
                width: "70%",
                fontSize: "1vw",
              }}
            >
              Collections
            </h4>
            <div
              className="w-100"
              style={{ height: "80vh", overflowY: "auto", paddingTop: "2px" }}
            >
              <div className="w-100 d-flex flex-column align-items-center">
                {collectionResult &&
                  collectionResult.map((item, index) => {
                    return (
                      <SearchResultCollection
                        item={item}
                        isSearching={collectionSearching}
                        key={index}
                        handleImgErr={handleImgErr}
                        toggleSearchResult={toggleSearchResult}
                      />
                    );
                  })}
              </div>
            </div>
          </div>

          <div
            style={{ width: "33%", height: "fit-content" }}
            className="d-flex flex-column align-items-center"
          >
            <h4
              className="d-flex justify-content-center rounded-4 pt-1 pb-1"
              style={{
                color: "rgb(47, 180, 132)",
                backgroundColor: "white",
                width: "70%",
                fontSize: "1vw",
              }}
            >
              Creators
            </h4>
            <div
              className="w-100"
              style={{ height: "80vh", overflowY: "auto", paddingTop: "2px" }}
            >
              <div className="w-100 d-flex flex-column align-items-center">
                {openseaUserSearchResult &&
                  openseaUserSearchResult.map((item, index) => {
                    return (
                      <SearchResultOpenseaUser
                        item={item}
                        key={index}
                        isSearching={userSearching}
                        handleImgErr={handleImgErr}
                        toggleSearchResult={toggleSearchResult}
                        userType={"creator"}
                      />
                    );
                  })}
              </div>
            </div>
          </div>

          <div
            style={{ width: "33%", height: "fit-content" }}
            className="d-flex flex-column align-items-center"
          >
            <h4
              className="d-flex justify-content-center rounded-4 pt-1 pb-1"
              style={{
                color: "rgb(47, 180, 132)",
                backgroundColor: "white",
                width: "70%",
                fontSize: "1vw",
              }}
            >
              Wallets
            </h4>
            <div
              className="w-100"
              style={{ height: "80vh", overflowY: "auto", paddingTop: "2px" }}
            >
              <div className="w-100 d-flex flex-column align-items-center">
                {openseaUserSearchResult &&
                  openseaUserSearchResult.map((item, index) => {
                    return (
                      <SearchResultOpenseaUser
                        item={item}
                        key={index}
                        isSearching={userSearching}
                        handleImgErr={handleImgErr}
                        toggleSearchResult={toggleSearchResult}
                      />
                    );
                  })}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div
          className="d-flex flex-column"
          style={{
            width: "80vw",
            overflowY: "hidden",
            backgroundColor: "rgba(47, 180, 132, 0.8)",
            position: "relative",
            zIndex: 100,
            borderRadius: "2vw",
            padding: "2vw",
          }}
        >
          <div
            style={{ width: "100%", height: "fit-content" }}
            className="d-flex flex-column align-items-center"
          >
            <h4
              className="d-flex justify-content-center"
              style={{
                color: "rgb(47, 180, 132)",
                backgroundColor: "white",
                width: "70%",
                fontSize: "2vw",
                borderRadius: "2vw",
                padding: "1vw",
                marginBottom: "2vw",
              }}
            >
              Collections
            </h4>
            <div
              className="w-100"
              style={{ overflowY: "auto", paddingTop: "2px" }}
            >
              <div className="w-100 d-flex flex-column align-items-center">
                {collectionResult &&
                  collectionResult.map((item, index) => {
                    return (
                      <SearchResultCollection
                        item={item}
                        isSearching={collectionSearching}
                        key={index}
                        handleImgErr={handleImgErr}
                        toggleSearchResult={toggleSearchResult}
                      />
                    );
                  })}
              </div>
            </div>
          </div>

          <div
            style={{ width: "100%", height: "fit-content" }}
            className="d-flex flex-column align-items-center"
          >
            <h4
              className="d-flex justify-content-center"
              style={{
                color: "rgb(47, 180, 132)",
                backgroundColor: "white",
                width: "70%",
                fontSize: "2vw",
                borderRadius: "2vw",
                padding: "1vw",
                marginBottom: "2vw",
                marginTop: "2vw",
              }}
            >
              Creators
            </h4>
            <div
              className="w-100"
              style={{ overflowY: "auto", paddingTop: "2px" }}
            >
              <div className="w-100 d-flex flex-column align-items-center">
                {openseaUserSearchResult &&
                  openseaUserSearchResult.map((item, index) => {
                    return (
                      <SearchResultOpenseaUser
                        item={item}
                        key={index}
                        isSearching={userSearching}
                        handleImgErr={handleImgErr}
                        toggleSearchResult={toggleSearchResult}
                        userType={"creator"}
                      />
                    );
                  })}
              </div>
            </div>
          </div>

          <div
            style={{ width: "100%", height: "fit-content" }}
            className="d-flex flex-column align-items-center"
          >
            <h4
              className="d-flex justify-content-center"
              style={{
                color: "rgb(47, 180, 132)",
                backgroundColor: "white",
                width: "70%",
                fontSize: "2vw",
                borderRadius: "2vw",
                padding: "1vw",
                marginBottom: "2vw",
                marginTop: "2vw",
              }}
            >
              Wallets
            </h4>
            <div
              className="w-100"
              style={{ overflowY: "auto", paddingTop: "2px" }}
            >
              <div className="w-100 d-flex flex-column align-items-center">
                {openseaUserSearchResult &&
                  openseaUserSearchResult.map((item, index) => {
                    return (
                      <SearchResultOpenseaUser
                        item={item}
                        key={index}
                        isSearching={userSearching}
                        handleImgErr={handleImgErr}
                        toggleSearchResult={toggleSearchResult}
                      />
                    );
                  })}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
