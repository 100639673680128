import "../../styles/Background.css";
import useIsMobile from "../../customHooks/UseIsMobile";

export default function Background({ children, addBlur }) {
  const isMobile = useIsMobile();

  return (
    <div
      className={isMobile ? "background-mobile" : "background"}
      id="itemPageBackground"
      onClick={(e) => {
        e.stopPropagation();
      }}
      style={addBlur === true ? { filter: "blur(0px)" } : {}}
    >
      {children}
    </div>
  );
}
