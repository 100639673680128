import React, { useEffect, useRef, useState } from "react";
import "../../../styles/itemPageStyles/ItemPage.css";
import OpenOriginalIcon from "../../../Icons/MoreOptionsMenuIcon/OpenOriginal";
import { IoCartOutline } from "react-icons/io5";
import { BiTransfer } from "react-icons/bi";
import { GrGrow } from "react-icons/gr";
import { PiFireBold } from "react-icons/pi";
import { HiArrowsUpDown } from "react-icons/hi2";
import {
  fetchTimeOfTransfer,
  fetchSalesPriceTransferEthUI,
  fetchSalesPriceTransferUSD,
  fetchTransferType,
  fetchSalesPriceTransferEth,
  fetchFromUserNFTTransfer,
  fetchToUserNFTTransfer,
} from "../../../utils/commonUtils/TransferHistoryUtil";
import { FaArrowUp, FaArrowDown } from "react-icons/fa";
import ActivityUserCell from "../../CommonComponents/ActivityUserCell";
import { getWalletProfileName } from "../../../utils/commonUtils/WalletDetailsUtils";
import {
  fetchWalletDetails,
  fetchWalletNameDetails,
} from "../../../api/walletDetailApi";
import SalesHistoryChart from "./SalesHistoryChart";
import LoadingSpinner from "../../../utils/commonUtils/LoadingSpinner";
import useIsMobile from "../../../customHooks/UseIsMobile";
import ArrowIcon from "../../../Icons/ArrowIcon";
import ArrowIconReverse from "../../../Icons/ArrowIconReverse";

const Activity = ({
  tokenTransferHistory,
  fetchPaginatedTransferHistory,
  showSalesHistory,
  isPDF,
  isFetchingData,
  transferHistoryPaginatedAPIMetadata,
}) => {
  const isMobile = useIsMobile();

  //sort transferHistory array based on event_timestamp
  const sortedHistory = tokenTransferHistory;
  const [showTimestamp, setShowTimestamp] = useState(false);
  const [showUSDPrice, setShowUSDPrice] = useState(false);
  const [sortingCol, setSortingCol] = useState("event_timestamp-desc");
  const [walletDataMap, setWalletDataMap] = useState({});

  const updateSort = (columnName) => {
    const sort_direction = sortingCol === `${columnName}-asc` ? -1 : 1;

    const newSortingCol =
      sortingCol === `${columnName}-asc`
        ? `${columnName}-desc`
        : `${columnName}-asc`;

    fetchPaginatedTransferHistory(columnName, sort_direction, true);

    setSortingCol(newSortingCol);
  };

  useEffect(() => {
    const fetchWalletData = async () => {
      const addresses = new Set(
        sortedHistory?.flatMap((item) => [
          fetchFromUserNFTTransfer(item),
          fetchToUserNFTTransfer(item),
        ])
      );
      const uniqueAddressesArray = Array.from(addresses);
      const walletUserDataArray = await fetchWalletNameDetails(
        uniqueAddressesArray
      );
      const userMap = {};
      walletUserDataArray.forEach((user) => {
        userMap[user.walletAddress] = user;
      });
      setWalletDataMap(userMap);
    };

    fetchWalletData();
  }, [sortedHistory]);

  const [openRows, setOpenRows] = useState([]);
  const [openMore, setOpenMore] = useState(false);
  const handleOtherActivityColumns = (index) => {
    setOpenRows((prevOpenRows) => {
      const newOpenRows = [...prevOpenRows];
      newOpenRows[index] = !newOpenRows[index];
      return newOpenRows;
    });
    setOpenMore(!openMore);
  };
  return (
    <div>
      <div
        className={!isPDF && "item-transfer-history-table-container"}
        style={{
          fontSize: isMobile
            ? "calc(var(--base-font-size-item-page) * 0.5)"
            : "",
        }}
      >
        <table className="alternateRows">
          <thead style={{ fontSize: "1.2em" }}>
            <tr>
              <th>
                <span>Event</span>
              </th>
              <th onClick={() => updateSort("from")}>
                <span>From</span>
              </th>
              <th>
                <span>To</span>
              </th>
              {!isMobile && (
                <>
                  <th onClick={() => updateSort("payment.quantity")}>
                    <span style={{ cursor: "pointer" }}>
                      Price
                      {sortingCol === "payment.quantity-asc"
                        ? !isPDF && (
                            <span style={{ marginLeft: "0.2em" }}>
                              {" "}
                              <FaArrowUp />
                            </span>
                          )
                        : sortingCol === "payment.quantity-desc"
                        ? !isPDF && (
                            <span style={{ marginLeft: "0.2em" }}>
                              {" "}
                              <FaArrowDown />
                            </span>
                          )
                        : !isPDF && (
                            <HiArrowsUpDown
                              style={{ marginLeft: "0.2em" }}
                              fontSize={"1em"}
                            />
                          )}
                    </span>
                  </th>

                  <th onClick={() => updateSort("event_timestamp")}>
                    <span style={{ cursor: "pointer" }}>
                      Time
                      {sortingCol === "event_timestamp-asc"
                        ? !isPDF && (
                            <span style={{ marginLeft: "0.2em" }}>
                              {" "}
                              <FaArrowUp />
                            </span>
                          )
                        : sortingCol === "event_timestamp-desc"
                        ? !isPDF && (
                            <span style={{ marginLeft: "0.2em" }}>
                              {" "}
                              <FaArrowDown />
                            </span>
                          )
                        : !isPDF && (
                            <HiArrowsUpDown
                              style={{ marginLeft: "0.2em" }}
                              fontSize={"1em"}
                            />
                          )}
                    </span>
                  </th>
                </>
              )}
              {!isPDF && <th> </th>}
            </tr>
          </thead>
          <tbody>
            {sortedHistory?.map((transfer, index) => {
              const walletDataFrom = walletDataMap[transfer.from_address] || {};
              const walletDataTo = walletDataMap[transfer.to_address] || {};
              return (
                <>
                  <tr
                    key={index}
                    style={{
                      ...(fetchTransferType(transfer) === "burned" && {
                        backgroundColor: "rgb(255, 0, 0, 0.5)",
                      }),
                    }}
                  >
                    <td className="transfer-history-table-event-row-item-page">
                      {!isPDF && (
                        <div>
                          {fetchTransferType(transfer) === "transfer" ? (
                            <BiTransfer fontSize={"1.2em"} />
                          ) : fetchTransferType(transfer) === "sale" ? (
                            <IoCartOutline fontSize={"1.2em"} />
                          ) : fetchTransferType(transfer) === "burned" ? (
                            <PiFireBold fontSize={"1.2em"} />
                          ) : (
                            <GrGrow fontSize={"1.2em"} />
                          )}
                        </div>
                      )}
                      <td
                        style={{
                          color: "green",
                        }}
                      >
                        {fetchTransferType(transfer)}
                      </td>
                    </td>
                    <td title={transfer?.from}>
                      <ActivityUserCell
                        wallet_addr={fetchFromUserNFTTransfer(transfer)}
                        iconsWidth={"1em"}
                        iconsHeight={"1em"}
                        showIcons={isPDF ? false : true}
                        cellInItemPage={true}
                        walletProfileName={getWalletProfileName(
                          walletDataFrom,
                          true
                        )}
                      />
                    </td>
                    <td title={transfer?.to}>
                      <ActivityUserCell
                        wallet_addr={fetchToUserNFTTransfer(transfer)}
                        iconsWidth={"1em"}
                        iconsHeight={"1em"}
                        showIcons={isPDF ? false : true}
                        cellInItemPage={true}
                        walletProfileName={getWalletProfileName(
                          walletDataTo,
                          true
                        )}
                      />
                    </td>
                    {!isMobile && (
                      <>
                        <td
                          title={
                            showUSDPrice
                              ? fetchSalesPriceTransferUSD(transfer) + " USD"
                              : fetchSalesPriceTransferEth(transfer) + " ETH"
                          }
                        >
                          {showUSDPrice
                            ? fetchSalesPriceTransferEthUI(transfer) + " ETH"
                            : fetchSalesPriceTransferEthUI(transfer) + " ETH"}
                          <p style={{ fontSize: "0.9em", color: "grey" }}>
                            {fetchSalesPriceTransferUSD(
                              transfer
                            ).toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            }) + " USD"}
                          </p>
                        </td>

                        <td>
                          {fetchTimeOfTransfer(transfer, showTimestamp)}
                          <p style={{ fontSize: "0.9em", color: "grey" }}>
                            {fetchTimeOfTransfer(transfer, true)}
                          </p>
                        </td>

                        {!isPDF && (
                          <td>
                            <OpenOriginalIcon
                              url={
                                "https://etherscan.io/tx/" +
                                transfer.transaction
                              }
                            />
                          </td>
                        )}
                      </>
                    )}

                    {isMobile && (
                      <td
                        onClick={() => handleOtherActivityColumns(index)}
                        style={{ fontSize: "0.7rem" }}
                      >
                        {openRows[index] ? <ArrowIcon /> : <ArrowIconReverse />}
                      </td>
                    )}
                  </tr>
                  {isMobile && (
                    <>
                      <td colspan="4">
                        {openRows[index] && (
                          <table
                            style={{
                              width: "100%",
                              fontSize: "0.7rem",
                              backgroundColor: "white",
                            }}
                          >
                            <thead
                              style={{
                                borderBottom: "1px solid grey",
                                fontWeight: "bold",
                              }}
                            >
                              <tr
                                className={
                                  transfer.index % 2 === 0
                                    ? "even-row"
                                    : "odd-row"
                                }
                                style={{ backgroundColor: "white" }}
                              >
                                <th
                                  onClick={() => updateSort("payment.quantity")}
                                >
                                  <span style={{ cursor: "pointer" }}>
                                    Price
                                  </span>
                                </th>

                                <th
                                  onClick={() => updateSort("event_timestamp")}
                                >
                                  <span style={{ cursor: "pointer" }}>
                                    Time
                                  </span>
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr style={{ backgroundColor: "white" }}>
                                <td
                                  title={
                                    showUSDPrice
                                      ? fetchSalesPriceTransferUSD(transfer) +
                                        " USD"
                                      : fetchSalesPriceTransferEth(transfer) +
                                        " ETH"
                                  }
                                >
                                  {showUSDPrice
                                    ? fetchSalesPriceTransferEthUI(transfer) +
                                      " ETH"
                                    : fetchSalesPriceTransferEthUI(transfer) +
                                      " ETH"}
                                  <p
                                    style={{ fontSize: "0.9em", color: "grey" }}
                                  >
                                    {fetchSalesPriceTransferUSD(
                                      transfer
                                    ).toLocaleString(undefined, {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                    }) + " USD"}
                                  </p>
                                </td>

                                <td>
                                  {fetchTimeOfTransfer(transfer, showTimestamp)}
                                  <p
                                    style={{ fontSize: "0.9em", color: "grey" }}
                                  >
                                    {fetchTimeOfTransfer(transfer, true)}
                                  </p>
                                </td>

                                {!isPDF && (
                                  <td>
                                    <OpenOriginalIcon
                                      url={
                                        "https://etherscan.io/tx/" +
                                        transfer.transaction
                                      }
                                    />
                                  </td>
                                )}
                              </tr>
                            </tbody>
                          </table>
                        )}
                      </td>
                    </>
                  )}
                </>
              );
            })}
            <tr
              onClick={() => {
                if (sortingCol) {
                  let [cname, cdirection] = sortingCol.split("-");
                  cdirection = cdirection === "asc" ? 1 : -1;
                  fetchPaginatedTransferHistory(cname, cdirection);
                } else {
                  fetchPaginatedTransferHistory("event_timestamp", -1);
                }
              }}
            >
              {!isPDF && transferHistoryPaginatedAPIMetadata?.more && (
                <td className="load-more-button" colspan="8">
                  {isFetchingData === true ? (
                    <LoadingSpinner
                      styleObj={{ width: "1.5em", height: "1.5em" }}
                    />
                  ) : (
                    "Load More"
                  )}
                </td>
              )}
            </tr>
          </tbody>
        </table>
      </div>

      <br></br>

      <div>
        {showSalesHistory && (
          <SalesHistoryChart tokenTransferHistory={tokenTransferHistory} />
        )}
      </div>
    </div>
  );
};

export default Activity;
