import React from "react";
import { Link } from "react-router-dom";

const UserEdit = ({ url, width, height }) => {
  return (
    <Link to={url}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width ? width : "1.5em"}
        height={height ? height : "1.5em"}
        viewBox="0 2 20 20"
        fill="none"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M18.3476 3.22834L17.8565 4.54625L17.2457 5.96032C16.6806 7.17273 15.903 8.59017 14.973 9.56833C14.0851 10.5022 12.7452 11.3625 11.672 11.9736C11.9479 13.3022 11.5701 14.7394 10.539 15.7706C8.64704 17.6626 6.53378 17.6629 4.96137 17.1955C4.12038 16.9454 3.28173 16.5411 2.57417 16.0039L1.85373 15.4057C1.68864 15.266 1.69909 15.0098 1.87607 14.8855L2.15284 14.6882C2.75938 14.2461 3.38511 13.7031 3.53464 12.9555C3.62999 12.1842 3.68461 11.7637 3.69851 11.6942C3.81334 11.1201 4.05634 10.4681 4.64642 9.878C5.67754 8.84692 7.11465 8.46917 8.4432 8.74492C9.05437 7.67174 9.9147 6.33177 10.8486 5.44387C11.8267 4.51388 13.2441 3.7363 14.4565 3.17117L15.8706 2.5604L17.1885 2.06926C17.9081 1.82348 18.5934 2.50878 18.3476 3.22834ZM5.82493 11.0566C5.53112 11.3503 5.40336 11.6683 5.33281 12.0211C5.23745 12.7925 5.18283 13.213 5.16894 13.2824C5.01829 14.0357 4.57799 14.6572 4.21099 15.0726C4.54789 15.2615 4.96724 15.4584 5.43632 15.5979C6.58922 15.9407 8.01151 15.941 9.36045 14.5921C10.3368 13.6158 10.3368 12.0328 9.36045 11.0566C8.38412 10.0802 6.80124 10.0802 5.82493 11.0566ZM9.98295 9.41058C10.1783 9.54767 10.3645 9.7035 10.539 9.878C10.7135 10.0525 10.8692 10.2387 11.0063 10.4339C11.3324 10.2449 11.6688 10.0398 11.9978 9.82417L11.9724 9.76808C11.8758 9.562 11.7067 9.278 11.4228 8.99408C11.1795 8.7508 10.9361 8.59182 10.7417 8.49054L10.5927 8.41917C10.377 8.74808 10.172 9.0845 9.98295 9.41058ZM16.1849 4.23198C15.8656 4.36423 15.5187 4.51491 15.1607 4.68179C13.9867 5.22901 12.7722 5.91468 11.997 6.65173C11.8661 6.77618 11.735 6.91407 11.6048 7.06237C11.9071 7.22964 12.2569 7.47114 12.6014 7.81562C12.9458 8.16006 13.1873 8.50983 13.3545 8.81208C13.5028 8.68183 13.6407 8.55075 13.7651 8.41992C14.5022 7.64468 15.1879 6.43018 15.7351 5.25618C15.902 4.89816 16.0526 4.55128 16.1849 4.23198Z"
          fill="#505050"
        />
      </svg>
    </Link>
  );
};

export default UserEdit;
