/* global BigInt */

import React, { useEffect, useRef, useState } from "react";
import { getNameFromCollectionObj } from "../../utils/commonUtils/CollectionUtils";
import SearchIcon from "../../Icons/CollectionPageIcons/SearchIcon";
import useIsMobile from "../../customHooks/UseIsMobile";
import LoadingSpinner from "../../utils/commonUtils/LoadingSpinner";
import RemoveButton from "../../Icons/CollectionPageIcons/RemoveButton";
import {
  createUserFilter,
  deleteUserFilter,
  getUserFilters,
} from "../../api/mjUserApi";
import CollectionSearchSection from "../CommonComponents/CollectionSearchSection";
import FolderDeleteIcon from "../../Icons/FolderDeleteIcon";
import HomePageActivityDropdown from "./HomePageActivityDropdown";
import EditIcon from "../../Icons/EditIcon";
import JoyrideHelper from "../../utils/joyRidehelper";
import FilterEditModal from "./FilterEditModal";
import CustomDropdown from "../FoldersPage/CustomDropDown";
import AddInfoModal from "../CommonComponents/AddInfoModal";
import { IoMdRefreshCircle } from "react-icons/io";
import RefreshIcon from "../../Icons/RefreshIcon";
import ArrowIcon from "../../Icons/ArrowIcon";
import ArrowIconReverse from "../../Icons/ArrowIconReverse";

const HomePageActivityFilterSection = ({
  allCollections,
  fetchingCollections,
  selectedCollections,
  setSelectedCollections,
  priceRange,
  setPriceRange,
  activityStatus,
  setActivityStatus,
  allFiltersSelected,
  setAllFiltersSelected,
  allTopCollectionsFiltersSelected,
  setAllTopCollectionsFiltersSelected,
  userLoggedIn,
  mjUserInfo,
  appliedFilterGroup,
  appliedFilterGroupUpdates,
  setAppliedFilterGroup,
  filterDropdownValue,
  setFilterDropdownValue,
  setStartTour,
  startTour,
  filtersLoading,
  setFiltersLoading,
  filterGroups,
  setFilterGroups,
  filterName,
  setFilterName,
  saveFilters,
  updateFilters,
  setFilterStateFromAppliedFilterGroup,
  isFilterOpen,
  setIsFilterOpen,
}) => {
  const [collectionsSortOption, setCollectionsSortOption] = useState({
    key: "A-Z",
    text: "A-Z",
    value: "A-Z",
  });
  const [collections, setCollections] = useState([]);
  const [defaultFilterGroup, setDefaultFilterGroup] = useState(null);
  const [searchQueryAllCollections, setSearchQueryAllCollections] =
    useState("");
  const [toggleSearchAllCollections, setToggleSearchAllCollections] =
    useState(true);
  const [searchQueryTopCollections, setSearchQueryTopCollections] =
    useState("");

  const [minPrice, setMinPrice] = useState("0.1");
  const [maxPrice, setMaxPrice] = useState("");

  const [openFilterModal, setOpenFilterModal] = useState(false);
  const [editFilter, setEditFiler] = useState(null);

  const [toggleStatusHeading, setToggleStatusHeading] = useState(true);

  const isMobile = useIsMobile();

  function sortCollections(collections) {
    let unsortedCol = [...collections];

    return (unsortedCol || []).sort((col1, col2) => {
      if (collectionsSortOption.value === "A-Z") {
        // Compare the "name" property of col1 and col2
        const name1 = (col1?.name || "").toLowerCase(); // Convert to lowercase for case-insensitive sorting
        const name2 = (col2?.name || "").toLowerCase();

        if (name1 < name2) {
          return -1; // col1 should come before col2
        } else if (name1 > name2) {
          return 1; // col1 should come after col2
        } else {
          return 0; // col1 and col2 have the same "name"
        }
      } else if (collectionsSortOption.value === "Volume(Eth)") {
        const vol1 = BigInt(col1?.totalVolume || 0); // Convert to lowercase for case-insensitive sorting
        const vol2 = BigInt(col2?.totalVolume || 0);

        if (vol1 < vol2) {
          return -1; // col1 should come before col2
        } else if (vol1 > vol2) {
          return 1; // col1 should come after col2
        } else {
          return 0; // col1 and col2 have the same "name"
        }
      }

      return 0;
    });
  }

  useEffect(() => {
    if (collections && collections.length > 0) {
      let sortedColls = sortCollections(collections);

      setCollections(sortedColls || []);
    }
  }, [collectionsSortOption]);

  useEffect(() => {
    let sortedColls = sortCollections(allCollections);

    setCollections(sortedColls);
  }, [allCollections]);

  useEffect(() => {
    setCollections(
      allCollections.filter((collection) =>
        (collection?.name || "")
          .toLowerCase()
          .includes(searchQueryTopCollections.toLowerCase())
      )
    );
  }, [searchQueryTopCollections]);

  const fetchFilters = () => {
    if (userLoggedIn && mjUserInfo?._id) {
      setFiltersLoading(true);
      getUserFilters(mjUserInfo?._id, "homepageActivity")
        .then((filters) => {
          setFilterGroups((prev) => {
            // Filter out new filters that are not already present in filterGroups
            const newFiltersToAdd = filters.filter(
              (newFilter) =>
                !prev.some(
                  (existingFilter) => existingFilter._id === newFilter._id
                )
            );

            // Add the new filters to the existing filterGroups
            return [...prev, ...newFiltersToAdd];
          });
        })
        .catch((error) => {})
        .finally(() => {
          setFiltersLoading(false);
        });
    }
  };

  useEffect(() => {
    fetchFilters();
  }, [mjUserInfo]);

  const fetchGeneralFilters = () => {
    getUserFilters(null, "homepageActivity")
      .then((filters) => {
        setFilterGroups((prev) => {
          // Filter out new filters that are not already present in filterGroups
          const newFiltersToAdd = filters.filter(
            (newFilter) =>
              !prev.some(
                (existingFilter) => existingFilter._id === newFilter._id
              )
          );

          // Add the new filters to the existing filterGroups
          return [...prev, ...newFiltersToAdd];
        });
      })
      .catch((error) => {});
  };

  useEffect(() => {
    fetchGeneralFilters();
  }, []);

  useEffect(() => {
    // Apply default filter if it exists and hasn't been applied yet
    if (
      mjUserInfo?.defaultFilter?.homepageActivity &&
      filterGroups.length > 0
    ) {
      const defaultFilterId = mjUserInfo.defaultFilter.homepageActivity;
      const defaultFilter = filterGroups.find(
        (filter) => filter._id === defaultFilterId
      );
      if (defaultFilter) {
        setAppliedFilterGroup(defaultFilter);
        setDefaultFilterGroup(defaultFilter);
      }
    }
  }, [filterGroups, mjUserInfo]);

  const handleCheckboxChange = (collectionObjToAdd) => {
    setSelectedCollections((prevSelected) => {
      let prevContractIdsSelected = prevSelected.map(
        (obj) => obj.contractAddress
      );

      if (
        prevContractIdsSelected?.includes(collectionObjToAdd?.contractAddress)
      ) {
        return prevSelected.filter(
          (obj) => obj.contractAddress !== collectionObjToAdd?.contractAddress
        );
      } else {
        return [...prevSelected, collectionObjToAdd];
      }
    });
  };

  const applyPriceFilter = () => {
    setPriceRange({
      minPriceFilter: parseFloat(minPrice),
      maxPriceFilter: parseFloat(maxPrice),
      priceCurrencyFilter: "ETH",
    });
  };

  const handleRemoveFilterGroup = (filterId) => () => {
    setFiltersLoading(true);
    deleteUserFilter(filterId)
      .then(() => {
        setFilterGroups((prev) =>
          prev.filter((group) => group._id !== filterId)
        );
        const isRemovingAppliedFilterGroup = filterGroups.some(
          (group) =>
            group?._id === filterId &&
            group?.filterName === appliedFilterGroup?.filterName
        );

        if (isRemovingAppliedFilterGroup) {
          setAppliedFilterGroup(null);
        }
      })
      .catch((error) => {})
      .finally(() => {
        setFiltersLoading(false);
      });
  };

  const handleEachFilterGroupApply = (index) => {
    setAppliedFilterGroup(filterGroups[index]);
  };

  const handleEachFilterGroupEdit = (index) => {
    setEditFiler(filterGroups[index]);
    setOpenFilterModal(true);
  };

  const handleRemoveAppliedFilter = () => {
    setAppliedFilterGroup(null);
  };
  const updateFilterNameInState = (filterId, newName) => {
    setFilterGroups((prev) =>
      prev.map((filter) =>
        filter._id === filterId ? { ...filter, filterName: newName } : filter
      )
    );
    if (appliedFilterGroup?._id === filterId) {
      setAppliedFilterGroup((prev) => ({ ...prev, filterName: newName }));
    }
  };

  const [showCreateFilterTour, setShowCreateFilterTour] = useState(false);
  const [showUpdateFilterTour, setShowUpdateFilterTour] = useState(false);
  const [showFilterMetadataEditTour, setShowFilterMetadataEditTour] =
    useState(false);
  const scrollToClass = (className) => {
    const element = document.querySelector(`.${className}`);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };
  const tourSteps = [
    {
      target: ".tour-step-1",
      content:
        'To start, select a category of collections. You can choose from options like "Top 20 Trending," "All Collections," "Your Collections," and "Your Top Trending 20." By default, you\'ll see collections owned by you if you\'re logged in, or top trending collections if you\'re not.',
      beforeFunc: () => {
        scrollToClass("tour-step-1");
      },
      afterFunc: () => {},
    },
    {
      target: ".tour-step-2",
      content:
        "Next, observe the Applied Filters section. Any collections you add or remove from your selected category will be shown here, reflecting your current filtering choices. You can remove the applied filters from here as well.",
      beforeFunc: () => {
        scrollToClass("tour-step-2");
      },
      afterFunc: () => {},
    },
    {
      target: ".tour-step-3",
      content:
        "Use the search bar to find specific collections. You can add or remove collections directly from the search results to further refine your filters.",
      beforeFunc: () => {
        scrollToClass("tour-step-3");
      },
      afterFunc: () => {},
    },
    {
      target: ".tour-step-4",
      content:
        "Again, the Changes made through the search bar will will be shown here.",
      beforeFunc: () => {
        scrollToClass("tour-step-4");
      },
      afterFunc: () => {},
    },
    {
      target: ".tour-step-5",
      content:
        "Set a price filter by defining a minimum and maximum price range. This helps you narrow down transactions based on their monetary value.",
      beforeFunc: () => {
        scrollToClass("tour-step-5");
      },
      afterFunc: () => {},
    },
    {
      target: ".tour-step-6",
      content:
        "The chosen price range filter will appear here. You might have already grasped the concept. Any applied filters will also be displayed here and can be removed as needed.",
      beforeFunc: () => {
        scrollToClass("tour-step-6");
      },
      afterFunc: () => {},
    },
    {
      target: ".tour-step-7",
      content:
        'Apply status filters to focus on specific transaction types like "Minted" or "Sales." This allows you to tailor your view to transactions based on their current state. ',
      beforeFunc: () => {
        scrollToClass("tour-step-7");
      },
      afterFunc: () => {
        setShowCreateFilterTour(true);
      },
    },
    {
      target: ".tour-step-8",
      content:
        "Once satisfied with your filters, click on 'Save.' Assign a name to save your current filter settings for future use.",
      beforeFunc: () => {
        scrollToClass("tour-step-8");
      },
      afterFunc: () => {
        setShowCreateFilterTour(false);
      },
    },
    {
      target: ".tour-step-9",
      content:
        "In the Filter Group section, you'll find all your saved filters listed. This makes it easy to manage and apply your preferred filter configurations at any time.",
      beforeFunc: () => {
        scrollToClass("tour-step-9");
      },
      afterFunc: () => {},
    },
    {
      target: ".tour-step-10",
      content:
        "Click 'Apply Filters' to activate any of your saved filters. This instantly updates the view to reflect your chosen filter criteria.",
      beforeFunc: () => {
        scrollToClass("tour-step-10");
      },
      afterFunc: () => {
        setShowUpdateFilterTour(true);
      },
    },
    {
      target: ".tour-step-11",
      content:
        "If you modify an existing filter after applying it, use the 'Update Filter' button to ensure your changes are reflected in the active filter without needing to recreate it.",
      beforeFunc: () => {
        scrollToClass("tour-step-11");
      },
      afterFunc: () => {},
    },
    {
      target: ".tour-step-12",
      content:
        "To revert to your previously saved filter settings, use the 'Refresh/Reset' button. This restores filters to their last saved state if changes were not applied.",
      beforeFunc: () => {
        scrollToClass("tour-step-12");
      },
      afterFunc: () => {
        setShowUpdateFilterTour(false);
      },
    },
    {
      target: ".tour-step-13",
      content:
        "You can edit metadata of any of your saved filters by selecting 'Update Filter Name' or setting it as default. This helps you keep your filters organized and easily identifiable.",
      beforeFunc: () => {
        scrollToClass("tour-step-13");
      },
      afterFunc: () => {
        setShowFilterMetadataEditTour(true);
      },
    },
    {
      target: ".tour-step-14",
      content:
        "You can rename any of your saved filters by selecting 'Update Filter Name.' This helps you keep your filters organized and easily identifiable.",
      beforeFunc: () => {
        scrollToClass("tour-step-14");
      },
      afterFunc: () => {},
    },
    {
      target: ".tour-step-15",
      content:
        "Finally, set a default filter that automatically applies each time you return to this page. This ensures your preferred filter settings are always ready when you need them.",
      beforeFunc: () => {
        scrollToClass("tour-step-15");
      },
      afterFunc: () => {
        setShowFilterMetadataEditTour(false);
      },
    },
  ];

  // Example usage:
  // You can iterate over tourSteps array to implement your guided tour functionality

  //sets all top collections selected checkbox based on size of selected collections wrt collections
  useEffect(() => {
    let selectedContractAddrs = selectedCollections.map(
      (obj) => obj.contractAddress
    );

    let allCollectionsAreSelected = true;

    if (allFiltersSelected) {
      for (let collection of collections) {
        if (selectedContractAddrs.includes(collection.contractAddress)) {
          allCollectionsAreSelected = false;
          break;
        }
      }
    } else {
      for (let collection of collections) {
        if (!selectedContractAddrs.includes(collection.contractAddress)) {
          allCollectionsAreSelected = false;
          break;
        }
      }
    }

    if (allCollectionsAreSelected) {
      setAllTopCollectionsFiltersSelected(true);
    } else {
      setAllTopCollectionsFiltersSelected(false);
    }
  }, [selectedCollections, allFiltersSelected, collections]);

  function removeCollectionsFromSelectedCollections() {
    let collectionsContractAddr = (collections || []).map(
      (obj) => obj.contractAddress
    );
    setSelectedCollections((prev) => {
      return [...prev].filter(
        (selectedCollectionObj) =>
          !collectionsContractAddr.includes(
            selectedCollectionObj.contractAddress
          )
      );
    });
  }

  function addCollectionsToSelectedCollections() {
    setSelectedCollections((prev) => {
      // Create a Set to store unique contract addresses
      const uniqueSelectedContractAddresses = new Set(
        prev.map((obj) => obj.contractAddress)
      );

      // Filter out collections with contract addresses that are not already in selectedCollections
      const newCollectionsToAdd = collections.filter((collection) => {
        return !uniqueSelectedContractAddresses.has(collection.contractAddress);
      });

      // Concatenate filteredCollections with the previous selectedCollections
      return [...prev, ...newCollectionsToAdd];
    });
  }

  let CreateFilterSubComponent = ({
    filterName,
    setFilterName,
    saveFilters,
  }) => {
    return (
      <div className="filter-section-custom-filters-home-page tour-step-8">
        <input
          type="text"
          placeholder="Enter filter name"
          className="filter-section-search-bar-home-page"
          value={filterName}
          onChange={(e) => setFilterName(e.target.value)}
          required={true}
        />

        <span
          className="filter-section-custom-filters-save"
          onClick={saveFilters}
        >
          {"Save"}
        </span>
      </div>
    );
  };

  let UpdateFilterSubComponent = ({ updateFilters }) => {
    return (
      <div className="filter-section-custom-filters-home-page">
        <AddInfoModal
          info={"Reset Applied Filters to Original state"}
          renderDirection={"up-right"}
          styleObj={{ fontWeight: "bold", cursor: "pointer" }}
          styleObjModal={{
            width: "calc(var(--base-font-size-collection-page) * (200 / 32))",
            textWrap: "wrap",
          }}
        >
          <RefreshIcon
            onClick={() => {
              setFilterStateFromAppliedFilterGroup();
            }}
            className="tour-step-12"
          ></RefreshIcon>
        </AddInfoModal>

        <span
          className="filter-section-custom-filters-save tour-step-11"
          onClick={updateFilters}
        >
          {"Update Filter"}
        </span>
      </div>
    );
  };

  return (
    <>
      {isFilterOpen && (
        <div className="HomePageActivityFilterSection">
          {startTour && (
            <JoyrideHelper
              steps={tourSteps}
              startTour={startTour}
              setStartTour={setStartTour}
              finishTourFunc={() => {
                setShowCreateFilterTour(false);
                setShowUpdateFilterTour(false);
                setShowFilterMetadataEditTour(false);
              }}
            />
          )}

          <div
            className={
              isMobile
                ? `home-page-filter-section-mobile`
                : "home-page-filter-section-container"
            }
          >
            {isMobile ? (
              <span
                style={{ display: "flex", justifyContent: "flex-end" }}
                onClick={() => setIsFilterOpen(false)}
              >
                <RemoveButton color={"black"} />
              </span>
            ) : null}

            <span
              className={
                !isMobile
                  ? "home-page-filter-section-heading tour-select-collection-filters tour-step-2 tour-step-4 tour-step-2 tour-step-6"
                  : "home-page-filter-section-heading-mobile tour-select-collection-filters tour-step-2 tour-step-4 tour-step-2 tour-step-6"
              }
            >
              Applied Filters
            </span>
            {showCreateFilterTour && (
              <CreateFilterSubComponent
                filterName={filterName}
                setFilterName={setFilterName}
                saveFilters={saveFilters}
              />
            )}

            {showUpdateFilterTour && (
              <UpdateFilterSubComponent updateFilters={updateFilters} />
            )}

            {(selectedCollections?.length > 0 ||
              priceRange?.minPriceFilter ||
              priceRange?.maxPriceFilter ||
              allFiltersSelected ||
              (activityStatus && activityStatus !== "All")) && (
              <>
                {userLoggedIn &&
                  (appliedFilterGroup ? (
                    appliedFilterGroupUpdates && (
                      <UpdateFilterSubComponent updateFilters={updateFilters} />
                    )
                  ) : (
                    <CreateFilterSubComponent
                      filterName={filterName}
                      setFilterName={setFilterName}
                      saveFilters={saveFilters}
                    />
                  ))}

                <span
                  className="home-page-filter-section-data"
                  style={{ height: "auto" }}
                >
                  {priceRange &&
                    (priceRange.minPriceFilter > 0 ||
                      priceRange.maxPriceFilter > 0) && (
                      <span
                        className="home-page-filter-section-collections"
                        style={{
                          color: "rgba(47, 180, 131, 1)",
                        }}
                      >
                        <span
                          className="selected-filter-remove-button"
                          onClick={() => {
                            setPriceRange(null);
                          }}
                        >
                          {" "}
                          <RemoveButton />
                        </span>
                        {priceRange.minPriceFilter || "min"} to{" "}
                        {priceRange.maxPriceFilter || "max"} ETH
                      </span>
                    )}
                  {activityStatus && activityStatus !== "All" && (
                    <span
                      className="home-page-filter-section-collections"
                      style={{
                        color: "rgba(47, 180, 131, 1)",
                      }}
                    >
                      <span
                        className="selected-filter-remove-button"
                        onClick={() => {
                          setActivityStatus("All");
                        }}
                      >
                        {" "}
                        <RemoveButton />
                      </span>
                      {activityStatus}
                    </span>
                  )}
                  {allFiltersSelected && (
                    <span
                      className="home-page-filter-section-collections"
                      style={{
                        color: "rgba(47, 180, 131, 1)",
                      }}
                    >
                      <span
                        className="selected-filter-remove-button"
                        onClick={() => {
                          setSelectedCollections([]);
                          setAllFiltersSelected(false);
                        }}
                      >
                        {" "}
                        <RemoveButton />
                      </span>
                      All Collections Selected
                    </span>
                  )}
                  {(selectedCollections || []).length > 0 && (
                    <>
                      {(selectedCollections || []).map((collectionObj) => (
                        <div
                          key={collectionObj.contractAddress}
                          className="home-page-filter-section-collections"
                          style={{
                            color: allFiltersSelected
                              ? "red"
                              : "rgba(47, 180, 131, 1)",
                          }}
                        >
                          <span
                            className="selected-filter-remove-button"
                            onClick={() => {
                              setSelectedCollections((prev) => {
                                return prev.filter(
                                  (selectedCollection) =>
                                    selectedCollection.contractAddress !==
                                    collectionObj.contractAddress
                                );
                              });
                            }}
                          >
                            {" "}
                            <RemoveButton />
                          </span>
                          {collectionObj?.name}
                        </div>
                      ))}
                    </>
                  )}
                </span>
              </>
            )}

            {/* {appliedFilterGroup && (
              <div className="applied-filter-group">
                <span
                  className={
                    !isMobile
                      ? "home-page-filter-section-heading"
                      : "home-page-filter-section-heading-mobile"
                  }
                >
                  Selected Filter Groups
                </span>
                <span
                  className="selected-filter"
                  style={{
                    background: "red",
                    width: "85%",
                    marginLeft: "7.5%",
                    marginBottom: "1em",
                  }}
                >
                  {appliedFilterGroup?.filterName}
                  <span
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "calc(var(--base-font-size-collection-page) * (10 / 32))",
                    }}
                  >
                    <AddInfoModal
                      info={"Reset Applied Filters to Original state"}
                      renderDirection={"up-left"}
                      styleObj={{
                        fontWeight: "bold",
                        cursor: "pointer",
                      }}
                      styleObjModal={{
                        width:
                          "calc(var(--base-font-size-collection-page) * (200 / 32))",
                        textWrap: "wrap",
                      }}
                    >
                      <RefreshIcon
                        onClick={() => {
                          setFilterStateFromAppliedFilterGroup();
                        }}
                        color="white"
                      ></RefreshIcon>
                    </AddInfoModal>
                    <span onClick={handleRemoveAppliedFilter}>
                      <RemoveButton color="white" />
                    </span>
                  </span>
                </span>
              </div>
            )} */}

            {filterGroups && filterGroups.length > 0 && (
              <>
                <span
                  className={
                    !isMobile
                      ? "home-page-filter-section-heading tour-select-collection-filters tour-step-9"
                      : "home-page-filter-section-heading-mobile tour-select-collection-filters tour-step-9"
                  }
                >
                  {" "}
                  Filter Groups
                </span>
                {!filtersLoading ? (
                  <>
                    {filterGroups.map((group, index) => (
                      <div
                        className={
                          group._id === defaultFilterGroup?._id
                            ? "each-filter-group-row-default"
                            : "each-filter-group-row"
                        }
                      >
                        <span>
                          {userLoggedIn && group.userId && (
                            <span
                              style={{
                                position: "relative",
                                top: "-1.3em",
                                left: "9.2em",
                              }}
                            >
                              <FolderDeleteIcon
                                color={"black"}
                                width={"1.3em"}
                                height={"1.3em"}
                                onClick={handleRemoveFilterGroup(group._id)}
                              />
                            </span>
                          )}
                          <span className="filter-groups-each-name">
                            {group.filterName}
                          </span>
                        </span>
                        <div className="filter-groups-each-apply-button-edit">
                          <span
                            className="filter-groups-each-apply-button tour-step-13"
                            onClick={() => handleEachFilterGroupEdit(index)}
                          >
                            Edit
                          </span>

                          <span
                            className="filter-groups-each-apply-button tour-step-10"
                            onClick={() => handleEachFilterGroupApply(index)}
                          >
                            {appliedFilterGroup?.filterName ===
                            group?.filterName
                              ? "Applied"
                              : "Apply"}
                          </span>

                          {appliedFilterGroup?.filterName ===
                            group?.filterName &&
                            appliedFilterGroupUpdates && (
                              <AddInfoModal
                                info={"Reset Applied Filter to Original state"}
                                renderDirection={"up-left"}
                                styleObj={{
                                  fontWeight: "bold",
                                  cursor: "pointer",
                                }}
                                styleObjModal={{
                                  width:
                                    "calc(var(--base-font-size-collection-page) * (200 / 32))",
                                  textWrap: "wrap",
                                }}
                              >
                                <span
                                  style={{ cursor: "pointer" }}
                                  onClick={() => {
                                    setFilterStateFromAppliedFilterGroup();
                                  }}
                                >
                                  <IoMdRefreshCircle size={"1.5em"} />
                                </span>
                              </AddInfoModal>
                            )}

                          {appliedFilterGroup?.filterName ===
                            group?.filterName && (
                            <AddInfoModal
                              info={"Remove Applied Filter from Filter Groups"}
                              renderDirection={"up-left"}
                              styleObj={{
                                fontWeight: "bold",
                                cursor: "pointer",
                              }}
                              styleObjModal={{
                                width:
                                  "calc(var(--base-font-size-collection-page) * (200 / 32))",
                                textWrap: "wrap",
                              }}
                            >
                              <span
                                className="remove-applied-filter"
                                onClick={handleRemoveAppliedFilter}
                              >
                                <RemoveButton color={"white"} />
                              </span>
                            </AddInfoModal>
                          )}
                        </div>
                      </div>
                    ))}
                  </>
                ) : (
                  <div className="loading-spinner-container">
                    <LoadingSpinner />
                  </div>
                )}
              </>
            )}

            {
              <div>
                <span
                  className={
                    !isMobile
                      ? "home-page-filter-section-heading tour-select-collection-filters tour-step-5"
                      : "home-page-filter-section-heading-mobile tour-select-collection-filters tour-step-5"
                  }
                >
                  {" "}
                  Sold Price
                </span>
                <div
                  style={{
                    width: "80%",
                    marginLeft: "5%",
                    marginBottom: "1em",
                  }}
                >
                  <div
                    className="price-filter-block"
                    style={{
                      alignItems: "center",
                      paddingLeft: "22%",
                    }}
                  >
                    <div className="price-filter-price-range">
                      <input
                        className="price-filter-price-input"
                        type="number"
                        placeholder="Min"
                        value={minPrice}
                        onChange={(e) => setMinPrice(e.target.value)}
                        min={0}
                      />
                      <span className="price-filter-min-max-to">to</span>
                      <input
                        className="price-filter-price-input"
                        type="number"
                        placeholder="Max"
                        value={maxPrice}
                        onChange={(e) => setMaxPrice(e.target.value)}
                        min={0}
                      />
                      <span
                        className="price-filter-min-max-to"
                        style={{ fontWeight: "700" }}
                      >
                        ETH
                      </span>
                    </div>
                  </div>
                  <button
                    className="price-filter-apply-button"
                    onClick={applyPriceFilter}
                  >
                    Apply
                  </button>
                </div>
              </div>
            }

            <div>
              <span
                className={
                  !isMobile
                    ? "home-page-filter-section-heading tour-select-collection-filters"
                    : "home-page-filter-section-heading-mobile tour-select-collection-filters"
                }
                onClick={() => setToggleStatusHeading(!toggleStatusHeading)}
              >
                Status
                {toggleStatusHeading ? <ArrowIcon /> : <ArrowIconReverse />}
              </span>
              {toggleStatusHeading && (
                <div
                  style={{
                    width: "88%",
                    marginLeft: "6%",
                    marginBottom: "1em",
                  }}
                  className="tour-step-7"
                >
                  {["All", "Transfer", "Sale", "Minted", "AirDropped"].map(
                    (listedStatus) => {
                      return (
                        <>
                          <div className="filter-section-traits-filter-trait-values-row">
                            <span
                              className="filter-section-sub-headings"
                              style={{ fontWeight: 600, marginBottom: "5%" }}
                            >
                              {listedStatus}
                            </span>
                            <input
                              className="filter-section-traits-filter-trait-value-checkbox"
                              type="checkbox"
                              value={listedStatus}
                              checked={listedStatus === activityStatus}
                              onChange={(e) => {
                                setActivityStatus(listedStatus);
                              }}
                            />
                          </div>
                        </>
                      );
                    }
                  )}
                </div>
              )}
            </div>

            <div>
              {
                <>
                  <span
                    className={
                      !isMobile
                        ? "home-page-filter-section-heading tour-select-collection-filters"
                        : "home-page-filter-section-heading-mobile tour-select-collection-filters"
                    }
                  >
                    Collections
                  </span>

                  {toggleSearchAllCollections && (
                    <div style={{ margin: "1vw" }} className="tour-step-3">
                      <CollectionSearchSection
                        collectionObjsSelected={selectedCollections || []}
                        addToCollectionObjsSelected={(collectionObj) => {
                          setSelectedCollections((prev) => [
                            ...prev,
                            {
                              contractAddress: collectionObj?.contractAddress,
                              name: collectionObj?.collectionDetails?.name,
                              totalVolume: collectionObj?.totalVolume,
                            },
                          ]);
                        }}
                        deleteFromCollectionObjsSelected={(collectionObj) => {
                          setSelectedCollections((prev) =>
                            prev.filter(
                              (obj) =>
                                obj.contractAddress !==
                                collectionObj.contractAddress
                            )
                          );
                        }}
                        allFiltersSelected={allFiltersSelected}
                      />
                    </div>
                  )}
                </>
              }

              <HomePageActivityDropdown
                filterDropdownValue={filterDropdownValue}
                setFilterDropdownValue={setFilterDropdownValue}
                userLoggedIn={userLoggedIn}
              />

              {
                <>
                  {filterDropdownValue.value !== "All_Collections" && (
                    <input
                      type="text"
                      placeholder={
                        "Search " + filterDropdownValue?.text + "..."
                      }
                      className="filter-section-search-bar"
                      value={searchQueryTopCollections}
                      onChange={(e) =>
                        setSearchQueryTopCollections(e.target.value)
                      }
                    />
                  )}

                  {
                    <div
                      className="filter-section-collections-sort-row"
                      style={{
                        justifyContent:
                          filterDropdownValue.value === "All_Collections"
                            ? "flex-start"
                            : "space-between",

                        gap:
                          filterDropdownValue.value === "All_Collections"
                            ? "calc(var(--base-font-size-home-page)*(10 / 32))"
                            : "",
                      }}
                    >
                      <AddInfoModal
                        info={
                          (filterDropdownValue.value === "All_Collections" &&
                            allFiltersSelected) ||
                          (filterDropdownValue.value !== "All_Collections" &&
                            allTopCollectionsFiltersSelected)
                            ? "Deselect all " + filterDropdownValue?.text
                            : "Select all " + filterDropdownValue?.text
                        }
                        renderDirection={"up-right"}
                        styleObj={{ zIndex: "99999", fontWeight: "bold" }}
                      >
                        <div className="filter-section-collections-select-box">
                          <input
                            type="checkbox"
                            style={{
                              cursor: "pointer",
                              height:
                                "calc(var(--base-font-size-home-page)*(18 / 32))",
                              width:
                                "calc(var(--base-font-size-home-page)*(18 / 32))",
                            }}
                            checked={
                              filterDropdownValue.value === "All_Collections"
                                ? allFiltersSelected
                                : allTopCollectionsFiltersSelected
                            }
                            onChange={() => {
                              if (
                                filterDropdownValue.value === "All_Collections"
                              ) {
                                setSelectedCollections([]);
                                setAllFiltersSelected(!allFiltersSelected);
                              } else {
                                if (allFiltersSelected) {
                                  if (allTopCollectionsFiltersSelected) {
                                    addCollectionsToSelectedCollections(
                                      collections || []
                                    );
                                  } else {
                                    removeCollectionsFromSelectedCollections(
                                      []
                                    );
                                  }
                                } else {
                                  if (allTopCollectionsFiltersSelected) {
                                    removeCollectionsFromSelectedCollections(
                                      []
                                    );
                                  } else {
                                    addCollectionsToSelectedCollections(
                                      collections || []
                                    );
                                  }
                                }
                              }
                            }}
                          />
                        </div>
                      </AddInfoModal>

                      {filterDropdownValue.value === "All_Collections" && (
                        <label
                          style={{
                            fontSize:
                              "calc(var(--base-font-size-home-page)*(15 / 32))",
                          }}
                        >
                          {!allFiltersSelected
                            ? "Select All Collections"
                            : "DeSelect All Collections"}
                        </label>
                      )}

                      {filterDropdownValue.value !== "All_Collections" && (
                        <CustomDropdown
                          id="collection-dropdown"
                          options={[
                            { key: "A-Z", text: "A-Z", value: "A-Z" },
                            {
                              key: "Volume(Eth)",
                              text: "Volume(Eth)",
                              value: "Volume(Eth)",
                            },
                          ]}
                          onChange={(selectedOption) =>
                            setCollectionsSortOption(selectedOption)
                          }
                          selectedOption={collectionsSortOption}
                          defaultPlaceholder={"Select NFT Collection..."}
                          styleObj={{
                            paddingBottom:
                              "calc(var(--base-font-size-folders-page)*(4 / 32))",
                            paddingTop:
                              "calc(var(--base-font-size-folders-page)*(4 / 32))",
                            maxHeight: "20vh",
                            width: "55%",
                            backgroundColor: "#ecf0f9",
                            position: "absolute",
                            right: "0",
                            top: "0",
                          }}
                        />
                      )}
                    </div>
                  }
                </>
              }

              {!fetchingCollections ? (
                (collections || []).length > 0 && (
                  <span className="home-page-filter-section-data tour-step-2">
                    {(collections || []).map((collection) => (
                      <div
                        key={collection.contractAddress}
                        className="home-page-filter-section-collections"
                      >
                        {
                          <input
                            style={{
                              cursor: "pointer",
                              height: "0.7em",
                              width: "0.7em",
                            }}
                            type="checkbox"
                            id={collection.contractAddress}
                            name={collection.contractAddress}
                            value={collection.contractAddress}
                            checked={
                              (allFiltersSelected &&
                                !(selectedCollections || [])
                                  .map(
                                    (collection) => collection.contractAddress
                                  )
                                  .includes(collection.contractAddress)) ||
                              (!allFiltersSelected &&
                                (selectedCollections || [])
                                  .map(
                                    (collection) => collection.contractAddress
                                  )
                                  .includes(collection.contractAddress))
                            }
                            onChange={() => handleCheckboxChange(collection)}
                          />
                        }
                        <label htmlFor={collection.contractAddress}>
                          {collection?.name}
                        </label>
                      </div>
                    ))}
                  </span>
                )
              ) : (
                <p>
                  <br />
                  <LoadingSpinner />
                  <br />
                </p>
              )}
            </div>
          </div>
        </div>
      )}
      {openFilterModal ||
        (showFilterMetadataEditTour && (
          <FilterEditModal
            setOpenFilterModal={setOpenFilterModal}
            editFilter={editFilter}
            userId={mjUserInfo?._id}
            defaultFilterGroup={defaultFilterGroup}
            setDefaultFilterGroup={setDefaultFilterGroup}
            onRename={updateFilterNameInState}
          />
        ))}
    </>
  );
};

export default HomePageActivityFilterSection;
