import { REACT_APP_BACKEND_API_BASE_URL } from "./backend_domains/backend_api_domains";
import Axios from "axios";
import { setupCache } from "axios-cache-interceptor";
const instance = Axios.create();
const axios = setupCache(instance);

class TokenApis {
  constructor() {
    this.apiUrl = `${REACT_APP_BACKEND_API_BASE_URL}`;
  }

  async getAllListingOfToken(contractAddress, tokenId) {
    if (!contractAddress || !tokenId) return [];
    const params = {
      contractAddress: contractAddress,
      tokenId: tokenId,
    };
    try {
      const response = await axios.get(`${this.apiUrl}/token_listing`, {
        params: params,
      });
      return response?.data;
    } catch (error) {
      throw error;
    }
  }

  async fetchUpdatedTokenListings(contractAddress, tokenId) {
    const params = {
      contractAddress: contractAddress,
      tokenId: tokenId,
    };
    try {
      const response = await axios.get(`${this.apiUrl}/token_listing/updated`, {
        params: params,
      });
      return response?.data || {};
    } catch (error) {
      throw error;
    }
  }

  async fetchTokenTransferHistory(
    contractAddress,
    tokenId,
    page,
    limit,
    sortKey,
    sortDirection
  ) {
    page = page || 1;
    limit = limit || 32;
    sortKey = sortKey || "logId";
    sortDirection = sortDirection || 1;

    const params = {
      contractAddress: contractAddress,
      tokenId: tokenId,
      page: page,
      limit: limit,
      sortKey: sortKey,
      sortDirection: sortDirection,
    };

    try {
      const response = await axios.get(
        `${this.apiUrl}/token_details/transfer_history`,
        {
          params: params,
        }
      );
      return response?.data || [];
    } catch (error) {
      throw error;
    }
  }
}

const tokenApis = new TokenApis();

export default tokenApis;
