import React, { useEffect, useState } from "react";
import logoIcon from "../assets/images/MetaJungle_MJ.png";
import logoTitle from "../assets/images/MetaJungle_Title.png";
import FooterFacebook from "../Icons/FooterFacebook";
import FooterInstagram from "../Icons/FooterInstagram";
import FooterTwitter from "../Icons/FooterTwitter";
import { useLocation, useNavigate } from "react-router-dom";
import useIsMobile from "../customHooks/UseIsMobile";
import { useSelector } from "react-redux";
import { fetchMJUserDetails } from "../api/mjUserApi";
import ProfileDropDown from "./Homepage/Header/ProfileDropDown";

const FooterSection = ({ isHomePage }) => {
  const navigate = useNavigate();
  const [userInfo, setUserInfo] = useState({});
  const [showProfileDropdown, setShowProfileDropdown] = useState(false);
  const userState = useSelector((state) => state.user);
  const [loggedIn, setLoggedIn] = useState(userState.loggedIn);

  const isMobile = useIsMobile();

  useEffect(() => {
    setLoggedIn(userState.loggedIn);
    setUserInfo(userState.userInfo);
  }, [userState]);

  function toggleProfileDropdown() {
    setShowProfileDropdown(false);
  }

  // function handleWalletReportClick() {
  //   if (!loggedIn) navigate("/login");
  //   else {
  //     fetchMJUserDetails(userState?.userInfo?.userId)
  //       .then((data) => {
  //         if (
  //           !data.walletAddressList ||
  //           (data?.walletAddressList || []).length === 0
  //         )
  //           toggleProfileDropdown();
  //         else {
  //           const mainWalletAddress = data.walletAddressList[0];
  //           navigate(`/wallet_report/${mainWalletAddress}`);
  //         }
  //       })
  //       .catch((e) => {});
  //   }
  // }

  return (
    <div
      className={
        !isMobile
          ? "footer-section-main-container"
          : "footer-section-main-container-mobile"
      }
    >
      <span
        className="footer-section-logos "
        onClick={() => {
          navigate("/");
        }}
      >
        <img height={"30em"} width={"30em"} src={logoIcon} alt="logoIcon" />
        <img height={"25em"} width={"197em"} src={logoTitle} alt="logoTitle" />
      </span>
      <span>
        <span
          className={
            !isMobile ? "footer-section-links" : "footer-section-links-mobile"
          }
        >
          {!isHomePage && (
            <span
              className="footer-section-links-heading"
              onClick={() => {
                navigate("/");
              }}
            >
              Home
            </span>
          )}
          {/* <span
            className="footer-section-links-heading"
            onClick={() => {
              navigate("/nft_transfers_activity");
            }}
          >
            Activity
          </span>
          <span
            className="footer-section-links-heading"
            onClick={() => {
              navigate("/dashboard");
            }}
          >
            Dashboard
          </span>
          <span
            onClick={handleWalletReportClick}
            className={` footer-section-links-heading${
              location.pathname.startsWith("/wallet_report") ? "" : ""
            }`}
          >
            Wallet Report
          </span>
          {!isHomePage && (
            <span
              className="footer-section-links-heading"
              onClick={() => {
                navigate("/contact");
              }}
            >
              Contact
            </span>
          )} */}
        </span>
      </span>
      {/* <div>
        <span className="footer-section-social-links">
          <a
            href="https://www.facebook.com/MetaJungleNFT"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FooterFacebook />
          </a>
          <a
            href="https://www.instagram.com/metajungle_nft/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FooterInstagram />
          </a>
          <a
            href="https://twitter.com/MetaJungleNFT"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FooterTwitter />
          </a>
        </span>
      </div> */}
      {showProfileDropdown && (
        <ProfileDropDown
          toggleProfileDropdown={toggleProfileDropdown}
          loggedIn={loggedIn}
        />
      )}
    </div>
  );
};

export default FooterSection;
