import React from "react";

const FilterIconOpenMobile = () => {
  return (
    <div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        viewBox="0 0 20 20"
        fill="none"
      >
        <path
          d="M0.938446 0L19.0614 0C19.8941 0 20.3143 1.01039 19.7243 1.60043L12.5 8.82582L12.5 16.875C12.5 17.1809 12.3507 17.4676 12.1001 17.643L8.97512 19.8298C8.3586 20.2613 7.50001 19.8239 7.50001 19.0617L7.50001 8.82582L0.275517 1.60043C-0.313311 1.01156 0.103992 0 0.938446 0Z"
          fill="#2FB484"
        />
      </svg>
    </div>
  );
};

export default FilterIconOpenMobile;
