import React, { useContext, useEffect, useRef, useState } from "react";
import useIsMobile from "../customHooks/UseIsMobile";
import HeaderDesktop from "../components/Homepage/Header/HeaderDesktop";
import Background from "../components/UtilComponents/Background";
import {
  fetchCollectionDetails,
  fetchCollectionTokens,
  fetchCollectionTransferHistory,
  fetchListingTokensCountForCollection,
  fetchTraitCounts,
} from "../api/collectionDetailsApi";
import ItemsTabSection from "../components/CollectionPage/ItemsTabSection";
import AnalysisTabSection from "../components/CollectionPage/AnalysisTabSection";
import SectionTab from "../components/CollectionPage/SectionTabs";
import { useParams } from "react-router-dom";
import DescriptionSection from "../components/CollectionPage/DescriptionSection";
import BannerProfileSection from "../components/CollectionPage/BannerProfileSection";
import {
  CollectionFilterSectionContext,
  initialCollectionPageContext,
} from "../contexts/CollectionPage/FilterSectionContext";
import ScrollToTopArrow from "../Icons/CollectionPageIcons/ScrollToTopArrow";
import { getNameFromCollectionObj } from "../utils/commonUtils/CollectionUtils";
import { Helmet } from "react-helmet";

export default function CollectionPage({ setPageNotFound }) {
  let { collectionId } = useParams();
  const { collectionPageFilters, updateCollectionPageFilters } = useContext(
    CollectionFilterSectionContext
  );
  const collectionTokensPaginatedAPIMetadataDefVal = {
    currentPage: 0,
    limit: 40,
    currentCount: 0,
    prevCount: -1,
  };
  const transferHistoryPaginatedAPIMetadataDefVal = {
    more: true,
    currentPage: 0,
    limit: 32,
    currentCount: 0,
    prevCount: -1,
  };

  const [contractAddress, setContractAddress] = useState(collectionId);
  const isMobile = useIsMobile();
  const isFirstRender = useRef(true);
  const [collectionData, setCollectionData] = useState(null);
  const [conditionType, setConditionTypeState] = useState("AND");
  const [traitCount, setTraitCount] = useState({});
  const [tokenDetailsArray, setTokenDetailsArray] = useState([]);
  const [transferHistory, setTransferHistory] = useState([]);
  const [isFetchingData, setIsFetchingData] = useState(false);
  const [showScrollButton, setShowScrollButton] = useState(false);
  const [listedTokensCount, setListedTokensCount] = useState(0);
  const [activeTab, setActiveTab] = useState("Items");
  const [isFetchingCollectionDetails, setIsFetchingCollectionDetails] =
    useState(false);
  const [
    transferHistoryPaginatedAPIMetadata,
    setTransferHistoryPaginatedAPIMetadata,
  ] = useState(transferHistoryPaginatedAPIMetadataDefVal);
  const [
    collectionTokensPaginatedAPIMetadata,
    setCollectionTokensPaginatedAPIMetadata,
  ] = useState(collectionTokensPaginatedAPIMetadataDefVal);
  const [activitySortingState, setActivitySortingState] = useState({
    activitySortText: "Transfer Date: Newest to Oldest",
    activitySortKey: "event_timestamp",
    activitySortDirection: -1,
  });

  async function fetchPaginatedTokens(refetchData) {
    if (
      refetchData === false &&
      collectionTokensPaginatedAPIMetadata.prevCount === 0
    )
      return;

    setIsFetchingData(true);
    let traitsArray = [];
    Object.keys(collectionPageFilters?.tokenSelectedTraitValues).map(
      (traitType) => {
        collectionPageFilters?.tokenSelectedTraitValues[traitType].map(
          (traitValue) => {
            traitsArray.push({ traitType, traitValue });
          }
        );
      }
    );

    let searchQueryTokensSelectedIds = Object.values(
      collectionPageFilters.searchQueryTokensSelected || {}
    ).map((obj) => obj.contractAddress + "-" + obj.tokenId);
    fetchCollectionTokens(
      contractAddress,
      searchQueryTokensSelectedIds,
      traitsArray,
      collectionPageFilters.tokenStatus,
      collectionPageFilters.listingPriceFilter,
      refetchData === true
        ? 1
        : collectionTokensPaginatedAPIMetadata.currentPage + 1,
      collectionTokensPaginatedAPIMetadata.limit,
      collectionPageFilters?.tokensSortKey,
      collectionPageFilters?.tokensSortDirection,
      conditionType
    )
      .then((response) => {
        refetchData === true
          ? setTokenDetailsArray(response.tokensWithDetails)
          : setTokenDetailsArray((prevTokens) => [
              ...prevTokens,
              ...response.tokensWithDetails,
            ]);
        if (response) {
          setCollectionTokensPaginatedAPIMetadata({
            ...collectionTokensPaginatedAPIMetadata,
            currentPage: response.page || 1,
            prevCount: collectionTokensPaginatedAPIMetadata.currentCount,
            currentCount:
              collectionTokensPaginatedAPIMetadata.currentCount +
              (response.count || 0),
          });
        }
      })
      .catch((e) => {
        console.error(e);
      })
      .finally(() => {
        setIsFetchingData(false);
      });
  }

  async function fetchTraitCountsFromAPI() {
    let traitsArray = [];
    Object.keys(collectionPageFilters?.tokenSelectedTraitValues).map(
      (traitType) => {
        collectionPageFilters?.tokenSelectedTraitValues[traitType].map(
          (traitValue) => {
            traitsArray.push({ traitType, traitValue });
          }
        );
      }
    );
    try {
      const traitCount = await fetchTraitCounts(
        contractAddress,
        null,
        null,
        traitsArray,
        collectionPageFilters.tokenStatus,
        collectionPageFilters.listingPriceFilter,
        conditionType
      );
      setTraitCount(traitCount);
    } catch (e) {}
  }

  function setConditionType(type) {
    setConditionTypeState(type);
  }

  async function fetchPaginatedTransferHistory(refetchData) {
    if (refetchData === false && !transferHistoryPaginatedAPIMetadata.more)
      return;
    if (isFetchingData) return;

    setIsFetchingData(true);
    let traitsArray = [];
    Object.keys(collectionPageFilters?.activitySelectedTraitValues).map(
      (traitType) => {
        collectionPageFilters?.activitySelectedTraitValues[traitType].map(
          (traitValue) => {
            traitsArray.push({ traitType, traitValue });
          }
        );
      }
    );

    fetchCollectionTransferHistory(
      contractAddress,
      null,
      null,
      traitsArray,
      collectionPageFilters?.activityStatus,
      refetchData === true
        ? 1
        : transferHistoryPaginatedAPIMetadata.currentPage + 1,
      transferHistoryPaginatedAPIMetadata.limit,
      activitySortingState?.activitySortKey,
      activitySortingState?.activitySortDirection
    )
      .then((response) => {
        refetchData === true
          ? setTransferHistory(response.nftTransfers || [])
          : setTransferHistory((prevTransferHistory) => [
              ...prevTransferHistory,
              ...(response.nftTransfers || []),
            ]);
        let limit = transferHistoryPaginatedAPIMetadata.limit;
        let resCount = response.nftTransfers.length;
        let more = true;
        if (!resCount || resCount < limit) more = false;
        if (response) {
          setTransferHistoryPaginatedAPIMetadata({
            ...transferHistoryPaginatedAPIMetadata,
            more: more,
            currentPage: parseInt(response?.page) || 1,
            prevCount: transferHistoryPaginatedAPIMetadata.currentCount,
            currentCount:
              transferHistoryPaginatedAPIMetadata.currentCount +
              (response?.count || 0),
          });
        }
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setIsFetchingData(false);
      });
  }

  function handleTabClick(index) {
    setActiveTab(index);
  }

  function handleScroll() {
    const scrollThreshold = 200;
    setShowScrollButton(window.scrollY > scrollThreshold);
  }

  function scrollToTop() {
    window.scrollTo({ top: 500, behavior: "smooth" });
  }

  useEffect(() => {
    setContractAddress(collectionId);
  }, [collectionId]);

  useEffect(() => {
    fetchListingTokensCountForCollection(contractAddress)
      .then((count) => {
        setListedTokensCount(count);
      })
      .catch((e) => {
        console.error(e);
      });
  }, [contractAddress]);

  useEffect(() => {
    if (!isFirstRender.current) {
      fetchPaginatedTokens(true);
      fetchTraitCountsFromAPI();
    }
  }, [
    collectionPageFilters?.tokenSelectedTraitValues,
    conditionType,
    contractAddress,
  ]);

  useEffect(() => {
    if (!isFirstRender.current) {
      fetchPaginatedTransferHistory(true);
    }
  }, [collectionPageFilters?.activitySelectedTraitValues, contractAddress]);

  useEffect(() => {
    if (!isFirstRender.current) fetchPaginatedTransferHistory(true);
  }, [
    collectionPageFilters.activityStatus,
    activitySortingState,
    contractAddress,
  ]);

  useEffect(() => {
    if (!isFirstRender.current) {
      fetchPaginatedTokens(true);
      fetchTraitCountsFromAPI();
    }
  }, [
    collectionPageFilters.tokenStatus,
    collectionPageFilters.tokensSortText,
    collectionPageFilters.tokensSortKey,
    collectionPageFilters.tokensSortDirection,
    collectionPageFilters.listingPriceFilter,
  ]);

  useEffect(() => {
    if (!isFirstRender.current) fetchPaginatedTokens(true);
  }, [collectionPageFilters.searchQueryTokensSelected]);

  useEffect(() => {
    setIsFetchingCollectionDetails(true);
    fetchCollectionDetails(contractAddress, null, null, true)
      .then((collectionDetail) => {
        setCollectionData(collectionDetail);
      })
      .catch((error) => {
        console.error(error);
        setPageNotFound(true);
      })
      .finally(() => {
        setIsFetchingCollectionDetails(false);
        fetchPaginatedTokens(true);
        fetchPaginatedTransferHistory(true);
      });
    isFirstRender.current = false;
  }, [contractAddress]);

  useEffect(() => {
    if (collectionPageFilters.collection !== collectionId) {
      updateCollectionPageFilters({
        type: "REPLACE",
        payload: { collection: collectionId, ...initialCollectionPageContext },
      });
    }
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div>
      <Helmet>
        <title>
          {getNameFromCollectionObj(collectionData) || collectionId}
        </title>
        <meta
          name="description"
          content="Welcome to the NFT Collection Page!
          On this page, you'll find detailed information about your NFT Collection created on Blockchain!"
        />
      </Helmet>
      {isMobile ? (
        <div>
          <div>
            <HeaderDesktop />
            <div style={{ height: "5vw" }} />
            <Background>
              <BannerProfileSection
                collectionData={collectionData}
                isFetchingData={isFetchingData}
                isFetchingCollectionDetails={isFetchingCollectionDetails}
              />
              <DescriptionSection collectionData={collectionData} />
              <div>
                <div className="section-tab-container">
                  <SectionTab
                    label="Items"
                    active={activeTab === "Items"}
                    onClick={() => handleTabClick("Items")}
                    info="The 'Items' tab displays all NFT tokens within the collection."
                  />
                  <SectionTab
                    label="Activity"
                    active={activeTab === "Activity"}
                    onClick={() => handleTabClick("Activity")}
                    info="The 'Activity' tab displays the complete transfer history of the tokens in this collection."
                  />
                </div>
                <div className="items-container-with-filter-sections-container">
                  {activeTab === "Items" && (
                    <ItemsTabSection
                      pageType={"CollectionPage"}
                      activeTab={activeTab}
                      setDataArray={setTokenDetailsArray}
                      fetchPaginatedData={fetchPaginatedTokens}
                      dataArray={tokenDetailsArray}
                      aggregated_traits={collectionData?.aggregated_traits}
                      dataTotalSize={collectionData?.tokensSize}
                      collectionData={collectionData}
                      isFetchingData={isFetchingData}
                      setConditionType={setConditionType}
                      conditionType={conditionType}
                      listedTokensCount={listedTokensCount}
                      setListedTokensCount={setListedTokensCount}
                      traitCount={traitCount}
                    />
                  )}
                </div>
                {activeTab === "Activity" && (
                  <ItemsTabSection
                    pageType={"CollectionPage"}
                    activeTab={activeTab}
                    setDataArray={setTransferHistory}
                    fetchPaginatedData={fetchPaginatedTransferHistory}
                    dataArray={transferHistory}
                    aggregated_traits={collectionData?.aggregated_traits}
                    dataTotalSize={collectionData?.tokensSize}
                    collectionData={collectionData}
                    isFetchingData={isFetchingData}
                    setConditionType={setConditionType}
                    conditionType={conditionType}
                    listedTokensCount={listedTokensCount}
                    setListedTokensCount={setListedTokensCount}
                    activitySortingState={activitySortingState}
                    setActivitySortingState={setActivitySortingState}
                    traitCount={traitCount}
                  />
                )}
                {activeTab === "Analysis" && <AnalysisTabSection />}
              </div>
            </Background>
            <div
              style={{
                display: "flex",
                height: "100px",
                justifyContent: "flex-end",
                alignItems: "end",
                position: "fixed",
                bottom: "0",
                right: "0",
              }}
            >
              <button
                className={`scroll-to-top-button ${
                  showScrollButton ? "scroll-to-top-button-visible" : ""
                }`}
                onClick={scrollToTop}
              >
                <ScrollToTopArrow />
              </button>
            </div>
          </div>
        </div>
      ) : (
        <div>
          <HeaderDesktop />
          <Background>
            <BannerProfileSection
              collectionData={collectionData}
              isFetchingData={isFetchingData}
              isFetchingCollectionDetails={isFetchingCollectionDetails}
            />
            <DescriptionSection collectionData={collectionData} />
            <div className="section-tab-container">
              <SectionTab
                label="Items"
                active={activeTab === "Items"}
                onClick={() => handleTabClick("Items")}
                info="The 'Items' tab displays all NFT tokens within the collection."
              />
              <SectionTab
                label="Activity"
                active={activeTab === "Activity"}
                onClick={() => handleTabClick("Activity")}
                info="The 'Activity' tab displays the complete transfer history of the tokens in this collection."
              />
            </div>
            <div className="items-container-with-filter-sections-container">
              {activeTab === "Items" && (
                <ItemsTabSection
                  pageType={"CollectionPage"}
                  activeTab={activeTab}
                  setDataArray={setTokenDetailsArray}
                  fetchPaginatedData={fetchPaginatedTokens}
                  dataArray={tokenDetailsArray}
                  aggregated_traits={collectionData?.aggregated_traits}
                  dataTotalSize={collectionData?.tokensSize}
                  collectionData={collectionData}
                  isFetchingData={isFetchingData}
                  setConditionType={setConditionType}
                  conditionType={conditionType}
                  listedTokensCount={listedTokensCount}
                  setListedTokensCount={setListedTokensCount}
                  traitCount={traitCount}
                />
              )}
            </div>
            {activeTab === "Activity" && (
              <ItemsTabSection
                pageType={"CollectionPage"}
                activeTab={activeTab}
                setDataArray={setTransferHistory}
                fetchPaginatedData={fetchPaginatedTransferHistory}
                dataArray={transferHistory}
                aggregated_traits={collectionData?.aggregated_traits}
                dataTotalSize={0}
                collectionData={collectionData}
                isFetchingData={isFetchingData}
                setConditionType={setConditionType}
                conditionType={conditionType}
                listedTokensCount={listedTokensCount}
                setListedTokensCount={setListedTokensCount}
                activitySortingState={activitySortingState}
                setActivitySortingState={setActivitySortingState}
                traitCount={traitCount}
              />
            )}
            {activeTab === "Analysis" && <AnalysisTabSection />}
          </Background>
          <div
            style={{
              display: "flex",
              height: "100px",
              justifyContent: "flex-end",
              alignItems: "end",
              position: "fixed",
              bottom: "0",
              right: "0",
            }}
          >
            <button
              className={`scroll-to-top-button ${
                showScrollButton && "scroll-to-top-button-visible"
              }`}
              onClick={scrollToTop}
            >
              <ScrollToTopArrow />
            </button>
          </div>
        </div>
      )}
    </div>
  );
}
