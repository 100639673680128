import { useNavigate } from "react-router-dom";

export default function LoginWelcomePart(params) {
  const navigate = useNavigate();
  return (
    <div className="login-page-welcome-animaion signup-welcome-textbox">
      <div
        className="d-flex flex-column align-items-center"
        style={{ marginBottom: "2vw" }}
      >
        <div
          style={{
            fontSize: "5vw",
            height: "5vw",
            fontWeight: "800",
            marginBottom: "1vw",
          }}
        >
          Welcome!
        </div>

        {/* <div className="d-flex">
          <div style={{ marginRight: "0.9vw", fontSize: "1.3vw" }}>
            Don't have an account?
          </div>
          <div
            onClick={() => {
              navigate("/signup");
            }}
            style={{
              fontSize: "1.3vw",
              cursor: "pointer",
              fontWeight: "700",
              textDecoration: "underline",
              paddingBottom: "0.2vw",
            }}
          >
            Create Account
          </div>
        </div> */}
      </div>
    </div>
  );
}
