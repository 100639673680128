import React, { useEffect, useState, useCallback } from "react";
import ProvenanceTokenDetailsSection from "../../components/ProvenanceReportPage/ProvenanceTokenDetailsSection";
import ProvenanceAllOtherDetails from "../../components/ProvenanceReportPage/ProvenanceAllOtherDetails";
import ProvenanceArchiveNotesSection from "../../components/ProvenanceReportPage/ProvenanceArchiveNotesSection";
import HeaderDesktop from "../../components/Homepage/Header/HeaderDesktop";
import Background from "../../components/UtilComponents/Background";
import { fetchTokenDetails } from "../../api/tokenDetailsApi";
import { fetchCollectionDetails } from "../../api/collectionDetailsApi";
import { useParams } from "react-router-dom";
import FooterSection from "../../components/FooterSection";
import { generatePDF } from "../../api/pdfApi";
import { getPdfFromS3Key } from "../../utils/commonUtils/Provenance";
import nftTransferApi from "../../api/nftTransferApi";
import { Helmet } from "react-helmet";

const ProvenanceReportPage = () => {
  const isPdf = useParams().pdf || false;
  const [itemData, setItemData] = useState(null);
  const [collectionData, setCollectionData] = useState({});
  const { collectionId, itemId } = useParams();

  const [isFetchingData, setIsFetchingData] = useState(false);
  const [downloadClicked, setDownloadClicked] = useState(false);
  const [tokenTransferHistory, setTokenTransferHistory] = useState([]);
  const [
    transferHistoryPaginatedAPIMetadata,
    setTransferHistoryPaginatedAPIMetadata,
  ] = useState({
    more: true,
    currentPage: 0,
    limit: isPdf ? 500 : 32,
    sortKey: "event_timestamp",
    sortDirection: -1,
    currentCount: 0,
  });

  function handleDownloadClick() {
    const contractAddress = collectionId;
    const tokenId = itemId;
    const url = window.location.origin;
    setDownloadClicked(true);
    generatePDF(contractAddress, tokenId, url)
      .then((s3PdfKey) => {
        const url = s3PdfKey ? getPdfFromS3Key(s3PdfKey) : null;
        if (!url || url === null) throw new Error("PDF URL not found");
        window.open(url, "_blank");
      })
      .catch((error) => {
        console.error("Error processing the PDF download:", error);
      })
      .finally(() => {
        setDownloadClicked(false);
      });
  }

  useEffect(() => {
    const contractAddress = collectionId;
    const tokenId = itemId;
    fetchPaginatedTransferHistory();
    fetchTokenDetails(contractAddress, tokenId) // This is a function from tokenDetailsApi.js that fetches the token details from the backend.
      .then((tokenDetail) => {
        setItemData(tokenDetail);
      })
      .catch((error) => {});

    fetchCollectionDetails(contractAddress) // This is a function from tokenDetailsApi.js that fetches the token details from the backend.
      .then((collectionDetail) => {
        setCollectionData(collectionDetail);
      })
      .catch((error) => {});
  }, []);

  const fetchPaginatedTransferHistory = async (sort, direction, refresh) => {
    if (
      refresh === false &&
      transferHistoryPaginatedAPIMetadata.more === false
    ) {
      return;
    }
    if (isFetchingData) return;

    setIsFetchingData(true);
    nftTransferApi
      .fetchNftTransfers(
        itemId,
        collectionId,
        refresh ? 1 : transferHistoryPaginatedAPIMetadata.currentPage + 1,
        transferHistoryPaginatedAPIMetadata.limit,
        sort || transferHistoryPaginatedAPIMetadata.sortKey,
        direction || transferHistoryPaginatedAPIMetadata.sortDirection
      )
      .then((response) => {
        if (refresh)
          setTokenTransferHistory(
            (prevTransferHistory) => response.nftTransfers || []
          );
        else
          setTokenTransferHistory((prevTransferHistory) => [
            ...prevTransferHistory,
            ...(response.nftTransfers || []),
          ]);
        const limit = transferHistoryPaginatedAPIMetadata.limit;
        const resCount = response.nftTransfers.length;
        let more = true;
        if (!resCount || resCount < limit) more = false;
        if (response) {
          setTransferHistoryPaginatedAPIMetadata({
            ...transferHistoryPaginatedAPIMetadata,
            more: more,
            currentPage: parseInt(response?.page) || 1,
            sortKey: response?.sortKey,
            sortDirection: response?.sortDirection || -1,
            currentCount:
              transferHistoryPaginatedAPIMetadata.currentCount +
              (response.count || 0),
          });
        }
      })
      .catch((error) => {
        setTokenTransferHistory([]);
      })
      .finally(() => {
        setIsFetchingData(false);
      });
  };

  return (
    <Background>
      <Helmet>
        <title>Metajungle Provenance Report</title>
        <meta
          name="description"
          content="Welcome to the Provenance on Metajungle Website!

          Now users can add data/Provenance on their NFT's on Metajungle website. This page shows detailed report for provenance added on Token/NFT!"
        />
      </Helmet>
      <HeaderDesktop isPDF={isPdf} />
      <div>
        <ProvenanceTokenDetailsSection
          itemData={itemData}
          collectionData={collectionData}
          isPDF={isPdf}
          downloadClicked={downloadClicked}
          setDownloadClicked={handleDownloadClick}
        />
        <ProvenanceAllOtherDetails
          isProvenanceReportPage={true}
          itemData={itemData}
          collectionData={collectionData}
          isPDF={isPdf}
          setItemData={setItemData}
          tokenTransferHistory={tokenTransferHistory}
          fetchPaginatedTransferHistory={fetchPaginatedTransferHistory}
          transferHistoryPaginatedAPIMetadata={
            transferHistoryPaginatedAPIMetadata
          }
          isFetchingData={isFetchingData}
        />
        <ProvenanceArchiveNotesSection
          itemData={itemData}
          collectionData={collectionData}
          isPDF={isPdf}
        />{" "}
        {/* {downloadClicked && (
          <ProvenanceReportPDFPage
            setDownloadClicked={setDownloadClicked}
            downloadClicked={downloadClicked}
            isProvenanceReportPage={true}
            itemData={itemData}
            collectionData={collectionData}
            setItemData={setItemData}
            tokenTransferHistory={tokenTransferHistory}
            fetchPaginatedTransferHistory={fetchPaginatedTransferHistory}
          />
        )} */}
      </div>

      <FooterSection />
    </Background>
  );
};

export default ProvenanceReportPage;
