// src/actions/filterSectionActions.js

export const setCreatorPageFiltersOwnedTokenStatus = (status) => ({
  type: "SET_OWNED_TOKEN_STATUS",
  payload: status,
});
export const setCreatorPageFiltersCreatedTokenStatus = (status) => ({
  type: "SET_CREATED_TOKEN_STATUS",
  payload: status,
});
export const setCreatorPageFiltersMintedTokenStatus = (status) => ({
  type: "SET_MINTED_TOKEN_STATUS",
  payload: status,
});

export const setCreatorPageFiltersActivityStatus = (status) => ({
  type: "SET_ACTIVITY_STATUS",
  payload: status,
});

export const setCreatorPageFiltersMinListingPrice = (
  minListingPriceFilter
) => ({
  type: "SET_MIN_LISTING_PRICE_FILTER",
  payload: minListingPriceFilter,
});

export const setCreatorPageFiltersMaxListingPrice = (
  maxListingPriceFilter
) => ({
  type: "SET_MAX_LISTING_PRICE_FILTER",
  payload: maxListingPriceFilter,
});

export const setCreatorPageFiltersListingPriceCurrency = (
  listingPriceCurrencyFilter
) => ({
  type: "SET_LISTING_PRICE_CURRENCY_FILTER",
  payload: listingPriceCurrencyFilter,
});

export const setCreatorPageFiltersListingPriceFilter = ({
  minListingPriceFilter,
  maxListingPriceFilter,
  listingPriceCurrencyFilter,
}) => ({
  type: "SET_LISTING_PRICE_FILTER",
  payload: {
    minListingPriceFilter,
    maxListingPriceFilter,
    listingPriceCurrencyFilter,
  },
});

export const setCreatorPageFiltersOwnedTokenSorting = ({
  tokensSortText,
  tokensSortKey,
  tokensSortDirection,
}) => ({
  type: "SET_OWNED_TOKENS_SORTING",
  payload: { tokensSortText, tokensSortKey, tokensSortDirection },
});
export const setCreatorPageFiltersCreatedTokenSorting = ({
  tokensSortText,
  tokensSortKey,
  tokensSortDirection,
}) => ({
  type: "SET_CREATED_TOKENS_SORTING",
  payload: { tokensSortText, tokensSortKey, tokensSortDirection },
});
export const setCreatorPageFiltersMintedTokenSorting = ({
  tokensSortText,
  tokensSortKey,
  tokensSortDirection,
}) => ({
  type: "SET_MINTED_TOKENS_SORTING",
  payload: { tokensSortText, tokensSortKey, tokensSortDirection },
});

export const setCreatorPageFiltersOwnedTokensSelectedTraits = ({
  traitType,
  traitValue,
}) => ({
  type: "SET_OWNED_TOKENS_SELECTED_TRAITS",
  payload: { traitType, value: traitValue },
});
export const setCreatorPageFiltersCreatedTokensSelectedTraits = ({
  traitType,
  traitValue,
}) => ({
  type: "SET_CREATED_TOKENS_SELECTED_TRAITS",
  payload: { traitType, value: traitValue },
});
export const setCreatorPageFiltersMintedTokensSelectedTraits = ({
  traitType,
  traitValue,
}) => ({
  type: "SET_MINTED_TOKENS_SELECTED_TRAITS",
  payload: { traitType, value: traitValue },
});

export const setCreatorPageFiltersActivitySelectedTraits = ({
  traitType,
  traitValue,
}) => ({
  type: "SET_ACTIVITY_SELECTED_TRAITS",
  payload: { traitType, value: traitValue },
});
