import React, { useEffect, useState } from "react";
import { trimMiddleText } from "../../../utils/commonUtils/Utils";
import RemoveButton from "../../../Icons/CollectionPageIcons/RemoveButton";
import UserModal from "../../../utils/ItemPageUtils/UserModal";
import { fetchWalletDetails } from "../../../api/walletDetailApi";

const ERC1155OwnersModal = ({ itemData, setToggleOwners }) => {
  const [ownerData, setOwnerData] = useState([]);
  const [hovered, setHovered] = useState("");

  useEffect(() => {
    const ownerAddressList = itemData.openseaDetails?.owners?.map(
      (owner) => owner.address
    );
    fetchWalletDetails(ownerAddressList).then((data) => {
      if (!Array.isArray(data)) data = [data];
      setOwnerData(data);
    });
  }, [itemData]);

  return (
    <div className="modal-overlay">
      <div className="modal-component">
        <span
          style={{
            alignSelf: "flex-end",
            marginBottom: "1.5em",
            cursor: "pointer",
          }}
          onClick={() => setToggleOwners(false)}
        >
          <RemoveButton color={"black"} />
        </span>
        {ownerData?.map((owner, index) => {
          return (
            <div key={index} className="erc1155-modal-container ">
              <span
                onMouseEnter={() => setHovered(owner)}
                onMouseLeave={() => setHovered("")}
              >
                <img
                  src={owner?.openseaDetails?.profile_image_url}
                  alt="owner-pic"
                  style={{
                    width: "2em",
                    height: "2em",
                    borderRadius: "50%",
                  }}
                />
                <span className="owner-name-erc1155" key={owner}>
                  {trimMiddleText(
                    owner?.openseaDetails?.username || owner.walletAddress
                  )}
                  {hovered === owner && (
                    <UserModal walletAddress={owner?.walletAddress} />
                  )}
                </span>
              </span>
              <span
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "0.2em",
                }}
              >
                <span style={{ color: "#747474", fontSize: "0.8em" }}>
                  Items:
                </span>
                <span style={{ color: "#183b56", fontSize: "1em" }}>
                  {owner?.count}
                </span>
              </span>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ERC1155OwnersModal;
