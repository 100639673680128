import React from "react";

const ThreeGridViewIcon = () => {
  return (
    <div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1.4em"
        height="1.4em"
        viewBox="0 0 28 28"
        fill="none"
      >
        <path
          d="M10.5 3.5H5.83333C5.21449 3.5 4.621 3.74583 4.18342 4.18342C3.74583 4.621 3.5 5.21449 3.5 5.83333V10.5C3.5 11.1188 3.74583 11.7123 4.18342 12.1499C4.621 12.5875 5.21449 12.8333 5.83333 12.8333H10.5C11.1188 12.8333 11.7123 12.5875 12.1499 12.1499C12.5875 11.7123 12.8333 11.1188 12.8333 10.5V5.83333C12.8333 5.21449 12.5875 4.621 12.1499 4.18342C11.7123 3.74583 11.1188 3.5 10.5 3.5ZM5.83333 10.5V5.83333H10.5V10.5H5.83333Z"
          fill="#231F20"
        />
        <path
          d="M22.166 3.5H17.4993C16.8805 3.5 16.287 3.74583 15.8494 4.18342C15.4118 4.621 15.166 5.21449 15.166 5.83333V10.5C15.166 11.1188 15.4118 11.7123 15.8494 12.1499C16.287 12.5875 16.8805 12.8333 17.4993 12.8333H22.166C22.7849 12.8333 23.3783 12.5875 23.8159 12.1499C24.2535 11.7123 24.4993 11.1188 24.4993 10.5V5.83333C24.4993 5.21449 24.2535 4.621 23.8159 4.18342C23.3783 3.74583 22.7849 3.5 22.166 3.5ZM17.4993 10.5V5.83333H22.166V10.5H17.4993Z"
          fill="#231F20"
        />
        <path
          d="M10.5 15.1667H5.83333C5.21449 15.1667 4.621 15.4125 4.18342 15.8501C3.74583 16.2877 3.5 16.8812 3.5 17.5V22.1667C3.5 22.7855 3.74583 23.379 4.18342 23.8166C4.621 24.2542 5.21449 24.5 5.83333 24.5H10.5C11.1188 24.5 11.7123 24.2542 12.1499 23.8166C12.5875 23.379 12.8333 22.7855 12.8333 22.1667V17.5C12.8333 16.8812 12.5875 16.2877 12.1499 15.8501C11.7123 15.4125 11.1188 15.1667 10.5 15.1667ZM5.83333 22.1667V17.5H10.5V22.1667H5.83333Z"
          fill="#231F20"
        />
        <path
          d="M22.166 15.1667H17.4993C16.8805 15.1667 16.287 15.4125 15.8494 15.8501C15.4118 16.2877 15.166 16.8812 15.166 17.5V22.1667C15.166 22.7855 15.4118 23.379 15.8494 23.8166C16.287 24.2542 16.8805 24.5 17.4993 24.5H22.166C22.7849 24.5 23.3783 24.2542 23.8159 23.8166C24.2535 23.379 24.4993 22.7855 24.4993 22.1667V17.5C24.4993 16.8812 24.2535 16.2877 23.8159 15.8501C23.3783 15.4125 22.7849 15.1667 22.166 15.1667ZM17.4993 22.1667V17.5H22.166V22.1667H17.4993Z"
          fill="#231F20"
        />
      </svg>
    </div>
  );
};

export default ThreeGridViewIcon;
