export default function HoverdAnimation(params) {
  return (
    <div
      className="collection-tokencard-hover-animation w-100 d-flex align-items-center justify-content-center"
      style={{ height: "38%" }}
    >
      <h3 style={{ fontSize: "1.3vw" }}>View Token</h3>
    </div>
  );
}
