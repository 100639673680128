import React, { useState, useEffect } from "react";
import "../../../styles/itemPageStyles/ItemPage.css";
import {
  isNullOrUndefined,
  trimLongText,
} from "../../../utils/commonUtils/Utils";
import TokenRarityInfoModal from "../TokenRarityInfoModal";
import InfoIcon from "../../../Icons/InfoIcon";
import { fetchFloorValueForTrait } from "../../../api/tokenDetailsApi";
import { prettyPrice } from "../../../utils/commonUtils/PriceUtils";
import AddInfoModal from "../../CommonComponents/AddInfoModal";
import useIsMobile from "../../../customHooks/UseIsMobile";

const calculateRarityScoreForTrait = (
  aggregated_traits,
  traitType,
  traitValue
) => {
  const occurrences = (aggregated_traits || [])[traitType];

  if (isNullOrUndefined(occurrences) || occurrences[traitValue] === 0) {
    return 0 + "%"; // or any other default value as per your requirement
  }

  const totalOccurrences = Object.values(occurrences).reduce(
    (sum, count) => sum + count,
    0
  );
  const traitOccurrence = occurrences[traitValue];

  // Calculate rarity based on the frequency of the trait value among all trait values
  const rarityScore = (1 / (traitOccurrence / totalOccurrences)).toFixed(2);
  const rarityScorePercentage =
    ((traitOccurrence / totalOccurrences) * 100).toFixed(2) + "%";
  return {
    rarityScore,
    traitOccurrence,
    totalOccurrences,
    rarityScorePercentage,
  };
};

const Traits = ({
  traits,
  aggregated_traits,
  contractAddress,
  collectionSlug,
  collectionCreator,
  isProvenanceReportPage,
  isPDF,
}) => {
  const isMobile = useIsMobile();
  const [hoveredTrait, setHoveredTrait] = useState(null);
  const [openTraitRarityModal, setOpenTraitRarityModal] = useState(false);
  const [floorValues, setFloorValues] = useState({});

  useEffect(() => {
    const fetchFloorValues = async () => {
      if (!traits || !Array.isArray(traits) || traits.length === 0) return;

      const promises = traits.map(async (trait) => {
        let floorValue = 0;
        try {
          floorValue = await fetchFloorValueForTrait(
            contractAddress,
            collectionSlug,
            collectionCreator,
            {
              traitType: trait.trait_type,
              traitValue: trait.value,
            }
          );
        } catch (e) {
          console.error(
            `Error fetching floor value for ${trait.trait_type}-${trait.value}:`,
            e
          );
        }
        return { key: `${trait.trait_type}-${trait.value}`, value: floorValue };
      });

      const results = await Promise.all(promises);
      const values = results.reduce((acc, { key, value }) => {
        acc[key] = value;
        return acc;
      }, {});

      setFloorValues(values);
    };

    fetchFloorValues();
  }, [traits, contractAddress, collectionSlug]);

  const toggleInfoModal = (trait) => {
    setHoveredTrait(trait);
    setOpenTraitRarityModal(true);
  };

  const closeInfoModal = () => {
    setOpenTraitRarityModal(false);
  };

  const getTraitFloorValue = (trait) => {
    // return;
    let floor = floorValues[trait.trait_type + "-" + trait.value];
    if (isNullOrUndefined(floor) || floor === 0) return "None Listed";
    floor = prettyPrice(floor);
    return floor;
  };

  return (
    <div className="traits">
      {traits && traits?.length > 0 ? (
        traits?.map((trait, index) => (
          <div key={index} className={isPDF ? "trait-pdf" : "trait"}>
            {Object.entries(trait).map(([key, value], i) => (
              <React.Fragment key={i}>
                {key === "trait_type" ? (
                  <>
                    <div className="trait-value-container h-100">
                      <div
                        className="trait-type"
                        style={{ fontSize: isMobile && "1rem" }}
                      >
                        <AddInfoModal
                          info={value}
                          renderDirection={"up-right"}
                          styleObj={{ fontWeight: "bold", cursor: "pointer" }}
                        >
                          {trimLongText(value, 25)}
                        </AddInfoModal>
                      </div>

                      <div
                        className="trait-value d-flex flex-column justify-content-around align-items-center"
                        style={{ height: "auto", fontSize: isMobile && "1rem" }}
                      >
                        <div
                          className="d-flex justify-content-center align-items-center"
                          style={{ height: "auto" }}
                        >
                          {trimLongText(trait.value, 50)}
                        </div>

                        <div
                          className="d-flex justify-content-center align-items-center"
                          style={{
                            fontSize: "0.9em",
                            marginTop: "0.2em",
                          }}
                        >
                          <div>
                            {
                              calculateRarityScoreForTrait(
                                aggregated_traits,
                                value,
                                trait.value
                              ).rarityScorePercentage
                            }
                          </div>
                          {!isProvenanceReportPage &&
                            calculateRarityScoreForTrait(
                              aggregated_traits,
                              value,
                              trait.value
                            ).rarityScorePercentage && (
                              <div
                                onMouseEnter={() =>
                                  toggleInfoModal(trait.value)
                                }
                                onMouseLeave={closeInfoModal}
                                style={{ cursor: "pointer" }}
                              >
                                <div
                                  style={{
                                    marginBottom: "0.2vw",
                                    marginLeft: "0.4vw",
                                  }}
                                >
                                  <InfoIcon />
                                </div>
                              </div>
                            )}
                        </div>
                        {aggregated_traits &&
                          calculateRarityScoreForTrait(
                            aggregated_traits,
                            value,
                            trait.value
                          ).traitOccurrence && (
                            <p
                              style={{ fontSize: "0.8em", marginTop: "0.2em" }}
                            >
                              {
                                calculateRarityScoreForTrait(
                                  aggregated_traits,
                                  value,
                                  trait.value
                                ).traitOccurrence
                              }
                              /
                              {
                                calculateRarityScoreForTrait(
                                  aggregated_traits,
                                  value,
                                  trait.value
                                ).totalOccurrences
                              }
                            </p>
                          )}
                      </div>
                      {
                        <div
                          className="d-flex"
                          style={{
                            fontSize: "0.8rem",
                            gap: "0.2em",
                          }}
                        >
                          <div style={{ whiteSpace: "nowrap" }}>
                            Floor Value:
                          </div>
                          <div
                            style={{
                              color: "#2fb484",
                              fontWeight: "bold",
                              whiteSpace: "nowrap",
                            }}
                          >
                            {getTraitFloorValue(trait)}
                          </div>
                          {getTraitFloorValue(trait) !== "None Listed" && (
                            <div>ETH</div>
                          )}
                        </div>
                      }
                    </div>
                    {openTraitRarityModal && hoveredTrait === trait.value && (
                      <TokenRarityInfoModal
                        traitValue={trait.value}
                        tokenRarityScore={
                          calculateRarityScoreForTrait(
                            aggregated_traits,
                            value,
                            trait.value
                          ).rarityScore
                        }
                        tokenOccurences={
                          calculateRarityScoreForTrait(
                            aggregated_traits,
                            value,
                            trait.value
                          ).traitOccurrence
                        }
                        totalOccurences={
                          calculateRarityScoreForTrait(
                            aggregated_traits,
                            value,
                            trait.value
                          ).totalOccurrences
                        }
                        tokenRarityScorePercentage={
                          calculateRarityScoreForTrait(
                            aggregated_traits,
                            value,
                            trait.value
                          ).rarityScorePercentage
                        }
                      />
                    )}
                  </>
                ) : (
                  <h6 className="trait-floor-value">{}</h6>
                )}
              </React.Fragment>
            ))}
          </div>
        ))
      ) : (
        <span>No traits available for this token.</span>
      )}
    </div>
  );
};

export default Traits;
