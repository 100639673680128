import React, { useEffect, useState } from "react";
import "../../../../styles/itemPageStyles/ItemPage.css";

import OpenSeaImage from "../../../../assets/images/NftMarketplaces/OpenSeaImage.png";
import BlurMPImage from "../../../../assets/images/NftMarketplaces/BlurMPImage.png";
import RaribleImage from "../../../../assets/images/NftMarketplaces/RaribleImage.png";
import LooksRareImage from "../../../../assets/images/NftMarketplaces/LooksRareImage.png";
import { getEthToUsdExchangeRate } from "../../../../api/ethExchangeRateApi";
import {
  ethToUSD,
  prettyEthToUSD,
  prettyUSDWithCommaSplit,
} from "../../../../utils/commonUtils/PriceUtils";

import { getTimeAgoFromDate } from "../../../../utils/commonUtils/timeUtils";
import { Link } from "react-router-dom";

function CurrentPriceSectionRow({
  marketplacePricingObj,
  marketPlace,
  itemData,
}) {
  const [imageSource, setImageSource] = useState(OpenSeaImage);
  const [ethToUsdExchangeRate, setEthToUsdExchangeRate] = useState(0);

  useEffect(() => {
    const exchangeRate = getEthToUsdExchangeRate();
    setEthToUsdExchangeRate(exchangeRate);
  }, []);

  useEffect(() => {
    if (marketPlace === "opensea") {
      setImageSource(OpenSeaImage);
    } else if (marketPlace === "looksrare") {
      setImageSource(LooksRareImage);
    } else if (marketPlace === "rarible") {
      setImageSource(RaribleImage);
    } else if (marketPlace === "blur") {
      setImageSource(BlurMPImage);
    }
  }, [marketPlace]);

  let linkTo;
  switch (marketPlace) {
    case "opensea":
      linkTo = `https://opensea.io/assets/ethereum/${itemData?.contractAddress}/${itemData?.decimalTokenId}`;
      break;
    case "blur":
      linkTo = `https://blur.io/asset/${itemData?.openseaDetails?.contract}/${itemData?.decimalTokenId}`;
      break;
    case "looksrare":
      linkTo = `https://looksrare.org/collections/${itemData?.contractAddress}/${itemData?.decimalTokenId}`;
      break;
    default:
      linkTo = "/" + marketPlace;
      break;
  }

  return (
    <div className="current-prices-row">
      <div className="current-prices-row-marketplace-icon-block">
        <img
          id="logo"
          src={imageSource}
          alt="logo"
          className="current-prices-row-marketplace-icon"
        />
      </div>

      <div className="current-prices-row-data-block">
        <div className="current-prices-row-2-row-block">
          <Link
            className="current-prices-row-2-row-block-upper-value link-field"
            to={linkTo}
            target="_blank"
            rel="noopener noreferrer"
          >
            {marketPlace === "opensea"
              ? "OpenSea"
              : marketPlace === "blur"
              ? "BLUR"
              : marketPlace === "looksrare"
              ? "LooksRare"
              : marketPlace}
          </Link>

          <div className="current-prices-row-2-row-block-lower-value">
            Last Updated:{" "}
            {getTimeAgoFromDate(marketplacePricingObj?.lastFetchTime)}
          </div>
        </div>
        <div className="current-prices-row-2-row-block current-prices-row-2-row-block-right">
          <div className="current-prices-row-2-row-block-upper-value">
            {marketplacePricingObj?.lastFetchListingPrice
              ? marketplacePricingObj?.lastFetchListingPrice + " ETH"
              : "Not Listed"}
          </div>
          {marketplacePricingObj?.lastFetchListingPrice && (
            <div className="current-prices-row-2-row-block-lower-value">
              {prettyUSDWithCommaSplit(
                ethToUSD(
                  marketplacePricingObj?.lastFetchListingPrice,
                  ethToUsdExchangeRate
                )
              )}{" "}
              USD
            </div>
          )}
          {!marketplacePricingObj?.lastFetchListingPrice && (
            <div
              className="current-prices-row-2-row-block-lower-value"
              style={{ height: "0.2vw" }}
            ></div>
          )}
        </div>
      </div>
    </div>
  );
}

export default CurrentPriceSectionRow;
