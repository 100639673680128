import React, { useState, useEffect } from "react";
import OpenSeaImage from "../../assets/images/OpenSeaImage.png";
import UserReport from "../../Icons/UserReport";
import { Link } from "react-router-dom";
import FavouriteIconFilled from "../../Icons/FavouriteIconFilled";
import FavouritesIconImage from "../../Icons/FavouriteIconImage";
import {
  addItemInUserFavourite,
  removeItemFromFavourite,
} from "../../api/mjUserApi";
import AddInfoModal from "../CommonComponents/AddInfoModal";

const CollectionIcons = ({
  openSeaURL,
  contractId,
  priority,
  walletAddress,
  userId,
  collectionId,
}) => {
  const [isFavouriteCreator, setIsFavouriteCreator] = useState(false);
  const [isFavouriteCollection, setIsFavouriteCollection] = useState(false);

  const handleFavouriteCollection = async () => {
    const collectionToAddToFavorites = collectionId;
    if (!userId) return;
    try {
      if (isFavouriteCollection) {
        await removeItemFromFavourite(userId, collectionToAddToFavorites);
      } else {
        await addItemInUserFavourite(
          userId,
          collectionToAddToFavorites,
          "COLLECTION"
        );
      }
      setIsFavouriteCollection(!isFavouriteCollection);
    } catch (e) {}
  };

  const handleFavouriteCreator = async () => {
    const creatorToAddToFavorites = walletAddress;
    if (!userId) return;
    try {
      if (isFavouriteCreator) {
        await removeItemFromFavourite(userId, creatorToAddToFavorites);
      } else {
        await addItemInUserFavourite(
          userId,
          creatorToAddToFavorites,
          "CREATORS"
        );
      }
      setIsFavouriteCreator(!isFavouriteCreator);
    } catch (e) {}
  };

  return (
    <div className="collection-info-icons-row">
      {/* {userId && collectionId && (
        <AddInfoModal
          info={"Add to Favorites"}
          renderDirection={"up-right"}
          styleObj={{ fontWeight: "bold" , cursor:"pointer"}}
        >
          <span
            onClick={handleFavouriteCollection}
            style={{ cursor: "pointer" }}
          >
            {" "}
            {isFavouriteCollection ? (
              <FavouriteIconFilled />
            ) : (
              <FavouritesIconImage />
            )}{" "}
          </span>
        </AddInfoModal>
      )} */}
      {/* {userId && walletAddress && (
        <AddInfoModal
          info={"Add to Favorites"}
          renderDirection={"up-left"}
          styleObj={{ fontWeight: "bold", cursor: "pointer" }}
        >
          <span onClick={handleFavouriteCreator} style={{ cursor: "pointer" }}>
            {" "}
            {isFavouriteCreator ? (
              <FavouriteIconFilled />
            ) : (
              <FavouritesIconImage />
            )}{" "}
          </span>
        </AddInfoModal>
      )} */}

      {userId && (openSeaURL || walletAddress) && (
        <div className="collection-icon-division" />
      )}
      {openSeaURL && (
        <Link to={openSeaURL} target="_blank">
          <img
            src={OpenSeaImage}
            alt="Marketplace Logo"
            height={"19vw"}
            width={"19vw"}
          />
        </Link>
      )}
      <AddInfoModal
        info={"View Wallet Report"}
        renderDirection={"up-left"}
        styleObj={{ fontWeight: "bold", cursor: "pointer" }}
      >
        {walletAddress && (
          <UserReport url={`/wallet_report/${walletAddress}`} />
        )}
      </AddInfoModal>
    </div>
  );
};

export default CollectionIcons;
