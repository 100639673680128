import LeftArrowIcon from "../../Icons/CollectionPageIcons/LeftArrowIcon";
import RightArrowIcon from "../../Icons/CollectionPageIcons/RightArrowIcon";

let PaginationButtonsComponent = ({
  currentPage,
  updateCurrentPage,
  nextPageClick,
  prevPageClick,
  totalPages,
  buttonStyleObj,
}) => {
  let startPage, endPage;

  startPage = Math.floor((currentPage - 1) / 5) * 5 + 1;
  endPage = totalPages ? Math.min(startPage + 5, totalPages) : startPage + 5;

  const pageNumbers = Array.from(
    { length: 5 },
    (_, index) => index + startPage
  );

  return (
    <>
      <div
        className="pagination-buttons-container"
        style={{ marginTop: "auto" }}
      >
        {" "}
        <button
          className="items_tab_header_buttons-home-page "
          onClick={() => {
            prevPageClick();
          }}
          style={buttonStyleObj || {}}
        >
          {<LeftArrowIcon />}
        </button>
        {pageNumbers.map((pageNumber, index) => {
          return (
            <div
              onClick={() => {
                updateCurrentPage(pageNumber);
              }}
              style={{
                color: currentPage === pageNumber ? "#2fb484" : "#183b56",
                fontWeight: "bold",
                cursor: "pointer",
                fontSize: "0.85em",
              }}
            >
              {pageNumber || 1}
            </div>
          );
        })}
        <button
          className="items_tab_header_buttons-home-page "
          onClick={() => {
            nextPageClick();
          }}
          style={buttonStyleObj || {}}
        >
          {<RightArrowIcon />}
        </button>
      </div>
    </>
  );
};

export default PaginationButtonsComponent;
