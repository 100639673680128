import "../../styles/Loading.css";

export default function LoadingText({ height, width }) {
  return (
    <div
      className="loading-block-backgroundcolor-animation"
      style={{
        height: height,
        width: width,
        borderRadius: "2vw",
      }}
    />
  );
}
