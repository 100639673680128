// actions folder page
export const setFolderPageFiltersCollections = (status) => ({
  type: "SET_COLLECTION",
  payload: status,
});

export const setFolderPageFiltersTokenSorting = ({
  tokensSortText,
  tokensSortKey,
  tokensSortDirection,
}) => ({
  type: "SET_TOKENS_SORTING",
  payload: { tokensSortText, tokensSortKey, tokensSortDirection },
});

export const setFolderPageFiltersTokenSearchQuery = ({ searchQuery }) => ({
  type: "SET_TOKENS_SELECTED_TRAITS",
  payload: { searchQuery },
});

export const setFolderPageFiltersTokensSelectedOnSearchQuery = ({
  searchQueryTokensSelected,
}) => ({
  type: "SET_TOKENS_SELECTED_TRAITS",
  payload: { searchQueryTokensSelected },
});
