import { createSlice } from "@reduxjs/toolkit";

export const userIdToUserObjMapSlice = createSlice({
  name: "userIdToUserObjMap",
  initialState: {},
  reducers: {
    addUserIdToUserObjMap: (state, action) => {
      const { userDetailsMap } = action.payload;
      state = { ...state, ...userDetailsMap };

      return state;
    },
    updateObjWithUserId: (state, action) => {
      const { userId, userObj } = action.payload;

      if (userId && userObj) {
        state[userId] = userObj;
      }

      return state;
    },
    updateObjWithSpamUser: (state, action) => {
      const { userId, currentlyUserIsSpam } = action.payload;
      state[userId].spammer = currentlyUserIsSpam ? false : true;

      return state;
    },
  },
});

export const {
  addUserIdToUserObjMap,
  updateObjWithUserId,
  updateObjWithSpamUser,
} = userIdToUserObjMapSlice.actions;

export default userIdToUserObjMapSlice.reducer;
