import React, { useEffect, useRef, useState } from "react";
import useIsMobile from "../../customHooks/UseIsMobile";
import { atlasSearchApi } from "../../api/atlasSearchApi";
import CollectionsSearchResult from "./CollectionsSearchResult";
import "../../styles/CommonComponentsStyles/CollectionSearch.css";
import RemoveButton from "../../Icons/CollectionPageIcons/RemoveButton";
import SearchIcon from "../../Icons/CollectionPageIcons/SearchIcon";
import { isAddress } from "../../utils/commonUtils/Utils";

function CollectionSearchSection({
  collectionObjsSelected,
  addToCollectionObjsSelected,
  deleteFromCollectionObjsSelected,
  styleobj,
  allFiltersSelected,
}) {
  const [searchQuery, setSearchQuery] = useState("");
  const [searchResult, setSearchResult] = useState([]);
  const [showSearchResult, setShowSearchResult] = useState(false);
  const [isSearching, setIsSearching] = useState(false);

  const handleSearchChange = (event) => {
    if (event.target.value.length > 0) {
      setSearchQuery(event.target.value);

      setShowSearchResult(true);
      setIsSearching(true);

      if (!isAddress(event.target.value)) {
        let collectionParams = {
          size: 15,
          searchMethod: "collection_autocomplete",
          searchType: "collection",
          input: event.target.value,
        };

        atlasSearchApi
          .searchAtlas(collectionParams)
          .then((data) => {
            if (data.query !== event.target.value) return;
            setSearchResult(data.collectionDataList || []);
          })
          .catch((e) => {})
          .finally(() => {
            setIsSearching(false);
          });
      } else {
        // search by address
        let params = {
          size: 1,
          searchMethod: "address",
          input: event.target.value,
          searchType: "address",
        };
        atlasSearchApi
          .searchAtlas(params)
          .then((data) => {
            setSearchResult(data.collection?.collectionDataList || []);
          })
          .catch((e) => {})
          .finally(() => {
            setIsSearching(false);
          });
      }
    } else if (event.target.value.length == 0) {
      setShowSearchResult(false);
      setSearchResult([]);
    }
  };

  const handleSearchReset = () => {
    setSearchQuery("");
    setShowSearchResult(false);
    setSearchResult([]);
  };
  const isMobile = useIsMobile();

  const inputRef = useRef();
  const [searchInputFocus, setSearchInputFocus] = useState(false);

  useEffect(() => {
    // Click Events
    inputRef.current.addEventListener("click", (event) => {
      // Focus on the exact box
      setSearchInputFocus(true);
    });

    // When we click outside of a <div>
    inputRef.current.addEventListener("focusout", (event) => {
      setSearchInputFocus(false);
    });
  }, []);

  return (
    <div className="collection-search-wrapper" style={styleobj}>
      <div
        className="collection-search-input-wrapper"
        ref={inputRef}
        tabIndex="0"
        style={{
          border: searchInputFocus
            ? "calc(var(--base-font-size-collection-page) * (1.5 / 32)) solid #b4b8bf"
            : "",
        }}
      >
        <span className="collection-search-input-icon">
          <SearchIcon width={"1.4em"} height={"1.4em"} />
        </span>
        <input
          type="text"
          className={
            !isMobile
              ? "collection-search-input"
              : "collection-search-input-mobile"
          }
          placeholder="Search All Collections..."
          onChange={handleSearchChange}
        />
      </div>

      {showSearchResult && (
        <>
          <CollectionsSearchResult
            collectionsResult={searchResult}
            isSearching={isSearching}
            collectionObjsSelected={collectionObjsSelected}
            addToCollectionObjsSelected={addToCollectionObjsSelected}
            deleteFromCollectionObjsSelected={deleteFromCollectionObjsSelected}
            resetSearch={handleSearchReset}
            allFiltersSelected={allFiltersSelected}
          />
        </>
      )}
    </div>
  );
}

export default CollectionSearchSection;
