import { getEthToUsdExchangeRate } from "../../api/ethExchangeRateApi";

export function formatNumber(number) {
  try {
    if (!number) {
      return number;
    }
    let formattedNumber = number.toString();
    if (formattedNumber.includes(".")) {
      let parts = formattedNumber.split(".");
      let integerPart = parts[0];
      let decimalPart = parts[1];

      // Format the integer part with commas
      integerPart = integerPart
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");

      // If the integer part was greater than 999 before formatting (has comma after formatting),
      // then limit the decimal part to two digits
      if (integerPart.includes(",")) {
        decimalPart = decimalPart.substring(0, 2);
      }

      // Reassemble the formatted number
      formattedNumber = integerPart + "." + decimalPart;
    } else {
      formattedNumber = formattedNumber
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    return formattedNumber;
  } catch (e) {
    return "format Number Error";
  }
}

export function prettyPrice(price) {
  try {
    if (typeof price === "string") {
      price = parseFloat(price);
    }

    if (price === null || isNaN(price)) {
      return "0";
    }

    if (!price) {
      return "0";
    }

    if (price === 0) {
      return "0";
    }

    if (price > 0 && price < 0.00001) {
      return "< 0.00001";
    }
    if (price > 0 && price > 999999) {
      return "> 999,999";
    }
    if (price < 0 && Math.abs(price) < 0.00001) {
      return "> -0.00001";
    }
    if (price < 0 && Math.abs(price) > 999999) {
      return "< -999,999";
    }

    price = price.toFixed(3);
    price = parseFloat(price).toString();

    let prettyPrice = formatNumber(price);
    return prettyPrice;
  } catch (e) {
    console.error(e);
    return "Price Error";
  }
}

export function prettyPriceUSD(price) {
  try {
    if (!price) {
      return price;
    }

    if (price === 0) {
      return "0";
    }

    if (price > 0 && price < 0.00001) {
      return "< 0.00001";
    }
    if (price > 0 && price > 99999) {
      return "> 99,999";
    }
    if (price < 0 && Math.abs(price) < 0.00001) {
      return "> -0.00001";
    }
    if (price < 0 && Math.abs(price) > 99999) {
      return "< -99,999";
    }

    price = price.toFixed(2);
    price = parseFloat(price).toString();

    let prettyPrice = formatNumber(price);
    return prettyPrice;
  } catch (e) {
    return price;
  }
}

export function weiToEth(weiPrice) {
  let ethPrice = weiPrice / 10 ** 18;

  let finalethPrice = ethPrice.toFixed(2);

  return finalethPrice;
}

export function prettyWeiToEth(weiPrice) {
  let ethPrice = weiPrice / 10 ** 18;

  return prettyPrice(ethPrice);
}

export function weiToUSD(weiPrice, ethToUsdExchangeRate) {
  let ethPrice = weiToEth(weiPrice);
  let usdPrice = ethPrice * ethToUsdExchangeRate;

  return usdPrice;
}

export function prettyWeiToUSD(weiPrice, ethToUsdExchangeRate) {
  let ethPrice = weiToEth(weiPrice);
  let usdPrice = ethPrice * ethToUsdExchangeRate;
  return prettyPriceUSD(usdPrice);
}

export function ethToUSD(ethPrice, ethToUsdExchangeRate) {
  let usdPrice = ethPrice * ethToUsdExchangeRate;

  return usdPrice;
}

export function prettyEthToUSD(ethPrice, ethToUsdExchangeRate) {
  let usdPrice = ethPrice * ethToUsdExchangeRate;

  return prettyPriceUSD(usdPrice);
}

export function prettyVolumeScore(volumeScoreString) {
  let prettyScore = volumeScoreString?.toFixed(2);
  return prettyScore;
}

export function prettyUSDWithCommaSplit(price) {
  if (typeof price === "string") {
    price = parseFloat(price);
  }
  price = price.toFixed(2);
  let prettyPrice = price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return prettyPrice;
}
