import React, { useContext, useEffect, useRef, useState } from "react";
import TokenSearchSection from "../components/CommonComponents/TokenSearchSection";
import ProvenanceFavoritesSearch from "../components/UserProvenancePage/ProvenanceFavoritesSearch";
import ProvenanceCollectionsFilterSection from "../components/UserProvenancePage/ProvenanceCollectionsFilterSection";
import ProvenanceTokens from "../components/UserProvenancePage/ProvenanceTokens";
import Background from "../components/UtilComponents/Background";
import HeaderDesktop from "../components/Homepage/Header/HeaderDesktop";
import useIsMobile from "../customHooks/UseIsMobile";
import EditProvenanceSection from "../components/UserProvenancePage/EditProvenanceSection";
import { fetchMJUserDetails, getUserFavourites } from "../api/mjUserApi";
import { useSelector } from "react-redux";
import {
  fetchCollectionSlugsForOwnedTokens,
  fetchCollectionsForOwnedTokens,
  fetchTokensOwnedByWallet,
} from "../api/walletDetailApi";
import AlertPopup from "../utils/commonUtils/AlertPopup";
import { TokenSortingSection } from "../components/CommonComponents/TokenSortingSection";
import { FolderFilterSectionContext } from "../contexts/FolderPage/FolderFilterSectionContext";
import RemoveButton from "../Icons/CollectionPageIcons/RemoveButton";
import FilterButtonMobile from "../components/CollectionPage/FilterButtonMobile";
import TokensListWithDropdown from "../components/CommonComponents/TokensListWithDropdown/TokensListWithDropdown";
import { useNavigate } from "react-router-dom";
import FooterSection from "../components/FooterSection";
import {
  alphatrilogyWalletAddress,
  niftyMetaGirlWalletAddress,
} from "../api/backend_domains/backend_api_domains";
import { Helmet } from "react-helmet";

const UserProvenancePage = () => {
  const { folderPageFilters, updateFolderPageFilters } = useContext(
    FolderFilterSectionContext
  );
  const isFirstRender = useRef(true);
  const walletAddress = useSelector((state) => state.user?.walletAddress);

  const isMobile = useIsMobile();

  const [mjUserInfo, setMJUserInfo] = useState({
    walletAddressList: [walletAddress],
  });

  const [isFetchingData, setIsFetchingData] = useState(false);
  const [tokensOwnedArray, setTokensOwnedArray] = useState([]);
  const [selectAllChecked, setSelectAllChecked] = useState(false);
  const [selectedProvenanceTokens, setSelectedProvenanceTokens] = useState([]);
  const [checkedProvenanceTokens, setCheckedProvenanceTokens] = useState([]);

  const [selectedCollections, setSelectedCollections] = useState([]);
  const [selectedCollectionSlugs, setSelectedCollectionSlugs] = useState([]);
  const [allCollections, setAllCollections] = useState([]);
  const [allCollectionSlugs, setAllCollectionSlugs] = useState([]);

  const [tokensOwnedPaginatedAPIMetadata, setTokensOwnedPaginatedAPIMetadata] =
    useState({
      prevFetchCount: -1,
      currentPage: 0,
      limit: 32,
      currentCount: 0,
    });

  const navigate = useNavigate();
  const userState = useSelector((state) => state.user);

  useEffect(() => {
    if (!userState?.loggedIn) navigate("/login");
  }, [userState]);

  const fetchPaginatedTokensOwnedByWallet = async (refetchData) => {
    //if  no more Paginated Data, dont call API
    if (refetchData === false && !tokensOwnedPaginatedAPIMetadata.more) return;
    if (isFetchingData) return;

    setIsFetchingData(true);

    let searchQueryTokensSelectedIds = Object.values(
      folderPageFilters.searchQueryTokensSelected || {}
    ).map((obj) => obj.contractAddress + "-" + obj.tokenId);

    let selectedContractAddresses = (selectedCollections || []).map(
      (obj) => obj.contractAddress
    );
    fetchTokensOwnedByWallet(
      mjUserInfo?.walletAddressList,
      searchQueryTokensSelectedIds || [],
      selectedContractAddresses,
      selectedCollectionSlugs || [],
      null,
      null,
      null,
      false,
      refetchData === true
        ? 1
        : tokensOwnedPaginatedAPIMetadata.currentPage + 1,
      tokensOwnedPaginatedAPIMetadata.limit,
      folderPageFilters.tokensSortKey,
      folderPageFilters.tokensSortDirection
    )
      .then((response) => {
        if (refetchData) setTokensOwnedArray(response.tokensOwned);
        else {
          setTokensOwnedArray((prevTokens) => [
            ...prevTokens,
            ...response.tokensOwned,
          ]);
        }
        const limit = tokensOwnedPaginatedAPIMetadata.limit;
        const resCount = response?.tokensOwned?.length;
        let more = true;
        if (!resCount || resCount < limit) more = false;
        setTokensOwnedPaginatedAPIMetadata({
          ...tokensOwnedPaginatedAPIMetadata,
          more: more,
          currentPage: response.page,
          currentCount:
            tokensOwnedPaginatedAPIMetadata.currentCount + response.count,
        });
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setIsFetchingData(false);
      });
  };

  // useEffect(() => {
  //   if (userState?.userInfo?.userId) {
  //     fetchMJUserDetails(userState?.userInfo?.userId)
  //       .then((mjUserInfo_) => {
  //         setMJUserInfo(mjUserInfo_);
  //       })
  //       .catch((e) => {});
  //   }
  // }, []);

  useEffect(() => {
    if (!walletAddress) return;
    setMJUserInfo({ walletAddressList: [walletAddress] });
  }, [walletAddress]);

  useEffect(() => {
    if (!mjUserInfo?.walletAddressList[0]) return;

    async function fetchAllData() {
      try {
        fetchPaginatedTokensOwnedByWallet(true);
        const [slugsData, collectionsData] = await Promise.all([
          fetchCollectionSlugsForOwnedTokens(mjUserInfo.walletAddressList),
          fetchCollectionsForOwnedTokens(mjUserInfo.walletAddressList),
        ]);

        setAllCollectionSlugs(slugsData.allCollectionSlugs || []);
        setAllCollections(collectionsData.allCollections || []);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }

    fetchAllData();

    if (isFirstRender.current) isFirstRender.current = false;
  }, [mjUserInfo?.walletAddressList]);
  useEffect(() => {
    if (!isFirstRender.current) fetchPaginatedTokensOwnedByWallet(true);
  }, [
    selectedCollections,
    selectedCollectionSlugs,
    folderPageFilters.tokensSortKey,
    folderPageFilters.tokensSortDirection,
    folderPageFilters.searchQueryTokensSelected,
  ]);

  const handleCheckAllTokens = () => {
    setSelectAllChecked(!selectAllChecked);
    setCheckedProvenanceTokens(selectAllChecked ? [] : tokensOwnedArray);
  };

  const [showAPISuccessAlert, setShowAPISuccessAlert] = useState(false);
  const [showAPIFailureAlert, setShowAPIFailureAlert] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [filterSectionVisible, setFilterSectionVisible] = useState(false);

  const closeFilterSection = () => {
    setFilterSectionVisible(false);
  };

  return (
    <Background>
      <Helmet>
        <title>Metajungle Provenance Page</title>
        <meta
          name="description"
          content="Welcome to the Provenance page on Metajungle!

          Now users can add data/Provenance on their NFT's on Metajungle website!"
        />
      </Helmet>
      <HeaderDesktop />
      <div
        className={
          isMobile
            ? "user-provenance-main-container-mobile"
            : "user-provenance-main-container"
        }
      >
        <span className="my-provenance-main-heading">My Provenance</span>

        <div
          className={
            !isMobile
              ? "user-provenance-main-details-container"
              : "user-provenance-main-details-container-mobile"
          }
        >
          <span
            className={
              !isMobile
                ? "user-provenance-sort-search-container"
                : "user-provenance-sort-search-container-mobile"
            }
          >
            <TokenSearchSection
              pageType={"FolderPage"}
              activeTab={"Items"}
              tokenOwners={mjUserInfo?.walletAddressList}
              tokensSelected={
                folderPageFilters?.searchQueryTokensSelected || []
              }
              addToTokensSelected={(tokenObj) =>
                updateFolderPageFilters({
                  type: "ADD_TO_TOKENS_SELECTED_ON_SEARCH",
                  payload: { searchQueryTokensSelected: tokenObj },
                })
              }
              deleteFromTokensSelected={(tokenObj) =>
                updateFolderPageFilters({
                  type: "DELETE_FROM_TOKENS_SELECTED_ON_SEARCH",
                  payload: { searchQueryTokensSelected: tokenObj },
                })
              }
              styleobj={{ width: "100%" }}
            />
          </span>
          {isMobile && (
            <span
              className={
                !isMobile
                  ? "user-provenance-sort-search-container"
                  : "user-provenance-sort-search-container-mobile"
              }
            >
              <FilterButtonMobile
                onClick={() => setFilterSectionVisible(!filterSectionVisible)}
              />
              {filterSectionVisible && (
                <>
                  <ProvenanceCollectionsFilterSection
                    allCollections={allCollections}
                    selectedCollections={selectedCollections}
                    setSelectedCollections={setSelectedCollections}
                    allCollectionSlugs={allCollectionSlugs}
                    selectedCollectionSlugs={selectedCollectionSlugs}
                    setSelectedCollectionSlugs={setSelectedCollectionSlugs}
                    filterSectionVisible={filterSectionVisible}
                    onFilterClickClose={closeFilterSection}
                  />
                </>
              )}
              <TokenSortingSection
                pageType={"ProvenancePage"}
                activeTab={"Items"}
              />
            </span>
          )}
          <span
            className={
              !isMobile
                ? "user-provenance-sort-search-container"
                : "user-provenance-sort-search-container-mobile"
            }
          >
            {folderPageFilters.searchQueryTokensSelected && (
              <div className="selected-filters">
                {Object.values(
                  folderPageFilters.searchQueryTokensSelected || {}
                ).map((tokenSelectedObj, index) => {
                  return (
                    <div key={index} className="selected-filter">
                      {tokenSelectedObj?.openseaDetails?.name}
                      <button
                        className="selected-filter-remove-button"
                        onClick={() =>
                          updateFolderPageFilters({
                            type: "DELETE_FROM_TOKENS_SELECTED_ON_SEARCH",
                            payload: {
                              searchQueryTokensSelected: tokenSelectedObj,
                            },
                          })
                        }
                      >
                        <RemoveButton color={"white"} />
                      </button>
                    </div>
                  );
                })}

                {Object.values(selectedCollections || {}).map(
                  (collectionObj, index) => {
                    return (
                      <div key={index} className="selected-filter">
                        {collectionObj.name}
                        <button
                          className="selected-filter-remove-button"
                          onClick={() =>
                            setSelectedCollections((prev) => {
                              let filtered = prev.filter(
                                (obj) => obj._id !== collectionObj._id
                              );
                              return filtered;
                            })
                          }
                        >
                          <RemoveButton color={"white"} />
                        </button>
                      </div>
                    );
                  }
                )}

                {Object.values(selectedCollectionSlugs || {}).map(
                  (collectionSlug, index) => {
                    return (
                      <div key={index} className="selected-filter">
                        {collectionSlug}
                        <button
                          className="selected-filter-remove-button"
                          onClick={() =>
                            setSelectedCollectionSlugs((prev) => {
                              let filtered = prev.filter(
                                (slug) => slug !== collectionSlug
                              );
                              return filtered;
                            })
                          }
                        >
                          <RemoveButton color={"white"} />
                        </button>
                      </div>
                    );
                  }
                )}
              </div>
            )}
          </span>
          <span
            className={
              isMobile
                ? "user-provenance-tokens-checked-row-mobile"
                : "user-provenance-tokens-checked-row"
            }
          >
            {checkedProvenanceTokens && checkedProvenanceTokens.length > 0 && (
              <div style={{ marginRight: "auto" }}>
                <TokensListWithDropdown
                  tokensToShow={checkedProvenanceTokens || []}
                  deleteFromTokensList={(tokenToRemove) => {
                    setCheckedProvenanceTokens((prev) => {
                      return prev.filter(
                        (token) => token._id !== tokenToRemove._id
                      );
                    });
                  }}
                />
              </div>
            )}
            {checkedProvenanceTokens.length > 0 && (
              <span
                className="provenance-tokens-buttons"
                style={{ marginRight: "1em" }}
                onClick={() => {
                  setSelectedProvenanceTokens(checkedProvenanceTokens);
                }}
              >
                Add To All Selected
              </span>
            )}
            <span
              className="provenance-tokens-buttons"
              onClick={() => {
                handleCheckAllTokens();
              }}
            >
              {selectAllChecked ? "Deselect All" : "Select All"}
            </span>
          </span>

          <div
            className={
              !isMobile ? "user-provenance-filters-tokens-container" : ""
            }
          >
            {!isMobile && (
              <ProvenanceCollectionsFilterSection
                allCollections={allCollections}
                selectedCollections={selectedCollections}
                setSelectedCollections={setSelectedCollections}
                allCollectionSlugs={allCollectionSlugs}
                selectedCollectionSlugs={selectedCollectionSlugs}
                setSelectedCollectionSlugs={setSelectedCollectionSlugs}
              />
            )}
            <ProvenanceTokens
              tokensOwned={tokensOwnedArray}
              fetchPaginatedTokensOwnedByWallet={
                fetchPaginatedTokensOwnedByWallet
              }
              isFetchingData={isFetchingData}
              selectedProvenanceTokens={selectedProvenanceTokens}
              setSelectedProvenanceTokens={setSelectedProvenanceTokens}
              checkedProvenanceTokens={checkedProvenanceTokens}
              setCheckedProvenanceTokens={setCheckedProvenanceTokens}
            />
          </div>
        </div>
      </div>

      <>
        {selectedProvenanceTokens && selectedProvenanceTokens.length > 0 && (
          <EditProvenanceSection
            selectedProvenanceTokens={selectedProvenanceTokens}
            setSelectedProvenanceTokens={setSelectedProvenanceTokens}
            setShowAPISuccessAlert={setShowAPISuccessAlert}
            setShowAPIFailureAlert={setShowAPIFailureAlert}
            setIsFetching={setIsFetching}
            isFetching={isFetching}
          />
        )}
        {showAPIFailureAlert && (
          <AlertPopup message="Provenance Failed To Update" />
        )}
        {showAPISuccessAlert && <AlertPopup message="Provenance Updated" />}
      </>
      <FooterSection />
    </Background>
  );
};

export default UserProvenancePage;
