import React, { useEffect, useState } from "react";
import DescriptionTab from "./DescriptionTab";
import ArtistDescription from "./ArtistDescription";
import MetaJungleDescription from "./MetaJungleDescription";
import InfoComponent from "../CommonComponents/InfoComponent";

const DescriptionSection = ({ collectionData }) => {
  const [activeTab, setActiveTab] = useState(0);

  useEffect(() => {
    if (collectionData?.mjCollectionDescription) setActiveTab(1);
  }, [collectionData]);

  const handleTabClick = (index) => {
    setActiveTab(index);
  };
  return (
    <div style={{ marginBottom: "2em" }}>
      <div>
        <div className="description-tab-container">
          <div style={{ marginRight: "2em" }}>
            <DescriptionTab
              label="By Artist"
              active={activeTab === 0}
              onClick={() => handleTabClick(0)}
            />
          </div>

          {/* <DescriptionTab
            showInfoIcon={true}
            label="By MetaJungle"
            active={activeTab === 1}
            onClick={() => handleTabClick(1)}
          /> */}
        </div>

        {activeTab === 0 && (
          <ArtistDescription collectionData={collectionData} />
        )}
        {activeTab === 1 && (
          <MetaJungleDescription collectionData={collectionData} />
        )}
      </div>
    </div>
  );
};

export default DescriptionSection;
