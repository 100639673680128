import { isNullOrUndefinedOrEmptyObject } from "../utils/commonUtils/Utils";
import { REACT_APP_BACKEND_API_BASE_URL } from "./backend_domains/backend_api_domains";
import Axios from "axios";
import { setupCache } from "axios-cache-interceptor";

const instance = Axios.create();
const axios = setupCache(instance);
const URL = `${REACT_APP_BACKEND_API_BASE_URL}`;

/* Fetch Collection Details using Contract Address */
export const fetchCollectionDetails = async (
  contractAddress,
  collectionSlug,
  collectionCreator,
  fetchImages
) => {
  try {
    // if (URL.includes("localhost")) fetchImages = false;
    const response = await axios.get(`${URL}/collection/detail`, {
      params: {
        contractAddress: contractAddress,
        collectionSlug: collectionSlug,
        collectionCreator,
        fetchImages: fetchImages,
      },
    });
    return response.data.collectionDetail || {};
  } catch (error) {
    throw new Error("Error fetching collection details: " + error.message);
  }
};

/* Fetch Collection contract address using case insensitive Contract Address */
export const fetchContractAddressbylowercase = async (contractAddress) => {
  try {
    const response = await axios.get(`${URL}/collection/get_contract_address`, {
      params: {
        contractAddress: contractAddress,
      },
    });
    return response.data.contractAddress;
  } catch (error) {
    throw new Error(
      "Error fetching collection contract address: " + error.message
    );
  }
};

/* Fetch Collection Details using Contract Address */
export const fetchCollectionTokens = async (
  contractAddress,
  contractTokenIds,
  traitsArray,
  status,
  listingPriceFilter,
  page,
  limit,
  sortKey,
  sortDirection,
  conditionType,
  countTokens,
  collectionSlug,
  collectionCreator
) => {
  try {
    page = page || 1;
    limit = limit || 32;
    sortKey = sortKey || "mintTimestamp";
    if (typeof sortKey === "string" && sortKey?.includes("Listing")) {
      status = "Listed";
    }
    sortKey = encodeURIComponent(JSON.stringify(sortKey));

    sortDirection = sortDirection || 1;

    if (isNullOrUndefinedOrEmptyObject(traitsArray)) traitsArray = [];
    traitsArray = encodeURIComponent(JSON.stringify(traitsArray)) || [];

    if (!conditionType) conditionType = "AND";
    if (!collectionSlug) collectionSlug = null;
    if (!collectionCreator) collectionCreator = null;
    let API_URL = `${URL}/collection/tokens?contractAddress=${contractAddress}&page=${page}&limit=${limit}&sortKey=${sortKey}&sortDirection=${sortDirection}&traitsArray=${traitsArray}&conditionType=${conditionType}&collectionSlug=${collectionSlug}&collectionCreator=${collectionCreator}`;

    if (status === "Listed") {
      if (
        listingPriceFilter &&
        listingPriceFilter.minListingPriceFilter &&
        listingPriceFilter.maxListingPriceFilter &&
        listingPriceFilter.minListingPriceFilter <=
          listingPriceFilter.maxListingPriceFilter
      ) {
        listingPriceFilter = encodeURIComponent(
          JSON.stringify(listingPriceFilter)
        );
        API_URL = API_URL + `&listingPriceRange=${listingPriceFilter}`;
      }
    }

    if (status !== "All") {
      status = encodeURIComponent(JSON.stringify(status));
      API_URL = API_URL + `&status=${status}`;
    }

    if (contractTokenIds && contractTokenIds.length > 0) {
      API_URL = API_URL + `&contractTokenIds=${contractTokenIds.join(",")}`;
    }

    API_URL = API_URL + `&countTokens=${countTokens}`;

    const response = await axios.get(API_URL);
    return response.data || [];
  } catch (error) {
    console.error("Error fetching collection tokens:", error);
    throw error;
  }
};

export async function fetchTraitCounts(
  contractAddress,
  collectionSlug,
  collectionCreator,
  traitsArray,
  status,
  listingPriceFilter,
  conditionType
) {
  try {
    if (traitsArray.length === 0) return {};
    const requestBody = {
      contractAddress: contractAddress,
      collectionSlug,
      collectionCreator,
      traitsArray: traitsArray,
      status: status,
      listingPriceFilter: listingPriceFilter,
      conditionType: conditionType,
    };

    // Making a POST request and passing the data in the body
    const res = await axios.post(`${URL}/collection/trait_count`, requestBody);
    if (!res.data.success) throw new Error("NOT_SUCCESS_RESPONSE");
    return res.data.traitCount;
  } catch (error) {
    console.error("Error fetching trait counts:", error);
    throw error;
  }
}

/* Fetch Collection Tokens Transfer History Details using Contract Address */
export const fetchCollectionTransferHistory = async (
  collectionId,
  collectionSlug,
  collectionCreator,
  traitsArray,
  status,
  page,
  limit,
  sortKey,
  sortDirection
) => {
  page = page || 1;
  limit = limit || 32;
  sortKey = sortKey || "logId";
  sortDirection = sortDirection || 1;

  if (isNullOrUndefinedOrEmptyObject(traitsArray)) traitsArray = [];
  traitsArray = encodeURIComponent(JSON.stringify(traitsArray)) || [];
  if (!collectionSlug) collectionSlug = null;
  if (!collectionCreator) collectionCreator = null;
  let API_URL = `${URL}/nft_transfer?contractAddress=${collectionId}&collectionSlug=${collectionSlug}&collectionCreator=${collectionCreator}&page=${page}&limit=${limit}&sortKey=${sortKey}&sortDirection=${sortDirection}`;
  if (status !== "All") API_URL = API_URL + `&status=${status}`;
  const response = await Axios.get(API_URL);
  return response.data;
};

export const fetchCollectionInfoBoxData = async (contractAddress) => {
  const response = await axios.get(`${URL}/collection/collection_price_info`, {
    params: { contractAddress: contractAddress },
  });
  if (!response.data.success)
    throw new Error("Error fetching collection info box data");
  return response.data.collectionPriceInfo;
};

export const fetchListingTokens = async (
  contractAddress,
  collectionSlug,
  collectionCreator
) => {
  try {
    const response = await axios.get(`${URL}/collection/token_listings`, {
      params: {
        contractAddress: contractAddress,
        collectionSlug: collectionSlug,
        collectionCreator: collectionCreator,
      },
    });
    if (!response.data.success) return null;
    return {
      collectionTokenListings: response.data.collectionTokenListings,
      listingPriceLastUpdated: response.data.listingPriceLastUpdated,
    };
  } catch (error) {
    console.error("Error fetching listing tokens:", error);
    throw error;
  }
};

export const fetchListingTokensCountForCollection = async (
  contractAddress,
  collectionSlug,
  collectionCreator
) => {
  try {
    const response = await axios.get(`${URL}/collection/token_listings/count`, {
      params: {
        contractAddress: contractAddress,
        collectionSlug,
        collectionCreator,
      },
    });
    if (!response.data.success) return 0;
    return response?.data?.tokensListedCount || 0;
  } catch (error) {
    console.error("Error fetching listing tokens:", error);
    throw error;
  }
};

export const updatePriorityApi = async (
  contractAddress,
  collectionSlug,
  collectionCreator,
  priority,
  userId
) => {
  const data = {
    contractAddress: contractAddress,
    collectionSlug,
    collectionCreator,
    priority: priority,
    userId: userId,
  };
  try {
    const response = await axios.post(`${URL}/collection/setPriority`, data);
    return response.data;
  } catch (error) {
    console.error("Failed to set priority: " + error.message);
  }
};

export const updateBannerImage = async (
  contractAddress,
  collectionSlug,
  collectionCreator,
  bannerImage
) => {
  let formData = new FormData();
  if (bannerImage) formData.append(`bannerImage`, bannerImage.selectedImg);
  const result = await axios.post(
    `${URL}/collection/update_collection_banner_image?contractAddress=${contractAddress}&collectionSlug=${collectionSlug}&collectionCreator=${collectionCreator}`,
    formData
  );
  return result?.data?.success || {};
};
