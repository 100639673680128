function isEmptyObject(variable) {
  return typeof variable === "object" && Object.keys(variable).length === 0;
}

function isNullOrUndefinedOrEmptyObject(variable) {
  return (
    variable === null ||
    variable === undefined ||
    (typeof variable === "object" && Object.keys(variable).length === 0)
  );
}

function isNullOrUndefined(variable) {
  return variable === null || variable === undefined;
}

function isNullOrUndefinedOrEmptyString(variable) {
  return variable === null || variable === undefined || variable === "";
}

// function hexToDecimalString(hexString) {
//   const bigInt = BigInt(hexString);
//   return bigInt.toString();
// }
function hexToDecimalString(hexString) {
  return parseInt(hexString, 16).toString(10);
}

function decimalToHex(decimal) {
  return decimal.toString(16);
}

function convertUnixTimestampToDate(unixTimestamp) {
  const date = new Date(unixTimestamp * 1000);
  return date;
}

function isObject(value) {
  return value !== null && typeof value === "object" && !Array.isArray(value);
}

function trimMiddleText(text) {
  return text?.length > 30
    ? text.substring(0, 4) + "..." + text.substring(text.length - 4)
    : text;
}

function trimLongText(text, maxLength = 18) {
  return text?.length > maxLength ? text.substring(0, maxLength) + "..." : text;
}

function check_IPFS_URL_Valid(tokenURI) {
  let cid = null;
  if (tokenURI.startsWith("ipfs://ipfs/")) {
    cid = tokenURI.replace("ipfs://ipfs/", "");
  } else if (tokenURI.startsWith("ipfs://")) {
    cid = tokenURI.replace("ipfs://", "");
  } else if (
    tokenURI.indexOf("/ipfs/") < 200 &&
    tokenURI.includes("/ipfs/") &&
    this.regex.test(tokenURI)
  ) {
    cid = tokenURI.replace(this.regex, "$1");
  }
  return cid;
}

function convertIpfsToUrl(ipfsLink) {
  let publicGateway = "https://ipfs.io/ipfs/";
  let cid = check_IPFS_URL_Valid(ipfsLink);
  return publicGateway + cid;
}

const copyToClipboard = (text) => {
  const textArea = document.createElement("textarea");
  textArea.value = text;
  document.body.appendChild(textArea);
  textArea.select();
  document.execCommand("copy");
  document.body.removeChild(textArea);
};

function getObjectProperty(obj, path) {
  const segments = path ? path.split(".") : [];
  let result = obj;

  for (const segment of segments) {
    if (result && typeof result === "object" && segment in result) {
      result = result[segment];
    } else {
      return undefined; // Property not found along the path
    }
  }

  return result;
}

function deepCopyObj(obj) {
  if (obj === null || typeof obj !== "object") {
    return obj;
  }

  // Handle Date objects
  if (obj instanceof Date) {
    return new Date(obj.getTime());
  }

  if (obj instanceof Array) {
    let copy = [];
    for (let i = 0, len = obj.length; i < len; i++) {
      copy[i] = deepCopyObj(obj[i]);
    }
    return copy;
  }

  if (obj instanceof Object) {
    let copy = {};
    for (let attr in obj) {
      if (obj.hasOwnProperty(attr)) copy[attr] = deepCopyObj(obj[attr]);
    }
    return copy;
  }

  throw new Error("Unable to copy obj! Its type isn't supported.");
}

function isAddress(value) {
  return value.length > 10 && value.startsWith("0x");
}

module.exports = {
  isEmptyObject,
  isNullOrUndefinedOrEmptyObject,
  isNullOrUndefined,
  isNullOrUndefinedOrEmptyString,
  trimMiddleText,
  trimLongText,
  decimalToHex,
  convertUnixTimestampToDate,
  isObject,
  hexToDecimalString,
  convertIpfsToUrl,
  copyToClipboard,
  getObjectProperty,
  deepCopyObj,
  isAddress,
};
