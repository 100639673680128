//hook for marketplaces

import { useState, useEffect } from "react";

function useMarketplaces(tokenListingsPricesAPIData) {
  const [marketplaces, setMarketplaces] = useState([]);

  useEffect(() => {
    if (tokenListingsPricesAPIData && tokenListingsPricesAPIData.pricing) {
      const uniqueMarketplaces = Object.keys(
        tokenListingsPricesAPIData.pricing
      );

      setMarketplaces(uniqueMarketplaces);
    }
  }, [tokenListingsPricesAPIData]);

  return marketplaces;
}

export default useMarketplaces;
