// src/contexts/FilterSectionContext.js
import React, { createContext, useEffect, useReducer } from "react";
import filterSectionReducer from "../../reducers/CreatorPage/filterSectionReducer";

export const CreatorFilterSectionContext = createContext();

export const initialCreatorPageContext = {
  ownedTokenStatus: "All",
  mintedTokenStatus: "All",
  createdTokenStatus: "All",
  activityStatus: "All",

  minListingPriceFilter: null,
  maxListingPriceFilter: null,

  listingPriceCurrencyFilter: "ETH",

  listingPriceFilter: {
    minListingPriceFilter: null,
    maxListingPriceFilter: null,
    listingPriceCurrencyFilter: "ETH",
  },

  ownedTokenSelectedTraitValues: {},
  createdTokensSelectedTraitValues: {},
  mintedTokensSelectedTraitValues: {},
  activitySelectedTraitValues: {},

  ownedTokensSortText: "Date Minted: Newest to Oldest",
  ownedTokensSortKey: "mintTimestamp",
  ownedTokensSortDirection: -1,

  mintedTokensSortText: "Date Minted: Newest to Oldest",
  mintedTokensSortKey: "mintTimestamp",
  mintedTokensSortDirection: -1,

  createdTokensSortText: "Date Minted: Newest to Oldest",
  createdTokensSortKey: "mintTimestamp",
  createdTokensSortDirection: -1,

  searchQuery: null,
  searchQueryTokensSelected: {},
};

const initialCreatorPageFiltersState =
  JSON.parse(localStorage.getItem("creatorPageFiltersState")) ||
  initialCreatorPageContext;

export const CreatorFilterSectionProvider = ({ children }) => {
  const [creatorPageFilters, updateCreatorPageFilters] = useReducer(
    filterSectionReducer,
    initialCreatorPageFiltersState
  );

  useEffect(() => {
    localStorage.setItem(
      "creatorPageFiltersState",
      JSON.stringify(creatorPageFilters)
    );
  }, [creatorPageFilters]);

  return (
    <CreatorFilterSectionContext.Provider
      value={{ creatorPageFilters, updateCreatorPageFilters }}
    >
      {children}
    </CreatorFilterSectionContext.Provider>
  );
};
