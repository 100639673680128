import { useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";

import DefaultProfileImage from "../../../assets/images/defaultProfileImage.png";
import { fetchMJUserDetails } from "../../../api/mjUserApi";
import { useEffect, useRef, useState } from "react";
import useIsMobile from "../../../customHooks/UseIsMobile";
import Search from "./Search";
import FolderPathIcon from "../../../Icons/FolderPathIcon";
import SignOutIcon from "../../../Icons/SignOutIcon";
import SignInIcon from "../../../Icons/SignInIcon";
import { MdOutlineDashboardCustomize } from "react-icons/md";
import { GrArticle } from "react-icons/gr";
import { FaRegFolderClosed } from "react-icons/fa6";
import { MdOutlineLibraryBooks } from "react-icons/md";
import { IoLogOut } from "react-icons/io5";
import { IoLogIn } from "react-icons/io5";
import { getMJUserProfileImage } from "../../../utils/commonUtils/WalletDetailsUtils";
import userNull from "../../../assets/images/defaultProfileImage.png";
import { PiUserCirclePlusFill } from "react-icons/pi";

export default function ProfileDropDown({
  handleLogOutButtonClick,
  toggleProfileDropdown,
  loggedIn,
  toggleButtonRef,
}) {
  const userState = useSelector((state) => state.user);
  const [mjUserInfo, setMJUserInfo] = useState({});
  const [mainWalletAddress, setMainWalletAddress] = useState("");
  const isMobile = useIsMobile();
  const location = useLocation();
  const navigate = useNavigate();

  const modalRef = useRef(null);

  useEffect(() => {
    const handleOutsideTap = (event) => {
      if (
        modalRef.current &&
        !modalRef.current.contains(event.target) &&
        toggleButtonRef &&
        !toggleButtonRef.current.contains(event.target)
      ) {
        toggleProfileDropdown();
      }
    };

    document.addEventListener("mousedown", handleOutsideTap);

    return () => {
      document.removeEventListener("mousedown", handleOutsideTap);
    };
  }, []);

  useEffect(() => {
    fetchMJUserDetails(userState?.userInfo?.userId)
      .then((mjUserInfo_) => {
        setMJUserInfo(mjUserInfo_);

        if (
          mjUserInfo_?.walletAddressList &&
          (mjUserInfo_?.walletAddressList || []).length > 0
        ) {
          setMainWalletAddress(mjUserInfo_?.walletAddressList[0]);
        }
      })
      .catch((e) => {});
  }, []);

  return (
    <>
      {!isMobile ? (
        <div className="profile-dropdown-animation" ref={modalRef}>
          <div className="dropdown-redirect-row-container ">
            <PiUserCirclePlusFill size={"1.6em"} />
            {userState.loggedIn && (
              <Link to={"/profile"} className="dropdown-link-text">
                Profile
              </Link>
            )}
          </div>
          {/* <div className="dropdown-redirect-row-container ">
            <MdOutlineDashboardCustomize size={"1.5em"} />
            <Link to={"/nft_transfers_activity"} className="dropdown-link-text">
              Activity
            </Link>
          </div> */}

          {/* <div className="dropdown-redirect-row-container ">
            <MdOutlineDashboardCustomize size={"1.5em"} />
            <Link to={"/dashboard"} className="dropdown-link-text">
              Dashboard
            </Link>
          </div> */}

          {/* <div className="d-flex justify-content-left w-100 dropdown-redirect-row-container">
            <GrArticle size={"1.4em"} />
            <Link
              to={`/wallet_report/${mainWalletAddress}`}
              className="dropdown-link-text"
            >
              Wallet Report
            </Link>
          </div> */}
          {/* <div className="d-flex justify-content-left w-100 dropdown-redirect-row-container">
            <FaRegFolderClosed size={"1.4em"} />
            <Link to={"/folders"} className="dropdown-link-text">
              Folders
            </Link>
          </div>*/}
          <div className="d-flex justify-content-left w-100 dropdown-redirect-row-container">
            <MdOutlineLibraryBooks size={"1.5em"} />
            <Link to={"/provenance"} className="dropdown-link-text">
              Provenance
            </Link>
          </div>
          <div className="dropdown-redirect-row-container ">
            {loggedIn ? (
              <IoLogOut size={"1.5em"} />
            ) : (
              <IoLogIn size={"1.5em"} />
            )}
            <div
              onClick={() => {
                handleLogOutButtonClick();
                toggleProfileDropdown();
              }}
            >
              <Link className="dropdown-link-text">SignOut</Link>
            </div>
          </div>
        </div>
      ) : (
        <div className="profiledropdown-mobile-animation">
          <div className="profiledropdown-mobile-container">
            {location.pathname !== "/" && <Search />}
            {userState.loggedIn && (
              <div className="d-flex justify-content-left w-100">
                <Link
                  to={"/profile"}
                  className="profiledropdown-mobile-link"
                  style={{
                    color:
                      location.pathname === "/profile" ? "#2fb484" : "#183b56",
                  }}
                >
                  <img
                    src={getMJUserProfileImage(mjUserInfo) || userNull}
                    alt="userProfileImage"
                    style={{
                      height: "1.6em",
                      width: "1.6em",
                      borderRadius: "50%",
                      marginRight: "0.5em",
                    }}
                  ></img>
                  Profile
                </Link>
              </div>
            )}
            <div className="d-flex justify-content-left w-100">
              <Link
                to={"/nft_transfers_activity"}
                className="profiledropdown-mobile-link"
                style={{
                  color:
                    location.pathname === "/nft_transfers_activity"
                      ? "#2fb484"
                      : "#183b56",
                }}
              >
                <span style={{ marginRight: "1.1em" }}>
                  <MdOutlineDashboardCustomize size={"1.6em"} />
                </span>
                Activity
              </Link>
            </div>

            {/* <div className="d-flex justify-content-left w-100">
              <Link
                to={"/dashboard"}
                className="profiledropdown-mobile-link"
                style={{
                  color:
                    location.pathname === "/dashboard" ? "#2fb484" : "#183b56",
                }}
              >
                <span style={{ marginRight: "1.1em" }}>
                  <MdOutlineDashboardCustomize size={"1.6em"} />
                </span>
                Dashboard
              </Link>
            </div> */}

            {/* <div className="d-flex justify-content-left w-100">
              <Link
                to={`/wallet_report/${mainWalletAddress}`}
                className="profiledropdown-mobile-link"
                style={{
                  color: location.pathname.includes("/wallet_report")
                    ? "#2fb484"
                    : "#183b56",
                }}
              >
                <span style={{ marginRight: "1.1em" }}>
                  <GrArticle size={"1.5em"} />
                </span>
                Wallet Report
              </Link>
            </div> */}
            {/* <div className="d-flex justify-content-left w-100">
              <Link
                to={"/folders"}
                className="profiledropdown-mobile-link"
                style={{
                  color:
                    location.pathname === "/folders" ? "#2fb484" : "#183b56",
                }}
              >
                <span style={{ marginRight: "1.1em" }}>
                  <FaRegFolderClosed size={"1.5em"} />
                </span>
                Folders
              </Link>
            </div> */}
            {/* <div className="d-flex justify-content-left w-100">
              <Link
                to={"/provenance"}
                className="profiledropdown-mobile-link"
                style={{
                  color:
                    location.pathname === "/provenance" ? "#2fb484" : "#183b56",
                }}
              >
                <span style={{ marginRight: "1.1em" }}>
                  <MdOutlineLibraryBooks size={"1.6em"} />
                </span>
                Provenance
              </Link>
            </div> */}
            <div className="w-100 d-flex align-items-center justify-content-center">
              <div className="d-flex justify-content-left">
                <button
                  onClick={() => {
                    if (loggedIn) {
                      handleLogOutButtonClick();
                      toggleProfileDropdown();
                    } else {
                      toggleProfileDropdown();
                      navigate("/login");
                    }
                  }}
                  className="border-0 d-flex align-items-center"
                  style={{
                    backgroundColor: "#2fb484",
                    color: "white",
                    borderRadius: "2vw",
                    height: "6.5vw",
                    paddingLeft: "4.5vw",
                    paddingRight: "4.5vw",
                    fontSize: "3.3vw",
                    gap: "2.3vw",
                  }}
                >
                  {loggedIn ? (
                    <IoLogOut size={"1.5em"} />
                  ) : (
                    <IoLogIn size={"1.5em"} />
                  )}
                  {loggedIn ? "Sign Out" : "Sign In"}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
