import React, { useEffect, useState } from "react";
import UserReport from "../../Icons/UserReport";
import UserEdit from "../../Icons/UserEdit";
import OpenSeaImage from "../../assets/images/OpenSeaImage.png";
import { fetchWalletDetails } from "../../api/walletDetailApi";
import { Link } from "react-router-dom";
import {
  getWalletProfileImage,
  getWalletProfileName,
} from "../commonUtils/WalletDetailsUtils";
import { trimMiddleText } from "../commonUtils/Utils";
import AddInfoModal from "../../components/CommonComponents/AddInfoModal";

const UserModal = ({ walletAddress, isCreator }) => {
  const [walletData, setWalletData] = useState(null);

  useEffect(() => {
    fetchWalletDetails(walletAddress, true, true)
      .then((walletDetail) => {
        setWalletData(walletDetail);
      })
      .catch((error) => {
        setWalletData({});
      });
  }, []);

  return (
    <div className="user-modal-container">
      <div className="user-profilepic">
        <img
          src={getWalletProfileImage(walletData)}
          alt="User Profile Pic"
          style={{
            width: "5.2rem",
            height: "5.2rem",
            borderRadius: "50%",
            // marginLeft: "1em",
          }}
        />
      </div>

      <div className="user-details">
        <Link
          className="username-modal link-field"
          to={
            isCreator
              ? `/creator/${walletAddress}`
              : `/wallet_report/${walletAddress}`
          }
        >
          {getWalletProfileName(walletData, true)}
        </Link>

        <div className="user-address-modal">
          {trimMiddleText(walletAddress)}
        </div>

        <div className="user-detail-icons">
          <Link
            to={`https://opensea.io/${walletData?.openseaDetails?.address}`}
            target="_blank"
            title="OpenSea"
          >
            <img
              src={OpenSeaImage}
              alt="Marketplace Logo"
              className="marketplace-logo"
            />
          </Link>

          <AddInfoModal
            info={"View Wallet Report"}
            renderDirection={"up-left"}
            styleObj={{ cursor: "pointer" }}
          >
            <span title="Wallet Report">
              <UserReport url={`/wallet_report/${walletAddress}`} />
            </span>
          </AddInfoModal>

          <AddInfoModal
            info={"View Creator Page"}
            renderDirection={"up-left"}
            styleObj={{ fontWeight: "bold", cursor: "pointer" }}
          >
            <span title="Creator Profile">
              {isCreator && <UserEdit url={`/creator/${walletAddress}`} />}
            </span>
          </AddInfoModal>
        </div>
      </div>
    </div>
  );
};

export default UserModal;
