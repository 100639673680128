// src/contexts/FilterSectionContext.js
import React, { createContext, useEffect, useReducer } from "react";
import folderFilterSectionReducer from "../../reducers/FolderPage/folderFilterSectionReducer";

export const FolderFilterSectionContext = createContext();

export const initialFolderPageFiltersStateStart = {
  collection: null,

  tokensSortText: "Date Minted: High to Low",
  tokensSortKey: "logId",
  tokensSortDirection: -1,

  searchQuery: null,
  searchQueryTokensSelected: {},
};

const initialFolderPageFiltersState =
  JSON.parse(localStorage.getItem("folderPageFiltersState")) ||
  initialFolderPageFiltersStateStart;

export const FolderFilterSectionProvider = ({ children }) => {
  const [folderPageFilters, updateFolderPageFilters] = useReducer(
    folderFilterSectionReducer,
    initialFolderPageFiltersState
  );

  useEffect(() => {
    localStorage.setItem(
      "folderPageFiltersState",
      JSON.stringify(folderPageFilters)
    );
  }, [folderPageFilters]);

  return (
    <FolderFilterSectionContext.Provider
      value={{ folderPageFilters, updateFolderPageFilters }}
    >
      {children}
    </FolderFilterSectionContext.Provider>
  );
};
