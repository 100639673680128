let REACT_APP_BACKEND_API_BASE_URL = process.env.REACT_APP_BACKEND_API_BASE_URL;

let REACT_APP_ATLAS_SEARCH_API_BASE_URL =
  process.env.REACT_APP_ATLAS_SEARCH_API_BASE_URL;
let REACT_APP_ELASTIC_SEARCH_API_BASE_URL =
  process.env.REACT_APP_ELASTIC_SEARCH_API_BASE_URL;

let REACT_APP_IMAGE_PROXY_API_BASE_URL =
  process.env.REACT_APP_IMAGE_PROXY_API_BASE_URL;

const niftyMetaGirlWalletAddress = "0x9ff77d193c091ae350b8ce26d50d46e392631292";

const alphatrilogyWalletAddress = "0x8497277c9339170a0420e86da8352e0c084624cd";

module.exports = {
  REACT_APP_BACKEND_API_BASE_URL,
  REACT_APP_ATLAS_SEARCH_API_BASE_URL,
  REACT_APP_ELASTIC_SEARCH_API_BASE_URL,
  REACT_APP_IMAGE_PROXY_API_BASE_URL,
  niftyMetaGirlWalletAddress,
  alphatrilogyWalletAddress,
};
