import React, { useEffect, useState } from "react";
import "../../styles/itemPageStyles/ItemPage.css";
import {
  getNameFromCollectionObj,
  getRoyaltyFromCollectionObj,
} from "../../utils/commonUtils/CollectionUtils";
import { timestampToDate } from "../../utils/commonUtils/timeUtils";
import CollectionIcons from "./CollectionIcons";
import { trimMiddleText } from "../../utils/commonUtils/Utils";
import { Link } from "react-router-dom";
import { prettyWalletAddress } from "../../utils/commonUtils/PrettyString";
import LoadingText from "../../utils/commonUtils/LoadingText";
import {
  fetchWalletDetails,
  getWalletAddressByLowerCaseAddress,
} from "../../api/walletDetailApi";
import { getWalletProfileName } from "../../utils/commonUtils/WalletDetailsUtils";
import UserModal from "../../utils/ItemPageUtils/UserModal";

/*function formatDateToDMY(dateString) {
  const options = { year: "numeric", month: "long", day: "numeric" };
  const date = new Date(dateString);
  return date.toLocaleDateString(undefined, options);
} */

const CollectionInfoMobile = ({
  collectionData,
  tokenCreator,
  isFetchingCollectionDetails,
}) => {
  const royalties = getRoyaltyFromCollectionObj(collectionData);

  const [collectionCreatorName, setCollectionCreatorName] = useState(null);

  const [openCreatorModal, setOpenCreatorModal] = useState(false);
  const [opneRoyaltyModal, setOpenRoyaltyModal] = useState(false);

  async function fetchAndUpdateCreator(collectionCreatorAddress) {
    collectionCreatorAddress = await getWalletAddressByLowerCaseAddress(
      collectionCreatorAddress
    );

    let userDetails = await fetchWalletDetails(collectionCreatorAddress);

    setCollectionCreatorName(getWalletProfileName(userDetails));
  }

  useEffect(() => {
    if (collectionData?.collectionCreator) {
      fetchAndUpdateCreator(collectionData?.collectionCreator);
    }
  }, [collectionData]);

  return (
    <>
      <div className="collection-info-name-mobile">
        <div>
          {collectionData && !isFetchingCollectionDetails ? (
            <div
              className="collection-info-collection-name"
              style={{ fontSize: "4.3vw", marginLeft: "3vw" }}
            >
              <div>{getNameFromCollectionObj(collectionData)}</div>
              {collectionCreatorName && (
                <Link
                  className="collection-info-collection-name-creator"
                  to={`/creator/${collectionData?.collectionCreator}`}
                >
                  By {collectionCreatorName}
                </Link>
              )}
            </div>
          ) : (
            <div style={{ marginBottom: "2vw", marginLeft: "3vw" }}>
              <LoadingText height="5vw" width="28vw" />
            </div>
          )}
        </div>
      </div>
      <div className="d-flex justify-content-center">
        <div className="collection-info-section-mobile">
          <div className="collection-info-mobile-row">
            <span>
              <span style={{ fontSize: "1.2em" }}>Creator</span>

              <Link
                className="collection-info-mobile-value link-field"
                onClick={() => setOpenCreatorModal(!openCreatorModal)}
              >
                {prettyWalletAddress(tokenCreator)}
              </Link>
              {openCreatorModal && (
                <UserModal isCreator={true} walletAddress={tokenCreator} />
              )}
            </span>

            <span>
              <span style={{ fontSize: "3vw" }}> Royalties Receiver</span>

              <Link
                // to={`/creator/${collectionData?.openseaDetails?.collectionDetails?.owner}`}
                className="collection-info-mobile-value link-field"
                onClick={() => setOpenRoyaltyModal(!opneRoyaltyModal)}
              >
                {prettyWalletAddress(
                  collectionData?.openseaDetails?.collectionDetails?.owner
                )}
              </Link>
              {opneRoyaltyModal && (
                <UserModal isCreator={true} walletAddress={tokenCreator} />
              )}
            </span>
          </div>
          {/* <p></p> */}
          <div className="collection-info-mobile-row">
            <span>
              <span style={{ fontSize: "1.2em" }}>Items</span>
              <span className="collection-info-mobile-value">
                {collectionData?.tokensSize}
              </span>
            </span>
            <span className="collection-info-collection-address-dot"></span>
            <span>
              <span style={{ fontSize: "1.2em" }}>Created</span>
              <span className="collection-info-mobile-value">
                {timestampToDate(collectionData?.block_timestamp)}
              </span>
            </span>
          </div>
          <div className="collection-info-mobile-row">
            <span>
              <span style={{ fontSize: "1.2em" }}>Royalties</span>
              <span className="collection-info-mobile-value">
                {royalties}%{" "}
              </span>
            </span>
            <span className="collection-info-collection-address-dot"></span>
            <span>
              <span style={{ fontSize: "1.2em" }}>Chain</span>{" "}
              <span className="collection-info-mobile-value">
                {" "}
                {collectionData?.blockchain || "Ethereum"}
              </span>
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

export default CollectionInfoMobile;
