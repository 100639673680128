import varifiedImg from "../../../assets/images/correct.png";
import useIsMobile from "../../../customHooks/UseIsMobile";

export default function VarifiedIcon({ varified }) {
  const isMobile = useIsMobile();
  return (
    <>
      {!isMobile ? (
        <div style={{ width: "1.5vw", opacity: 1 }}>
          {varified && <img src={varifiedImg} style={{ height: "1.5vw" }} />}
        </div>
      ) : (
        <div style={{ width: "3vw", opacity: 1 }}>
          {varified && <img src={varifiedImg} style={{ height: "3vw" }} />}
        </div>
      )}
    </>
  );
}
