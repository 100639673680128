import { isNullOrUndefinedOrEmptyObject } from "../utils/commonUtils/Utils";
import { REACT_APP_BACKEND_API_BASE_URL } from "./backend_domains/backend_api_domains";
import { saveErrorLogsApi } from "./loggingApis";
import Axios from "axios";
import { setupCache } from "axios-cache-interceptor";
const instance = Axios.create();
const axios = setupCache(instance);

const URL = `${REACT_APP_BACKEND_API_BASE_URL}`;

export const fetchEthPricetoUSD = async (days) => {
  try {
    const response = await axios.get(
      `${REACT_APP_BACKEND_API_BASE_URL}/homepage/EthPriceToUSD?days=${days}`
    );
    return response?.data?.message || null;
  } catch (error) {
    console.error("Error fetching Previous Eth Value to USD:", error);
    throw error;
  }
};

export const fetchHistoricalNFTTransactionsTimeAggregates = async (
  duration,
  UNIT
) => {
  try {
    if (!UNIT) UNIT = "DAY";
    if (!duration) duration = 1;

    const response = await axios.get(
      `${REACT_APP_BACKEND_API_BASE_URL}/homepage/historical/NFTTransactionsTimeAggregates?duration=${duration}&UNIT=${UNIT}`
    );
    return response?.data?.historicalNFTTransactionsTimeAggregates || [];
  } catch (error) {
    console.error("Error fetching Historical NFT Volume in USD:", error);
    throw error;
  }
};

export const fetchTrendingCollections = async (
  number,
  collectionsOnly = false
) => {
  try {
    if (!number) number = 10;

    const response = await axios.get(
      `${REACT_APP_BACKEND_API_BASE_URL}/homepage/trending/collections?number=${number}&collectionsOnly=${collectionsOnly}`
    );
    return response?.data?.topTrendingCollection || [];
  } catch (error) {
    console.error(
      `Error fetching top ${number} Trending NFT Collections in USD:`,
      error
    );
    return null;
  }
};

export const fetchTrendingWallets = async (number) => {
  try {
    if (!number) number = 10;

    const response = await axios.get(
      `${REACT_APP_BACKEND_API_BASE_URL}/homepage/trending/wallets?number=${number}`
    );
    return response?.data?.topTrendingWallets || [];
  } catch (error) {
    console.error(
      `Error fetching top ${number} Trending NFT wallets in USD:`,
      error
    );
    throw error;
  }
};

export const fetchSpotLightArtists = async () => {
  try {
    const response = await axios.get(
      `${REACT_APP_BACKEND_API_BASE_URL}/homepage/SpotLightArtists`
    );
    return response?.data?.users || [];
  } catch (error) {
    console.error(`Error fetching SpotLight Artists`, error);
    throw error;
  }
};

export const sendFeedBack = async ({ name, email, message }) => {
  try {
    const response = await axios.post(
      `${REACT_APP_BACKEND_API_BASE_URL}/homepage/sendFeedBack`,
      { name, email, message }
    );
    return response?.data;
  } catch (error) {
    console.error(`Error sending FeedBack`, error);
    throw error;
  }
};

export const fetchNFTTransferHistory = async ({
  contractAddresses = [],
  collectionSlugs = [],
  collectionCreators = [],
  walletAddresses = [],
  deselectContractAddress = false,
  priceRange,
  status,
  page = 1,
  limit = 50,
  sortKey = "event_timestamp",
  sortDirection = -1,
  projections,
  groupByProjections,
  noCache,
}) => {
  try {
    let axios_ins = axios;
    if (noCache) {
      axios_ins = Axios;
    }
    const params = new URLSearchParams();

    if (contractAddresses && contractAddresses.length > 0) {
      params.append("contractAddresses", contractAddresses.join(","));
    }

    if (collectionSlugs && collectionSlugs.length > 0) {
      params.append("collectionSlugs", collectionSlugs.join(","));
    }

    if (walletAddresses && walletAddresses.length > 0) {
      params.append("walletAddresses", walletAddresses.join(","));
    }
    if (status && status !== "All") {
      status = encodeURIComponent(JSON.stringify(status));
      params.append("status", status);
    }

    if (priceRange) params.append("priceRange", JSON.stringify(priceRange));

    if (projections && projections.length > 0) {
      params.append("projections", projections.join(","));
    }

    if (groupByProjections && groupByProjections.length > 0) {
      params.append("groupByProjections", groupByProjections.join(","));
    }

    params.append("deselectContractAddress", deselectContractAddress);

    if (noCache) {
      params.append("noCache", true);
    }
    params.append("page", page);
    params.append("limit", limit);
    params.append("sortKey", sortKey);
    params.append("sortDirection", sortDirection);
    const response = await Axios.get(
      `${REACT_APP_BACKEND_API_BASE_URL}/homepage/nft_transfer_history?${params.toString()}`
    );
    return response?.data?.nftTransfers || [];
  } catch (error) {
    console.error(`Error fetching NFT Transfer History`, error);
    throw error;
  }
};
