// src/actions/filterSectionActions.js

export const setCollectionPageFiltersTokenStatus = (status) => ({
  type: "SET_TOKEN_STATUS",
  payload: status,
});

export const setCollectionPageFiltersActivityStatus = (status) => ({
  type: "SET_ACTIVITY_STATUS",
  payload: status,
});

export const setCollectionPageFiltersMinListingPrice = (
  minListingPriceFilter
) => ({
  type: "SET_MIN_LISTING_PRICE_FILTER",
  payload: minListingPriceFilter,
});

export const setCollectionPageFiltersMaxListingPrice = (
  maxListingPriceFilter
) => ({
  type: "SET_MAX_LISTING_PRICE_FILTER",
  payload: maxListingPriceFilter,
});

export const setCollectionPageFiltersListingPriceCurrency = (
  listingPriceCurrencyFilter
) => ({
  type: "SET_LISTING_PRICE_CURRENCY_FILTER",
  payload: listingPriceCurrencyFilter,
});

export const setCollectionPageFiltersListingPriceFilter = ({
  minListingPriceFilter,
  maxListingPriceFilter,
  listingPriceCurrencyFilter,
}) => ({
  type: "SET_LISTING_PRICE_FILTER",
  payload: {
    minListingPriceFilter,
    maxListingPriceFilter,
    listingPriceCurrencyFilter,
  },
});

export const setCollectionPageFiltersTokenSorting = ({
  tokensSortText,
  tokensSortKey,
  tokensSortDirection,
}) => ({
  type: "SET_TOKENS_SORTING",
  payload: { tokensSortText, tokensSortKey, tokensSortDirection },
});

export const setCollectionPageFiltersActivitySelectedTraits = ({
  traitType,
  traitValue,
}) => ({
  type: "SET_ACTIVITY_SELECTED_TRAITS",
  payload: { traitType, value: traitValue },
});

export const setCollectionPageFiltersTokensSelectedTraits = ({
  traitType,
  traitValue,
}) => ({
  type: "SET_TOKENS_SELECTED_TRAITS",
  payload: { traitType, value: traitValue },
});
