import { isNullOrUndefinedOrEmptyObject } from "../utils/commonUtils/Utils";
import { REACT_APP_BACKEND_API_BASE_URL } from "./backend_domains/backend_api_domains";
import Axios from "axios";
import { setupCache } from "axios-cache-interceptor";
const instance = Axios.create();
const axios = setupCache(instance);

const URL = `${REACT_APP_BACKEND_API_BASE_URL}`;

export async function fetchWalletDetails(
  walletAddress,
  getUpdatedAggregates,
  getMjUserDetail = false
) {
  if (!walletAddress) return {};
  const params = {
    walletAddress: walletAddress,
    getMjUserDetail: false,
    getUpdatedAggregates: false,
  };
  try {
    const response = await axios.get(`${URL}/user/details`, { params });
    const data = response.data;
    const userDetailList = data?.userDetailList || [];
    if (!userDetailList.length) return {};
    if (userDetailList.length == 1) return userDetailList[0];
    return userDetailList;
  } catch (error) {
    console.error("Wallet Details API is not working. " + error.message);
    throw error;
  }
}

export async function fetchWalletNameDetails(walletAddresses) {
  if (walletAddresses.length === 0) return [];
  walletAddresses = walletAddresses.join(",");
  try {
    const response = await axios.post(`${URL}/user/name_details`, {
      walletAddresses: walletAddresses,
    });
    return response?.data?.userNameDetails || [];
  } catch (error) {
    console.error("Wallet Details API is not working. " + error.message);
    throw error;
  }
}
/* Fetch Wallet Tokens Minted By Address */
export const fetchTokensMintedByWallet = async (
  walletAddress,
  contractTokenIds,
  traitsArray,
  status,
  listingPriceFilter,
  page,
  limit,
  sortKey,
  sortDirection
) => {
  try {
    page = page || 1;
    limit = limit || 32;

    sortKey = sortKey || "mintTimestamp";
    sortKey = encodeURIComponent(JSON.stringify(sortKey));

    sortDirection = sortDirection || 1;

    if (isNullOrUndefinedOrEmptyObject(traitsArray)) traitsArray = [];
    traitsArray = encodeURIComponent(JSON.stringify(traitsArray)) || [];

    let API_URL = `${URL}/user/tokens/minted?walletAddress=${walletAddress}&page=${page}&limit=${limit}&sortKey=${sortKey}&sortDirection=${sortDirection}&traitsArray=${traitsArray}`;

    if (status === "Listed") {
      if (
        listingPriceFilter &&
        listingPriceFilter.minListingPriceFilter &&
        listingPriceFilter.maxListingPriceFilter &&
        listingPriceFilter.minListingPriceFilter <=
          listingPriceFilter.maxListingPriceFilter
      ) {
        listingPriceFilter = encodeURIComponent(
          JSON.stringify(listingPriceFilter)
        );
        API_URL = API_URL + `&listingPriceRange=${listingPriceFilter}`;
      }
    }

    if (status !== "All") {
      status = encodeURIComponent(JSON.stringify(status));
      API_URL = API_URL + `&status=${status}`;
    }

    if (contractTokenIds && contractTokenIds.length > 0) {
      API_URL = API_URL + `&contractTokenIds=${contractTokenIds.join(",")}`;
    }

    const response = await axios.get(API_URL);
    return response.data || [];
  } catch (error) {
    throw error;
  }
};

/* Fetch Wallet Tokens Created By Address */
export const fetchTokensCreatedByWallet = async (
  walletAddress,
  contractTokenIds,
  traitsArray,
  status,
  listingPriceFilter,
  page,
  limit,
  sortKey,
  sortDirection
) => {
  try {
    page = page || 1;
    limit = limit || 32;

    sortKey = sortKey || "mintTimestamp";
    sortKey = encodeURIComponent(JSON.stringify(sortKey));

    sortDirection = sortDirection || 1;

    if (isNullOrUndefinedOrEmptyObject(traitsArray)) traitsArray = [];
    traitsArray = encodeURIComponent(JSON.stringify(traitsArray)) || [];

    let API_URL = `${URL}/user/tokens/created?walletAddress=${walletAddress}&page=${page}&limit=${limit}&sortKey=${sortKey}&sortDirection=${sortDirection}&traitsArray=${traitsArray}`;

    if (status === "Listed") {
      if (
        listingPriceFilter &&
        listingPriceFilter.minListingPriceFilter &&
        listingPriceFilter.maxListingPriceFilter &&
        listingPriceFilter.minListingPriceFilter <=
          listingPriceFilter.maxListingPriceFilter
      ) {
        listingPriceFilter = encodeURIComponent(
          JSON.stringify(listingPriceFilter)
        );
        API_URL = API_URL + `&listingPriceRange=${listingPriceFilter}`;
      }
    }

    if (status !== "All") {
      status = encodeURIComponent(JSON.stringify(status));
      API_URL = API_URL + `&status=${status}`;
    }

    if (contractTokenIds && contractTokenIds.length > 0) {
      API_URL = API_URL + `&contractTokenIds=${contractTokenIds.join(",")}`;
    }

    const response = await axios.get(API_URL);
    return response.data || [];
  } catch (error) {
    throw error;
  }
};

/* Fetch Wallet Tokens Owned By Address */
export async function fetchTokensOwnedByWallet(
  walletAddresses,
  contractTokenIds,
  contractAddresses,
  collectionSlugs,
  traitsArray,
  status,
  listingPriceFilter,
  addFolderInfo,
  page = 1,
  limit = 32,
  sortKey = "mintTimestamp",
  sortDirection = 1
) {
  if (isNullOrUndefinedOrEmptyObject(walletAddresses)) return [];
  try {
    const params = {
      walletAddresses: walletAddresses.join(","),
      page,
      limit,
      sortKey: JSON.stringify(sortKey),
      sortDirection,
      traitsArray: traitsArray ? JSON.stringify(traitsArray) : undefined,
      contractAddresses: contractAddresses?.join(","),
      collectionSlugs: collectionSlugs?.join(","),
      addFolderInfo: addFolderInfo || undefined,
      contractTokenIds: contractTokenIds,
    };

    if (
      status === "Listed" &&
      listingPriceFilter?.minListingPriceFilter <=
        listingPriceFilter?.maxListingPriceFilter
    ) {
      params.listingPriceRange = JSON.stringify(listingPriceFilter);
    }

    if (status !== "All") {
      params.status = JSON.stringify(status);
    }

    Object.keys(params).forEach(
      (key) => params[key] === undefined && delete params[key]
    );

    const response = await axios.get(`${URL}/user/tokens/owned`, { params });
    return response.data || [];
  } catch (error) {
    throw error;
  }
}

/* Fetch  collections for Tokens Owned By Wallet Address */
export const fetchCollectionsForOwnedTokens = async (
  walletAddresses,
  page,
  limit,
  top
) => {
  try {
    if (!walletAddresses || walletAddresses.length === 0) {
      return [];
    }

    const API_URL = `${URL}/user/collectionsForTokensOwned?walletAddresses=${walletAddresses.join(
      ","
    )}&page=${page}&limit=${limit}&top=${top}`;

    const response = await axios.get(API_URL);
    return response.data || [];
  } catch (error) {
    throw error;
  }
};

export const fetchCollectionSlugsForOwnedTokens = async (
  walletAddresses,
  page,
  limit
) => {
  try {
    if (!walletAddresses || walletAddresses.length === 0) {
      return [];
    }

    const API_URL = `${URL}/user/collectionSlugsForTokensOwned?walletAddresses=${walletAddresses.join(
      ","
    )}&page=${page}&limit=${limit}`;

    const response = await axios.get(API_URL);

    return response.data || [];
  } catch (error) {
    throw error;
  }
};

/* Fetch Owners Transfer History Details*/
export const fetchOwnerTransferHistoryDetails = async (
  walletAddresses,
  tokenCreator,
  page,
  limit,
  contractAddresses,
  folderIds,
  sortKey,
  sortDirection
) => {
  try {
    page = page || 1;
    limit = limit || 32;

    if (walletAddresses) walletAddresses = walletAddresses.join(",");

    let API_URL = `${URL}/user/owner/transfer_history?walletAddresses=${walletAddresses}&page=${page}&limit=${limit}&sortKey=${sortKey}&sortDirection=${sortDirection}`;

    if (tokenCreator) {
      tokenCreator = encodeURIComponent(JSON.stringify(tokenCreator));
      API_URL = API_URL + `&tokenCreator=${tokenCreator}`;
    }

    if (contractAddresses && Array.isArray(contractAddresses)) {
      contractAddresses = contractAddresses.join(",");
      API_URL = API_URL + `&contractAddresses=${contractAddresses}`;
    }

    if (folderIds && Array.isArray(folderIds)) {
      folderIds = folderIds.join(",");
      API_URL = API_URL + `&folderIds=${folderIds}`;
    }

    const response = await axios.get(API_URL);

    return response.data || [];
  } catch (error) {
    throw error;
  }
};

/* Fetch Wallet Tokens Owned By Address Count */
export const fetchTokensOwnedByWalletCount = async (walletAddresses) => {
  try {
    if (!walletAddresses || walletAddresses.length === 0) {
      return 0;
    }

    let API_URL = `${URL}/user/tokens/owned/count?walletAddresses=${walletAddresses.join(
      ","
    )}`;

    const response = await axios.get(API_URL);
    return response?.data?.totalTokens || 0;
  } catch (error) {
    throw error;
  }
};

/* Fetch Wallet Transfer History Details*/
export const fetchWalletTransferHistoryDetails = async (
  walletAddresses,
  status,
  page,
  limit,
  sortKey,
  sortDirection
) => {
  try {
    page = page || 1;
    limit = limit || 32;

    sortKey = sortKey || "event_timestamp";
    // sortKey = encodeURIComponent(JSON.stringify(sortKey));

    sortDirection = sortDirection || 1;

    if (!walletAddresses) return [];
    walletAddresses = walletAddresses.join(",");
    const params = {
      walletAddresses: walletAddresses,
      page: page,
      limit: limit,
      sortKey: sortKey,
      sortDirection: sortDirection,
    };
    if (status && status !== "All") {
      // status = encodeURIComponent(JSON.stringify(status));
      params.status = status;
    }
    let API_URL = `${URL}/nft_transfer`;

    // if (status && status !== "All") {
    //   status = encodeURIComponent(JSON.stringify(status));
    //   API_URL = API_URL + `&status=${status}`;
    // }

    const response = await axios.get(API_URL, { params: params });

    return response.data || [];
  } catch (error) {
    throw error;
  }
};

/* Fetch Owners Transfer History Details*/
export const fetchOwnerTokenAndCollectionInvestmentAggregates = async (
  walletAddress,
  tokenCreator,
  page,
  limit
) => {
  if (!walletAddress && !tokenCreator) return [];
  try {
    page = page || 1;
    limit = limit || 32;

    let API_URL = `${URL}/user/owner/collectionInvestment?walletAddress=${walletAddress}`;

    if (tokenCreator) {
      tokenCreator = encodeURIComponent(JSON.stringify(tokenCreator));
      API_URL = API_URL + `&tokenCreator=${tokenCreator}`;
    }

    const response = await axios.get(API_URL);

    return (
      {
        collectionToInvestmentMap: response?.data?.collectionToInvestmentMap,
        tokenIdToInvestmentMap: response?.data?.tokenIdToInvestmentMap,
      } || {}
    );
  } catch (error) {
    throw error;
  }
};

/* Fetch Creator Transfer History Details*/
export const fetchCreatorTransferHistoryDetails = async (
  walletAddress,
  status,
  page,
  limit,
  sortKey,
  sortDirection
) => {
  try {
    page = page || 1;
    limit = limit || 32;

    sortKey = sortKey || "event_timestamp";
    sortKey = encodeURIComponent(JSON.stringify(sortKey));

    sortDirection = sortDirection || 1;

    let API_URL = `${URL}/user/creator/transfer_history?walletAddress=${walletAddress}&page=${page}&limit=${limit}&sortKey=${sortKey}&sortDirection=${sortDirection}`;

    if (status !== "All") {
      status = encodeURIComponent(JSON.stringify(status));
      API_URL = API_URL + `&status=${status}`;
    }

    const response = await axios.get(API_URL);

    return response.data || [];
  } catch (error) {
    throw error;
  }
};

/* Fetch details for a Creator's owned collections */
export const fetchCreatorOwnedCollections = async (walletAddresses) => {
  try {
    if (!walletAddresses) return {};
    walletAddresses = walletAddresses.join(",");

    const response = await fetch(
      `${URL}/user/collections_owned?walletAddresses=${walletAddresses}`
    );
    const data = await response.json();
    if (data.success) {
      return data;
    } else {
      console.error("Failed to fetch owned collections");
      throw Error(
        "Failed to fetch owned collections for Creator " + walletAddresses
      );
    }
  } catch (error) {
    throw error;
  }
};

/* Fetch details for a Creator's created collections */
export const fetchCreatorCreatedCollections = async (walletAddress) => {
  try {
    const response = await fetch(
      `${URL}/user/${walletAddress}/collections_created?walletAddress=${walletAddress}`
    );
    const data = await response.json();
    if (data.success) {
      return data;
    } else {
      console.error("Failed to fetch creators collections");
      throw Error(
        "Failed to fetch creators collections for Creator " + walletAddress
      );
    }
  } catch (error) {
    throw error;
  }
};

export const fetchListingInfoForAllTokenForUser = async (
  walletAddress,
  userType
) => {
  try {
    if (!userType) {
      userType = "Creator";
    }

    const response = await axios.get(`${URL}/user/token_listings`, {
      params: {
        walletAddress: walletAddress,
        userType,
      },
    });

    const data = response.data;
    if (data.success) {
      return data ? data : {};
    } else {
      console.error("Failed to fetch creators tokens");
      throw Error(
        "Failed to Listing Info For All Token For User " + walletAddress
      );
    }
  } catch (error) {
    throw error;
  }
};

export const fetchListingCountForAllTokenForUser = async (
  walletAddress,
  userType
) => {
  try {
    const response = await axios.get(`${URL}/user/token_listings/count`, {
      params: {
        walletAddress: walletAddress,
        userType,
      },
    });
    const data = response.data;
    if (data.success) {
      return data ? data.tokensListedCount : {};
    } else {
      throw Error(
        "Failed to Listing Count For All Token For User " + walletAddress
      );
    }
  } catch (error) {
    throw error;
  }
};

export const fetchOwnersCountForCreatedTokens = async (tokenCreator) => {
  try {
    const API_URL = `${URL}/user/ownersCountForTokens?tokenCreator=${tokenCreator}`;

    const response = await axios.get(API_URL);
    if (response && response.data && response.data.success) {
      return response?.data?.ownersCountForCreatedTokens || 0;
    }
    return 0;
  } catch (error) {
    throw error;
  }
};

export const fetchCreatorAggregates = async (walletAddress) => {
  try {
    const response = await axios.get(`${URL}/user/aggregatesForCreatorPage`, {
      params: {
        walletAddress: walletAddress,
      },
    });

    return response?.data?.aggregatesForCreatorPage || {};
  } catch (error) {
    console.error("Error fetching Creator Aggregates:", error);
    throw error;
  }
};

export const fetchWalletReportAggregates = async (walletAddress) => {
  try {
    const response = await axios.get(`${URL}/user/wallet_report_aggregates`, {
      params: {
        walletAddress: walletAddress,
      },
    });

    return response.data || {};
  } catch (error) {
    console.error("Error fetching Wallet Report Aggregates:", error);
    throw error;
  }
};

export const fetchProfileWalletSummary = async (
  walletAddresses,
  distributionOnly
) => {
  try {
    if (!walletAddresses) return {};
    walletAddresses = walletAddresses.join(",");
    const response = await axios.get(`${URL}/user/profile_wallet_summary`, {
      params: {
        walletAddresses: walletAddresses,
        distributionOnly: distributionOnly,
      },
    });
    return response?.data?.profileWalletSummary || {};
  } catch (error) {
    console.error("Error fetching Profile Wallet Summary:", error);
    throw error;
  }
};

export async function getWalletAddressByLowerCaseAddress(walletAddress) {
  try {
    const res = await axios.get(
      `${URL}/user/wallet_address_by_lowercase_address`,
      { params: { walletAddressLowerCase: walletAddress } }
    );
    return res?.data?.walletAddress || [];
  } catch (e) {
    throw e;
  }
}

export async function getUserCreatedTokensCount(walletAddress) {
  try {
    const res = await axios.get(
      `${URL}/token_details/creator/tokens/created/count`,
      {
        params: {
          walletAddress: walletAddress,
        },
      }
    );
    return res?.data?.tokensCreatedCount || [];
  } catch (e) {
    throw e;
  }
}
export async function getUserMintedTokensCount(walletAddress) {
  try {
    const res = await axios.get(
      `${URL}/token_details/creator/tokens/minted/count`,
      {
        params: {
          walletAddress: walletAddress,
        },
      }
    );
    return res?.data?.tokensMintedCount || [];
  } catch (e) {
    throw e;
  }
}
export async function getUserOwnedTokensCount(walletAddress) {
  try {
    const res = await axios.get(
      `${URL}/token_details/creator/tokens/owned/count`,
      {
        params: {
          walletAddress: walletAddress,
        },
      }
    );
    return res?.data?.tokensOwnedCount || [];
  } catch (e) {
    throw e;
  }
}
