import { useEffect, useState } from "react";
import CollectionSearchResultItem from "./CollectionSearchResultItem";
import RemoveButton from "../../Icons/CollectionPageIcons/RemoveButton";
import useIsMobile from "../../customHooks/UseIsMobile";

export default function CollectionsSearchResult({
  collectionsResult,
  isSearching,
  collectionObjsSelected,
  addToCollectionObjsSelected,
  deleteFromCollectionObjsSelected,
  onClose,
  resetSearch,
  allFiltersSelected,
}) {
  const [showResults, setShowResults] = useState(true);
  const isMobile = useIsMobile();

  return (
    <>
      {showResults && (
        <div
          className={
            !isMobile
              ? "search-results d-flex flex-column align-items-center border-0"
              : "search-results-mobile d-flex flex-column align-items-center border-0"
          }
          style={{ zIndex: 999999999 }}
        >
          <span
            onClick={resetSearch}
            style={{
              alignSelf: "flex-end",
              cursor: "pointer",
            }}
          >
            <RemoveButton color="black" />
          </span>
          {collectionsResult &&
            collectionsResult.map((item, index) => {
              let contractAddrSelected = (collectionObjsSelected || [])
                .map((obj) => obj.contractAddress)
                .includes(item?.contractAddress);

              if (allFiltersSelected) {
                contractAddrSelected = !contractAddrSelected;
              }

              return (
                <CollectionSearchResultItem
                  item={item}
                  isSearching={isSearching}
                  key={index}
                  contractAddrSelected={contractAddrSelected}
                  addToCollectionObjsSelected={addToCollectionObjsSelected}
                  deleteFromCollectionObjsSelected={
                    deleteFromCollectionObjsSelected
                  }
                  allFiltersSelected={allFiltersSelected}
                />
              );
            })}
        </div>
      )}
    </>
  );
}
