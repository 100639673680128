import React from "react";
import "../../styles/CommonComponentsStyles/LoadingSpinner.css";

export default function LoadingSpinner({ styleObj }) {
  return (
    <div
      className="spinner-container"
      // style={{
      //   ...styleObj,
      // }}
    >
      <div
        className="loading-spinner"
        style={{
          ...styleObj,
        }}
      ></div>
    </div>
  );
}
