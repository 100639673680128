import React, { useEffect, useState } from "react";
import { updatePriorityApi } from "../../api/collectionDetailsApi";
import { useSelector } from "react-redux";
import "../../styles/collectionPageStyles/CollectionPage.css";
import useIsMobile from "../../customHooks/UseIsMobile";

const WhitelistBlacklistSection = ({
  contractId,
  collectionSlug,
  collectionCreator,
  priority,
}) => {
  const [isPriority, setIsPriority] = useState("normal");
  const userInfo = useSelector((state) => state.user.userInfo);
  const isMobile = useIsMobile();

  const handlePriority = (currentpriority) => {
    try {
      let priority = null;
      if (currentpriority === isPriority) {
        priority = "normal";
      } else if (currentpriority === "white") {
        priority = "white";
      } else if (currentpriority === "black") {
        priority = "black";
      }

      if (priority) {
        updatePriorityApi(
          contractId,
          collectionSlug,
          collectionCreator,
          priority,
          userInfo.userId
        ).then(setIsPriority(priority));
      }
    } catch (e) {
      console.error(e?.message);
    }
  };

  useEffect(() => {
    if (priority === "white") {
      setIsPriority(priority);
    } else if (priority === "black") {
      setIsPriority(priority);
    } else {
      setIsPriority("normal");
    }
  }, [priority]);

  return (
    <div
      className={
        !isMobile
          ? "whitelist-blacklist-section"
          : "whitelist-blacklist-section-mobile"
      }
    >
      <div
        className={`whitelist-blacklist-button ${
          isPriority === "white" ? "active" : ""
        }`}
        onClick={() => handlePriority("white")}
      >
        Whitelist
      </div>
      <div className="whitelist-blacklist-button ">|</div>

      <div
        className={`whitelist-blacklist-button ${
          isPriority === "black" ? "active" : ""
        }`}
        onClick={() => handlePriority("black")}
      >
        Blacklist
      </div>
    </div>
  );
};

export default WhitelistBlacklistSection;
