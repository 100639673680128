import React, { useEffect, useState } from "react";
import Traits from "../ItemPage/InfoTabs/Traits";
import Activity from "../ItemPage/InfoTabs/Activity";
import { fetchRefreshedTokenDetails } from "../../api/tokenDetailsApi";
import RefreshIcon from "../../Icons/RefreshIcon";
import { Title } from "chart.js";
import {
  isNullOrUndefined,
  isNullOrUndefinedOrEmptyObject,
} from "../../utils/commonUtils/Utils";
import {
  getMJUserBio,
  getWalletBio,
} from "../../utils/commonUtils/WalletDetailsUtils";
import {
  fetchTraitsFromTokenObj,
  getTokenCreator,
} from "../../utils/commonUtils/TokenUtils";
import tokenApis from "../../api/tokenApis";
import LoadingSpinner from "../../utils/commonUtils/LoadingSpinner";
import CreatorQRcode from "./CreatorQRcode";
import useIsMobile from "../../customHooks/UseIsMobile";
import { fetchMJUserDetails } from "../../api/mjUserApi";
import { fetchWalletDetails } from "../../api/walletDetailApi";
import InfoIcon from "../../Icons/InfoIcon";

const ProvenanceAllOtherDetails = ({
  itemData,
  collectionData,
  setItemData,
  tokenTransferHistory,
  fetchPaginatedTransferHistory,
  transferHistoryPaginatedAPIMetadata,
  isProvenanceReportPage,
  isFetchingData,
  isPDF,
}) => {
  const [modalData, setModalData] = useState({});
  const [modalDataLoading, setModalDataLoading] = useState(true);
  const [decodedModalData, setDecodedModalData] = useState("");
  const [validData, setValidData] = useState(true);
  const [showSalesHistory, setShowSalesHistory] = useState(false);

  const [creatorBioMJ, setCreatorBioMJ] = useState(null);
  const [creatorBioOpensea, setCreatorBioOpensea] = useState(null);

  const isMobile = useIsMobile();

  useEffect(() => {
    setModalData(itemData?.tokenMetadata?.data);
    let _decodedModalData = JSON.stringify(
      itemData?.tokenMetadata?.data,
      null,
      1
    );
    setDecodedModalData(_decodedModalData);

    if (isNullOrUndefined(itemData?.tokenMetadata?.data)) {
      setValidData(false);
    }
    setModalDataLoading(false);

    let creatorWalletAddress = getTokenCreator(itemData);

    if (creatorWalletAddress) {
      fetchMJUserDetails(null, creatorWalletAddress)
        .then((creatorUserObj) => {
          if (isNullOrUndefinedOrEmptyObject(creatorUserObj)) {
            fetchWalletDetails(creatorWalletAddress)
              .then((walletDetails) => {
                setCreatorBioOpensea(getWalletBio(walletDetails));
              })
              .catch((e) => {});
          } else {
            let bioObj = getMJUserBio(creatorUserObj);
            setCreatorBioMJ(bioObj?.mjUserBio);
          }
        })
        .catch((e) => {});
    }
  }, [itemData]);

  async function refreshMetadata() {
    setModalDataLoading(true);
    try {
      let refreshedItemData_ = await fetchRefreshedTokenDetails(itemData);

      setItemData(refreshedItemData_);
    } catch (e) {}
    setModalDataLoading(false);
  }

  return (
    <div>
      <div>
        <div className="dashboard-sub-headings-header">
          <span className="dashboard-sub-headings">Description by Creator</span>
        </div>

        <div
          className={
            isPDF
              ? "provenance-token-description-container"
              : !isMobile
              ? "provenance-token-description-container"
              : "provenance-token-description-container-mobile"
          }
        >
          <span
            className={
              isPDF
                ? "provenance-token-description"
                : !isMobile
                ? "provenance-token-description"
                : "provenance-token-description-mobile"
            }
          >
            {itemData?.tokenMetadata?.data?.description ||
              "No description provided by the creator yet!"}
          </span>
          <span>
            <CreatorQRcode />
          </span>
        </div>
      </div>
      <div>
        <div
          className="dashboard-sub-headings-header"
          style={{ display: "flex", flexDirection: "row", gap: "0.5em" }}
        >
          <span className="dashboard-sub-headings">
            Metadata{" "}
            {!isPDF && (
              <span
                onClick={refreshMetadata}
                style={{ cursor: "pointer", fontSize: "0.65em" }}
              >
                <RefreshIcon tooltipTitle="Refresh Metadata" />
              </span>
            )}{" "}
          </span>
        </div>
        <span>
          {!validData || modalDataLoading ? (
            <div className="provenance-metadata-section">
              <pre
                className={
                  isPDF
                    ? "provenance-metadata-data-pdf"
                    : "provenance-metadata-data"
                }
              >
                {modalDataLoading ? (
                  <span>
                    <LoadingSpinner />
                  </span>
                ) : decodedModalData === "null" ? (
                  " Metadata Missing / No Longer Hosted"
                ) : (
                  decodedModalData
                )}
              </pre>
            </div>
          ) : (
            <div
              style={{ whiteSpace: "nowrap" }}
              className="provenance-token-description"
            >
              Metadata Missing / No Longer Hosted
            </div>
          )}
        </span>
      </div>
      <div style={{ width: "80%", marginLeft: "10%" }}>
        <div className="provenance-sub-headings-header">
          <span className="dashboard-sub-headings">Traits</span>
        </div>

        <Traits
          isProvenanceReportPage={isProvenanceReportPage}
          contractAddress={collectionData?.contractAddress}
          collectionSlug={collectionData?.collectionSlug}
          collectionCreator={collectionData?.collectionCreator}
          traits={fetchTraitsFromTokenObj(itemData) || []}
          aggregated_traits={collectionData?.aggregated_traits}
          isPDF={isPDF}
        />
      </div>
      <div style={{ width: "80%", marginLeft: "10%" }}>
        <div className="provenance-sub-headings-header">
          <span className="dashboard-sub-headings">Activity</span>
        </div>
        <Activity
          tokenTransferHistory={tokenTransferHistory}
          fetchPaginatedTransferHistory={fetchPaginatedTransferHistory}
          transferHistoryPaginatedAPIMetadata={
            transferHistoryPaginatedAPIMetadata
          }
          isFetchingData={isFetchingData}
          showSalesHistory={showSalesHistory}
          isPDF={isPDF}
        />
      </div>
      {/* <div style={{ width: "80%", marginLeft: "10%" }}>
        <div className="provenance-sub-headings-header">
          <span className="dashboard-sub-headings">License</span>
        </div>
        <span style={{ lineHeight: "normal", color: "red" }}>
          Can be used to display privately, or in commercial and non-commercial
          settings, or in groups with an unlimited number of participants. The
          license includes unlimited use and display in virtual or physical
          galleries, documentaries, and essays by the NFT holder. Provides no
          rights to create commercial merchandise, commercial distribution, or
          derivative works.
        </span>
      </div> */}
      <div style={{ width: "80%", marginLeft: "10%" }}>
        <div className="provenance-sub-headings-header">
          <span className="dashboard-sub-headings">
            Creator Bio{" "}
            {creatorBioMJ && (
              <span className="bio-info-icon-row">
                <InfoIcon />
                Bio by MetaJungle.
              </span>
            )}
            {creatorBioOpensea?.bio && (
              <span className="bio-info-icon-row">
                <InfoIcon />
                Bio by OpenSea.
              </span>
            )}
          </span>
        </div>
        <span>{creatorBioMJ || creatorBioOpensea?.bio || "Bio Missing"}</span>
      </div>
    </div>
  );
};

export default ProvenanceAllOtherDetails;
