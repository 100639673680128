import React, { useState } from "react";
import useIsMobile from "../../customHooks/UseIsMobile";
import ActivityTable from "../CommonComponents/ActivityTable";

function ActivityTableContainer({
  transferHistory,
  showFilterSection,
  isFetchingData,
  activitySortingState,
  setActivitySortingState,
  stickyHeader,
  disableSorting,
}) {
  const isMobile = useIsMobile();

  return (
    <div
      className={`${
        isMobile
          ? " transfer-history-table-container  items-tab-items-container-without-filter-section"
          : !showFilterSection
          ? " transfer-history-table-container  items-tab-items-container-without-filter-section"
          : " transfer-history-table-container-with-filter items-tab-items-container-with-filter-section"
      }`}
    >
      <ActivityTable
        transferHistory={transferHistory}
        isFetchingData={isFetchingData}
        activitySortingState={activitySortingState}
        setActivitySortingState={setActivitySortingState}
        showFilterSection={showFilterSection}
        stickyHeader={stickyHeader || false}
        disableSorting={disableSorting}
      />
    </div>
  );
}

ActivityTableContainer.propTypes = {};

export default ActivityTableContainer;
