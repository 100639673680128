import { REACT_APP_BACKEND_API_BASE_URL } from "./backend_domains/backend_api_domains";
import Axios from "axios";
import { setupCache } from "axios-cache-interceptor";
const instance = Axios.create();
const axios = setupCache(instance);

class NftTransferApi {
  constructor() {
    this.apiUrl = `${REACT_APP_BACKEND_API_BASE_URL}`;
  }

  async fetchNftTransfers(
    tokenId,
    contractAddress,
    page,
    limit,
    sortKey,
    sortDirection
  ) {
    const params = {
      tokenId: tokenId,
      contractAddress: contractAddress,
      page: page || 1,
      limit: limit,
      sortKey: sortKey || "event_timestamp",
      sortDirection: sortDirection || 1,
    };
    try {
      const response = await axios.get(`${this.apiUrl}/nft_transfer`, {
        params: params,
      });
      return response?.data || [];
    } catch (error) {
      throw error;
    }
  }
}

const nftTransferApi = new NftTransferApi();
export default nftTransferApi;
