import React from "react";

const EditIcon = ({}) => {
  return (
    <div style={{ cursor: "pointer" }}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1.2em"
        height="1.2em"
        viewBox="0 0 20 20"
        fill="none"
      >
        <path
          d="M0.625 13.2782V15.9382C0.625 16.1832 0.8175 16.3757 1.0625 16.3757H3.7225C3.83625 16.3757 3.95 16.332 4.02875 16.2445L13.5837 6.69822L10.3025 3.41697L0.75625 12.9632C0.66875 13.0507 0.625 13.1557 0.625 13.2782ZM16.1212 4.16072C16.4625 3.81947 16.4625 3.26822 16.1212 2.92697L14.0738 0.879473C13.7325 0.538223 13.1812 0.538223 12.84 0.879473L11.2388 2.48072L14.52 5.76197L16.1212 4.16072Z"
          fill="#747474"
        />
      </svg>
    </div>
  );
};

export default EditIcon;
