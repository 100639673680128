import React, { useEffect, useState } from "react";
import ArrowIcon from "../../Icons/ArrowIcon";
import EachArciveNote from "./EachArciveNote";
import ArrowIconReverse from "../../Icons/ArrowIconReverse";
import { fetchTokenProvenance } from "../../api/tokenDetailsApi";

const ProvenanceArchiveNotesSection = ({ itemData, collectionData, isPDF }) => {
  const [tokenProvenance, setTokenProvenance] = useState(null);

  useEffect(() => {
    const contractAddress = itemData?.contractAddress;
    const tokenId = itemData?.tokenId;

    if (!contractAddress || !tokenId) return;

    fetchTokenProvenance(contractAddress, tokenId)
      .then((provenance) => {
        if (provenance) {
          setTokenProvenance(provenance?.data);
        }
      })
      .catch((e) => {});
  }, [itemData]);

  const [openNoteIndex, setOpenNoteIndex] = useState(0);
  return (
    <div>
      <div>
        <div
          className="dashboard-sub-headings-header"
          style={{ width: "80%", marginLeft: "10%" }}
        >
          <span className="dashboard-sub-headings">Archive Notes</span>
        </div>
      </div>

      {tokenProvenance && tokenProvenance.length > 0 ? (
        <div className="provenance-archive-note-section">
          {tokenProvenance?.map((noteType, index) => {
            const isOpen = openNoteIndex === index;

            return (
              noteType.data.length > 0 && (
                <div
                  className={`provenance-archive-note ${
                    isOpen ? "open-note" : ""
                  }`}
                  key={index}
                >
                  <div
                    className="provenance-archive-note-headings"
                    onClick={() => setOpenNoteIndex(isOpen ? null : index)}
                  >
                    <span className="provenance-archive-note-heading">
                      {noteType?.categoryName}
                    </span>
                    {!isPDF && (
                      <span>
                        {isOpen ? <ArrowIcon /> : <ArrowIconReverse />}
                      </span>
                    )}
                  </div>
                  {(isOpen || isPDF) && (
                    <div>
                      <EachArciveNote
                        provenanceData={noteType?.data}
                        categoryName={noteType?.categoryName}
                        isPDF={isPDF}
                      />
                    </div>
                  )}
                </div>
              )
            );
          })}
        </div>
      ) : (
        <p
          className="provenance-token-description"
          style={{ marginLeft: "10%", paddingBottom: "5%" }}
        >
          {" "}
          No Provenance Data added for this token!
        </p>
      )}
    </div>
  );
};

export default ProvenanceArchiveNotesSection;
