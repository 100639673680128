import React, { useState } from "react";
import "../../styles/collectionPageStyles/CollectionPage.css";
import LeftArrowIcon from "../../Icons/CollectionPageIcons/LeftArrowIcon";
import RightArrowIcon from "../../Icons/CollectionPageIcons/RightArrowIcon";
import useIsMobile from "../../customHooks/UseIsMobile";
import FilterIconClosedMobile from "../../Icons/CollectionPageIcons/FilterIconClosedMobile";
import FilterIconOpenMobile from "../../Icons/CollectionPageIcons/FilterIconOpenMobile";

const FilterButtonMobile = ({ onClick }) => {
  const [isLeftArrow, setIsLeftArrow] = useState(true);

  const toggleArrow = () => {
    setIsLeftArrow(!isLeftArrow);
  };

  const isMobile = useIsMobile();

  return (
    <button
      className="items_tab_header_buttons filter-button"
      onClick={() => {
        toggleArrow();
        onClick();
      }}
    >
      {isLeftArrow ? <FilterIconClosedMobile /> : <FilterIconOpenMobile />}{" "}
    </button>
  );
};

export default FilterButtonMobile;
