import React, { useEffect, useRef, useState } from "react";
import OpenOriginalIcon from "../../Icons/MoreOptionsMenuIcon/OpenOriginal";
import { trimMiddleText } from "../../utils/commonUtils/Utils";
import { fetchTokenDefaultImageOnError } from "../../utils/commonUtils/TokenUtils";
import {
  fetchQuantityFromNftTransfer,
  fetchSalesPriceTransferEth,
  fetchSalesPriceTransferEthUI,
  fetchSalesPriceTransferUSD,
  fetchFromUserNFTTransfer,
  fetchToUserNFTTransfer,
  fetchTimeOfTransfer,
  fetchTransferType,
  getTokenNameFromNft,
  getCollectionNameFromNft,
  getDisplayImageUrlFromNft,
  getImageUrlFromNft,
} from "../../utils/commonUtils/TransferHistoryUtil";
import useIsMobile from "../../customHooks/UseIsMobile";
import OtherActivityColumns from "./OtherActivityColumns";
import TableLoader from "../../utils/commonUtils/TableLoader";
import { FaArrowUp, FaArrowDown } from "react-icons/fa";
import { HiArrowsUpDown } from "react-icons/hi2";
import "../../styles/itemPageStyles/AllStylings/ActivityTab.css";
import ActivityUserCell from "./ActivityUserCell";
import { FaCircleCheck } from "react-icons/fa6";
import { MdDoNotDisturbOn } from "react-icons/md";
import {
  fetchWalletDetails,
  fetchWalletNameDetails,
} from "../../api/walletDetailApi";
import { getWalletProfileName } from "../../utils/commonUtils/WalletDetailsUtils";
import { IoCartOutline } from "react-icons/io5";
import { BiTransfer } from "react-icons/bi";
import { GrGrow } from "react-icons/gr";
import { PiFireBold } from "react-icons/pi";
import AddInfoModal from "./AddInfoModal";

function ActivityTable({
  transferHistory,
  isFetchingData,
  activitySortingState,
  setActivitySortingState,
  collectionName,
  showImageAvailable,
  showFilterSection,
  stickyHeader,
  ScrollOn,
  disableSorting,
}) {
  const rowRef = useRef(null);
  const [colWidths, setColWidths] = useState([]);
  const [openRows, setOpenRows] = useState([]);
  const [openMore, setOpenMore] = useState(false);
  const [originalImageHostedMap, setOriginalImageHostedMap] = useState({});
  const [walletDataMap, setWalletDataMap] = useState({});
  const isMobile = useIsMobile();

  const handleOtherActivityColumns = (index) => {
    setOpenRows((prevOpenRows) => {
      const newOpenRows = [...prevOpenRows];
      newOpenRows[index] = !newOpenRows[index];
      return newOpenRows;
    });
    setOpenMore(!openMore);
  };

  const handlePriceHeaderClick = () => {
    if (!activitySortingState) return;
    if (activitySortingState.activitySortText === "Sold Price: Low to High") {
      setActivitySortingState({
        activitySortKey: "payment.quantity",
        activitySortDirection: -1,
        activitySortText: "Sold Price: High to Low",
      });
    } else if (
      activitySortingState.activitySortText === "Sold Price: High to Low"
    ) {
      setActivitySortingState({
        activitySortKey: "event_timestamp",
        activitySortDirection: -1,
        activitySortText: "Transfer Date: Newest to Oldest",
      });
    } else {
      setActivitySortingState({
        activitySortKey: "payment.quantity",
        activitySortDirection: 1,
        activitySortText: "Sold Price: Low to High",
      });
    }
  };

  const handleTimeHeaderClick = () => {
    if (!activitySortingState) return;
    if (
      activitySortingState.activitySortText ===
      "Transfer Date: Newest to Oldest"
    ) {
      setActivitySortingState({
        activitySortKey: "event_timestamp",
        activitySortDirection: 1,
        activitySortText: "Transfer Date: Oldest to Newest",
      });
    } else {
      setActivitySortingState({
        activitySortKey: "event_timestamp",
        activitySortDirection: -1,
        activitySortText: "Transfer Date: Newest to Oldest",
      });
    }
  };

  useEffect(() => {
    if (rowRef?.current) {
      const widths = Array.from(rowRef.current.children).map(
        (td) => td.offsetWidth
      );
      setColWidths(widths);
    }
  }, [transferHistory, showFilterSection, walletDataMap]);

  useEffect(() => {
    const fetchWalletData = async () => {
      const addresses = new Set(
        transferHistory?.flatMap((item) => [
          fetchFromUserNFTTransfer(item),
          fetchToUserNFTTransfer(item),
        ])
      );
      const uniqueAddressesArray = Array.from(addresses);
      const walletUserDataArray = await fetchWalletNameDetails(
        uniqueAddressesArray
      );
      const userMap = {};
      walletUserDataArray.forEach((user) => {
        userMap[user.walletAddress] = user;
      });
      setWalletDataMap(userMap);
    };

    fetchWalletData();
  }, [transferHistory]);

  const updateOriginalImageHosted = (contractTokenId, value) => {
    setOriginalImageHostedMap((prevMap) => {
      const newMap = { ...prevMap };
      newMap[contractTokenId] = value;
      return newMap;
    });
  };

  const classNameForHeader = `${
    !stickyHeader && !ScrollOn ? "non-sticky-border-bottom" : ""
  } ${ScrollOn ? "scroll-on-table-header" : ""}`.trim();

  const sortTransferHistory = () => {
    return [...transferHistory].sort((a, b) => {
      const direction = activitySortingState.activitySortDirection;
      const key = activitySortingState.activitySortKey;

      const valA = a[key];
      const valB = b[key];

      if (valA > valB) return direction;
      if (valA < valB) return -direction;
      return 0;
    });
  };

  const sortedTransferHistory = sortTransferHistory();

  let headerComponent = (
    <thead>
      <tr
        className={classNameForHeader}
        style={{ color: "#183b56", height: "25px" }}
      >
        <th
          className="hiddenOnMobile"
          style={
            stickyHeader
              ? {
                  width: colWidths[0] || "auto",
                  padding: "1rem",
                }
              : {}
          }
        >
          Event
        </th>
        <th
          style={
            stickyHeader
              ? { width: colWidths[1] || "auto", padding: "0.5rem 1rem" }
              : {}
          }
        >
          Item
        </th>
        <th
          style={
            stickyHeader
              ? {
                  width: colWidths[2] || "auto",
                  padding: "0px",
                }
              : {}
          }
          onClick={disableSorting?.soldPrice ? null : handlePriceHeaderClick}
        >
          Sold Price{" "}
          {activitySortingState ? (
            activitySortingState.activitySortText ===
            "Sold Price: Low to High" ? (
              <span style={{ marginLeft: "0.2em" }}>
                <FaArrowUp />
              </span>
            ) : activitySortingState.activitySortText ===
              "Sold Price: High to Low" ? (
              <span style={{ marginLeft: "0.2em" }}>
                <FaArrowDown />
              </span>
            ) : (
              <HiArrowsUpDown
                style={{ marginLeft: "0.2em" }}
                fontSize={"1em"}
              />
            )
          ) : null}
        </th>
        <th
          style={
            stickyHeader
              ? { width: colWidths[3] || "auto", padding: "0px" }
              : {}
          }
          className="hiddenOnMobile"
        >
          Quantity
        </th>
        <th
          style={
            stickyHeader
              ? { width: colWidths[4] || "auto", padding: "0px" }
              : {}
          }
          className="hiddenOnMobile"
        >
          From
        </th>
        <th
          style={
            stickyHeader
              ? { width: colWidths[5] || "auto", padding: "0px" }
              : {}
          }
          className="hiddenOnMobile"
        >
          To
        </th>
        <th
          style={{
            ...(stickyHeader
              ? { width: colWidths[6] || "auto", padding: "0px" }
              : {}),
            cursor: "pointer",
          }}
          onClick={handleTimeHeaderClick}
          className="hiddenOnMobile"
        >
          Time{" "}
          {activitySortingState ? (
            activitySortingState.activitySortText ===
            "Transfer Date: Oldest to Newest" ? (
              <span style={{ marginLeft: "0.2em" }}>
                <FaArrowUp />
              </span>
            ) : activitySortingState.activitySortText ===
              "Transfer Date: Newest to Oldest" ? (
              <span style={{ marginLeft: "0.2em" }}>
                <FaArrowDown />
              </span>
            ) : (
              <HiArrowsUpDown
                style={{ marginLeft: "0.2em" }}
                fontSize={"1em"}
              />
            )
          ) : null}
        </th>
        <th
          // title="Verify the hosting status of the NFT image link."
          style={
            stickyHeader
              ? { width: colWidths[7] || "auto", padding: "0px" }
              : {}
          }
          className="hiddenOnMobile "
        >
          <AddInfoModal
            info={"Verify the hosting status of the NFT image link."}
            renderDirection={"bottom-left"}
            styleObj={{ fontWeight: "bold", cursor: "pointer" }}
          >
            Image{" "}
          </AddInfoModal>
        </th>

        <th
          style={
            stickyHeader
              ? { width: colWidths[8] || "auto", padding: "0px" }
              : {}
          }
        ></th>
      </tr>
    </thead>
  );

  return (
    <>
      {stickyHeader && (
        <div className="transfer-history-table-header-table">
          <table
            className="transfer-history-table"
            style={{
              tableLayout: "fixed",
              width: "100%",
            }}
          >
            {headerComponent}
          </table>
        </div>
      )}

      <table className="transfer-history-table">
        {!stickyHeader && headerComponent}

        <tbody>
          {sortedTransferHistory.map((item, index) => {
            const walletDataFrom = walletDataMap[item.from_address] || {};
            const walletDataTo = walletDataMap[item.to_address] || {};

            return (
              <React.Fragment key={index}>
                {isFetchingData ? (
                  <div style={{ width: "92%", overflow: "hidden" }}>
                    <TableLoader />
                  </div>
                ) : (
                  <tr
                    key={index}
                    ref={index === 0 ? rowRef : null}
                    style={{
                      ...(fetchTransferType(item) === "burned" && {
                        backgroundColor: "rgb(255, 0, 0, 0.5)",
                      }),
                    }}
                  >
                    <td className="hiddenOnMobile">
                      <div>
                        {fetchTransferType(item) === "transfer" ? (
                          <BiTransfer fontSize={"1.2em"} />
                        ) : fetchTransferType(item) === "sale" ? (
                          <IoCartOutline fontSize={"1.2em"} />
                        ) : fetchTransferType(item) === "burned" ? (
                          <PiFireBold fontSize={"1.2em"} />
                        ) : (
                          <GrGrow fontSize={"1.2em"} />
                        )}
                      </div>
                      <div
                        className="transfer-history-table-item-id"
                        style={{ color: "green" }}
                      >
                        {fetchTransferType(item)}
                      </div>
                    </td>
                    <td
                      style={{
                        width:
                          "calc(var(--base-font-size-transfer-history-table)* 3)",
                        padding: isMobile ? "0.3rem 0" : "0.5rem 0",
                      }}
                    >
                      <div className="transfer-history-table-item-container">
                        <img
                          className="transfer-history-table-token-image"
                          src={getDisplayImageUrlFromNft(item.nft)}
                          alt={"Token Img"}
                          onError={(event) => {
                            event.target.src = fetchTokenDefaultImageOnError(
                              item.tokenDetails
                            );
                          }}
                        />
                        <div
                          className="transfer-history-table-item-info"
                          style={{ marginRight: "1rem" }}
                        >
                          <span
                            className="transfer-history-table-item-name link-field"
                            style={{
                              fontSize: isMobile && "0.9rem",
                              marginBottom: "0.2rem",
                            }}
                          >
                            <a
                              href={item?.nft?.opensea_url}
                              target="_blank"
                              rel="noreferrer"
                            >
                              {getTokenNameFromNft(item.nft, false, 14)}
                            </a>
                          </span>
                          <span
                            className="transfer-history-table-item-id"
                            style={{ fontSize: isMobile && "0.8rem" }}
                          >
                            {trimMiddleText(collectionName) ||
                              trimMiddleText(
                                getCollectionNameFromNft(item.nft)
                              ) ||
                              "n/a"}
                          </span>
                          {isMobile && (
                            <span
                              onClick={() => handleOtherActivityColumns(index)}
                              style={{ fontSize: "0.7rem" }}
                            >
                              {openRows[index] ? " - Less" : " + More"}
                            </span>
                          )}
                        </div>
                      </div>
                    </td>
                    <td>
                      <div
                        title={fetchSalesPriceTransferEth(item) + " ETH"}
                        className="transfer-history-table-cell"
                        style={{ fontSize: isMobile && "0.7rem" }}
                      >
                        {fetchSalesPriceTransferEthUI(item)} ETH
                      </div>
                      {item?.payment?.ethToUSDRate && (
                        <div
                          title={fetchSalesPriceTransferUSD(item) + " USD"}
                          className="transfer-history-table-price-usd-cell"
                          style={{ fontSize: isMobile && "0.6rem" }}
                        >
                          {fetchSalesPriceTransferUSD(item).toLocaleString(
                            undefined,
                            {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            }
                          ) + " USD"}
                        </div>
                      )}
                    </td>

                    <td className="hiddenOnMobile">
                      <div className="transfer-history-table-item-info">
                        <span style={{ width: "5rem" }}>
                          {fetchQuantityFromNftTransfer(item) > 100
                            ? `> 100`
                            : fetchQuantityFromNftTransfer(item)}
                        </span>
                      </div>
                    </td>
                    <td className="hiddenOnMobile">
                      <div className="transfer-history-table-cell">
                        <ActivityUserCell
                          walletProfileName={getWalletProfileName(
                            walletDataFrom,
                            true
                          )}
                          wallet_addr={fetchFromUserNFTTransfer(item)}
                          showIcons={true}
                        />
                      </div>
                    </td>
                    <td className="hiddenOnMobile">
                      <ActivityUserCell
                        walletProfileName={getWalletProfileName(
                          walletDataTo,
                          true
                        )}
                        wallet_addr={fetchToUserNFTTransfer(item)}
                        showIcons={true}
                      />
                    </td>
                    <td
                      title={fetchTimeOfTransfer(item, true)}
                      className="hiddenOnMobile"
                    >
                      <span className="transfer-history-table-cell">
                        {fetchTimeOfTransfer(item, false)}
                      </span>
                    </td>

                    <AddInfoModal
                      info={
                        originalImageHostedMap[item?.contractTokenId] === "true"
                          ? "Image Source Verified"
                          : originalImageHostedMap[item?.contractTokenId] ===
                            "false"
                          ? "Image Source Missing"
                          : "Verifying Image Source"
                      }
                      renderDirection={"up-left"}
                      styleObj={{ fontWeight: "bold" }}
                    >
                      <td className="hiddenOnMobile">
                        <img
                          src={getImageUrlFromNft(item?.nft)}
                          alt="Nft "
                          style={{
                            position: "fixed",
                            height: "0",
                            width: "0",
                          }}
                          onLoad={() => {
                            updateOriginalImageHosted(
                              item?.nft?.contract + "-" + item?.nft?.identifier,
                              "true"
                            );
                          }}
                          onError={() => {
                            updateOriginalImageHosted(
                              item?.nft?.contract + "-" + item?.nft?.identifier,
                              "false"
                            );
                          }}
                        />
                        <div className="image-hover">
                          {originalImageHostedMap[
                            item?.nft?.contract + "-" + item?.nft?.identifier
                          ] === "true" ? (
                            <span
                              title="Image Source Verified"
                              style={{ cursor: "pointer" }}
                            >
                              <FaCircleCheck size={"1.2em"} color="#2fb484" />
                            </span>
                          ) : originalImageHostedMap[item?.contractTokenId] ===
                            "false" ? (
                            <span
                              title="Image Source Missing"
                              style={{ cursor: "pointer" }}
                            >
                              <FaCircleCheck size={"1.2em"} color="red" />
                            </span>
                          ) : (
                            <span
                              title="Verifying Image Source"
                              style={{ cursor: "pointer" }}
                            >
                              <MdDoNotDisturbOn size={"1.2em"} color="orange" />
                            </span>
                          )}
                        </div>
                      </td>
                    </AddInfoModal>

                    <td className="transfer-history-table-etherscan-link">
                      <OpenOriginalIcon
                        url={"https://etherscan.io/tx/" + item?.transaction}
                        width={isMobile ? "1.2em" : "1.2em"}
                        height={isMobile ? "1.2em" : "1.2em"}
                      />
                    </td>
                  </tr>
                )}

                {isMobile && (
                  <>
                    <td colspan="4">
                      {openRows[index] && (
                        <OtherActivityColumns
                          walletProfileFromName={getWalletProfileName(
                            walletDataFrom,
                            true
                          )}
                          walletProfileToName={getWalletProfileName(
                            walletDataTo,
                            true
                          )}
                          showImageAvailable={showImageAvailable}
                          item={item}
                          originalImageHostedMap={originalImageHostedMap}
                          updateOriginalImageHosted={updateOriginalImageHosted}
                        />
                      )}
                    </td>
                  </>
                )}
              </React.Fragment>
            );
          })}
        </tbody>
      </table>
    </>
  );
}

ActivityTable.propTypes = {};

export default ActivityTable;
