import { initialFolderPageFiltersStateStart } from "../../contexts/FolderPage/FolderFilterSectionContext";

const folderFilterSectionReducer = (state, action) => {
  switch (action.type) {
    case "REPLACE":
      return { ...action.payload };
    case "SET_FOLDER":
      return { ...state, folder: action.payload };
    case "RESET_STATE":
      return { ...initialFolderPageFiltersStateStart };
    case "SET_COLLECTION":
      return { ...state, collection: action.payload };
    case "SET_TOKENS_SORTING":
      return {
        ...state,
        tokensSortText: action.payload.tokensSortText,
        tokensSortKey: action.payload.tokensSortKey,
        tokensSortDirection: action.payload.tokensSortDirection,
      };
    case "SET_TOKENS_SEARCH_QUERY":
      return {
        ...state,
        searchQuery: action.payload.searchQuery,
      };
    case "RESET_TOKENS_SELECTED_ON_SEARCH":
      return {
        ...state,
        searchQueryTokensSelected: {},
      };
    case "ADD_TO_TOKENS_SELECTED_ON_SEARCH":
      const tokenObjToBeAdded = action.payload?.searchQueryTokensSelected;

      const newValuesSearchQueryTokensSelected = {
        ...state.searchQueryTokensSelected,
      };
      newValuesSearchQueryTokensSelected[
        tokenObjToBeAdded?.contractAddress + "-" + tokenObjToBeAdded?.tokenId
      ] = action.payload?.searchQueryTokensSelected;

      return {
        ...state,
        searchQueryTokensSelected: newValuesSearchQueryTokensSelected,
      };
    case "DELETE_FROM_TOKENS_SELECTED_ON_SEARCH":
      const tokenObjToBeDeleted = action.payload?.searchQueryTokensSelected;

      const searchQueryTokensSelectedAfterDeletion = {
        ...state.searchQueryTokensSelected,
      };

      delete searchQueryTokensSelectedAfterDeletion[
        tokenObjToBeDeleted?.contractAddress +
          "-" +
          tokenObjToBeDeleted?.tokenId
      ];

      return {
        ...state,
        searchQueryTokensSelected: searchQueryTokensSelectedAfterDeletion,
      };
    // ... other action handlers
    default:
      return state;
  }
};

export default folderFilterSectionReducer;
