import React, { useContext, useEffect, useRef, useState } from "react";
import ActivityTableContainer from "../CollectionPage/ActivityTableContainer";
import { fetchCollectionTransferHistory } from "../../api/collectionDetailsApi";
import { CollectionFilterSectionContext } from "../../contexts/CollectionPage/FilterSectionContext";
import useIsMobile from "../../customHooks/UseIsMobile";
import FilterSection from "../CollectionPage/FilterSection";
import HomePageActivityFilterSection from "./HomePageActivityFilterSection";
import { useSelector } from "react-redux";
import {
  createUserFilter,
  fetchMJUserDetails,
  updateUserFilter,
} from "../../api/mjUserApi";
import {
  fetchCollectionSlugsForOwnedTokens,
  fetchCollectionsForOwnedTokens,
} from "../../api/walletDetailApi";
import FilterButton from "../CollectionPage/FilterButton";
import FilterButtonMobile from "../CollectionPage/FilterButtonMobile";
import LeftArrowIcon from "../../Icons/CollectionPageIcons/LeftArrowIcon";
import RightArrowIcon from "../../Icons/CollectionPageIcons/RightArrowIcon";

import "../../styles/CommonComponentsStyles/pagination.css";
import {
  fetchNFTTransferHistory,
  fetchTrendingCollections,
} from "../../api/homePageApi";
import RemoveButton from "../../Icons/CollectionPageIcons/RemoveButton";
import RefreshIcon from "../../Icons/RefreshIcon";
import { isNullOrUndefinedOrEmptyObject } from "../../utils/commonUtils/Utils";
import { getNameFromCollectionObj } from "../../utils/commonUtils/CollectionUtils";
import AddInfoModal from "../CommonComponents/AddInfoModal";
import FilterIconOpenMobile from "../../Icons/CollectionPageIcons/FilterIconOpenMobile";
import FilterIconClosedMobile from "../../Icons/CollectionPageIcons/FilterIconClosedMobile";
import PaginationButtonsComponent from "../CommonComponents/PaginationButtonsComponent";

export default function HomePageActivity() {
  const [isFilterOpen, setIsFilterOpen] = useState(true);
  const isMobile = useIsMobile();

  const isFirstRender = useRef(true);

  const userState = useSelector((state) => state.user);
  const [mjUserInfo, setMJUserInfo] = useState({});

  const [selectedCollections, setSelectedCollections] = useState([]);
  const [allCollections, setAllCollections] = useState([]);
  const [fetchingCollections, setFetchingCollections] = useState(false);

  const [activityStatus, setActivityStatus] = useState("All");
  const [priceRange, setPriceRange] = useState({
    minPriceFilter: parseFloat("0.1"),
    maxPriceFilter: null,
    priceCurrencyFilter: "ETH",
  });
  const [allFiltersSelected, setAllFiltersSelected] = useState(false);
  const [
    allTopCollectionsFiltersSelected,
    setAllTopCollectionsFiltersSelected,
  ] = useState(false);

  const [appliedFilterGroup, setAppliedFilterGroup] = useState(null);
  const [appliedFilterGroupUpdates, setAppliedFilterGroupUpdates] =
    useState(null);

  const [activitySortingState, setActivitySortingState] = useState({
    activitySortText: "Transfer Date: Newest to Oldest",
    activitySortKey: "event_timestamp",
    activitySortDirection: -1,
  });

  const [isFetchingData, setIsFetchingData] = useState(true);
  const [startTour, setStartTour] = useState(false);
  const [transferHistory, setTransferHistory] = useState([]);
  const [paginationMetadata, setPaginationMetadata] = useState({
    currentPage: 1,
    limit: 10,
  });

  const [filterDropdownValue, setFilterDropdownValue] = useState(
    userState?.loggedIn
      ? {
          key: "Your_Collections",
          text: "Your Collections",
          value: "Your_Collections",
        }
      : {
          key: "Top_20_Trending_Collections",
          text: "Top 20 Trending Collections",
          value: "Top_20_Trending_Collections",
        }
  );

  useEffect(() => {
    if (userState?.userInfo?.userId) {
      fetchMJUserDetails(userState?.userInfo?.userId)
        .then((mjUserInfo_) => {
          setMJUserInfo(mjUserInfo_);
        })
        .catch((e) => {});
    }
  }, []);

  function setFilterStateFromAppliedFilterGroup() {
    const priceFilter = appliedFilterGroup?.filters?.priceFilter || {};
    setPriceRange({
      minPriceFilter: priceFilter?.minPriceFilter || null,
      maxPriceFilter: priceFilter?.maxPriceFilter || null,
    });

    const activityStatusFilter =
      appliedFilterGroup?.filters?.activityStatusFilter;
    setActivityStatus(activityStatusFilter || "All");

    const selecetdCollectionsFilter =
      appliedFilterGroup?.filters?.contractAddressFilter?.selectedCollections;
    if (selecetdCollectionsFilter) {
      setSelectedCollections(selecetdCollectionsFilter);
    }

    const deselectContractAddressFilter =
      appliedFilterGroup?.filters?.contractAddressFilter
        ?.deselectContractAddress;
    if (!isNullOrUndefinedOrEmptyObject(deselectContractAddressFilter))
      setAllFiltersSelected(deselectContractAddressFilter);
  }

  function compareFilterStateFromAppliedFilterGroup() {
    let change = false;

    const priceFilter = appliedFilterGroup?.filters?.priceFilter || {};
    if (
      (priceFilter?.minPriceFilter || null) !==
      (priceRange?.minPriceFilter || null)
    ) {
      change = true;
    }
    if (
      (priceFilter?.maxPriceFilter || null) !==
      (priceRange?.maxPriceFilter || null)
    ) {
      change = true;
    }

    const activityStatusFilter =
      appliedFilterGroup?.filters?.activityStatusFilter || "All";
    if (activityStatusFilter !== (activityStatus || "All")) {
      change = true;
    }

    const selecetdCollectionsFilter =
      appliedFilterGroup?.filters?.contractAddressFilter?.selectedCollections;
    if (selecetdCollectionsFilter !== selectedCollections) {
      change = true;
    }

    const deselectContractAddressFilter =
      appliedFilterGroup?.filters?.contractAddressFilter
        ?.deselectContractAddress;
    if (
      (deselectContractAddressFilter || null) !== (allFiltersSelected || null)
    ) {
      change = true;
    }

    if (change === true) {
      setAppliedFilterGroupUpdates(true);
    } else {
      setAppliedFilterGroupUpdates(false);
    }
  }

  //whenever filter group is removed, reset filter dropdown value to default to reset filters state
  //whenever filter group is applied, update filter state yourself using filter applied
  useEffect(() => {
    if (appliedFilterGroup === null) {
      setAppliedFilterGroupUpdates(null);
      setActivityStatus("All");
      setPriceRange(null);
      setSelectedCollections([]);
      setAllFiltersSelected(false);
      setFilterDropdownValue(
        userState?.loggedIn
          ? {
              key: "Your_Collections",
              text: "Your Collections",
              value: "Your_Collections",
            }
          : {
              key: "Top_20_Trending_Collections",
              text: "Top 20 Trending Collections",
              value: "Top_20_Trending_Collections",
            }
      );
    } else {
      setFilterStateFromAppliedFilterGroup();
    }
  }, [appliedFilterGroup]);

  //whenever filter drop down value or mjuser value updates, refetch all collections and reset filter state
  useEffect(() => {
    setFetchingCollections(true);

    if (userState?.loggedIn) {
      if (filterDropdownValue.value === "All_Collections") {
        setAllCollections([]);
        setFetchingCollections(false);
      } else if (filterDropdownValue.value === "Top_20_Trending_Collections") {
        fetchTrendingCollections(20, true)
          .then((data) => {
            const topTrendingCollection = data
              .map((item) => item.CollectionDetails)
              .map((collectionObj) => {
                return {
                  contractAddress: collectionObj?.contractAddress,
                  name: getNameFromCollectionObj(collectionObj),
                  totalVolume: collectionObj?.totalVolume,
                };
              });
            setAllCollections(topTrendingCollection || []);
          })
          .catch((error) => {})
          .finally(() => {
            setFetchingCollections(false);
          });
      } else if (filterDropdownValue.value === "Your_Collections") {
        fetchCollectionsForOwnedTokens(
          mjUserInfo?.walletAddressList,
          null,
          null,
          null
        )
          .then((data) => {
            setAllCollections(
              (data.allCollections || []).map((collectionObj) => {
                return {
                  contractAddress: collectionObj?.contractAddress,
                  name: getNameFromCollectionObj(collectionObj),
                  totalVolume: collectionObj?.totalVolume,
                };
              })
            );
          })
          .catch((error) => {})
          .finally(() => {
            setFetchingCollections(false);
          });
      } else if (
        filterDropdownValue.value === "Your_Top_20_Trending_Collections"
      ) {
        fetchCollectionsForOwnedTokens(
          mjUserInfo?.walletAddressList,
          null,
          null,
          20
        )
          .then((data) => {
            setAllCollections(
              (data.allCollections || []).map((collectionObj) => {
                return {
                  contractAddress: collectionObj?.contractAddress,
                  name: getNameFromCollectionObj(collectionObj),
                  totalVolume: collectionObj?.totalVolume,
                };
              })
            );
          })
          .catch((error) => {})
          .finally(() => {
            setFetchingCollections(false);
          });
      } else {
        setFetchingCollections(false);
      }
    } else {
      if (filterDropdownValue.value === "All_Collections") {
        setAllCollections([]);
        setFetchingCollections(false);
      } else if (filterDropdownValue.value === "Top_20_Trending_Collections") {
        fetchTrendingCollections(20, true)
          .then((data) => {
            const topTrendingCollection = data
              .map((item) => item.CollectionDetails)
              .map((collectionObj) => {
                return {
                  contractAddress: collectionObj?.contractAddress,
                  name: getNameFromCollectionObj(collectionObj),
                  totalVolume: collectionObj?.totalVolume,
                };
              });
            setAllCollections(topTrendingCollection || []);
          })
          .catch((error) => {})
          .finally(() => {
            setFetchingCollections(false);
          });
      } else {
        setFetchingCollections(false);
      }
    }
  }, [mjUserInfo, filterDropdownValue]);

  useEffect(() => {
    if (
      allCollections.length > 0 &&
      isFirstRender.current &&
      mjUserInfo &&
      !mjUserInfo?.defaultFilter?.homepageActivity
    ) {
      isFirstRender.current = false;

      setSelectedCollections(allCollections || []);
    }
  }, [allCollections, mjUserInfo]);

  useEffect(() => {
    setFilterDropdownValue(
      userState?.loggedIn
        ? {
            key: "Your_Collections",
            text: "Your Collections",
            value: "Your_Collections",
          }
        : {
            key: "Top_20_Trending_Collections",
            text: "Top 20 Trending Collections",
            value: "Top_20_Trending_Collections",
          }
    );
  }, [userState]);

  useEffect(() => {
    fetchPaginatedTransferHistory();
  }, [
    selectedCollections,
    activitySortingState,
    paginationMetadata,
    priceRange,
    activityStatus,
    allFiltersSelected,
  ]);

  useEffect(() => {
    if (appliedFilterGroup) {
      compareFilterStateFromAppliedFilterGroup();
    }
  }, [selectedCollections, activityStatus, allFiltersSelected, priceRange]);

  const [filtersLoading, setFiltersLoading] = useState(false);
  const [filterGroups, setFilterGroups] = useState([]);
  const [filterName, setFilterName] = useState("");
  const saveFilters = () => {
    setFiltersLoading(true);
    createUserFilter(
      mjUserInfo?._id,
      "homepageActivity",
      appliedFilterGroup ? appliedFilterGroup?.filterName : filterName,
      {
        contractAddressFilter: {
          deselectContractAddress: allFiltersSelected,
          selectedCollections: selectedCollections.map((collectionObj) => {
            return collectionObj;
          }),
        },
        priceFilter: {
          minPriceFilter: priceRange.minPriceFilter,
          maxPriceFilter: priceRange.maxPriceFilter,
        },
        activityStatusFilter: activityStatus,
      }
    )
      .then((newFilter) => {
        setFilterGroups((prev) => {
          return [...prev, newFilter];
        });
        setFilterName("");
      })
      .catch((error) => {})
      .finally(() => {
        setFiltersLoading(false);
      });
  };
  const updateFilters = () => {
    setFiltersLoading(true);
    updateUserFilter(appliedFilterGroup?._id, appliedFilterGroup?.filterName, {
      contractAddressFilter: {
        deselectContractAddress: allFiltersSelected,
        selectedCollections: selectedCollections.map((collectionObj) => {
          return collectionObj;
        }),
      },
      priceFilter: {
        minPriceFilter: priceRange.minPriceFilter,
        maxPriceFilter: priceRange.maxPriceFilter,
      },
      activityStatusFilter: activityStatus,
    })
      .then((newFilter) => {
        setFilterGroups((prev) => {
          let newFilterGroups = [...prev];
          newFilterGroups.filter((group) => group?._id !== newFilter._id);
          newFilterGroups.push(newFilter);
          return newFilterGroups;
        });
      })
      .catch((error) => {})
      .finally(() => {
        setFiltersLoading(false);
      });
  };

  const fetchPaginatedTransferHistory = async (noCache) => {
    setIsFetchingData(true);

    // fetchCollectionTransferHistory(
    //   "0xA3F7250306Dbb856D8d312f93029be73343939aF",
    //   null,
    //   null,
    //   null,
    //   null,
    //   paginationMetadata.currentPage,
    //   paginationMetadata.limit,
    //   activitySortingState?.activitySortKey,
    //   activitySortingState?.activitySortDirection
    // )
    const contractAddresses = selectedCollections.map(
      (collection) => collection.contractAddress
    );

    fetchNFTTransferHistory({
      contractAddresses: contractAddresses,
      walletAddresses: null,
      priceRange: priceRange,
      status: activityStatus,
      page: paginationMetadata.currentPage,
      limit: paginationMetadata.limit,
      sortKey: activitySortingState?.activitySortKey,
      sortDirection: activitySortingState?.activitySortDirection,
      noCache: noCache,
      deselectContractAddress: allFiltersSelected,
    })
      .then((response) => {
        setTransferHistory(response || []);
      })
      .catch((error) => {})
      .finally(() => {
        setIsFetchingData(false);
      });
  };

  const closeFilterSection = () => {
    setIsFilterOpen(false);
  };

  return (
    <div
      className={!isMobile ? "home-page-activity" : "home-page-activity-mobile"}
    >
      <div
        className="home-page-filter-section-row"
        style={isMobile ? { position: "relative" } : {}}
      >
        {isMobile ? (
          <>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "flex-start",
                gap: "3%",
              }}
            >
              <div
                className="items_tab_header_buttons-home-page"
                onClick={() => {
                  setIsFilterOpen(!isFilterOpen);
                }}
              >
                {isFilterOpen ? (
                  <FilterIconOpenMobile />
                ) : (
                  <FilterIconClosedMobile />
                )}
              </div>
              {/* <FilterButtonMobile
                onClick={() => {
                  setIsFilterOpen(!isFilterOpen);
                }}
              /> */}
              <RefreshIcon
                onClick={() => {
                  fetchPaginatedTransferHistory(true);
                }}
              ></RefreshIcon>
            </div>
            <PaginationButtonsComponent
              currentPage={paginationMetadata.currentPage}
              updateCurrentPage={(page) => {
                setPaginationMetadata((prev) => {
                  return {
                    ...prev,
                    currentPage: page,
                  };
                });
              }}
              prevPageClick={() =>
                setPaginationMetadata((prev) => {
                  return {
                    ...prev,
                    currentPage:
                      prev.currentPage === 1 ? 1 : prev.currentPage - 1,
                  };
                })
              }
              nextPageClick={() =>
                setPaginationMetadata((prev) => {
                  return { ...prev, currentPage: prev.currentPage + 1 };
                })
              }
              totalPages={null}
            />
            {isFilterOpen && (
              <div
                style={{ display: "flex", flexDirection: "column", gap: "1em" }}
              >
                <span
                  className="filter-tour-start-button"
                  onClick={() => setStartTour(true)}
                >
                  Start Tour
                </span>
                <HomePageActivityFilterSection
                  fetchingCollections={fetchingCollections}
                  allCollections={allCollections || []}
                  selectedCollections={selectedCollections || []}
                  setSelectedCollections={setSelectedCollections}
                  onFilterClickClose={closeFilterSection}
                  priceRange={priceRange || {}}
                  setPriceRange={setPriceRange}
                  activityStatus={activityStatus}
                  setActivityStatus={setActivityStatus}
                  allFiltersSelected={allFiltersSelected}
                  setAllFiltersSelected={setAllFiltersSelected}
                  allTopCollectionsFiltersSelected={
                    allTopCollectionsFiltersSelected
                  }
                  setAllTopCollectionsFiltersSelected={
                    setAllTopCollectionsFiltersSelected
                  }
                  userLoggedIn={userState?.loggedIn}
                  mjUserInfo={mjUserInfo}
                  appliedFilterGroup={appliedFilterGroup}
                  appliedFilterGroupUpdates={appliedFilterGroupUpdates}
                  setAppliedFilterGroup={setAppliedFilterGroup}
                  filterDropdownValue={filterDropdownValue}
                  setFilterDropdownValue={setFilterDropdownValue}
                  startTour={startTour}
                  setStartTour={setStartTour}
                  filtersLoading={filtersLoading}
                  setFiltersLoading={setFiltersLoading}
                  filterGroups={filterGroups}
                  setFilterGroups={setFilterGroups}
                  filterName={filterName}
                  setFilterName={setFilterName}
                  saveFilters={saveFilters}
                  updateFilters={updateFilters}
                  setFilterStateFromAppliedFilterGroup={
                    setFilterStateFromAppliedFilterGroup
                  }
                  isFilterOpen={isFilterOpen}
                  setIsFilterOpen={setIsFilterOpen}
                />
              </div>
            )}
          </>
        ) : (
          <>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "flex-start",
                gap: "3%",
                cursor: "pointer",
              }}
            >
              <FilterButton
                onClick={() => {
                  setIsFilterOpen(!isFilterOpen);
                }}
              />
              <RefreshIcon
                onClick={() => {
                  fetchPaginatedTransferHistory(true);
                }}
              ></RefreshIcon>
            </div>
            <PaginationButtonsComponent
              currentPage={paginationMetadata.currentPage}
              updateCurrentPage={(page) => {
                setPaginationMetadata((prev) => {
                  return {
                    ...prev,
                    currentPage: page,
                  };
                });
              }}
              prevPageClick={() =>
                setPaginationMetadata((prev) => {
                  return {
                    ...prev,
                    currentPage:
                      prev.currentPage === 1 ? 1 : prev.currentPage - 1,
                  };
                })
              }
              nextPageClick={() =>
                setPaginationMetadata((prev) => {
                  return { ...prev, currentPage: prev.currentPage + 1 };
                })
              }
              totalPages={null}
            />
          </>
        )}
      </div>

      <div className="home-page-all-selected-filters">
        {userState?.loggedIn &&
          (selectedCollections?.length > 0 ||
            priceRange?.minPriceFilter ||
            priceRange?.maxPriceFilter ||
            (activityStatus && activityStatus !== "All")) && (
            <div
              style={{
                alignSelf: "flex-end",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: "0.2em",
                marginLeft: "auto",
              }}
            >
              {appliedFilterGroup ? (
                appliedFilterGroupUpdates && (
                  <>
                    <AddInfoModal
                      info={"Reset Applied Filters to Original state"}
                      renderDirection={"up-left"}
                      styleObj={{ fontWeight: "bold", cursor: "pointer" }}
                    >
                      <RefreshIcon
                        onClick={() => {
                          setFilterStateFromAppliedFilterGroup();
                        }}
                      ></RefreshIcon>
                    </AddInfoModal>
                    <span
                      className="filter-section-custom-filters-save"
                      onClick={updateFilters}
                    >
                      {"Update Filter"}
                    </span>
                  </>
                )
              ) : (
                <>
                  <input
                    type="text"
                    placeholder="Enter filter name"
                    className="filter-section-search-bar-home-page"
                    value={filterName}
                    onChange={(e) => setFilterName(e.target.value)}
                    required={true}
                  />

                  <span
                    className="filter-section-custom-filters-save"
                    onClick={saveFilters}
                  >
                    {"Save"}
                  </span>
                </>
              )}
            </div>
          )}
      </div>

      <div className="home-page-activity-container">
        {!isMobile && isFilterOpen && (
          <div style={{ display: "flex", flexDirection: "column", gap: "1em" }}>
            <span
              className="filter-tour-start-button"
              onClick={() => setStartTour(true)}
            >
              Start Tour
            </span>
            <HomePageActivityFilterSection
              fetchingCollections={fetchingCollections}
              allCollections={allCollections || []}
              selectedCollections={selectedCollections || []}
              setSelectedCollections={setSelectedCollections}
              onFilterClickClose={closeFilterSection}
              priceRange={priceRange || {}}
              setPriceRange={setPriceRange}
              activityStatus={activityStatus}
              setActivityStatus={setActivityStatus}
              allFiltersSelected={allFiltersSelected}
              setAllFiltersSelected={setAllFiltersSelected}
              allTopCollectionsFiltersSelected={
                allTopCollectionsFiltersSelected
              }
              setAllTopCollectionsFiltersSelected={
                setAllTopCollectionsFiltersSelected
              }
              userLoggedIn={userState?.loggedIn}
              mjUserInfo={mjUserInfo}
              appliedFilterGroup={appliedFilterGroup}
              appliedFilterGroupUpdates={appliedFilterGroupUpdates}
              setAppliedFilterGroup={setAppliedFilterGroup}
              filterDropdownValue={filterDropdownValue}
              setFilterDropdownValue={setFilterDropdownValue}
              startTour={startTour}
              setStartTour={setStartTour}
              filtersLoading={filtersLoading}
              setFiltersLoading={setFiltersLoading}
              filterGroups={filterGroups}
              setFilterGroups={setFilterGroups}
              filterName={filterName}
              setFilterName={setFilterName}
              saveFilters={saveFilters}
              updateFilters={updateFilters}
              setFilterStateFromAppliedFilterGroup={
                setFilterStateFromAppliedFilterGroup
              }
              isFilterOpen={isFilterOpen}
              setIsFilterOpen={setIsFilterOpen}
            />
          </div>
        )}

        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <ActivityTableContainer
            transferHistory={transferHistory || []}
            showFilterSection={isFilterOpen}
            isFetchingData={isFetchingData}
            activitySortingState={activitySortingState}
            setActivitySortingState={setActivitySortingState}
            stickyHeader={true}
            // disableSorting={{ soldPrice: true }}
          />
        </div>
      </div>
    </div>
  );
}
