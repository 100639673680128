import React from "react";

const EnlargeIcon = () => {
  return (
    <div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        viewBox="0 0 20 20"
        fill="none"
      >
        <path
          d="M1 19L7.49977 12.5002"
          stroke="#2FB484"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M12.5 7.49977L18.9998 1"
          stroke="#2FB484"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M12.998 1H18.9978V6.99979"
          stroke="#2FB484"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M1 12.9998V18.9995H6.99978"
          stroke="#2FB484"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </div>
  );
};

export default EnlargeIcon;
