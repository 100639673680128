import React, { useEffect, useRef, useState } from "react";
import useIsMobile from "../../customHooks/UseIsMobile";
import RefreshIcon from "../../Icons/RefreshIcon";
import { isNullOrUndefined } from "../commonUtils/Utils";
import RemoveButton from "../../Icons/CollectionPageIcons/RemoveButton";

function TokenMetadataModal({
  closeModal,
  title,
  modalData,
  refreshMetadata,
  modalDataLoading,
  validData,
  openModal,
}) {
  const [decodedModalData, setDecodedModalData] = useState("");

  useEffect(() => {
    let _decodedModalData = JSON.stringify(modalData, null, 1);
    setDecodedModalData(_decodedModalData);
  }, [modalData]);

  const modalRef = useRef(null);

  useEffect(() => {
    const handleOutsideTap = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        closeModal();
      }
    };

    if (openModal) {
      document.addEventListener("mousedown", handleOutsideTap);
    }

    return () => {
      document.removeEventListener("mousedown", handleOutsideTap);
    };
  }, [openModal]);

  return (
    <div className="modal-overlay">
      <div className="modal-component" ref={modalRef}>
        <div className="modal-header">
          <div className="modal-header-title">{title}</div>

          <div className="modal-header-icons">
            <div className="close-button" onClick={refreshMetadata}>
              {title === "Metadata" && (
                <RefreshIcon
                  className="close-button"
                  tooltipTitle="Refresh Metadata"
                  color="#183B56"
                />
              )}
            </div>
            <div className="close-button" onClick={closeModal}>
              <RemoveButton color={"black"} />
            </div>
          </div>
        </div>
        {validData ? (
          <div className="item-metadata-data-container-box">
            <pre className="item-metadata-data">
              {modalDataLoading ? (
                "...Loading"
              ) : title === "Token URI" ? (
                <a
                  href={decodedModalData.slice(1, -1)}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {decodedModalData}
                </a>
              ) : (
                decodedModalData
              )}
            </pre>
          </div>
        ) : (
          <div
            style={{
              color: "red",
              whiteSpace: "nowrap",
              display: "flex",
              flexDirection: "column",
              gap: "2vw",
            }}
          >
            {title === "Metadata" ? (
              "Metadata Missing / No Longer Hosted"
            ) : (
              <>
                <div>{decodedModalData}</div>
                <div>URI No longer Hosted</div>
              </>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export default TokenMetadataModal;
