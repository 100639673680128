const { trimMiddleText } = require("./Utils");

function getWalletProfileName(walletData, returnWAIfNull, realValue) {
  // if (!walletData && !walletData?.mjUserDetail) return "No Name";
  if (walletData?.openseaDetails?.username) {
    let username = walletData.openseaDetails?.username;
    return realValue ? username : trimMiddleText(username);
  }

  if (walletData?.walletENSName) {
    let username = walletData.walletENSName;
    return realValue ? username : trimMiddleText(username);
  }

  if (walletData?.walletAddress) {
    return realValue
      ? walletData?.walletAddress
      : trimMiddleText(walletData?.walletAddress);
  }

  return returnWAIfNull
    ? walletData?.walletAddress
    : realValue
    ? null
    : "No Name";
}

function getWalletProfileImage(walletObj) {
  if (walletObj?.mjUserInfo?.profileImage?.s3Path) {
    return `https://d34arbzl1t6d7.cloudfront.net/${walletObj?.mjUserInfo?.profileImage?.s3Path}`;
  } else {
    if (walletObj?.openseaDetails?.profile_image_url)
      return walletObj?.openseaDetails?.profile_image_url;
    return "https://montevista.greatheartsamerica.org/wp-content/uploads/sites/2/2016/11/default-placeholder.png";
  }
}

function getMJUserProfileVideo(mjUserInfo) {
  if (mjUserInfo?.profileVideoFile?.s3Path) {
    return `https://d2enhgs887be3v.cloudfront.net/${mjUserInfo?.profileVideoFile?.s3Path}`;
  } else {
    return "https://montevista.greatheartsamerica.org/wp-content/uploads/sites/2/2016/11/default-placeholder.png";
  }
}

function getMJUserProfileImage(mjUserInfo) {
  if (mjUserInfo?.profileImage?.s3Path) {
    return `https://d34arbzl1t6d7.cloudfront.net/${mjUserInfo?.profileImage?.s3Path}`;
  } else {
    return "https://montevista.greatheartsamerica.org/wp-content/uploads/sites/2/2016/11/default-placeholder.png";
  }
}

function getWalletBio(walletInfo) {
  return walletInfo?.openseaDetails;
}

function getMJUserBio(mjUserInfo) {
  if (mjUserInfo?.bio) {
    return { mjUserBio: mjUserInfo?.bio };
  } else {
    (mjUserInfo?.walletAddressInfos || []).forEach((walletAddressInfo) => {
      if (walletAddressInfo?.openseaDetails?.bio) {
        return {
          mjUserBio: mjUserInfo?.bio,
          openseaBio: walletAddressInfo?.openseaDetails?.bio,
        };
      }
    });

    return "Bio missing";
  }
}

module.exports = {
  getWalletProfileName,
  getWalletProfileImage,
  getMJUserProfileVideo,
  getMJUserProfileImage,
  getWalletBio,
  getMJUserBio,
};
