// utility component for Description Tabs on Collection page

import React from "react";
import InfoComponent from "../CommonComponents/InfoComponent";

const DescriptionTab = ({ label, active, onClick, showInfoIcon }) => {
  return (
    <div
      className={`description-tab ${active ? "active" : ""}`}
      style={{
        display: "flex",
        flexDirection: "row",
        gap: "0.5em",
        justifyContent: "flex-start",
        alignItems: "flex-start",
        lineHeight: "normal",
      }}
      onClick={onClick}
    >
      {label}
      {showInfoIcon && (
        <InfoComponent
          iconWidth={"1em"}
          iconHeight={"1em"}
          // info="This is a test modal"
          info="Description of the collection generated by MetaJungle."
        />
      )}
    </div>
  );
};

export default DescriptionTab;
