import { isNullOrUndefinedOrEmptyString, convertIpfsToUrl } from "./Utils";
import ImgSrcMissingImg from "../../assets/images/imageSRCmissing.png";
import {
  getNameFromCollectionObj,
  specialContractAddresses,
} from "./CollectionUtils";
import { compressedImageCFUrl } from "../../configs/urls";

export function fetchOriginalImageURLFromTokenObj(token, fullSize = false) {
  let url = token?.openseaDetails?.image_url;
  if (!url) return "IMAGE_URL_NOT_FOUND";
  if (typeof url === "string" && url.startsWith("ipfs://"))
    url = convertIpfsToUrl(url);
  return url;
}

export function fetchDescriptionFromTokenObj(token) {
  return (
    token?.openseaDetails?.description ||
    "No description provided by the creator yet."
  );
}

export function fetchImageURLFromTokenObj(token, fullSize = false) {
  if (!fullSize && token?.compressedS3Image?.url) {
    const cfImageUrl = compressedImageCFUrl + token?.compressedS3Image?.url;
    return cfImageUrl;
  }
  const url = token?.openseaDetails?.image_url;
  if (!url) return ImgSrcMissingImg;
  return url;
}

export function fetchTokenDefaultImageOnError(token, fullSize = false) {
  if (token?.compressedS3Image?.error?.exists === false && !fullSize) {
    if (token?.compressedS3Image?.url) {
      return getDefaultImageUrl();
    }
  } else if (token?.s3Image?.error?.exists === false) {
    if (token?.s3Image?.url) {
      return getDefaultImageUrl();
    }
  }

  return ImgSrcMissingImg;
}

export function fetchCollectionSlugFromTokenObj(token, slice = false) {
  let str = !isNullOrUndefinedOrEmptyString(token?.openseaDetails?.collection)
    ? token?.openseaDetails?.collection
    : "No Name";
  if (slice && str.length > 25) {
    str = `${str.slice(0, 10)}...` + `${str.slice(-15)}`;
  }
  return str;
}

export function fetchCollectionNameFromTokenObj(token, slice = false) {
  let str = getNameFromCollectionObj(token?.collectionObj) || "No Name";
  if (slice && str.length > 30) str = `${str.slice(0, 30)}...`;
  return str;
}

export function fetchTokenNameFromTokenObj(token, trimSize = 25) {
  // if (!isNullOrUndefinedOrEmptyString(token?.openseaDetails?.name)) {
  //   if (token?.openseaDetails?.name.length > trimSize + 5) {
  //     return `${token?.openseaDetails?.name.slice(0, trimSize)}...`;
  //   } else {
  //     return token?.openseaDetails?.name;
  //   }
  // } else if (
  //   !isNullOrUndefinedOrEmptyString(token?.openseaDetails?.identifier)
  // ) {
  //   if (token?.openseaDetails?.identifier.length > trimSize + 5) {
  //     return `${token?.openseaDetails?.identifier.slice(0, trimSize)}...`;
  //   } else {
  //     return token?.openseaDetails?.identifier;
  //   }
  // } else {
  //   return "No Name";
  // }
  return !isNullOrUndefinedOrEmptyString(token?.openseaDetails?.name)
    ? token?.openseaDetails?.name.length > trimSize + 5
      ? `${token?.openseaDetails?.name.slice(0, trimSize)}...`
      : token?.openseaDetails?.name
    : !isNullOrUndefinedOrEmptyString(token?.openseaDetails?.identifier)
    ? token?.openseaDetails?.identifier.length > trimSize + 5
      ? `${token?.openseaDetails?.identifier.slice(0, trimSize)}...`
      : token?.openseaDetails?.identifier
    : "No Name";
}

export function getDefaultImageUrl() {
  return "https://www.shutterstock.com/image-vector/dark-blue-green-vector-blur-600nw-1692448585.jpg";
}

export function fetchTraitsFromTokenObj(token) {
  const tokenTraits = token?.openseaDetails?.traits || [];
  return tokenTraits;
}

export const generateTraitsFromTokenArray = (tokenDetailsArray) => {
  const traitOptions = {};

  if (
    isNullOrUndefinedOrEmptyString(tokenDetailsArray) ||
    tokenDetailsArray.length === 0
  ) {
    return { error: "No data available" };
  }

  tokenDetailsArray.forEach((token) => {
    const tokenTraits = fetchTraitsFromTokenObj(token);

    if (isNullOrUndefinedOrEmptyString(tokenTraits)) {
      return;
    }

    tokenTraits.forEach((attribute) => {
      if (!traitOptions[attribute.trait_type]) {
        traitOptions[attribute.trait_type] = {};
      }
      if (!traitOptions[attribute.trait_type][attribute.value]) {
        traitOptions[attribute.trait_type][attribute.value] = 1;
      } else {
        traitOptions[attribute.trait_type][attribute.value]++;
      }
    });
  });

  // Sort the trait types based on the number of values and then alphabetically
  const sortedTraitTypes = Object.keys(traitOptions).sort((a, b) => {
    const countA = Object.keys(traitOptions[a]).length;
    const countB = Object.keys(traitOptions[b]).length;

    if (countA === countB) {
      // If the counts are the same, sort alphabetically
      return a.localeCompare(b);
    }

    return countB - countA;
  });

  const sortedTraitOptions = {};
  for (const traitType of sortedTraitTypes) {
    sortedTraitOptions[traitType] = Object.entries(
      traitOptions[traitType]
    ).sort((a, b) => {
      if (b[1] === a[1]) {
        return a[0].localeCompare(b[0]);
      }
      return b[1] - a[1];
    });
  }

  return sortedTraitOptions;
};

export function checkTokenBurned(token) {
  if (token.tokenType === "ERC721") {
    const owner = token.tokenOwner;
    if (owner && owner === "0x0000000000000000000000000000000000000000")
      return true;
    return false;
  } else if (token.tokenType === "ERC1155") {
    const owners = token.ERC1155tokenOwners;
    if (
      owners &&
      Array.isArray(owners) &&
      owners.length > 0 &&
      owners.every(
        (owner) => owner === "0x0000000000000000000000000000000000000000"
      )
    )
      return true;
    return false;
  }
}

export function getTokenRank(tokenObj) {
  if (
    tokenObj?.contractAddress?.toLowerCase() ===
    specialContractAddresses.OSSharedFront.toLowerCase()
  )
    return tokenObj?.rarityRankSlugLevel;
  else if (
    tokenObj?.contractAddress?.toLowerCase() ===
    specialContractAddresses.Foundation.toLowerCase()
  )
    return tokenObj?.rarityRankFoundationLevel;
  else return tokenObj?.rarityRank;
}

export function getTokenRankUI(tokenObj) {
  let rank = getTokenRank(tokenObj);

  if (rank === -1) {
    return "UnRanked (No Traits)";
  } else {
    return rank;
  }
}

export function getTokenCreator(tokenObj) {
  if (tokenObj?.openseaDetails?.creator) return tokenObj.openseaDetails.creator;

  return null;
}
