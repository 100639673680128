import React from "react";

const CopyLinkIcon = ({ width, height }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width ? width : "1em"}
      height={height ? height : "1em"}
      viewBox="0 0 12 14"
      fill="none"
    >
      <path
        d="M8.33366 0.583252H1.33366C0.691992 0.583252 0.166992 1.10825 0.166992 1.74992V9.91659H1.33366V1.74992H8.33366V0.583252ZM10.0837 2.91659H3.66699C3.02533 2.91659 2.50033 3.44159 2.50033 4.08325V12.2499C2.50033 12.8916 3.02533 13.4166 3.66699 13.4166H10.0837C10.7253 13.4166 11.2503 12.8916 11.2503 12.2499V4.08325C11.2503 3.44159 10.7253 2.91659 10.0837 2.91659ZM10.0837 12.2499H3.66699V4.08325H10.0837V12.2499Z"
        fill="black"
      />
    </svg>
  );
};

export default CopyLinkIcon;
