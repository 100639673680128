import React from "react";

const InfoIcon = ({ width, height }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width ? width : "1.2em"}
      height={height ? height : "1.2em"}
      viewBox="0 0 21 21"
      fill="none"
    >
      <path
        d="M10.5029 1.75073C8.77235 1.75073 7.08062 2.26391 5.64169 3.22537C4.20276 4.18684 3.08125 5.5534 2.41899 7.15225C1.75672 8.75111 1.58344 10.5104 1.92106 12.2078C2.25868 13.9051 3.09204 15.4642 4.31575 16.6879C5.53946 17.9116 7.09856 18.745 8.79589 19.0826C10.4932 19.4202 12.2526 19.2469 13.8514 18.5847C15.4503 17.9224 16.8168 16.8009 17.7783 15.362C18.7398 13.923 19.2529 12.2313 19.2529 10.5007C19.2529 9.35167 19.0266 8.21385 18.5869 7.15225C18.1472 6.09065 17.5026 5.12606 16.6901 4.31355C15.8776 3.50104 14.913 2.85652 13.8514 2.41679C12.7898 1.97706 11.652 1.75073 10.5029 1.75073ZM10.5029 17.5007C9.11846 17.5007 7.76509 17.0902 6.61394 16.321C5.4628 15.5519 4.56559 14.4586 4.03578 13.1795C3.50596 11.9004 3.36734 10.493 3.63744 9.1351C3.90753 7.77723 4.57422 6.52995 5.55319 5.55099C6.53215 4.57202 7.77943 3.90533 9.1373 3.63524C10.4952 3.36514 11.9026 3.50376 13.1817 4.03358C14.4608 4.56339 15.554 5.4606 16.3232 6.61174C17.0924 7.76289 17.5029 9.11626 17.5029 10.5007C17.5029 12.3572 16.7654 14.1377 15.4527 15.4505C14.1399 16.7632 12.3594 17.5007 10.5029 17.5007Z"
        fill="#183B56"
      />
      <path
        d="M10.5029 7.87524C10.9862 7.87524 11.3779 7.48349 11.3779 7.00024C11.3779 6.517 10.9862 6.12524 10.5029 6.12524C10.0197 6.12524 9.62793 6.517 9.62793 7.00024C9.62793 7.48349 10.0197 7.87524 10.5029 7.87524Z"
        fill="#183B56"
      />
      <path
        d="M10.5029 8.75024C10.2709 8.75024 10.0483 8.84243 9.88421 9.00653C9.72012 9.17062 9.62793 9.39318 9.62793 9.62524V14.0002C9.62793 14.2323 9.72012 14.4549 9.88421 14.619C10.0483 14.7831 10.2709 14.8752 10.5029 14.8752C10.735 14.8752 10.9576 14.7831 11.1216 14.619C11.2857 14.4549 11.3779 14.2323 11.3779 14.0002V9.62524C11.3779 9.39318 11.2857 9.17062 11.1216 9.00653C10.9576 8.84243 10.735 8.75024 10.5029 8.75024Z"
        fill="#183B56"
      />
    </svg>
  );
};

export default InfoIcon;
