import React, { useEffect, useState } from "react";
import "../../../styles/itemPageStyles/ItemPage.css";
import EditIcon from "../../../Icons/EditIcon";
import UserModal from "../../../utils/ItemPageUtils/UserModal";
import ShareIcon from "../../../Icons/ShareIcon";
import OpenSeaImage from "../../../assets/images/OpenSeaImage.png";
import useIsMobile from "../../../customHooks/UseIsMobile.js";
import InfoIcon from "../../../Icons/InfoIcon";
import RarityInfoModal from "../../CollectionPage/RarityInfoModal";
import TokenInfoToggleModal from "./TokenInfoToggleModal";
import { Link } from "react-router-dom";
import {
  copyToClipboard,
  trimMiddleText,
} from "../../../utils/commonUtils/Utils";
import {
  fetchTokenNameFromTokenObj,
  fetchTraitsFromTokenObj,
  getTokenRank,
  getTokenRankUI,
} from "../../../utils/commonUtils/TokenUtils";
import AlertPopup from "../../../utils/commonUtils/AlertPopup";
import {
  getNameFromCollectionObj,
  getSlugFromCollectionObj,
  getURLForCollection,
  specialContractAddresses,
} from "../../../utils/commonUtils/CollectionUtils";
import LoadingText from "../../../utils/commonUtils/LoadingText.js";
import { fetchWalletDetails } from "../../../api/walletDetailApi";
import { getWalletProfileName } from "../../../utils/commonUtils/WalletDetailsUtils";
import ERC1155OwnersModal from "./ERC1155OwnersModal.js";
import SalesModalIcon from "../../../Icons/CreatorPageIcons/SalesModalIcon.js";
import AddInfoModal from "../../CommonComponents/AddInfoModal";

const TokenInfo = ({
  itemData,
  collectionData,
  burnedDate,
  isFetchingData,
}) => {
  const [isOwnedByHovered, setIsOwnedByHovered] = useState(false);
  const [isCreatedByHovered, setIsCreatedByHovered] = useState(false);
  const [isERC1155OwnedByHovered, setIsERC1155OwnedByHovered] = useState(false);
  const [isInfoModalOpen, setInfoModalOpen] = useState(false);
  const [tabToggles, setTabToggles] = useState([true, true, true, true]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [alertPopupMessage, setAlertPopupMessage] = useState(false);
  const [tokenOwner, setTokenOwner] = useState("");
  const [ERC1155TokenOwners, setERC1155TokenOwners] = useState([]);
  const [toggleOwners, setToggleOwners] = useState(false);
  const [creatorWalletData, setCreatorWalletData] = useState(null);
  const [ownerWalletData, setOwnerWalletData] = useState(null);
  const [ERC1155OwnerWalletData, setERC1155OwnerWalletData] = useState(null);
  const [ERC1155OwnerData, setERC1155OwnerData] = useState([]);
  const [collectionName, setCollectionName] = useState("");

  async function getAndSetCollectionName(collectionData) {
    let collectionName_ = "";
    if (
      collectionData?.contractAddress?.toLowerCase() ===
        specialContractAddresses.Foundation.toLowerCase() &&
      collectionData?.collectionCreator
    ) {
      let creator = await fetchWalletDetails(collectionData?.collectionCreator);
      collectionName_ = "Foundation" + " By " + getWalletProfileName(creator);
    } else if (
      collectionData?.contractAddress?.toLowerCase() ===
        specialContractAddresses.OSSharedFront.toLowerCase() &&
      collectionData?.collectionSlug
    ) {
      collectionName_ =
        getNameFromCollectionObj(collectionData) +
        " - " +
        getSlugFromCollectionObj(collectionData);
      setCollectionName(collectionName_);
    } else {
      collectionName_ = getNameFromCollectionObj(collectionData);
    }

    setCollectionName(collectionName_);
  }

  async function formatDate(isoString) {
    const date = new Date(isoString);
    const month = date.getMonth() + 1; // Months are zero-based
    const day = date.getDate();
    const year = date.getFullYear();
    return `${month}/${day}/${year}`;
  }

  useEffect(() => {
    if (itemData?.tokenType === "ERC721") {
      setTokenOwner(itemData?.tokenOwner);
    } else {
      setERC1155TokenOwners(itemData?.ERC1155tokenOwners);
    }
  }, [itemData]);

  useEffect(() => {
    getAndSetCollectionName(collectionData);
  }, [collectionData]);

  const isMobile = useIsMobile();

  const toggleInfoModal = () => {
    setInfoModalOpen(!isInfoModalOpen);
  };

  const toggleTab = (index) => {
    const newToggles = [...tabToggles];
    newToggles[index] = !newToggles[index];
    setTabToggles(newToggles);
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const copyLinkHandler = () => {
    copyToClipboard(window.location.href);
    setAlertPopupMessage(true);
    setTimeout(() => {
      setAlertPopupMessage(false);
    }, 1500);
  };

  useEffect(() => {
    if (!itemData) return;
    fetchWalletDetails(itemData?.openseaDetails?.creator, true, true)
      .then((walletDetail) => {
        setCreatorWalletData(walletDetail);
      })
      .catch((error) => {
        setCreatorWalletData({});
      });

    if (itemData?.tokenType === "ERC721") {
      fetchWalletDetails(itemData?.tokenOwner, true, true)
        .then((walletDetail) => {
          setOwnerWalletData(walletDetail);
        })
        .catch((error) => {
          setOwnerWalletData({});
        });
    }

    if (itemData?.tokenType === "ERC1155") {
      const ERC1155OwnerAddresses = itemData?.openseaDetails?.owners?.map(
        (owner) => owner?.address
      );
      fetchWalletDetails(ERC1155OwnerAddresses, true, true)
        .then((walletDetail) => {
          setERC1155OwnerWalletData(walletDetail);
        })
        .catch((error) => {
          setERC1155OwnerWalletData({});
        });
    }
  }, [itemData?.openseaDetails?.creator, tokenOwner, ERC1155OwnerData]);

  return (
    <div
      className={
        isMobile ? "token-info-container-mobile" : "token-info-container"
      }
    >
      <div className="token-info-icons-row token-info-row">
        {tabToggles[0] && (
          <Link
            className="collection-name link-field"
            style={{ fontSize: isMobile && "1.1rem" }}
            to={getURLForCollection(collectionData)}
          >
            {isFetchingData ? (
              <LoadingText width="40%" height="2vw" />
            ) : collectionName && collectionName.length > 0 ? (
              collectionName
            ) : (
              "Name Missing"
            )}
          </Link>
        )}
        <div className="token-info-icons">
          <div className="token-info-openseaicon">
            <Link
              to={itemData?.openseaDetails?.opensea_url}
              target="_blank"
              title="OpenSea"
            >
              <img src={OpenSeaImage} alt="Marketplace Logo" />
            </Link>
          </div>
          <>
            {isMobile ? (
              <></>
            ) : (
              <AddInfoModal
                info={"Edit"}
                renderDirection={"up-left"}
                styleObj={{ fontWeight: "bold", cursor: "pointer" }}
              >
                <div onClick={() => openModal()}>
                  <EditIcon />{" "}
                </div>
              </AddInfoModal>
            )}
          </>
          <AddInfoModal
            info={"Copy Link to Share"}
            renderDirection={"up-left"}
            styleObj={{ fontWeight: "bold", cursor: "pointer" }}
          >
            <div className="token-info-share-icon" onClick={copyLinkHandler}>
              <ShareIcon />
            </div>
          </AddInfoModal>
        </div>
      </div>

      {tabToggles[1] && (
        <div
          className="token-info-token-name token-info-row"
          title={itemData?.openseaDetails?.name}
        >
          {itemData && Object.keys(itemData)?.length > 0 ? (
            fetchTokenNameFromTokenObj(itemData)
          ) : (
            <LoadingText width="40%" height="2vw" />
          )}
        </div>
      )}

      {tabToggles[2] &&
        getTokenRankUI(itemData) &&
        getTokenRankUI(itemData) !== "UnRanked (No Traits)" && (
          <div className="token-info-rarity-row token-info-row">
            <div
              className="rarity-ranking-heading"
              style={{ fontSize: isMobile && "1rem" }}
            >
              Rarity Ranking
              <span
                className="token-info-token-ranking"
                style={{ fontSize: "inherit" }}
              >
                {getTokenRankUI(itemData) || "Un Ranked"}
              </span>
              {collectionData?.tokensSize && (
                <span
                  className="token-info-total-tokens"
                  style={{ fontSize: "inherit" }}
                >
                  / {collectionData?.tokensSize || "NA"}
                </span>
              )}
            </div>
            <div>
              {getTokenRankUI(itemData) !== "UnRanked (No Traits)" && (
                <div
                  onMouseEnter={() => toggleInfoModal(true)}
                  onMouseLeave={() => toggleInfoModal(false)}
                  className="info-icon-container"
                >
                  <InfoIcon color="#183B56"></InfoIcon>
                </div>
              )}

              {isInfoModalOpen && getTokenRank(itemData) && (
                <RarityInfoModal
                  itemData={itemData}
                  collectionData={collectionData}
                  traits={fetchTraitsFromTokenObj(itemData) || []}
                  aggregated_traits={collectionData?.aggregated_traits}
                />
              )}
            </div>
          </div>
        )}

      {tabToggles[3] && (
        <div
          className={
            isMobile
              ? "token-info-owner-creator-container-mobile token-info-row"
              : "token-info-owner-creator-container token-info-row"
          }
          style={{ marginTop: "1rem" }}
        >
          {
            <div
              className="owner-creator-section"
              style={{ fontSize: isMobile && "1rem" }}
            >
              <div className="owner-creator-row">
                {ERC1155TokenOwners && ERC1155TokenOwners?.length > 1
                  ? "Total Owners: "
                  : tokenOwner !==
                      "0x0000000000000000000000000000000000000000" &&
                    "Owned by"}
              </div>
              {ERC1155TokenOwners && ERC1155TokenOwners?.length > 0 ? (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {itemData?.tokenType === "ERC1155" ? (
                    <span
                      onClick={() => setToggleOwners(!toggleOwners)}
                      className=" link-field"
                    >
                      {ERC1155TokenOwners?.length}
                    </span>
                  ) : (
                    <Link
                      className="owner-name link-field"
                      onMouseEnter={() => setIsERC1155OwnedByHovered(true)}
                      onMouseLeave={() => setIsERC1155OwnedByHovered(false)}
                      to={
                        !isMobile && `/wallet_report/${ERC1155TokenOwners[0]}`
                      }
                    >
                      {getWalletProfileName(ERC1155OwnerWalletData) !==
                        "No Name" &&
                      Object.keys(ERC1155OwnerWalletData).length > 0
                        ? getWalletProfileName(ERC1155OwnerWalletData)
                        : trimMiddleText(ERC1155TokenOwners[0])}

                      {isERC1155OwnedByHovered && (
                        <UserModal
                          isCreator={false}
                          walletAddress={ERC1155TokenOwners[0]}
                        />
                      )}
                    </Link>
                  )}

                  {itemData?.tokenType === "ERC1155" && (
                    <span
                      style={{
                        fontSize: "1em",
                        marginLeft: "0.5em",
                        cursor: "pointer",
                      }}
                      onClick={() => setToggleOwners(!toggleOwners)}
                    >
                      <span>
                        <SalesModalIcon />
                      </span>
                    </span>
                  )}
                </div>
              ) : tokenOwner ===
                "0x0000000000000000000000000000000000000000" ? (
                <div
                  style={{
                    color: "white",
                    fontSize: "1em",
                    fontWeight: "600",
                    border: "1px solid red",
                    backgroundColor: "red",
                    padding: "0.3em",
                    display: "flex",
                    lineHeight: "normal",
                    justifyContent: "center",
                    alugnItems: "center",
                    borderRadius: "0.5em",
                    flexDirection: "column",
                    alignItems: "center",
                    gap: "0.2em",
                  }}
                >
                  Burned (No Longer Available)
                  {
                    <span
                      style={{
                        fontSize: "0.9em",
                        color: "white",
                        fontWeight: "700",
                      }}
                    >
                      {formatDate([burnedDate[0]?.createdAt])}
                    </span>
                  }
                </div>
              ) : (
                <div
                  className="owner-creator-row link-field"
                  onMouseEnter={() => setIsOwnedByHovered(true)}
                  onMouseLeave={() => setIsOwnedByHovered(false)}
                >
                  <Link
                    className="owner-name link-field"
                    // to={!isMobile && `/wallet_report/${tokenOwner}`}
                    to={`https://opensea.io/${tokenOwner}`}
                    target="_blank"
                  >
                    {Object.keys(ownerWalletData || {}).length > 0
                      ? getWalletProfileName(ownerWalletData || {})
                      : trimMiddleText(tokenOwner)}
                  </Link>
                  {/* {isOwnedByHovered && (
                    <UserModal isCreator={false} walletAddress={tokenOwner} />
                  )} */}
                </div>
              )}
            </div>
          }

          <div
            className="owner-creator-section"
            style={{ fontSize: isMobile && "1rem" }}
          >
            <div className="owner-creator-row"> Created by </div>
            <div
              className="owner-creator-row d-flex align-items-center justify-content-center"
              onMouseEnter={() => setIsCreatedByHovered(true)}
              onMouseLeave={() => setIsCreatedByHovered(false)}
            >
              <Link
                className="owner-name link-field"
                to={`https://opensea.io/${itemData?.openseaDetails?.creator}`}
                target="_blank"
              >
                {getWalletProfileName(creatorWalletData, true) || "No Name"}
              </Link>
            </div>
          </div>
        </div>
      )}

      {ERC1155TokenOwners && ERC1155TokenOwners?.length > 0 && toggleOwners && (
        <ERC1155OwnersModal
          itemData={itemData}
          setToggleOwners={setToggleOwners}
        />
      )}

      {isModalOpen && (
        <TokenInfoToggleModal
          itemData={itemData}
          tabToggles={tabToggles}
          toggleTab={toggleTab}
          closeModal={closeModal}
          openModal={openModal}
        />
      )}
      {alertPopupMessage && <AlertPopup message={"Link Copied!"} />}
    </div>
  );
};

export default TokenInfo;
