import { combineReducers } from "redux";
import userReducer from "./userReducer";
import headerReducer from "./headerReducer";
import userIdToUserObjMapReducer from "./userIdToUserMapObjSlice";

const rootReducer = combineReducers({
  user: userReducer,
  header: headerReducer,
  userIdToUserObjMap: userIdToUserObjMapReducer,
});

export default rootReducer;
