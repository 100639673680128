import React, { useEffect, useRef, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Search from "./Search";
import userNull from "../../../assets/images/defaultProfileImage.png";
import { fetchMJUserDetails } from "../../../api/mjUserApi";
import { logout } from "../../../redux/reducers/actions/userActions";
import ProfileDropDown from "./ProfileDropDown";
import "../../../styles/componenetStyles/Header.css";
import CloseButton from "../../../Icons/CloseButton";
import ExpandIcon from "../../../assets/images/VectorExpandIcon.png";
import ExpandIconWhite from "../../../assets/images/VectorExpandIconWhite.png";

import mjBetaLogo from "../../../assets/images/MJ_logo_beta.png";
import useIsMobile from "../../../customHooks/UseIsMobile";
import { getMJUserProfileImage } from "../../../utils/commonUtils/WalletDetailsUtils";
import { alphatrilogyWalletAddress } from "../../../api/backend_domains/backend_api_domains";
import nmgHeaderLogo from "../../../assets/images/nmg-header.jpg";
import atvLogo from "../../../assets/images/atv-logo.png";
import nmgLogo from "../../../assets/images/nmg-logo.png";
import pcnmLogo from "../../../assets/images/pcnm-logo.png";
import { color } from "d3";
import { PiVideoCamera } from "react-icons/pi";

export default function HeaderDesktop({ isPDF }) {
  const walletAddress = useSelector((state) => state.user?.walletAddress);
  const walletName =
    walletAddress === alphatrilogyWalletAddress ? "alphatrilogy" : "niftymeta";

  const alphatrilogy = walletName === "alphatrilogy";
  const navigate = useNavigate();
  const [mjUserInfo, setMJUserInfo] = useState({});
  const [userInfo, setUserInfo] = useState({});
  const [showProfileDropdown, setShowProfileDropdown] = useState(false);
  const userState = useSelector((state) => state.user);
  const [loggedIn, setLoggedIn] = useState(userState.loggedIn);
  const dispatch = useDispatch();

  function handleProfileDropdownButtonClick() {
    setShowProfileDropdown((prevState) => !prevState);
  }

  function toggleProfileDropdown() {
    setShowProfileDropdown(false);
  }

  function handleLogOutButtonClick() {
    dispatch(logout());
    navigate("/");
  }

  function handleLogoClick() {
    navigate("/");
  }

  function handleWalletReportClick() {
    if (!loggedIn) navigate("/login");
    else {
      fetchMJUserDetails(userState?.userInfo?.userId)
        .then((data) => {
          if (
            !data.walletAddressList ||
            (data?.walletAddressList || []).length === 0
          )
            alert(
              "Add Wallet Address in your Profile to Open Your Wallet Report Page"
            );
          else {
            const mainWalletAddress = data.walletAddressList[0];
            navigate(`/wallet_report/${mainWalletAddress}`);
          }
        })
        .catch((e) => {});
    }
  }

  const toggleButtonRef = useRef(null);
  const isMobile = useIsMobile();

  useEffect(() => {
    fetchMJUserDetails(userState?.userInfo?.userId)
      .then((mjUserInfo_) => {
        setMJUserInfo(mjUserInfo_);
      })
      .catch((e) => {});
  }, []);

  useEffect(() => {
    setLoggedIn(userState.loggedIn);
    setUserInfo(userState.userInfo);
  }, [userState]);

  return (
    <React.Fragment>
      {!isMobile || isPDF ? (
        <div
          className={isPDF ? "nav-bar-pdf" : "nav-bar"}
          style={{
            ...(alphatrilogy
              ? { backgroundColor: "rgba(255, 255, 255, 0.78)" }
              : {
                  backgroundImage: `url(${nmgHeaderLogo})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat",
                  backgroundBlendMode: "overlay",
                  backgroundColor: "rgba(255, 255, 255, 0.10)",
                }),
          }}
        >
          <button className="logo-button" style={{ marginLeft: "5%" }}>
            <img
              src={alphatrilogy ? atvLogo : pcnmLogo}
              className="header-MJ-title"
              onClick={handleLogoClick}
              alt="logoTitle"
            />
          </button>
          {/* <Search isPDF={isPDF} /> */}
          <div className="header-user-info">
            {/* {!loggedIn && (
              <div className="header-signin">
                <Link
                  style={{ textDecoration: "none" }}
                  className="header-login-button header-login-button-animation"
                  to={"/login"}
                >
                  Log In
                </Link>
              </div>
            )} */}
            {/* {loggedIn && (
              <div className="header-loggedin">
                <div className="header-user">
                  <Link to={"/profile"} title="Profile">
                    <img
                      src={getMJUserProfileImage(mjUserInfo) || userNull}
                      alt="userProfileImage"
                      style={{
                        height: "3vw",
                        width: "3vw",
                        borderRadius: "50%",
                      }}
                    ></img>
                  </Link>

                  <div className="header-user-profile-name">
                    <div
                      style={{
                        marginRight: "0.6vw",
                        fontWeight: "600",
                        ...(alphatrilogy
                          ? { color: "#183B56" }
                          : { color: "#fff" }),
                      }}
                    >
                      Hello
                    </div>
                    <div className="header-profile-name">
                      {userInfo?.profileName || "User"}
                    </div>
                  </div>
                </div>
                <div className="header-dropdown">
                  <button
                    ref={toggleButtonRef}
                    onClick={handleProfileDropdownButtonClick}
                    className="header-profile-dropdown"
                  >
                    <div>
                      {!showProfileDropdown ? (
                        <img
                          src={alphatrilogy ? ExpandIcon : ExpandIconWhite}
                          className="header-dropdown-icon"
                        />
                      ) : (
                        <CloseButton
                          className="header-dropdown-icon"
                          style={{
                            marginRight: "",
                            ...(alphatrilogy
                              ? { color: "#183B56" }
                              : { color: "#fff" }),
                          }}
                        />
                      )}
                    </div>
                  </button>
                </div>
                {showProfileDropdown && (
                  <ProfileDropDown
                    handleLogOutButtonClick={handleLogOutButtonClick}
                    toggleProfileDropdown={toggleProfileDropdown}
                    loggedIn={loggedIn}
                    toggleButtonRef={toggleButtonRef}
                  />
                )}
              </div>
            )} */}
          </div>
        </div>
      ) : (
        <>
          <div
            className={isPDF ? "nav-bar-pdf-mobile" : "nav-bar-mobile"}
            style={{
              ...(alphatrilogy
                ? { backgroundColor: "rgba(255, 255, 255, 0.78)" }
                : {
                    backgroundImage: `url(${nmgHeaderLogo})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                    backgroundBlendMode: "overlay",
                    backgroundColor: "rgba(255, 255, 255, 0.10)",
                  }),
            }}
          >
            <div className="header-block-mobile">
              <button className="logo-button">
                <div className="header-logo">
                  <img
                    src={alphatrilogy ? atvLogo : pcnmLogo}
                    className="header-MJ-title-mobile"
                    onClick={handleLogoClick}
                    alt="logoTitle"
                  />
                </div>
              </button>
              <div>
                {loggedIn && (
                  <div className="header-loggedin">
                    <div className="header-space-mobile"></div>
                  </div>
                )}
              </div>
              <div
                ref={toggleButtonRef}
                onClick={handleProfileDropdownButtonClick}
                className="h-100 d-flex justify-content-center align-items-center"
              >
                {/* <div>
                  {!showProfileDropdown ? (
                    <img
                      className="header-dropdown-icon-mobile"
                      src={alphatrilogy ? ExpandIcon : ExpandIconWhite}
                    />
                  ) : (
                    <span className="header-dropdown-icon-mobile">
                      <CloseButton color="#183B56" />
                    </span>
                  )}
                </div> */}

                {/* <img
                    className="header-dropdown-icon-mobile"
                    src={ExpandIcon}
                  /> */}
              </div>
            </div>
          </div>
          {showProfileDropdown && (
            <ProfileDropDown
              handleLogOutButtonClick={handleLogOutButtonClick}
              toggleProfileDropdown={toggleProfileDropdown}
              loggedIn={loggedIn}
              toggleButtonRef={toggleButtonRef}
            />
          )}
        </>
      )}
    </React.Fragment>
  );
}
