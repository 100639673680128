import React, { useEffect, useRef } from "react";
import "../../../styles/itemPageStyles/ItemPage.css";
import RemoveButton from "../../../Icons/CollectionPageIcons/RemoveButton";
import { getTokenRankUI } from "../../../utils/commonUtils/TokenUtils";

const TokenInfoToggleModal = ({
  tabToggles,
  toggleTab,
  closeModal,
  itemData,
  openModal,
}) => {
  const modalRef = useRef(null);

  useEffect(() => {
    const handleOutsideTap = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        closeModal();
      }
    };

    if (openModal) {
      document.addEventListener("mousedown", handleOutsideTap);
    }

    return () => {
      document.removeEventListener("mousedown", handleOutsideTap);
    };
  }, [openModal]);
  return (
    <div className="modal-overlay">
      <div className="modal-component" ref={modalRef}>
        <div className="modal-header">
          <div className="modal-header-title">Toggle Tabs</div>
          <button className="close-button" onClick={closeModal}>
            <RemoveButton color={"black"} />
          </button>
        </div>

        <div className="modal-row">
          <label className="modal-row-heading">Collection Name</label>
          <input
            type="checkbox"
            class="pill-toggle"
            id="traitToggle0"
            checked={tabToggles[0]}
            onChange={() => toggleTab(0)}
          />
          <label class="pill-label" for="traitToggle0"></label>
        </div>
        <div class="modal-row">
          <label class="modal-row-heading">Token Name</label>
          <input
            type="checkbox"
            class="pill-toggle"
            id="traitToggle1"
            checked={tabToggles[1]}
            onChange={() => toggleTab(1)}
          />
          <label class="pill-label" for="traitToggle1"></label>
        </div>

        {getTokenRankUI(itemData) !== "UnRanked (No Traits)" && (
          <div className="modal-row">
            <label className="modal-row-heading">Rarity Ranking</label>
            <input
              type="checkbox"
              class="pill-toggle"
              id="traitToggle2"
              checked={tabToggles[2]}
              onChange={() => toggleTab(2)}
            />
            <label class="pill-label" for="traitToggle2"></label>
          </div>
        )}
        <div className="modal-row">
          <label className="modal-row-heading">Owner and Creator Details</label>
          <input
            type="checkbox"
            class="pill-toggle"
            id="traitToggle3"
            checked={tabToggles[3]}
            onChange={() => toggleTab(3)}
          />
          <label class="pill-label" for="traitToggle3"></label>
        </div>
      </div>
    </div>
  );
};

export default TokenInfoToggleModal;
