import React from "react";

const SmallArrowIcon = () => {
  return (
    <div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="9"
        height="5"
        viewBox="0 0 9 5"
        fill="none"
      >
        <path
          d="M8.20111 0.232761C8.05114 0.0836789 7.84827 0 7.6368 0C7.42534 0 7.22247 0.0836789 7.0725 0.232761L4.19893 3.06631L1.36538 0.232761C1.21541 0.0836789 1.01254 0 0.801076 0C0.589612 0 0.38674 0.0836789 0.236768 0.232761C0.161744 0.307172 0.102196 0.395701 0.0615589 0.493242C0.0209218 0.590782 0 0.695404 0 0.801071C0 0.906738 0.0209218 1.01136 0.0615589 1.1089C0.102196 1.20644 0.161744 1.29497 0.236768 1.36938L3.63062 4.76323C3.70503 4.83826 3.79356 4.8978 3.8911 4.93844C3.98864 4.97908 4.09326 5 4.19893 5C4.3046 5 4.40922 4.97908 4.50676 4.93844C4.6043 4.8978 4.69283 4.83826 4.76724 4.76323L8.20111 1.36938C8.27614 1.29497 8.33568 1.20644 8.37632 1.1089C8.41696 1.01136 8.43788 0.906738 8.43788 0.801071C8.43788 0.695404 8.41696 0.590782 8.37632 0.493242C8.33568 0.395701 8.27614 0.307172 8.20111 0.232761Z"
          fill="#183b56"
        />
      </svg>
    </div>
  );
};

export default SmallArrowIcon;
